<template>
  <div class="subscriptions-selector">
    <pill-list
      :items="items"
      :available-items="availableItems"
      :togglable="false"
      :multi-select="true"
      @update="onUpdate"
    ></pill-list>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import MenuItem from "@/interfaces/menu-item";
import PillItem from "@/interfaces/pill-item";
import NewsletterDefinition from "@/model/newsletter-definition";
import { defineComponent, PropType } from "vue";
import PillList from "@/components/pill-list.vue";

export default defineComponent({
  components: {
    PillList,
  },
  props: {
    modelValue: {
      type: Array as PropType<number[]>,
      required: true,
    },
    definitions: {
      type: Array as PropType<NewsletterDefinition[]>,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    items(): PillItem[] {
      return this.modelValue
        .filter((elem, pos, arr) => arr.indexOf(elem) == pos) // uniq
        .map((id) => {
          const definition = _.find(this.definitions, (d) => id === d.id);
          return (
            definition && {
              value: id.toString(),
              name: definition.title,
            }
          );
        })
        .filter(Boolean) as PillItem[];
    },
    availableItems(): MenuItem[] {
      return (
        this.definitions?.map((definition) => {
          return {
            id: definition.id.toString(),
            name: definition.title,
          };
        }) || []
      );
    },
  },
  methods: {
    onUpdate({ items }: { items: PillItem[] }) {
      const value = items.map((item) => parseInt(item.value));
      this.$emit("update:modelValue", value);
    },
  },
});
</script>
