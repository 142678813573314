<template>
  <pill-list
    :class="className"
    :title="title"
    :items="items"
    :available-items="menuItems"
    :radio="radio"
    :draggable="true"
    @update="onUpdateItems($event.items)"
  ></pill-list>
</template>

<script lang="ts">
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import { defineComponent, PropType } from "vue";
import PillList from "./pill-list.vue";
import FilterConfigurationItemMap, {
  filterConfigurationItemMapFromPillItemList,
} from "../model/filter-configuration-item-map";
import PillItem, { pillItemListFromFilterConfigurationItemMap } from "../interfaces/pill-item";
import i18n from "../i18n";
import Actions from "../store/actions";

export default defineComponent({
  components: { PillList },
  props: {
    name: {
      type: String,
      required: true,
    },
    config: {
      type: Object as PropType<FilterConfigurationItemMap>,
      default: null,
    },
    radio: { default: false, type: Boolean },
    availableItems: {
      type: Array as PropType<MenuItem[]>,
      default: null,
    },
  },
  emits: ["update"],
  computed: {
    title(): string {
      return i18n.t("filter.config." + this.name);
    },
    className(): string {
      // Tag for intercom tours
      const name = this.name.replace(/_/g, "-");
      return "intercom-tag-filters-" + name;
    },
    menuItems(): MenuItem[] {
      return this.availableItems || this.$store.getters.getParameters(this.paramKey);
    },
    items(): PillItem[] {
      return pillItemListFromFilterConfigurationItemMap(this.config).map((item) => {
        if (!item.name) {
          const menuItem = this.menuItems?.find((menuItem) => menuItemKey(menuItem) === item.value);
          item.name = menuItem?.name || item.value;
        }
        return item;
      });
    },
    paramKey() {
      if (["metrics", "metrics2", "metrics_x", "metrics_y", "metrics_z"].includes(this.name)) {
        return "metrics";
      } else if (
        [
          "grouping",
          "grouping_x",
          "grouping_y",
          "grouping_stack",
          "column_grouping",
          "top_grouping",
          "total_grouping",
          "dimensions",
        ].includes(this.name)
      ) {
        return "grouping";
      } else if (["sort", "sort_x", "sort_y", "sort_stack"].includes(this.name)) {
        return "sort";
      } else {
        return this.name;
      }
    },
  },
  created() {
    if (!this.menuItems) {
      this.fetchMenuItems();
    }
  },
  methods: {
    onUpdateItems(items: PillItem[]) {
      const config = filterConfigurationItemMapFromPillItemList(items);

      this.$emit("update", config);
    },
    fetchMenuItems() {
      this.$store.dispatch(Actions.fetchParameters(this.paramKey));
    },
  },
});
</script>
