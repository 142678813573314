<template>
  <div class="report-attachment-section">
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.report_attachment.report") }}</label>
      <select :value="section.report" @input="updateReport">
        <option v-for="option in reportOptions" :key="option.id" :value="option.id">{{ option.name }}</option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  emits: ["update-section"],
  computed: {
    reportOptions() {
      return this.$store.state.excelReports.all;
    },
  },
  methods: {
    updateReport(event) {
      const report = Number.parseInt(event.target.value);
      this.$emit("update-section", { ...this.section, report });
    },
  },
});
</script>
