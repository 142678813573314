<template>
  <div class="user-listing">
    <div>
      <div class="table-controls">
        <search-bar v-model="search"></search-bar>
        <div class="flex-row gap-md flex-wrap">
          <button class="btn btn-primary" @click="inviteUser()">
            {{ $t("company_admin.invite_users.invite_user") }}
          </button>
          <button class="btn btn-primary" @click="exportUsers()">
            {{ $t("filters.export_xlsx") }}
          </button>
          <button class="btn btn-primary" @click="importUsers()">
            {{ $t("company_admin.import_users.title") }}
          </button>
        </div>
      </div>
      <user-table :users="users" @resend="resend" @delete="deleteUser" @updated="fetchUsers"></user-table>
      <div v-if="total > perPage" class="centered">
        <uiv-pagination v-model="page" :total-page="Math.ceil(total / perPage)"></uiv-pagination>
      </div>
    </div>
    <user-invite-dialog
      ref="userInviteDialog"
      :teams="teams"
      :definitions="newsletterDefinitions"
      @add="fetchUsers"
    ></user-invite-dialog>
    <user-import-dialog ref="userImportDialog" @add-users="fetchUsers"></user-import-dialog>
  </div>
</template>

<script lang="ts">
import UserCompanyRole from "@/model/user-company-role";
import { defineComponent } from "vue";
import UserTable from "./user-table.vue";
import adminNewsletterDefinitions from "@/api/admin-newsletter-definitions-api";
import adminTeamsApi from "@/api/admin-teams-api";
import adminUsersApi from "@/api/admin-users-api";
import NewsletterDefinition from "@/model/newsletter-definition";
import Team from "@/model/team";
import toastr from "toastr";
import { v4 as uuidv4 } from "uuid";
import { downloadFile } from "../lib/download";
import { getDownloadUrl } from "@/channel/file-download-channel";
import i18n from "@/i18n";
import SearchBar from "@/components/search-bar.vue";
import UserInviteDialog from "./user-invite-dialog.vue";
import UserImportDialog from "./user-import-dialog.vue";
import { hideOverlay, showOverlay } from "@/ui/overlay";

export default defineComponent({
  components: {
    UserTable,
    SearchBar,
    UserInviteDialog,
    UserImportDialog,
  },
  data() {
    const teams: Team[] = null;
    const newsletterDefinitions: NewsletterDefinition[] = null;
    const users: UserCompanyRole[] = null;

    return {
      page: 1,
      perPage: 15,
      search: "",
      total: 0,
      users,
      newsletterDefinitions,
      teams,
    };
  },
  computed: {
    companyId() {
      return window.zoinedContext.companyId;
    },
    params() {
      const params = {
        company_id: this.companyId,
        page: this.page,
        per_page: this.perPage,
      };

      if (this.search.length > 0) {
        params["search"] = this.search;
      }

      return params;
    },
  },
  watch: {
    search: [
      {
        handler: "onSearchChange",
      },
    ],
    params: [{ immediate: true, handler: "fetchUsers" }],
  },
  created() {
    adminNewsletterDefinitions.get({ company_id: this.companyId }).then((newsletterDefinitions) => {
      this.newsletterDefinitions = newsletterDefinitions;
    });
    adminTeamsApi.get({ company_id: this.companyId }).then((teams) => {
      this.teams = teams;
    });
  },
  methods: {
    resend(user) {
      return adminUsersApi.resendInvite({ company_id: this.companyId, id: user.id }).then(() => {
        toastr.success(i18n.t("company_admin.resend_invite.invite_resent"));
      });
    },
    inviteUser() {
      (this.$refs.userInviteDialog as typeof UserInviteDialog).show();
    },
    deleteUser(user) {
      adminUsersApi
        .delete({ company_id: this.companyId, id: user.id })
        .then(() => this.fetchUsers())
        .catch((err) => {
          const message = (err && err.error) || "Failed to delete user.";
          toastr.error(message, { timeOut: 0 });
        });
    },
    async exportUsers() {
      const download_id = uuidv4();

      showOverlay(i18n.t("processing_export_file"));
      try {
        // Listen for export finish
        const url = await getDownloadUrl(download_id, {
          connected: () => {
            // Start export
            const data: any = {
              download_id,
            };
            fetch(`/api/admin/companies/${this.companyId}/users/export`, {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            });
          },
        });

        // Download file for user
        await downloadFile(url);
      } catch {
        (window.Rollbar || console).error("Export failed. Download id: " + download_id);
        toastr.error(i18n.t("errors.export_fail"), { timeOut: 0 });
      } finally {
        hideOverlay();
      }
    },
    importUsers() {
      (this.$refs.userImportDialog as typeof UserImportDialog).show();
    },
    onSearchChange() {
      this.page = 1;
    },
    fetchUsers() {
      adminUsersApi.get(this.params).then((users) => {
        this.users = users;
        this.total = users.total_count;
      });
    },
  },
});
</script>
