<template>
  <ui-button-multi-toggle
    :model-value="modelValue"
    :options="options"
    button-class="btn-xs"
    @update:model-value="emit('update:modelValue', $event)"
  />
</template>

<script lang="ts" setup>
import { useI18n } from "@/composables/i18n";
import UiButtonMultiToggle, { UiButtonMultiToggleOption } from "@/ui/ui-button-multi-toggle.vue";

defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const { t } = useI18n();

const options: UiButtonMultiToggleOption[] = [
  { value: false, label: t("dashboard.show_values.values") },
  { value: true, label: t("dashboard.show_values.percentage") },
];
</script>
