interface MenuItemBase {
  name: string;
  category?: string;
  wildcard?: boolean;
  icon?: string;
  enabled_for_limited?: boolean;
  deletable?: boolean;
}

type MenuItemKey = string | number;

interface MenuItemWithKey extends MenuItemBase {
  key: MenuItemKey;
}

interface MenuItemWithId extends MenuItemBase {
  id: MenuItemKey;
}

type MenuItem = MenuItemWithKey | MenuItemWithId;

const menuItemKey = (item: MenuItem): MenuItemKey => {
  if ("key" in item) {
    return item.key;
  } else if ("id" in item) {
    return item.id;
  }
};

export { menuItemKey };
export default MenuItem;
