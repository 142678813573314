<template>
  <div class="selectors">
    <draggable
      v-model="model"
      class="flex-row align-items-center flex-wrap gap-sm"
      item-key="key"
      @update="onOrderUpdated"
    >
      <template #item="{element: item}">
        <pill-button :key="item.key" :enabled="item.enabled" :removable="false" @toggle="clickItem(item)"
          >{{ item.name || item.label }}<span v-if="item.translatable">&nbsp;&nbsp;</span
          ><i v-if="item.translatable" class="fa fa-edit"></i
        ></pill-button>
      </template>
    </draggable>
    <metric-dialog ref="metricDialog" :type="type" @save="onSave"></metric-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";
import MetricDialog from "./metric-dialog.vue";
import draggable from "vuedraggable";
import pillButton from "@/components/pill-button.vue";

export default defineComponent({
  components: {
    MetricDialog,
    draggable,
    pillButton,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  emits: ["update"],
  data() {
    return {
      model: null,
    };
  },
  watch: {
    items: [{ immediate: true, handler: "onItemsUpdated" }],
  },
  methods: {
    onOrderUpdated() {
      const model = this.model.map((item, index) => {
        return { ...item, order: index + 1 };
      });
      this.$emit("update", model);
    },
    clickItem(item) {
      if (item.translatable === true) {
        this.editTranslations(item);
      } else {
        item.enabled = !item.enabled;
        this.$emit("update", this.model);
      }
    },
    editTranslations(item) {
      const config = {
        invisible: !item.enabled,
        label: item.custom_labels,
        original_label: item.originalLabels,
      };
      (this.$refs.metricDialog as typeof MetricDialog).show({ key: item.key, config });
    },
    onSave({ key, config: { invisible, label } }) {
      const model = this.model.map((item) => {
        return item.key === key ? { ...item, enabled: !invisible, custom_labels: label } : item;
      });
      this.$emit("update", model);
    },
    onItemsUpdated() {
      this.model = _.cloneDeep(this.items);
    },
  },
});
</script>
