<template>
  <pill-list :items="items" :available-items="availableItems" @update="onUpdate"></pill-list>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import pillList from "./pill-list.vue";
import Metric from "@/model/metric";

export default defineComponent({
  components: {
    pillList,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    onlyIds: {
      type: Array,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    allMetrics(): Metric[] {
      return this.$store.getters.getParameters("metrics");
    },
    items() {
      return (
        this.modelValue &&
        this.modelValue.map(({ id, enabled }) => ({
          value: id,
          name: this.allMetrics.find((m) => m.id === id)?.name || id,
          enabled: !!enabled,
        }))
      );
    },
    availableItems() {
      let metrics = this.allMetrics;
      if (metrics && this.onlyIds) {
        metrics = metrics.filter(({ id }) => this.onlyIds.includes(id));
      }
      return metrics;
    },
  },
  methods: {
    onUpdate({ items }) {
      this.$emit(
        "update:modelValue",
        items.map(({ value, enabled }) => ({ id: value, enabled }))
      );
    },
  },
});
</script>
