<template>
  <div
    class="feedback-button flex-row align-items-center justify-content-center rounded-full"
    :class="{ active, 'cursor-pointer': !active }"
    @click="onClick"
  >
    <Icon :icon="icon" class="text-xs" />
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import { computed } from "vue";

const props = defineProps<{
  like?: boolean;
  active?: boolean;
}>();

const emit = defineEmits(["click"]);

const icon = computed(() => {
  const id = props.like ? "thumb-like" : "thumb-dislike";
  const style = props.active ? "filled" : "regular";
  return `fluent:${id}-16-${style}`;
});

const onClick = () => {
  emit("click", !!props.like);
};
</script>

<style scoped lang="scss">
.feedback-button {
  height: 16px;
  width: 16px;

  &:hover:not(.active) {
    background-color: var(--color-interactive-fill-neutral-mid-contrast);
  }
}
</style>
