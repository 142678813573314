<template>
  <div class="ui-toggle-switch" :class="{ active: !!modelValue }" @click="toggle">
    <div v-if="title" class="ui-toggle-switch__title text-xs font-semibold">{{ title }}</div>
    <div class="ui-toggle-switch__button"></div>
    <div class="ui-toggle-switch__bg"></div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue?: boolean;
  title?: string;
}>();

const emit = defineEmits(["update:modelValue"]);

const toggle = () => {
  emit("update:modelValue", !props.modelValue);
};
</script>

<style lang="scss" scoped>
.ui-toggle-switch {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  height: 20px;
  position: relative;
  cursor: pointer;

  &__bg {
    background-color: var(--color-interactive-fill-disabled);
    border-radius: 8px;
    width: 36px;
    height: 16px;
    transition: background-color 0.3s;
  }

  &__button {
    background-color: var(--color-text-primary-inverse);
    box-shadow: var(--elevation-sm);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 16px;
    top: 0;
    transition: right 0.3s;
  }

  &.active {
    .ui-toggle-switch__bg {
      background-color: var(--color-button-primary-active-background);
    }

    .ui-toggle-switch__button {
      right: 0px;
    }
  }

  &:hover {
    .ui-toggle-switch__bg {
      background-color: var(--color-button-primary-active-background);
    }

    &.active {
      .ui-toggle-switch__bg {
        background-color: var(--color-button-primary-active-background);
      }
    }
  }
}
</style>
