<template>
  <div class="flex-row align-items-center gap-lg">
    <div class="title">{{ $t("filter.config.rangefilter_union") }}</div>
    <btn-group class="btn-multiple">
      <btn
        v-model="model"
        class="btn-sm"
        type="primary"
        input-type="radio"
        :input-value="false"
        @click="update(false)"
        >{{ $t("filter.config.and") }}</btn
      >
      <btn v-model="model" class="btn-sm" type="primary" input-type="radio" :input-value="true" @click="update(true)">{{
        $t("filter.config.or")
      }}</btn>
    </btn-group>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Btn, BtnGroup } from "uiv";
export default defineComponent({
  components: {
    Btn,
    BtnGroup,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      model: true,
    };
  },
  watch: {
    modelValue: [
      {
        handler: "setModel",
      },
    ],
  },
  created() {
    this.setModel();
  },
  methods: {
    update(value) {
      this.$emit("update:modelValue", !!value);
    },
    setModel() {
      this.model = !!this.modelValue;
    },
  },
});
</script>

<style lang="scss" scoped>
.btn {
  text-transform: uppercase;
}
</style>
