<template>
  <div class="zoined-report">
    <zoined-report-row
      v-for="(rowComponents, index) in componentRows"
      v-bind="$attrs"
      :key="index"
      :components="rowComponents"
      :detail-component="detailComponent"
      :filter-configuration="filterConfiguration"
      :chart-options="chartOptions"
      :highchart-options="highchartOptions"
      :table-config="tableConfig"
      :custom="custom"
      :dashboard="dashboard"
      :preview="preview"
      :public="public"
      :overrides="overrides"
      :controls-enabled="controlsEnabled"
      @toggle-details="setDetailComponent"
      @component-updated="onComponentUpdated"
      @filter-configuration-updated="$emit('filter-configuration-updated', $event)"
      @chart-options-updated="$emit('chart-options-updated', $event)"
      @table-config-updated="$emit('table-config-updated', $event)"
      @highchart-options-updated="$emit('highchart-options-updated', $event)"
      @excel-export-component="$emit('excel-export-component', $event)"
      @update-overrides="overrides = $event"
      @edit="$emit('edit', $event)"
    ></zoined-report-row>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import zoinedReportRow from "./zoined-report-row.vue";
import HighchartOptions from "@/model/highchart-options";
import Component from "@/model/component";

const getMaxRowSpan = () => (window.innerWidth < 768 ? 3 : 12);

export default {
  components: {
    zoinedReportRow,
  },
  props: {
    components: {
      type: Array,
      required: true,
    },
    filterConfiguration: {
      type: Object,
      required: false,
      default: null,
    },
    chartOptions: {
      type: Object,
      required: false,
      default: null,
    },
    tableConfig: {
      type: Object,
      required: false,
      default: null,
    },
    highchartOptions: {
      type: Object as PropType<HighchartOptions>,
      required: false,
      default: null,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    dashboard: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    public: {
      type: Boolean,
      default: false,
    },
    controlsEnabled: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    "filter-configuration-updated",
    "chart-options-updated",
    "table-config-updated",
    "highchart-options-updated",
    "excel-export-component",
    "edit",
    "components-updated",
  ],
  data() {
    return {
      detailComponent: null,
      maxRowSpan: getMaxRowSpan(),
      overrides: {},
    };
  },
  computed: {
    componentRows() {
      const rows = [];

      let currentRow;
      let rowSpan = 0;
      this.components.forEach((component: Component) => {
        const componentSpan = component.span || 12;
        if (!currentRow || rowSpan + componentSpan > this.maxRowSpan) {
          currentRow = [];
          rowSpan = 0;
          rows.push(currentRow);
        }
        currentRow.push(component);
        rowSpan += componentSpan;
      });

      return rows;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    setDetailComponent(component) {
      this.detailComponent = component == this.detailComponent ? null : component;
    },
    onComponentUpdated({ component, updatedComponent }) {
      const components = this.components.map((c) => (c == component ? updatedComponent : c));
      this.$emit("components-updated", components);
    },
    handleResize() {
      this.maxRowSpan = getMaxRowSpan();
    },
  },
};
</script>
