<template>
  <span class="config-pill-list">
    <pill-list
      :title="title"
      :searchable="searchable"
      :radio="radio"
      :draggable="draggable"
      :items="items"
      :defaults="defaultItems"
      :available-items="availableItems"
      @update="onUpdate($event.items)"
    ></pill-list
  ></span>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import pillList from "./pill-list.vue";
import _ from "lodash";
import MenuItem from "@/interfaces/menu-item";

export default defineComponent({
  components: {
    pillList,
  },
  props: {
    config: { type: Object, default: null },
    defaultConfig: { type: Object, default: null },
    availableItems: { type: Array as PropType<MenuItem[]>, default: null },
    title: { type: String, default: null },
    radio: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update"],
  computed: {
    searchable() {
      return this.availableItems && this.availableItems.length > 10;
    },
    items() {
      return (
        this.config &&
        _.map(this.config, ({ name, value, ...rest }, key) => ({
          ...rest,
          value: key,
          name: name || value,
        }))
      );
    },
    defaultItems() {
      return (
        this.defaultConfig &&
        _.map(this.defaultConfig, ({ name, value, ...rest }, key) => ({
          ...rest,
          value: key,
          name: name || value,
        }))
      );
    },
  },
  methods: {
    onUpdate(items) {
      this.$emit(
        "update",
        items.reduce(
          (result, item) => ({
            ...result,
            [item.value]: item,
          }),
          {}
        )
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.config-pill-list {
  margin-right: 20px;
}
</style>
