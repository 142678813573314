<template>
  <div class="sidenav-new-analytics">
    <sidenav-category
      v-for="category in navigationItems"
      :key="category.name"
      :category="category"
      :force-open="true"
    ></sidenav-category>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SidenavCategory from "./sidenav-category.vue";

export default defineComponent({
  components: { SidenavCategory },
  data() {
    return {
      isLimited: window.zoinedContext.limited,
    };
  },
  computed: {
    navigationItems(): any[] {
      return this.$store.state.navigation.data?.new_analytics || [];
    },
  },
});
</script>
