<template>
  <div class="widget-filters flex-column gap-lg empty-hidden">
    <div class="main-widget-filters flex-row align-items-center flex-wrap gap-lg column-gap-xxl empty-hidden">
      <widget-filter-selector
        v-for="filter in widgetFilters"
        :key="filter"
        :name="filter"
        :config="filterConfiguration.widgets[filter]"
        :radio="isRadio(filter)"
        :available-items="optionsFor(filter)"
        @update="updateWidget(filter, $event)"
      ></widget-filter-selector>
    </div>
    <div class="extra-filters flex-row align-items-center flex-wrap gap-lg column-gap-xxl empty-hidden">
      <component-span-selector
        v-if="isActive('span')"
        :model-value="filterConfiguration.raw_filters.span"
        @update:model-value="updateRawFilters('span', $event)"
      ></component-span-selector>
      <filter-dropdown
        v-if="isActive('variant')"
        :title="$t('table.show')"
        :available-items="optionsFor('variant')"
        :value="filterConfiguration.raw_filters.variant"
        @update="updateRawFilters('variant', $event)"
      ></filter-dropdown>
      <filter-dropdown
        v-if="isActive('limit')"
        :title="$t('newsletter.definition.sections.common.entries_to_show')"
        :available-items="optionsFor('limit')"
        :value="filterConfiguration.raw_filters.limit"
        @update="updateRawFilters('limit', $event)"
      ></filter-dropdown>
      <filter-dropdown
        v-if="isActive('limit_y')"
        :title="$t('newsletter.definition.sections.common.entries_to_show')"
        :available-items="optionsFor('limit_y')"
        :value="filterConfiguration.raw_filters.limit_y"
        @update="updateRawFilters('limit_y', $event)"
      ></filter-dropdown>
      <filter-selector
        v-if="isActive('selected_items')"
        :excludable="false"
        :config="filterConfiguration.widgets.selected_items"
        :filter="optionsFor('selected_items')"
        @update="updateWidget('selected_items', $event)"
      ></filter-selector>
      <filter-dropdown
        v-if="isActive('proportions_set')"
        :title="$t('filter.config.proportions_set')"
        :available-items="optionsFor('proportions_set')"
        :value="filterConfiguration.raw_filters.proportions_set"
        @update="updateRawFilters('proportions_set', $event)"
      ></filter-dropdown>
      <filter-dropdown
        v-if="isActive('trend')"
        :title="$t('filter.config.trend')"
        :available-items="optionsFor('trend')"
        :value="filterConfiguration.raw_filters.trend"
        @update="updateRawFilters('trend', $event)"
      ></filter-dropdown>
      <filter-dropdown
        v-if="isActive('show_values')"
        :title="$t('filter.config.show_values')"
        :available-items="optionsFor('show_values')"
        :value="filterConfiguration.raw_filters.show_values"
        @update="updateRawFilters('show_values', $event)"
      ></filter-dropdown>
      <filter-dropdown
        v-if="isActive('metric_type')"
        :title="$t('filter.config.metric_type')"
        :available-items="optionsFor('metric_type')"
        :value="filterConfiguration.raw_filters.metric_type"
        @update="updateRawFilters('metric_type', $event)"
      ></filter-dropdown>
      <div v-if="isActive('metric_range')" class="metric-range">
        <metricRangeSelector
          :items="filterConfiguration.widgets.metric_range"
          @update="updateWidget('metric_range', $event)"
        ></metricRangeSelector>
        <rangefilterUnionSelector
          v-if="filterConfiguration.widgets.metric_range && filterConfiguration.widgets.metric_range.length >= 2"
          :model-value="filterConfiguration.raw_filters.rangefilter_union"
          @update:model-value="updateRawFilters('rangefilter_union', $event)"
        ></rangefilterUnionSelector>
      </div>
      <likeForLikeSelector
        v-if="isActive('like_for_like')"
        :config="filterConfiguration.widgets.like_for_like"
        @update="updateWidget('like_for_like', $event)"
      ></likeForLikeSelector>
      <zerofillSelector
        v-if="isActive('zerofill')"
        :model-value="filterConfiguration.raw_filters.zerofill"
        @update:model-value="updateRawFilters('zerofill', $event)"
      ></zerofillSelector>
    </div>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import FilterConfiguration from "../model/filter-configuration";
import { defineComponent, PropType } from "vue";
import WidgetFilterSelector from "../components/widget-filter-selector.vue";
import metricRangeSelector from "../components/metric-range-selector.vue";
import rangefilterUnionSelector from "../components/rangefilter-union-selector.vue";
import likeForLikeSelector from "../components/like-for-like-selector.vue";
import zerofillSelector from "../components/zerofill-selector.vue";
import FilterDropdown from "../components/filter-dropdown.vue";
import filterSelector from "../components/filter-selector.vue";
import { flatFilterConfiguration, WIDGET_FILTERS } from "../lib/filter-util";
import ReportConfig from "../model/report-config";
import ReportType, { reportTypeActiveFilters, reportTypeFromReportConfig } from "../model/report-type";
import ComponentSpanSelector from "../components/component-span-selector.vue";

export const widgetFilterSectionFilterTypes = [
  ...WIDGET_FILTERS,
  "span",
  "variant",
  "limit",
  "limit_y",
  "selected_items",
  "proportions_set",
  "trend",
  "show_values",
  "metric_range",
  "like_for_like",
  "zerofill",
];

export const isWidgetFilterSectionEnabled = ({
  filterConfiguration,
  reportConfig,
}: {
  filterConfiguration: FilterConfiguration;
  reportConfig: ReportConfig;
}) => {
  const reportType = reportTypeFromReportConfig(reportConfig);
  return (
    reportType &&
    reportTypeActiveFilters(reportType, flatFilterConfiguration(filterConfiguration)).some((filter) =>
      widgetFilterSectionFilterTypes.includes(filter)
    )
  );
};

export default defineComponent({
  components: {
    WidgetFilterSelector,
    metricRangeSelector,
    rangefilterUnionSelector,
    likeForLikeSelector,
    zerofillSelector,
    FilterDropdown,
    filterSelector,
    ComponentSpanSelector,
  },
  props: {
    filterConfiguration: {
      type: Object as PropType<FilterConfiguration>,
      required: true,
    },
    reportConfig: {
      type: Object as PropType<ReportConfig>,
      required: true,
    },
  },
  emits: ["update"],
  computed: {
    reportType(): ReportType {
      return reportTypeFromReportConfig(this.reportConfig);
    },
    flatFilterConfiguration() {
      return flatFilterConfiguration(this.filterConfiguration);
    },
    activeFilters() {
      return reportTypeActiveFilters(this.reportType, this.flatFilterConfiguration);
    },
    widgetFilters() {
      return this.activeFilters.filter((filter) => WIDGET_FILTERS.includes(filter));
    },
  },
  methods: {
    isActive(filter) {
      return this.activeFilters.includes(filter);
    },
    optionsFor(key) {
      const options = _.get(this.reportType, `filters.${key}.options`);
      return _.isFunction(options) ? options(this.flatFilterConfiguration) : options;
    },
    isRadio(filter) {
      return this.reportType.config.radio_selectors?.includes(filter);
    },
    updateWidget(widget, config) {
      const widgets = {
        ...this.filterConfiguration.widgets,
        [widget]: config,
      };

      this.$emit("update", {
        ...this.filterConfiguration,
        widgets,
      });
    },
    updateRawFilters(key, value) {
      const raw_filters =
        _.isNull(value) || _.isUndefined(value)
          ? _.omit(this.filterConfiguration.raw_filters, key)
          : {
              ...(this.filterConfiguration.raw_filters || {}),
              [key]: value,
            };

      this.$emit("update", {
        ...this.filterConfiguration,
        raw_filters,
      });
    },
  },
});
</script>
