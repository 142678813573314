<template>
  <span>
    <dropdown>
      <template #toggle="toggleProps">
        <div class="btn-group">
          <button class="btn btn-primary" type="button" @click="newNewsletter()">
            {{ $t("newsletter.definition.new_form_title") }}
          </button>
          <button
            v-if="enableTemplates && newsletterTemplates && newsletterTemplates.length > 0"
            class="btn btn-primary"
            type="button"
            @click="toggleProps.toggleDropdown()"
          >
            <span class="caret"></span>
          </button>
        </div>
      </template>
      <template #menu>
        <li>
          <dropdown-item @click="newNewsletter()">{{ $t("newsletter.definition.create_empty") }}</dropdown-item>
          <dropdown-item @click="newNewsletterFromTemplate()">{{
            $t("newsletter.definition.create_from_template")
          }}</dropdown-item>
        </li>
      </template>
    </dropdown>
    <newsletter-definition-from-template-modal
      ref="newsletterDefinitionFromTemplateModal"
      :newsletter-templates="newsletterTemplates"
      @new-from-template="onNewFromTemplate"
    ></newsletter-definition-from-template-modal
  ></span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import dropdown from "../components/dropdown.vue";
import NewsletterDefinitionFromTemplateModal from "./newsletter-definition-from-template-modal.vue";
import newsletterTemplatesApi from "../api/newsletter-templates-api";
import _ from "lodash";
import DropdownItem from "../components/dropdown-item.vue";

export default defineComponent({
  components: {
    dropdown,
    NewsletterDefinitionFromTemplateModal,
    DropdownItem,
  },
  props: {
    enableTemplates: { default: false, type: Boolean },
  },
  emits: ["new-newsletter", "new-newsletter-from-template"],
  data() {
    return {
      newsletterTemplates: null,
    };
  },
  created() {
    this.fetchNewsletterTemplates();
  },
  methods: {
    newNewsletter() {
      this.$emit("new-newsletter");
    },
    onNewFromTemplate(id) {
      this.$emit("new-newsletter-from-template", id);
    },
    newNewsletterFromTemplate() {
      (this.$refs.newsletterDefinitionFromTemplateModal as typeof NewsletterDefinitionFromTemplateModal).show();
    },
    fetchNewsletterTemplates() {
      newsletterTemplatesApi.getAll().then(
        (templates) =>
          (this.newsletterTemplates = templates.map((template) => {
            const localizedTitle = _.get(template, `merged_translations_hash.title.${window.zoinedContext.locale}`);
            if (localizedTitle) {
              template = { ...template, title: localizedTitle };
            }
            return template;
          }))
      );
    },
  },
});
</script>
