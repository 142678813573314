<template>
  <div v-if="teamMemberships" class="team-membership-table">
    <table v-if="teamMemberships.length > 0">
      <thead>
        <tr>
          <th>{{ $t("attributes.name") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in teamMemberships" :key="item.id">
          <td>{{ teamName(item.team_id) }}</td>
          <td>
            <div class="checkbox">
              <label>
                <input type="checkbox" :checked="item.role == 'admin'" @change="toggleItemAdmin(item)" />{{
                  $t("roles.admin")
                }}
              </label>
            </div>
          </td>
          <td>
            <a @click="removeItem(item)"><i class="fa fa-trash"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="availableTeams.length > 0">
      <uiv-dropdown>
        <button class="btn btn-primary dropdown-toggle" type="button">{{ $t("actions.add") }}</button>
        <template #dropdown>
          <li v-for="team in availableTeams" :key="team.id">
            <a href="#" @click="addTeam(team)">{{ team.name }}</a>
          </li>
        </template>
      </uiv-dropdown>
    </div>
  </div>
</template>

<script lang="ts">
import Team from "@/model/team";
import TeamMembership from "@/model/team-membership";
import { defineComponent, PropType } from "vue";
export default defineComponent({
  props: {
    teamMemberships: {
      type: Array as PropType<TeamMembership[]>,
      required: true,
    },
    teams: {
      type: Array as PropType<Team[]>,
      required: true,
    },
  },
  emits: ["update"],
  computed: {
    availableTeams() {
      const memberTeamIds = this.teamMemberships.map(({ team_id }) => team_id);

      return this.teams.filter(({ id }) => !memberTeamIds.includes(id));
    },
  },
  methods: {
    teamName(teamId) {
      return this.teams.find(({ id }) => id === teamId)?.name;
    },
    toggleItemAdmin(item) {
      const items = this.teamMemberships.map((it) =>
        it === item ? { ...it, role: it.role === "admin" ? "member" : "admin" } : it
      );
      this.$emit("update", items);
    },
    removeItem(item) {
      const items = this.teamMemberships.filter((it) => it !== item);
      this.$emit("update", items);
    },
    addTeam(team) {
      const items = [...this.teamMemberships, { team_id: team.id, role: "member" }];
      this.$emit("update", items);
    },
  },
});
</script>

<style lang="scss" scoped>
table {
  width: 100%;

  td,
  th {
    &:first-child {
      width: 50%;
    }

    &:not(:first-child) {
      width: 25%;
    }
  }
}

.dropdown-content {
  margin: 0 10px;
}
</style>
