<template>
  <div class="limit-selector">
    <div class="input-group input-group-sm">
      <input class="form-control" :value="value" @input="updateLimit(($event.target as HTMLInputElement).value)" />
      <div class="input-group-btn">
        <dropdown v-model="dropdownOpen" :append-to-body="appendToBody" :dropup="dropup" :menu-right="menuRight">
          <template #default>
            <btn class="dropdown-toggle" data-role="trigger" size="sm">
              <svg-icon :name="dropdownOpen ? 'chevron-up' : 'chevron-down'"></svg-icon>
            </btn>
          </template>
          <template #dropdown>
            <li v-for="option in options" :key="option" class="dropdown-item">
              <a role="button" @click="updateLimit(option)">{{ option }}</a>
            </li>
          </template>
        </dropdown>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { Btn, Dropdown } from "uiv";
import SvgIcon from "../components/svg-icon.vue";
import _ from "lodash";

const defaultOptions = [5, 10, 20, 50, 100];

export default defineComponent({
  components: { Btn, Dropdown, SvgIcon },
  props: {
    limit: { type: Number, required: true },
    limitOptions: {
      type: Array,
      default: null,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    dropup: {
      type: Boolean,
      default: false,
    },
    menuRight: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update"],
  data() {
    return {
      dropdownOpen: false,
    };
  },
  computed: {
    value() {
      return this.limit;
    },
    options() {
      return this.limitOptions || defaultOptions;
    },
  },
  created() {
    // Debounce limit change for input typing
    this.updateLimit = _.debounce(this.updateLimit, 300);
  },
  methods: {
    updateLimit(value) {
      value = Number(value);
      if (value) {
        this.$emit("update", value);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.limit-selector {
  display: inline-block;
  width: 80px;

  .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.dropdown-item a {
  &:hover {
    background-color: #cceeff;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.component.full-screen {
  .limit-selector {
    button.btn.dropdown-toggle {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
*:not(.component.full-screen) {
  .limit-selector {
    width: 100px;

    .input-group {
      .form-control {
        box-shadow: none;
        border: none;
        background-color: var(--color-interactive-bg-emphasis-1);
        color: var(--color-text-variant);
        padding-left: 20px;
        border-top-left-radius: var(--border-radius-lg);
        border-bottom-left-radius: var(--border-radius-lg);

        &:hover {
          background-color: var(--color-interactive-bg-emphasis-2);
          color: var(--color-text-primary-inverse);
        }
      }

      .input-group-btn {
        &:last-child {
          .btn {
            background-color: var(--color-interactive-bg-emphasis-1);
            color: var(--color-text-variant);
            padding-right: 20px;

            &:hover {
              background-color: var(--color-interactive-bg-emphasis-2);
              color: var(--color-text-primary-inverse);
            }
          }
        }
      }
    }
  }
}
</style>
