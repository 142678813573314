<template>
  <div class="audit-log">
    <div>
      <div class="table-controls">
        <search-bar v-model="search"></search-bar>
      </div>
      <div>
        <spinner v-if="!audits"></spinner>
        <table class="table table-hover">
          <thead>
            <tr>
              <th>{{ $t("audit_log.description") }}</th>
              <th>{{ $t("audit_log.time") }}</th>
              <th>{{ $t("audit_log.initiator") }}</th>
            </tr>
          </thead>
          <tbody v-if="audits">
            <tr v-if="audits.length == 0">
              <td colspan="4">{{ $t("table.no_results") }}</td>
            </tr>
            <tr v-for="audit in audits" :key="audit.id">
              <td>{{ audit.description }}</td>
              <td>{{ dateTime(audit.time) }}</td>
              <td>{{ audit.initiator.email }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="total > perPage" class="centered">
          <uiv-pagination v-model="page" :total-page="Math.ceil(total / perPage)"></uiv-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SearchBar from "@/components/search-bar.vue";
import spinner from "@/components/spinner.vue";
import adminAuditsApi from "@/api/admin-audits-api";
import { dateTime } from "@/lib/vue/filters/date";

export default defineComponent({
  components: {
    SearchBar,
    spinner,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      search: "",
      total: 0,
      audits: null,
    };
  },
  computed: {
    companyId() {
      return window.zoinedContext.companyId;
    },
    params() {
      const params = {
        page: this.page,
        per_page: this.perPage,
        company_id: this.companyId,
      };

      if (this.search) {
        params["search"] = this.search;
      }

      return params;
    },
  },
  watch: {
    params: [{ immediate: true, handler: "fetchAudits" }],
    search: [
      {
        handler: "onSearchChange",
      },
    ],
  },
  methods: {
    dateTime(s) {
      return dateTime(s);
    },
    async fetchAudits() {
      return adminAuditsApi.get(this.params).then((audits) => {
        console.log(audits.total_count);
        this.audits = audits;
        this.total = audits.total_count;
      });
    },
    onSearchChange() {
      this.page = 1;
    },
  },
});
</script>
