<template>
  <div class="dashboard-components-grid-editor">
    <draggable
      v-model="components"
      class="component-row"
      draggable=".component-wrapper"
      handle=".handle"
      item-key="id"
      @change="updateComponents(components)"
    >
      <template #item="{element: component, index}">
        <div
          class="component-wrapper"
          :class="{ half: component.span == 6, third: component.span == 4, quarter: component.span == 3 }"
        >
          <zoined-report-component
            class="component-item"
            :snippet="component"
            :component="component"
            :filter-configuration="flatFilterConfiguration"
            :editor="true"
            :chart-options="effectiveChartOptions(component)"
            :show-filter-configuration="true"
            :custom="true"
            :dashboard="true"
            :editable="true"
            :duplicatable="true"
            :removable="true"
            @component-updated="onComponentUpdated(component, $event)"
            @edit="showAddComponentModal(index)"
            @remove="removeComponent(index)"
            @duplicate="duplicateComponent(index)"
          ></zoined-report-component>
        </div>
      </template>
      <template #footer>
        <div class="add-component-button half">
          <div id="new-component-button" class="panel panel-default">
            <div class="button intercom-tag-custom-dashboard-add-component" @click="showAddComponentModal()">
              <div class="icon"><i class="fa fa-plus"></i></div>
              <div class="title">{{ $t("dashboard_custom.report_editor.add_component") }}</div>
            </div>
            <div class="button intercom-tag-custom-dashboard-embed-report" @click="showEmbedReportModal()">
              <div class="icon"><i class="fa fa-download"> </i></div>
              <div class="title">{{ $t("dashboard_custom.report_editor.embed_report") }}</div>
            </div>
          </div>
        </div>
      </template>
    </draggable>
    <add-component-modal
      ref="addComponentModal"
      :add-component="addComponent"
      :set-component="setComponent"
      :filter-configuration="flatFilterConfiguration"
      :shared="shared"
    ></add-component-modal>
    <embed-report-modal ref="embedReportModal" @embed-components="embedComponents"></embed-report-modal>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import { flatFilterConfiguration } from "../lib/filter-util";
import { defineComponent, PropType } from "vue";
import draggable from "vuedraggable";
import AddComponentModal from "./add-component-modal.vue";
import EmbedReportModal from "./embed-report-modal.vue";
import zoinedReportComponent from "../analytics/zoined-report-component.vue";
import ReportConfig from "../model/report-config";
import FilterConfiguration from "../model/filter-configuration";
import { v4 as uuidv4 } from "uuid";
import ChartOptions from "@/model/chart-options";
import Component from "@/model/component";

export default defineComponent({
  components: {
    draggable,
    AddComponentModal,
    EmbedReportModal,
    zoinedReportComponent,
  },
  props: {
    filterConfiguration: {
      type: Object as PropType<FilterConfiguration>,
      required: true,
    },
    config: {
      type: Object as PropType<ReportConfig>,
      required: true,
    },
    chartOptions: {
      type: Object as PropType<ChartOptions>,
      default: () => ({}),
    },
    shared: { default: false, type: Boolean },
  },
  emits: ["update"],
  data() {
    return {
      components: null,
    };
  },
  computed: {
    flatFilterConfiguration() {
      return flatFilterConfiguration(this.filterConfiguration);
    },
  },
  watch: {
    config: [
      {
        handler: "onConfigUpdated",
      },
    ],
  },
  created() {
    this.onConfigUpdated();
  },
  methods: {
    effectiveChartOptions(component) {
      return {
        ...this.chartOptions,
        ...component.chartOptions,
      };
    },
    showAddComponentModal(index = -1) {
      index = _.toNumber(index);
      if (index > -1) {
        (this.$refs.addComponentModal as typeof AddComponentModal).showAddComponentModal(this.components, index);
      } else {
        (this.$refs.addComponentModal as typeof AddComponentModal).showAddComponentModal();
      }
    },
    showEmbedReportModal() {
      (this.$refs.embedReportModal as typeof EmbedReportModal).show();
    },
    embedComponents(components) {
      this.updateComponents([...this.components, ...components]);
    },
    removeComponent(index) {
      this.components.splice(index, 1);
      this.updateComponents(this.components);
    },
    duplicateComponent(index) {
      const duplicate = _.cloneDeep(this.components[index]);
      duplicate.id = uuidv4();
      this.components.splice(index + 1, 0, duplicate);
      this.updateComponents(this.components);
    },
    addComponent(component) {
      component.id = component.id || uuidv4();
      const components = [...this.components, component];
      this.updateComponents(components);
    },
    setComponent(component, index) {
      component.id = component.id || uuidv4();
      this.components.splice(index, 1, component);
      this.updateComponents(this.components);
    },
    onComponentUpdated(component, newComponent) {
      newComponent.id = newComponent.id || uuidv4();
      const index = this.components.indexOf(component);
      this.setComponent(newComponent, index);
    },
    updateComponents(components: Component[]) {
      this.$emit("update", {
        ...this.config,
        components,
      });
    },
    onConfigUpdated() {
      this.components = this.config.components.map((c) => {
        return {
          ...c,
          id: c.id || uuidv4(),
        };
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.panel {
  border-style: dashed !important;
}

#new-component-button {
  height: 250px;
  display: flex;
  color: #555;

  .button {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      color: #81e4ff;
    }

    .icon {
      font-size: 120px;
      line-height: 1;
    }

    &:first-child {
      border-right: 1px dashed #ddd;
    }
  }
}

.component-row {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));

  grid-column-gap: 30px;
  grid-row-gap: 30px;

  > * {
    grid-column-end: span 12;

    &.half {
      grid-column-end: span 6;
    }

    &.third {
      grid-column-end: span 4;
    }

    &.quarter {
      grid-column-end: span 3;
    }
  }
}
</style>
