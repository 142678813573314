<template>
  <uiv-modal v-if="role" v-model="open" class="user-dialog" :title="title" :backdrop="false">
    <spinner v-if="saving"></spinner>
    <div class="row">
      <div class="col-md-12">
        <h4>{{ $t("attributes.name") }}</h4>
      </div>
      <div class="col-md-4">
        <label for="edit_first_name">{{ $t("activerecord.attributes.user.first_name") }}</label>
        <input
          id="edit_first_name"
          v-model="role.first_name"
          class="form-control"
          type="text"
          :placeholder="$t('activerecord.attributes.user.first_name')"
        />
      </div>
      <div class="col-md-6">
        <label class="control-label" for="edit_last_name">{{ $t("activerecord.attributes.user.last_name") }}</label>
        <input
          id="edit_last_name"
          v-model="role.last_name"
          class="form-control"
          type="text"
          :placeholder="$t('activerecord.attributes.user.last_name')"
        />
      </div>
      <div class="col-md-6">
        <div class="mt-md">
          <label class="control-label" for="edit_email">{{ $t("activerecord.attributes.user.email") }}</label>
          <input
            id="edit_email"
            v-model="role.email"
            class="form-control"
            type="email"
            :placeholder="$t('activerecord.attributes.user.email')"
          />
        </div>
      </div>
      <div v-if="role.unconfirmed_email" class="col-md-12">
        {{ $t("activerecord.attributes.user.unconfirmed_email") }}: {{ role.unconfirmed_email }}
      </div>
      <div class="col-md-6">
        <div class="mt-md">
          <label class="control-label" for="edit_title">{{ $t("activerecord.attributes.user.title") }}</label>
          <user-title-selector id="edit_title" v-model="role.title"></user-title-selector>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>
          {{ $t("profile.user_preferences")
          }}<span v-uiv-tooltip.right="userPreferencesTooltip" class="info-bubble"></span>
        </h4>
      </div>
      <div class="col-md-3">
        <label for="edit_role">{{ $t("activerecord.attributes.user.role") }}</label>
        <select id="edit_role" v-model="role.role" class="form-control">
          <option v-for="roleOption in roleOptions" :key="roleOption.id" :value="roleOption.id">
            {{ roleOption.name }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="edit_language">{{ $t("activerecord.attributes.user.language") }}</label>
        <select id="edit_language" v-model="role.language" class="form-control">
          <option v-for="locale in localeOptions" :key="locale.id" :value="locale.id">{{ locale.name }}</option>
        </select>
      </div>
      <div class="col-md-4">
        <label for="edit_newsletter_currency">{{ $t("activerecord.attributes.user.newsletter_currency") }}</label>
        <select id="edit_newsletter_currency" v-model="role.newsletter_currency" class="form-control">
          <option v-for="currency in currencyOptions" :key="currency.currency" :value="currency.currency">
            {{ currency.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>{{ $t("filter.config.allowFileExport") }}</h4>
      </div>
      <div class="form-group">
        <div class="col-sm-9">
          <div class="allow-options">
            <div class="btn-group btn-multiple" data-toggle="buttons">
              <label class="btn btn-primary" :class="{ active: !role.deny_export }" @click="updateExport(false)">{{
                $t("permissions.allow")
              }}</label>
              <label class="btn btn-primary" :class="{ active: role.deny_export }" @click="updateExport(true)">{{
                $t("permissions.deny")
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="teams.length > 0" class="row">
      <div class="col-md-12">
        <h4>
          {{ $t("company_admin.teams.title") }}<span v-uiv-tooltip.right="teamsTooltip" class="info-bubble"></span>
        </h4>
        <teamMembershipTable
          :team-memberships="role.team_memberships"
          :teams="teams"
          @update="updateTeamMemberships"
        ></teamMembershipTable>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>
          {{ $t("profile.user_permissions") }}<span v-uiv-tooltip.right="permissionsTooltip" class="info-bubble"></span>
        </h4>
        <permissions-editor
          :permissions="role.permissions"
          :effective-permissions="role.effective_permissions"
          @update="updatePermissions"
        ></permissions-editor>
      </div>
    </div>
    <div v-if="companyDashboards" class="row">
      <div class="col-md-12">
        <h4>
          {{ $t("dashboard_custom.default_dashboard")
          }}<span v-uiv-tooltip="defaultDashboardTooltip" class="info-bubble"></span>
        </h4>
        <select id="edit_default_dashboard" v-model="role.default_dashboard_id" class="form-control">
          <option :value="null">{{ $t("dashboard.system_dashboard") }}</option>
          <option v-for="dashboard in companyDashboards" :key="dashboard.report.id" :value="dashboard.report.id">
            {{ dashboard.name }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="restrictedDashboards.length > 0" class="row">
      <div class="col-md-12">
        <h4>
          {{ $t("profile.allowed_restricted_dashboards")
          }}<span v-uiv-tooltip.right="allowedDashboardsTooltip" class="info-bubble"></span>
        </h4>
        <div class="permissions-row">
          <allowed-reports
            :title="$t('dashboard_custom.dashboards')"
            :restricted-reports="restrictedDashboards"
            :allowed-reports="role.permitted_dashboards"
            @change="updatePermittedDashboards"
          ></allowed-reports>
        </div>
      </div>
    </div>
    <div v-if="restrictedReports.length > 0" class="row">
      <div class="col-md-12">
        <h4>
          {{ $t("profile.allowed_restricted_reports")
          }}<span v-uiv-tooltip.right="allowedReportsTooltip" class="info-bubble"></span>
        </h4>
        <div class="permissions-row">
          <allowed-reports
            :title="$t('analytics.reports')"
            :restricted-reports="restrictedReports"
            :allowed-reports="role.permitted_reports"
            @change="updatePermittedReports"
          ></allowed-reports>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>
          {{ $t("profile.default_params")
          }}<span v-uiv-tooltip.right="defaultParametersTooltip" class="info-bubble"></span>
        </h4>
        <defaultParameterSelector
          v-model="role.default_parameters"
          :permissions="role.effective_permissions"
        ></defaultParameterSelector>
      </div>
    </div>
    <div v-if="definitions && definitions.length > 0" class="row">
      <div class="col-md-12">
        <h4>
          {{ $t("newsletter.subscription.list_title")
          }}<span v-uiv-tooltip.right="newsletterSubscriptionsTooltip" class="info-bubble"></span>
        </h4>
        <subscriptions-selector
          ref="subscriptionsSelector"
          v-model="role.definition_ids"
          :definitions="definitions"
        ></subscriptions-selector>
        <p v-if="role.team_newsletter_subscriptions.length > 0" style="padding-left: 5px">
          {{ $t("profile.team_subscriptions") }}: {{ teamNewsletterSubscriptionsText }}
        </p>
      </div>
    </div>
    <template #footer>
      <confirm-button class="pull-left" :button-title="$t('actions.delete')" @confirm="deleteUser"></confirm-button>
      <button class="btn" @click="cancel()">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" type="submit" :disabled="saving" @click="ok()">{{ $t("actions.save") }}</button>
    </template>
  </uiv-modal>
</template>

<script lang="ts">
import i18n from "../i18n";
import { defineComponent, PropType } from "vue";
import Actions from "@/store/actions";
import adminCompaniesApi from "../api/admin-companies-api";
import adminUsersApi from "../api/admin-users-api";
import DashboardsApiService from "../api/dashboards-api-service";
import UserCompanyRole from "../model/user-company-role";
import { normalizeConfig } from "@/lib/filter-util";
import _ from "lodash";
import { calculateEffectivePermissions, defaultPermissions } from "@/permissions/utils";
import Team from "@/model/team";
import NewsletterDefinition from "@/model/newsletter-definition";
import TeamMembershipTable from "./team-membership-table.vue";
import ConfirmButton from "@/components/confirm-button.vue";
import AllowedReports from "@/permissions/allowed_reports.vue";
import PermissionsEditor from "@/permissions/permissions-editor.vue";
import DefaultParameterSelector from "./default-parameter-selector.vue";
import SubscriptionsSelector from "./subscriptions-selector.vue";
import Spinner from "@/components/spinner.vue";
import UserTitleSelector from "@/components/user-title-selector.vue";

export default defineComponent({
  components: {
    TeamMembershipTable,
    ConfirmButton,
    AllowedReports,
    PermissionsEditor,
    DefaultParameterSelector,
    SubscriptionsSelector,
    Spinner,
    UserTitleSelector,
  },
  props: {
    teams: {
      type: Array as PropType<Team[]>,
      default: null,
    },
    definitions: {
      type: Array as PropType<NewsletterDefinition[]>,
      default: null,
    },
  },
  emits: ["updated", "delete"],
  data() {
    const originalRole: UserCompanyRole = null;
    const role: UserCompanyRole = null;
    const companyDashboards = null;
    const restrictedReports = null;
    const restrictedDashboards = null;

    return {
      open: false,
      restrictedDashboards,
      restrictedReports,
      companyDashboards,
      role,
      originalRole,
      saving: false,
    };
  },
  computed: {
    title() {
      return `${this.originalRole.first_name} ${this.originalRole.last_name} - ${this.originalRole.email}`;
    },
    userPreferencesTooltip() {
      return i18n.t("setup.users.tooltips.user_preferences");
    },
    permissionsTooltip() {
      return i18n.t("setup.users.tooltips.permissions");
    },
    defaultDashboardTooltip() {
      return i18n.t("setup.users.tooltips.default_dashboard");
    },
    allowedDashboardsTooltip() {
      return i18n.t("setup.users.tooltips.allowed_dashboards");
    },
    allowedReportsTooltip() {
      return i18n.t("setup.users.tooltips.allowed_reports");
    },
    defaultParametersTooltip() {
      return i18n.t("setup.users.tooltips.default_parameters");
    },
    newsletterSubscriptionsTooltip() {
      return i18n.t("setup.users.tooltips.newsletter_subscriptions");
    },
    teamsTooltip() {
      return i18n.t("setup.users.tooltips.teams");
    },
    companyId() {
      return window.zoinedContext.companyId;
    },
    company() {
      return window.zoinedContext.company;
    },
    currencyOptions() {
      return [
        { currency: "", label: i18n.t("activerecord.attributes.company.company_currency") },
        ...window.zoinedContext.currencies.map(({ currency, symbol }) => ({
          currency,
          label: `${currency} (${symbol})`,
        })),
      ];
    },
    roleOptions() {
      return ["email", "user", "admin"].map((id) => {
        return { id, name: i18n.t(`roles.${id}`) };
      });
    },
    localeOptions() {
      return this.$store.getters.getParameters("locales");
    },
    availableFilters() {
      return this.$store.getters.getParameters("filters");
    },
    teamNewsletterSubscriptionsText() {
      return this.role.team_newsletter_subscriptions.map(({ title }) => title).join(", ");
    },
    userTeams() {
      return this.role.team_memberships.map(({ team_id }) => _.find(this.teams, (team) => team.id === team_id));
    },
  },
  created() {
    this.$store.dispatch(Actions.fetchParameters("locales"));
    adminCompaniesApi.getRestrictedDashboards({ id: this.companyId }).then((r) => {
      this.restrictedDashboards = r;
    });

    adminCompaniesApi.getRestrictedReports({ id: this.companyId }).then((r) => {
      this.restrictedReports = r;
    });
  },
  methods: {
    setupRole(role: UserCompanyRole) {
      this.role = null;
      this.originalRole = role;
      role = _.cloneDeep(role);
      this.companyDashboards = null;

      new DashboardsApiService()
        .getCompanyDashboards(role.user_id)
        .then((dashboards) => (this.companyDashboards = dashboards));

      if (role.language === null) {
        role.language = "";
      }
      if (role.newsletter_currency === null) {
        role.newsletter_currency = "";
      }
      if (_.isEmpty(role.default_parameters)) {
        role.default_parameters = {};
      }
      role.default_parameters = normalizeConfig(role.default_parameters).filters;
      if (_.isEmpty(role.permissions)) {
        role.permissions = defaultPermissions();
      }
      role.definition_ids = (role.newsletter_subscriptions || []).map((s) => s.id);
      this.role = role;
    },
    ok() {
      const params: Partial<UserCompanyRole> = {};
      if (this.role.email != this.originalRole.email) {
        params.email = this.role.email;
      }
      if (this.role.first_name != this.originalRole.first_name) {
        params.first_name = this.role.first_name;
      }
      if (this.role.last_name != this.originalRole.last_name) {
        params.last_name = this.role.last_name;
      }
      if (this.role.role != this.originalRole.role) {
        params.role = this.role.role;
      }
      if (this.role.title != this.originalRole.title) {
        params.title = this.role.title;
      }
      if (this.role.language != this.originalRole.language) {
        params.language = this.role.language;
      }
      if (this.role.newsletter_currency != this.originalRole.newsletter_currency) {
        params.newsletter_currency = this.role.newsletter_currency;
      }
      if (!_.isEqual(this.role.permissions, this.originalRole.permissions)) {
        params.permissions = this.role.permissions;
      }
      if (!_.isEqual(this.role.default_parameters, this.originalRole.default_parameters)) {
        params.default_parameters = this.role.default_parameters;
      }
      const subs = _.sortBy(this.role.definition_ids);
      if (!_.isEqual(subs, _.sortBy(this.originalRole.newsletter_subscriptions.map((s) => s.id)))) {
        params.definition_ids = subs;
      }
      params.team_memberships = this.role.team_memberships;
      if (this.role.deny_export != this.originalRole.deny_export) {
        params.deny_export = this.role.deny_export;
      }

      if (this.role.default_dashboard_id != this.originalRole.default_dashboard_id) {
        params.default_dashboard_id = this.role.default_dashboard_id;
      }

      params.permitted_dashboard_ids = this.role.permitted_dashboards.map(({ id }) => id);
      params.permitted_report_ids = this.role.permitted_reports.map(({ id }) => id);

      if (!_.isEmpty(params)) {
        this.saving = true;
        adminUsersApi
          .put({ id: this.role.id, company_id: this.companyId, user: params })
          .then((role) => {
            this.$emit("updated", role);
            this.open = false;
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
    show(role: UserCompanyRole) {
      this.setupRole(role);
      this.open = true;
    },
    cancel() {
      this.open = false;
    },
    deleteUser() {
      this.$emit("delete", this.originalRole);
      this.open = false;
    },
    updateTeamMemberships(teamMemberships) {
      this.role.team_memberships = teamMemberships;
      this.updateEffectivePermissions();
    },
    updateExport(denyExport) {
      this.role.deny_export = denyExport;
    },
    updatePermissions(permissions) {
      this.role.permissions = permissions;
      this.updateEffectivePermissions();
    },
    updatePermittedReports(reports) {
      this.role.permitted_reports = reports;
    },
    updatePermittedDashboards(dashboards) {
      this.role.permitted_dashboards = dashboards;
    },
    updateEffectivePermissions() {
      this.role.effective_permissions = calculateEffectivePermissions(this.role, this.userTeams);
    },
  },
});
</script>
