<template>
  <uiv-modal v-model="open" size="sm" :title="$t('filters.export_pdf')" @hide="$emit('close-dialog')">
    <label>{{ $t("pdf.choose_layout") }}</label>
    <select v-model="orientation">
      <option value="portrait">{{ $t("pdf.orientation.portrait") }}</option>
      <option value="landscape">{{ $t("pdf.orientation.landscape") }}</option>
    </select>
    <template #footer>
      <button class="btn btn-primary" @click="download()">{{ $t("filters.export_report") }}</button>
    </template>
  </uiv-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["close-dialog"],
  data() {
    return {
      open: true,
      orientation: "portrait",
    };
  },
  methods: {
    download() {
      this.$emit("close-dialog", { orientation: this.orientation });
    },
  },
});
</script>
