<template>
  <MyReportAccessEditor
    :title="title"
    :model-value="modelValue"
    :entities="entities"
    entity-type="Company"
    @update:model-value="onInput"
  />
</template>

<script setup lang="ts">
import { useCompanies } from "@/api/companies-api";
import MyReportAccessEditor, { MyReportAccessEntity } from "@/components/my-report-access/my-report-access-editor.vue";
import { useI18n } from "@/composables/i18n";
import MyReportAccess from "@/model/my_report_access";
import { computed } from "vue";

defineProps<{
  modelValue: MyReportAccess[];
}>();

const emit = defineEmits(["update:modelValue"]);

const { t } = useI18n();

const title = t("metadata.category.Customers");

const partnerId = window.zoinedContext.partner?.id;

const { data } = useCompanies({ partner_id: partnerId, enabled: !!partnerId });

const entities = computed<MyReportAccessEntity[]>(() => {
  return (
    data.value?.map((company) => ({
      id: company.id,
      name: company.name,
    })) || []
  );
});

const onInput = (value: MyReportAccess[]) => {
  emit("update:modelValue", value);
};
</script>
