<template>
  <div class="metric-range-selector flex-row align-items-center flex-wrap gap-sm intercom-tag-filters-metric-range">
    <div class="title">{{ $t("filter.config.metric_range") }}</div>
    <pill-button
      v-for="item in items"
      :key="item.value"
      :enabled="item.enabled"
      :label="itemLabel(item)"
      @toggle="toggleItem(item)"
      @remove="removeItem(item)"
    ></pill-button>
    <dropdown ref="dropdown" v-model="open" class="inline-block" tag="span" :not-close-elements="notCloseElements">
      <plus-button class="dropdown-toggle"></plus-button>
      <template #dropdown>
        <div class="metric-range-dropdown">
          <div v-if="!filterForm">
            <item-menu
              :title="$t('filter.config.metrics')"
              :items="metrics"
              :search-query="search"
              @select="onSelectMetric($event)"
              @search="search = $event"
            ></item-menu>
          </div>
          <div v-else>
            <form class="filter-form" name="filterForm" novalidate @submit.prevent="addItem()">
              <div class="filter-form-title">{{ filterForm.item.name }}</div>
              <div class="form-horizontal">
                <div class="form-group">
                  <label class="control-label col-sm-5">{{ $t("filters.metric_range.lower_limit") }}</label>
                  <div class="col-sm-7">
                    <input v-model="filterForm.lower_limit" class="form-control lower_limit" type="number" autofocus />
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-sm-5">{{ $t("filters.metric_range.upper_limit") }}</label>
                  <div class="col-sm-7">
                    <input v-model="filterForm.upper_limit" class="form-control upper_limit" type="number" autofocus />
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-sm-5">{{ $t("filters.metric_range.include_limits") }}</label>
                  <div class="col-sm-7">
                    <btn-group class="btn-multiple">
                      <btn
                        v-model="filterForm.inclusive"
                        class="btn-sm"
                        type="primary"
                        input-type="radio"
                        :input-value="true"
                        @click="filterForm.inclusive = true"
                        >{{ $t("options.yes") }}</btn
                      >
                      <btn
                        v-model="filterForm.inclusive"
                        class="btn-sm"
                        type="primary"
                        input-type="radio"
                        :input-value="false"
                        @click="filterForm.inclusive = false"
                        >{{ $t("options.no") }}</btn
                      >
                    </btn-group>
                  </div>
                </div>
              </div>
              <div class="filter-form-buttons">
                <button class="btn" type="button" @click="open = false">{{ $t("actions.cancel") }}</button>
                <button class="btn btn-primary" type="submit" :disabled="!isFormValid">{{ $t("actions.add") }}</button>
              </div>
            </form>
          </div>
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Dropdown, BtnGroup, Btn } from "uiv";
import pillButton from "./pill-button.vue";
import itemMenu from "./item-menu.vue";
import TranslationService from "../core/translation.service";
import MetricRangeItem from "../model/metric-range-item";
import PlusButton from "@/components/buttons/plus-button.vue";

export default defineComponent({
  components: {
    Dropdown,
    BtnGroup,
    Btn,
    pillButton,
    itemMenu,
    PlusButton,
  },
  props: {
    items: { default: () => [], type: Array as PropType<MetricRangeItem[]> },
  },
  emits: ["update"],
  data() {
    return {
      open: false,
      filterForm: null,
      notCloseElements: [],
      search: "",
    };
  },
  computed: {
    isFormValid() {
      return !!(this.filterForm && (this.filterForm.lower_limit || this.filterForm.upper_limit));
    },
    metrics() {
      return this.$store.state.parameters.metrics.all;
    },
  },
  watch: {
    open: [
      {
        handler: "onChangeOpen",
      },
    ],
  },
  mounted() {
    this.notCloseElements.push((this.$refs.dropdown as typeof Dropdown).$el);
  },
  methods: {
    onSelectMetric(metric) {
      this.filterForm = {
        item: metric,
        lower_limit: null,
        upper_limit: null,
        inclusive: true,
      };
    },
    addItem() {
      if (!this.isFormValid) {
        return;
      }
      const {
        item: { id },
        lower_limit,
        upper_limit,
        inclusive,
      } = this.filterForm;

      const items = [
        ...this.items.map((item) => (item.id === id ? { ...item, enabled: false } : item)),
        { id, lower_limit, upper_limit, inclusive, enabled: true },
      ];
      this.$emit("update", items);

      this.open = false;
    },
    removeItem(item) {
      const items = this.items.filter((each) => each !== item);
      this.$emit("update", items);
    },
    toggleItem(item) {
      const enabled = !item.enabled;
      const items = this.items.map((each) =>
        each === item
          ? { ...item, enabled }
          : each.id === item.id
          ? { ...each, enabled: enabled ? false : each.enabled }
          : each
      );
      this.$emit("update", items);
    },
    itemLabel(metricRange) {
      return new TranslationService({ metrics: this.metrics }).metricRangeTitle(metricRange);
    },
    onChangeOpen() {
      if (this.open) {
        this.search = "";
        this.filterForm = null;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.title {
  display: inline-block;
  margin-right: 10px;
}

.metric-range-dropdown {
  min-width: 340px;
}

.filter-form {
  padding: 6px;

  .filter-form-title {
    padding-bottom: 10px;
  }

  .filter-form-buttons {
    padding-top: 10px;
    text-align: right;

    .btn + .btn {
      margin-left: 5px;
    }
  }
}
</style>
