<template>
  <div class="dropdown-item" @click="onClick">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  inject: ["dropdown"],
  methods: {
    onClick() {
      this.dropdown?.close();
    },
  },
});
</script>

<style lang="scss" scoped>
.dropdown-item {
  padding: 5px 10px;
  margin: 0 5px;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 2px;
  &:hover {
    background-color: #f5f5f5;
  }
}
</style>
