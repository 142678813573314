<template>
  <div class="ui-button-multi-toggle btn-group btn-multiple">
    <button
      v-for="option in props.options"
      :key="option.value"
      class="ui-button-multi-toggle__button btn btn-primary"
      :class="[option.value === props.modelValue ? 'active' : '', buttonClass]"
      @click="emit('update:modelValue', option.value)"
    >
      {{ option.label }}
    </button>
  </div>
</template>

<script setup lang="ts">
type UiButtonMultiToggleValue = string | number | boolean;

export type UiButtonMultiToggleOption = {
  value: UiButtonMultiToggleValue;
  label: string;
};

type Props = {
  modelValue: UiButtonMultiToggleValue;
  options: UiButtonMultiToggleOption[];
  buttonClass?: string;
};

const props = defineProps<Props>();

const emit = defineEmits(["update:modelValue"]);
</script>
