<template>
  <div class="default-parameter-selector flex-row align-items-center flex-wrap gap-md column-gap-xxl">
    <filter-selector
      v-for="filter in availableFilters"
      :key="filter.id"
      :config="modelValue[filter.id]"
      :excludable="false"
      :filter="filter"
      @update="updateDefaultParameter(filter.id, $event)"
    ></filter-selector>
    <div v-if="showDefaultsWarning" class="help-block">
      <i class="fa fa-exclamation-triangle text-danger"></i>{{ $t("profile.warn_defaults_lack_rights") }}
    </div>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import { hasAccessToDimension } from "@/permissions/utils";
import { defineComponent, PropType } from "vue";
import FilterSelector from "@/components/filter-selector.vue";
import FilterConfigurationItemMap from "@/model/filter-configuration-item-map";
import Permissions from "@/model/permissions";

export default defineComponent({
  components: {
    FilterSelector,
  },
  props: {
    modelValue: {
      type: Object as PropType<Record<string, FilterConfigurationItemMap>>,
      required: true,
    },
    permissions: {
      type: Object as PropType<Permissions>,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    availableFilters() {
      return this.$store.getters.getParameters("filters");
    },
    defaultParametersStoreList() {
      const storeConfig = (this.modelValue || {})["store"];
      return _.values(storeConfig).map(({ name, value }) => value || name);
    },
    showDefaultsWarning() {
      return !_.every(this.defaultParametersStoreList, (storeId) =>
        hasAccessToDimension(this.permissions, "store", storeId)
      );
    },
  },
  methods: {
    updateDefaultParameter(id, config) {
      const value = { ...this.modelValue, [id]: config };
      this.$emit("update:modelValue", value);
    },
  },
});
</script>
