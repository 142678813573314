<template>
  <pill-list
    :title="$t('activerecord.models.team.other')"
    :typeahead="true"
    :items="items"
    :available-items="menuItems"
    @update="onUpdate($event)"
    @search="fetchTeams($event)"
  ></pill-list>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import pillList from "./pill-list.vue";
import TeamApiService from "../api/teams-api-service";
import MyReportAccess from "@/model/my_report_access";

// interface Team {
//   id: string;
//   name: string;
// }

export default defineComponent({
  components: {
    pillList,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      menuTeams: [],
    };
  },
  computed: {
    items() {
      return (this.modelValue as MyReportAccess[])
        .filter((item) => item.entity_type === "Team")
        .map(({ entity_id, entity_name }) => ({
          value: entity_id.toString(),
          name: entity_name,
        }));
    },
    menuItems() {
      return this.menuTeams.map(({ id, name }) => ({
        key: id,
        name,
      }));
    },
  },
  methods: {
    fetchTeams(search) {
      this.menuTeams = [];
      new TeamApiService().getTeams({ search }).then((teams) => (this.menuTeams = teams));
    },
    onUpdate({ items }) {
      const myReportAccesses = items
        .map(({ value, name }) => ({
          entity_type: "Team",
          entity_id: parseInt(value),
          entity_name: name,
        }))
        .concat(this.modelValue.filter((item: MyReportAccess) => item.entity_type !== "Team"));
      this.$emit("update:modelValue", myReportAccesses);
    },
  },
});
</script>
