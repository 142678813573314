<template>
  <dropdown
    ref="dropdown"
    v-model="open"
    class="inline-block"
    tag="span"
    :not-close-elements="notCloseElements"
    :append-to-body="appendDropdownToBody"
  >
    <div class="dropdown-toggle">
      <slot name="default"></slot>
    </div>
    <template #dropdown>
      <div class="item-menu-dropdown">
        <item-menu
          v-bind="$attrs"
          :search-query="searchQuery"
          @select="onSelect($event)"
          @deselect="onDeselect($event)"
          @search="onSearch($event)"
          @delete="$emit('delete', $event)"
        >
          <template v-if="$slots['footer']" #footer>
            <slot name="footer"></slot>
          </template>
        </item-menu>
      </div>
    </template>
  </dropdown>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Dropdown } from "uiv";
import itemMenu from "./item-menu.vue";

export default defineComponent({
  components: {
    Dropdown,
    itemMenu,
  },
  props: {
    multiSelect: { default: false, type: Boolean },
    menuOpen: {
      type: Boolean,
    },
    appendDropdownToBody: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["select", "deselect", "search", "delete", "update:menu-open"],
  data() {
    return {
      open: false,
      notCloseElements: [],
      searchQuery: "",
    };
  },
  watch: {
    open: [
      {
        handler: "onOpenChange",
      },
    ],
    menuOpen: [
      {
        handler: "onMenuOpenChange",
      },
    ],
  },
  mounted() {
    this.notCloseElements = [(this.$refs.dropdown as typeof Dropdown).$el];
  },
  methods: {
    onSelect(item) {
      if (!this.multiSelect) {
        this.open = false;
      }
      this.$emit("select", item);
    },
    onDeselect(item) {
      if (!this.multiSelect) {
        this.open = false;
      }
      this.$emit("deselect", item);
    },
    onSearch(q) {
      this.searchQuery = q;
      this.$emit("search", this.searchQuery);
    },
    onOpenChange() {
      if (this.open) {
        this.onSearch("");
      }
      this.$emit("update:menu-open", this.open);
    },
    onMenuOpenChange() {
      if (this.menuOpen === true || this.menuOpen === false) {
        this.open = this.menuOpen;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.item-menu-dropdown {
  width: 300px;
}
</style>
