<template>
  <div class="btn-group btn-multiple">
    <div class="btn btn-primary intercom-tag-restrict-access-yes" :class="{ active: modelValue }" @click="update(true)">
      Yes
    </div>
    <div class="btn btn-primary" :class="{ active: !modelValue }" @click="update(false)">No</div>
  </div>
</template>

<script setup lang="ts">
defineProps<{ modelValue: boolean }>();

const emit = defineEmits(["update:modelValue"]);

const update = (value) => emit("update:modelValue", value);
</script>
