<template>
  <div class="comparison-scenario-form">
    <div class="form-group">
      <label>{{ $t("comparison") }}</label>
      <comparison-selector v-model="scenario.comparison" :choices="availableComparisons"></comparison-selector>
    </div>
    <div class="form-group">
      <label>{{ $t("filters.custom_scenario.percentage_change") }}</label>
      <div class="row">
        <div class="col-md-12">
          <div class="input-group">
            <input v-model="scenario.percentageChange" class="form-control" type="number" /><span
              class="input-group-btn"
            >
              <button class="btn btn-default" @click="addToScalingModifier(5)">+</button></span
            ><span class="input-group-btn">
              <button class="btn btn-default" @click="addToScalingModifier(-5)">-</button></span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="btn btn-success" :disabled="!validScenario" @click="addCustomScenario">
        {{ $t("actions.add") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ComparisonSelector from "./comparison-selector.vue";
import Comparison from "../model/comparison";
import _ from "lodash";

export default defineComponent({
  components: {
    ComparisonSelector,
  },
  inject: ["dropdown"],
  props: {
    availableComparisons: {
      type: Array as PropType<Comparison[]>,
      required: true,
    },
  },
  emits: ["add"],
  data() {
    return {
      scenario: {
        comparison: null,
        percentageChange: 0,
      },
    };
  },
  computed: {
    validScenario() {
      return this.scenario && this.scenario.comparison && this.scenario.percentageChange !== 0;
    },
  },
  created() {
    const comparison = _.first(this.availableComparisons);

    this.scenario = {
      comparison,
      percentageChange: 0,
    };
  },
  methods: {
    addToScalingModifier(value) {
      this.scenario.percentageChange += value;
    },
    addCustomScenario() {
      const { comparison, percentageChange } = this.scenario;

      const scaling_modifier = percentageChange / 100 + 1;

      const scenario = { ...comparison, scaling_modifier, enabled: true };

      this.$emit("add", scenario);
      this.dropdown?.close();
    },
  },
});
</script>

<style lang="scss" scoped>
.comparison-scenario-form {
  width: 300px;
  padding: 10px;

  .input-group-btn:not(:last-child) {
    .btn {
      border-radius: 0;
      margin-left: -1px;
    }
  }

  .actions {
    margin-top: 15px;
  }
}
</style>
