<template>
  <div class="row-wrapper">
    <div class="component-row" :class="rowClasses">
      <zoined-report-component
        v-for="(component, index) in components"
        v-bind="$attrs"
        :key="index"
        class="component"
        :class="{ half: component.span == 6, third: component.span == 4, quarter: component.span == 3 }"
        :component="effectiveComponentConfig(component)"
        :filter-configuration="filterConfiguration"
        :chart-options="chartOptions"
        :table-config="tableConfig"
        :highchart-options="highchartOptions"
        :is-details-open="component == detailComponent"
        :show-filter-configuration="dashboard"
        :custom="custom"
        :dashboard="dashboard"
        :preview="preview"
        :public="public"
        :saveable="saveable"
        :controls-enabled="controlsEnabled"
        @data="onData($event, index)"
        @redraw="onRedraw($event, index)"
        @drilldown="handleDrilldown(component, $event)"
        @page-changed="handlePageChange(component, $event)"
        @toggle-details="$emit('toggle-details', component)"
        @filter-configuration-updated="$emit('filter-configuration-updated', $event)"
        @chart-options-updated="$emit('chart-options-updated', $event)"
        @table-config-updated="$emit('table-config-updated', $event)"
        @highchart-options-updated="$emit('highchart-options-updated', $event)"
        @component-updated="$emit('component-updated', { component, updatedComponent: $event })"
        @excel-export-component="$emit('excel-export-component', $event)"
        @save="$emit('save', component)"
        @edit="$emit('edit', component)"
      ></zoined-report-component>
    </div>
    <div v-if="detailComponent" class="detail-row">
      <div v-for="(component, index) in components" :key="index">
        <component-details
          v-if="component == detailComponent"
          :filters="componentDetailFilters(component)"
          :component="component"
          :row-components="components"
          :data="datas[index]"
          @open-report="openReport(component, $event)"
        ></component-details>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import { componentFilters, filterToFlyover, refreshFilterTimes } from "../lib/filter-util";
import { openReport } from "../lib/report";
import { defineComponent, PropType } from "vue";
import zoinedReportComponent from "./zoined-report-component.vue";
import componentDetails from "./component-details.vue";
import Component from "@/model/component";
import ChartOptions from "@/model/chart-options";
import FlatFilterConfiguration from "@/model/flat-filter-configuration";
import TableConfig from "@/model/table-config";
import HighchartOptions from "@/model/highchart-options";

export default defineComponent({
  components: {
    zoinedReportComponent,
    componentDetails,
  },
  props: {
    components: {
      type: Array as PropType<Component[]>,
      required: true,
    },
    detailComponent: {
      type: Object as PropType<Component>,
      default: null,
    },
    filterConfiguration: {
      type: Object as PropType<FlatFilterConfiguration>,
      required: true,
    },
    chartOptions: {
      type: Object as PropType<ChartOptions>,
      default: null,
    },
    tableConfig: {
      type: Object as PropType<TableConfig>,
      default: null,
    },
    highchartOptions: {
      type: Object as PropType<HighchartOptions>,
      default: null,
    },
    preview: { default: false, type: Boolean },
    custom: { default: false, type: Boolean },
    dashboard: { default: false, type: Boolean },
    public: { default: false, type: Boolean },
    saveable: { default: false, type: Boolean },
    overrides: { default: () => ({}), type: Object },
    controlsEnabled: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    "update-overrides",
    "loaded",
    "toggle-details",
    "filter-configuration-updated",
    "chart-options-updated",
    "table-config-updated",
    "highchart-options-updated",
    "component-updated",
    "excel-export-component",
    "save",
    "edit",
  ],
  data() {
    return {
      detailChartOverrides: {},
      datas: {},
    };
  },
  computed: {
    rowClasses() {
      const hasChart = !!this.components.find((c) => c.type === "chart");
      const hasTable = !!this.components.find((c) => c.name == "cross_table");
      return {
        "has-chart": hasChart,
        "has-table": hasTable,
      };
    },
    company() {
      return window.zoinedContext.company;
    },
  },
  methods: {
    effectiveComponentConfig(component) {
      return _.merge({}, component, this.overrides[component.id || component.name]);
    },
    handleDrilldown(component, filters) {
      const index = this.components.indexOf(component);

      if (component.drilldown_remote_for) {
        let overrides = this.overrides;
        component.drilldown_remote_for.forEach((name) => {
          overrides = _.assign({}, overrides, {
            [name]: { drilldownFilters: filters || {} },
          });
        });
        this.$emit("update-overrides", overrides);
      } else if (index >= 0) {
        this.detailChartOverrides[index] = filters;
      }
    },
    handlePageChange(component, paging) {
      if (component.pagination_remote_for) {
        let overrides = this.overrides;
        component.pagination_remote_for.forEach((name) => {
          overrides = _.assign({}, overrides, {
            [name]: {
              drilldownFilters: {
                ...(this.overrides?.[name]?.drilldownFilters || {}),
                limit: paging.pageSize,
                offset: (paging.page - 1) * paging.pageSize,
              },
            },
          });
        });
        this.$emit("update-overrides", overrides);
      }
    },
    componentDetailFilters(component) {
      const index = this.components.indexOf(component);
      return {
        ...componentFilters(this.filterConfiguration, component),
        ...(index >= 0 ? this.detailChartOverrides[index] : {}),
      };
    },
    openReport(component, params) {
      const filterConfiguration = filterToFlyover(
        refreshFilterTimes({
          ...this.filterConfiguration,
          ...component.filterConfiguration,
        })
      );
      openReport(filterConfiguration, params, this.company);
    },
    onData(data, index) {
      this.datas[index] = data;
      this.$emit("loaded", this.components[index]);
    },
    onRedraw(_chart, index) {
      this.$emit("loaded", this.components[index]);
    },
  },
});
</script>

<style lang="scss" scoped>
.row-wrapper {
  margin-bottom: 30px;

  .component-row {
    page-break-inside: avoid;

    @media print {
      margin-bottom: 45px;
    }

    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: 30px;

    .component {
      grid-column-end: span 12;

      @media (min-width: 768px) {
        &.half {
          grid-column-end: span 6;
        }

        &.third {
          grid-column-end: span 4;
        }

        &.quarter {
          grid-column-end: span 3;
        }
      }

      @media print {
        &.half {
          grid-column-end: span 6;
        }

        &.third {
          grid-column-end: span 4;
        }

        &.quarter {
          grid-column-end: span 3;
        }
      }
    }
  }
}
</style>
