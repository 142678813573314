<template>
  <report-view v-if="loaded" :key="route.path"></report-view>
  <spinner v-else></spinner>
</template>

<script lang="ts">
import { sendMixpanelEvent } from "@/mixpanel";
import { computed } from "vue";
import { useStore } from "@/composables/store";
import ReportView from "@/analytics/report-view.vue";
import Spinner from "@/components/spinner.vue";
import Mutations from "@/store/mutations";
import Actions from "@/store/actions";
import { useRoute, useRouter } from "vue-router";
import { withErrorHandling } from "@/core/error-handling";

export default {
  name: "ReportPage",
  components: {
    ReportView,
    Spinner,
  },
  beforeRouteLeave(to, from, next) {
    this.beforeRouteLeave(to, from, next);
  },
  props: {
    name: { type: String, required: true },
    myReportId: { type: String, required: false, default: null },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const reportContextState = computed(() => {
      return store.state.reportContext.state;
    });

    const loaded = computed(() => {
      return reportContextState.value === "loaded";
    });

    const beforeRouteLeave = (to, from, next) => {
      store.commit(Mutations.clearReportContext);
      next();
    };

    // Do we have reportContext passed from page request
    if (reportContextState.value === "initial" && window.reportContext) {
      store.commit(Mutations.fetchReportContextSuccess, window.reportContext);
    }
    // If not then fetch context
    else {
      withErrorHandling(() =>
        store
          .dispatch(Actions.fetchAnalyticsReport, { report_name: props.name, my_report_id: props.myReportId })
          .catch((error) => {
            if (error?.response?.status === 404) {
              router.push("/");
            } else {
              throw error;
            }
          })
      );
    }

    sendMixpanelEvent("Opened Report", { Type: props.name });

    return { loaded, route, beforeRouteLeave };
  },
};
</script>
