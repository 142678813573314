<template>
  <pill-list
    :title="$t('activerecord.models.user.other')"
    :typeahead="true"
    :items="items"
    :available-items="menuItems"
    @update="onUpdate($event)"
    @search="fetchUsers($event)"
  ></pill-list>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import PillItem from "../interfaces/pill-item";
import MenuItem from "../interfaces/menu-item";
import pillList from "./pill-list.vue";
import UsersApiService from "../api/users-api-service";
import MyReportAccess from "@/model/my_report_access";
export default defineComponent({
  components: {
    pillList,
  },
  props: {
    modelValue: { default: () => [], type: Array as PropType<Array<MyReportAccess>> },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      menuUsers: [],
    };
  },
  computed: {
    items(): PillItem[] {
      return (this.modelValue as MyReportAccess[])
        .filter((item) => item.entity_type === "UserCompanyRole")
        .map(({ entity_id, entity_name }) => ({
          value: entity_id.toString(),
          name: entity_name,
        }));
    },
    menuItems(): MenuItem[] {
      return this.menuUsers.map(({ id, first_name, last_name }) => ({
        key: id,
        name: `${first_name} ${last_name}`,
      }));
    },
  },
  methods: {
    fetchUsers(search) {
      this.menuUsers = [];
      new UsersApiService().getUsers({ search }).then((users) => (this.menuUsers = users));
    },
    onUpdate({ items }) {
      const myReportAccesses = items
        .map(({ value, name }) => ({
          entity_type: "UserCompanyRole",
          entity_id: parseInt(value),
          entity_name: name,
        }))
        .concat(this.modelValue.filter((item) => item.entity_type !== "UserCompanyRole"));
      this.$emit("update:modelValue", myReportAccesses);
    },
  },
});
</script>
