<template>
  <uiv-modal id="password-modal" v-model="open" :title="$t('profile.change_password')">
    <div v-if="error" class="alert alert-danger" role="alert">{{ error }}</div>
    <spinner v-if="loading"></spinner>
    <div class="row">
      <form class="form-horizontal">
        <div class="form-group">
          <label class="col-md-4 control-label" for="current_password">{{
            $t("activerecord.attributes.user.current_password")
          }}</label>
          <div class="col-md-8">
            <input v-model="current_password" type="password" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 string required control-label" for="new_password">{{
            $t("devise.passwords.edit.new_password")
          }}</label>
          <div class="col-md-8">
            <input v-model="new_password" type="password" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="confirm_new_password">{{
            $t("devise.passwords.edit.confirm_new_password")
          }}</label>
          <div class="col-md-8">
            <input v-model="confirm_new_password" type="password" />
          </div>
        </div>
      </form>
    </div>
    <template #footer>
      <button class="btn btn-default" type="button" @click="open = false">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" type="button" @click="save">{{ $t("actions.save") }}</button>
    </template>
  </uiv-modal>
</template>

<script lang="ts">
import { changePassword } from "../lib/data/profile";
import { i18n } from "../lib/vue/filters/i18n";
import spinner from "../components/spinner.vue";

export default {
  components: {
    spinner,
  },
  emits: ["get-alert"],
  data() {
    return {
      open: false,
      loading: false,
      error: null,
      current_password: "",
      new_password: "",
      confirm_new_password: "",
    };
  },
  computed: {
    userInputs() {
      const email = window.zoinedContext.email;
      const parts = email.split(/[^\w]/);
      return [email, ...parts];
    },
  },
  mounted: function() {
    this.loading = true;
  },
  methods: {
    handleSubmit: function() {
      const data = {
        current_password: this.current_password,
        password: this.new_password,
        password_confirmation: this.confirm_new_password,
      };

      this.loading = true;

      changePassword(data)
        .then((res) => {
          this.$emit("get-alert", res.success);
          this.open = false;
          this.error = null;
          this.current_password = "";
          this.new_password = "";
          this.confirm_new_password = "";
          this.loading = false;
        })
        .catch((error) => {
          this.error = error.message;
          this.loading = false;
        });
    },
    save: function() {
      const blank = i18n("errors.messages.blank");

      if (!this.current_password.length) {
        this.error = `${i18n("activerecord.attributes.user.current_password")} ${blank}`;
      } else if (!this.new_password.length) {
        this.error = `${i18n("devise.passwords.edit.new_password")} ${blank}`;
      } else if (!this.confirm_new_password.length) {
        this.error = `${i18n("devise.passwords.edit.confirm_new_password")} ${blank}`;
      } else {
        this.handleSubmit();
      }
    },
    show: function() {
      this.loading = false;
      this.open = true;
    },
  },
};
</script>
