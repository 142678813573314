<template>
  <div v-if="model" class="permissions-editor">
    <div class="permissions-row">
      <dimension-permissions
        dimension="store"
        :config="model.dimensions.store"
        :effective-config="effectivePermissions && effectivePermissions.dimensions.store"
        @update="updateDimensionConfig('store', $event)"
      ></dimension-permissions>
    </div>
    <div class="permissions-row">
      <dimension-permissions
        dimension="metrics"
        :config="model.metrics"
        :effective-config="effectivePermissions && effectivePermissions.metrics"
        @update="updateMetricsConfig($event)"
      ></dimension-permissions>
    </div>
    <div class="permissions-row">
      <dimension-permissions
        dimension="groupings"
        :config="model.groupings"
        :effective-config="effectivePermissions && effectivePermissions.groupings"
        @update="updateGroupingsConfig($event)"
      ></dimension-permissions>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import DimensionPermissions from "@/permissions/dimension-permissions.vue";
import Permissions from "@/model/permissions";

export default defineComponent({
  components: { DimensionPermissions },
  props: {
    permissions: {
      type: Object as PropType<Permissions>,
      default: null,
    },
    effectivePermissions: {
      type: Object as PropType<Permissions>,
      default: null,
    },
    defaultPermission: { default: "all", type: String },
  },
  emits: ["update"],
  data() {
    const model: Permissions = null;

    return {
      model,
    };
  },
  watch: {
    permissions: [{ deep: true, immediate: true, handler: "onPermissionsChange" }],
  },
  methods: {
    sanitizePermissions(permissions) {
      return {
        dimensions: {
          store: { allow: this.defaultPermission },
          ...(permissions.dimensions || {}),
        },
        metrics: {
          allow: this.defaultPermission,
          ...(permissions.metrics || {}),
        },
        groupings: {
          allow: this.defaultPermission,
          ...(permissions.groupings || {}),
        },
      };
    },
    updateDimensionConfig(dimension, config) {
      const permissions = {
        ...this.model,
        dimensions: {
          ...this.model.dimensions,
          [dimension]: config,
        },
      };
      this.$emit("update", permissions);
    },
    updateMetricsConfig(metrics) {
      const permissions = {
        ...this.model,
        metrics,
      };
      this.$emit("update", permissions);
    },
    updateGroupingsConfig(groupings) {
      const permissions = {
        ...this.model,
        groupings,
      };
      this.$emit("update", permissions);
    },
    onPermissionsChange() {
      this.model = this.sanitizePermissions(this.permissions || {});
    },
  },
});
</script>

<style lang="scss" scoped>
.permissions-row {
  padding-bottom: 10px;

  &:last-child {
    padding-bottom: 0;
  }
}
</style>
