<template>
  <modal
    v-model="open"
    class="dashboard-template-from-dashboard-modal"
    :append-to-body="true"
    :title="$t('dashboard_templates.select_dashboard')"
    :footer="false"
  >
    <div v-if="!dashboards" class="loading-indicator">
      <spinner></spinner>
    </div>
    <div v-else class="content">
      <div v-for="dashboard in dashboards" :key="dashboard.id" class="item" @click="createFromDashboard(dashboard)">
        {{ dashboard.name }}
      </div>
    </div>
  </modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Modal } from "uiv";
import spinner from "../../components/spinner.vue";
import { makeApiInstance } from "../../api/instance";

export default defineComponent({
  components: {
    Modal,
    spinner,
  },
  emits: ["select"],
  data() {
    return {
      open: false,
      dashboards: null,
    };
  },
  computed: {
    reportsApi() {
      return makeApiInstance({
        baseURL: "/api/v1/reports",
      });
    },
  },
  methods: {
    show() {
      this.open = true;
      if (!this.dashboards) {
        this.fetchDashboards();
      }
    },
    createFromDashboard(dashboard) {
      this.open = false;
      this.$emit("select", dashboard.id);
    },
    fetchDashboards() {
      this.reportsApi.get("company_dashboards").then((result) => (this.dashboards = result.data));
    },
  },
});
</script>

<style lang="scss" scoped>
.dashboard-template-from-dashboard-modal {
  .loading-indicator {
    min-height: 100px;
  }

  .item {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: #cceeff;
    }
  }
}
</style>
