<template>
  <a v-if="isLimited" class="sidenav-report limited" :class="report.class_name">
    <div class="icon">
      <svg-icon :name="icon"></svg-icon>
    </div>
    <span class="font-weight-sb font-size-md report-name">{{ report.name }}</span> <pro-text class="ml-sm"></pro-text
  ></a>
  <router-link
    v-else-if="report.router !== false"
    class="sidenav-report"
    :to="report.path"
    :title="report.description"
    :class="report.class_name"
  >
    <div class="icon">
      <svg-icon :name="icon"></svg-icon>
    </div>
    <span class="font-weight-sb font-size-md">{{ report.name }}</span> </router-link
  ><a
    v-else
    class="sidenav-report"
    :title="report.description"
    :class="{ 'router-link-exact-active': active, [report.classname]: true }"
    :href="report.path"
  >
    <div class="icon">
      <svg-icon :name="icon"></svg-icon>
    </div>
    <span class="font-weight-sb font-size-md">{{ report.name }}</span></a
  >
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SvgIcon from "../components/svg-icon.vue";
import { isReportActive } from "./utils";
import proText from "main/components/pro-text.vue";

export default defineComponent({
  components: {
    SvgIcon,
    proText,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isLimited() {
      return window.zoinedContext.limited && !this.report.enabled_in_limited;
    },
    icon() {
      switch (this.report.type) {
        case "new-dashboard":
          return "new-analytics";
        case "new-report":
          return "new-analytics";
        case "dashboard":
          return "dashboard";
        case "table":
          return "table";
        default:
          return "visual-chart";
      }
    },
    active() {
      return isReportActive(this.report);
    },
  },
});
</script>

<style lang="scss" scoped>
.sidenav-report {
  padding: 6px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1.2;
  color: var(--color-sidenav-text);

  .icon {
    display: flex;
    justify-content: center;
    height: 16px;
    margin-right: 10px;
    svg {
      max-height: 16px;
      max-width: 16px;
    }
  }

  &:hover:not(.limited) {
    background-color: var(--color-sidenav-report-active-background);
  }

  &.router-link-exact-active {
    background-color: var(--color-sidenav-report-active-background);
    color: var(--color-sidenav-report-active-text);
  }

  &.limited {
    .icon,
    .report-name {
      opacity: 0.5;
    }
  }
}
</style>
