<!-- Pill list component with (+) button and modal -->
<template>
  <div v-if="disabled" class="pill-list">
    <div v-if="title" class="title disabled">{{ title }}</div>
    <template v-if="!items">
      <pill-button
        v-for="item in defaults"
        :key="item.value"
        class="disabled"
        :enabled="item.enabled"
        :exclude="item.exclude"
        :label="itemLabel(item)"
        :pill-style="pillStyle"
      ></pill-button>
    </template>
    <pill-button
      v-for="item in model"
      :key="item.value"
      class="disabled"
      :enabled="item.enabled"
      :exclude="item.exclude"
      :label="itemLabel(item)"
      :pill-style="pillStyle"
    ></pill-button>
    <plus-button v-if="addable" :disabled="true"></plus-button>
    <minus-button v-if="excludable" :disabled="true"></minus-button>
  </div>
  <div v-else class="pill-list">
    <div v-if="title" class="title">{{ title }}</div>
    <template v-if="!items">
      <pill-button
        v-for="item in defaults"
        :key="item.value"
        class="shaded"
        :enabled="item.enabled"
        :exclude="item.exclude"
        :label="itemLabel(item)"
        :pill-style="pillStyle"
        @remove="removeDefaultItem(item)"
      ></pill-button>
    </template>
    <draggable
      v-if="model.length > 0"
      v-model="model"
      class="draggable flex-row flex-wrap gap-sm"
      item-key="value"
      :delay="100"
      :disabled="!draggable"
      @update="onOrderUpdated"
    >
      <template #item="{ element: item }">
        <pill-button
          :key="item.value"
          :enabled="item.enabled"
          :exclude="item.exclude"
          :label="itemLabel(item)"
          :pill-style="pillStyle"
          @toggle="toggleItem(item)"
          @remove="removeItem(item.value)"
        ></pill-button>
      </template>
    </draggable>
    <item-menu-dropdown
      v-if="addable"
      :menu-open="menuOpen"
      :items="menuItems"
      :typeahead="typeahead"
      :searchable="searchable"
      :multi-select="multiSelect"
      :deleteable="deleteable"
      :title="title"
      :selected-items="selectedItems"
      :wildcard="wildcard"
      :is-loading="isLoading"
      @select="addItem"
      @deselect="removeItem(menuItemKey($event))"
      @search="onSearch($event)"
      @delete="$emit('delete', $event.key)"
      @update:menu-open="$emit('update:menu-open', $event)"
    >
      <plus-button class="intercom-tag-pill-list-add-button" @click="onToggleMenu"></plus-button>
      <template v-if="$slots['menu-footer']" #footer>
        <slot name="menu-footer"></slot>
      </template>
    </item-menu-dropdown>
    <item-menu-dropdown
      v-if="excludable"
      :items="excludeMenuItems"
      :typeahead="typeahead"
      :searchable="searchable"
      :multi-select="multiSelect"
      :title="title"
      :selected-items="excludedItems"
      :wildcard="wildcard"
      :exclude="true"
      :is-loading="isLoading"
      @select="excludeItem"
      @deselect="removeItem(menuItemKey($event))"
      @update:menu-open="$emit('update:menu-open', $event)"
      @search="onSearch($event)"
    >
      <minus-button class="intercom-tag-pill-list-minus-button" @click="onToggleMenu"></minus-button>
    </item-menu-dropdown>
  </div>
</template>

<script lang="ts">
import pillButton from "./pill-button.vue";
import itemMenuDropdown from "./item-menu-dropdown.vue";
import draggable from "vuedraggable";
import { defineComponent, PropType } from "vue";
import _ from "lodash";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import PillItem from "../interfaces/pill-item";
import plusButton from "@/components/buttons/plus-button.vue";
import MinusButton from "@/components/buttons/minus-button.vue";

export default defineComponent({
  components: {
    pillButton,
    itemMenuDropdown,
    draggable,
    plusButton,
    MinusButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    defaults: {
      type: Array as PropType<PillItem[]>,
      default: () => [],
    },
    items: {
      type: Array as PropType<PillItem[]>,
      default: () => [],
    },
    availableItems: {
      type: Array as PropType<MenuItem[]>,
      default: null,
    },
    togglable: { default: true, type: Boolean },
    itemLabelFn: {
      type: Object as PropType<(item: PillItem) => string>,
      default: null,
    },
    addable: { default: true, type: Boolean },
    excludable: { default: false, type: Boolean },
    radio: { default: false, type: Boolean },
    typeahead: { default: false, type: Boolean },
    searchable: { default: true, type: Boolean },
    multiSelect: { default: false, type: Boolean },
    menuOpen: { default: false, type: Boolean },
    deleteable: { default: false, type: Boolean },
    draggable: { default: false, type: Boolean },
    pillStyle: { default: "pill", type: String },
    disabled: { default: false, type: Boolean },
    wildcard: { default: false, type: Boolean },
    isLoading: { default: false, type: Boolean },
  },
  emits: ["update", "toggleMenu", "search", "delete", "update:menu-open"],
  data() {
    const model: PillItem[] = [];

    return {
      model,
      menuItemKey: menuItemKey,
    };
  },
  computed: {
    selectedItems(): PillItem[] {
      return (this.items || []).filter(({ exclude }) => !exclude);
    },
    excludedItems(): PillItem[] {
      return (this.items || []).filter(({ exclude }) => exclude);
    },
    menuItems(): MenuItem[] {
      return (
        this.availableItems &&
        this.availableItems.filter((availableItem) =>
          this.multiSelect
            ? !this.excludedItems.some((item) => item.value === menuItemKey(availableItem))
            : !_.some(this.items, (item) => item.value === menuItemKey(availableItem))
        )
      );
    },
    excludeMenuItems(): MenuItem[] {
      return (
        this.availableItems &&
        this.availableItems.filter((availableItem) =>
          this.multiSelect
            ? !this.selectedItems.some((item) => item.value === menuItemKey(availableItem))
            : !_.some(this.items, (item) => item.value === menuItemKey(availableItem))
        )
      );
    },
  },
  watch: {
    items: [
      {
        handler: "updateModel",
      },
    ],
  },
  created() {
    this.updateModel();
  },
  methods: {
    itemLabel(item: PillItem) {
      return (
        (this.itemLabelFn && this.itemLabelFn(item)) ||
        _.get(
          _.find(this.availableItems, (menuItem) => menuItemKey(menuItem) == item.value),
          "name"
        ) ||
        item.name ||
        item.value
      ).toString();
    },
    onToggleMenu() {
      this.$emit("toggleMenu");
    },
    onToggleExcludeMenu() {
      this.$emit("toggleMenu");
    },
    onSearch(str) {
      this.$emit("search", str);
    },
    onOrderUpdated() {
      this.$emit("update", { items: [...this.model] });
    },
    addItem(item: MenuItem) {
      const currentItems = (this.items || []).map((item) => ({
        ...item,
        enabled: this.radio ? false : item.enabled,
      }));
      const items: PillItem[] = [
        ...currentItems,
        { value: menuItemKey(item), name: item.name, enabled: true, wildcard: item.wildcard },
      ];
      this.$emit("update", { items });
    },
    excludeItem(item: MenuItem) {
      const items: PillItem[] = [
        ...(this.items || []),
        { value: menuItemKey(item), name: item.name, enabled: true, exclude: true, wildcard: item.wildcard },
      ];
      this.$emit("update", { items });
    },
    removeItem(value: string | number) {
      const items: PillItem[] = this.items.filter((item) => item.value !== value);

      this.$emit("update", { items });
    },
    removeDefaultItem(item: PillItem) {
      const items: PillItem[] = this.defaults.filter((each) => each.value !== item.value);
      this.$emit("update", { items });
    },
    toggleItem(item: PillItem) {
      if (!this.togglable) {
        return;
      }
      const items: PillItem[] = this.items.map((each) => {
        const enabled = each.value === item.value ? !each.enabled : this.radio ? false : each.enabled;
        return { ...each, enabled };
      });
      this.$emit("update", { items });
    },
    updateModel() {
      this.model = [].concat(this.items || []);
    },
  },
});
</script>

<style lang="scss" scoped>
.pill-list {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}

.shaded {
  opacity: 0.5;
}

.title {
  margin-right: 10px;
}

.title.disabled {
  color: var(--color-text-disabled);
}
.pill-button.disabled {
  background-color: #4d4d4d;
  color: #dadad9;
  cursor: not-allowed;
  &:hover {
    background-color: #4d4d4d;
  }
}
</style>
