<template>
  <toggle-button
    :model-value="modelValue == 'value'"
    :true-title="'Value'"
    :false-title="'Change'"
    button-class="btn-xs"
    @update:model-value="onToggle"
  ></toggle-button>
</template>

<script setup lang="ts">
import ToggleButton from "@/components/toggle-button.vue";

defineProps<{
  modelValue: string;
}>();

const emit = defineEmits(["update:modelValue"]);

const onToggle = (value: boolean) => {
  emit("update:modelValue", value ? "value" : "change");
};
</script>
