<template>
  <uiv-modal
    v-model="open"
    class="newsletter-definition-from-template-modal"
    :append-to-body="true"
    :title="$t('newsletter.definition.select_newsletter_template')"
    :footer="false"
  >
    <div v-if="!newsletterTemplates" class="loading-indicator">
      <spinner></spinner>
    </div>
    <div v-else class="content">
      <div
        v-for="template in newsletterTemplates"
        :key="template.id"
        class="item"
        @click="createFromTemplate(template)"
      >
        {{ template.title }}
      </div>
    </div>
  </uiv-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import spinner from "../components/spinner.vue";
import NewsletterTemplate from "@/model/newsletter-template";
export default defineComponent({
  components: {
    spinner,
  },
  props: {
    newsletterTemplates: {
      type: Array as PropType<NewsletterTemplate[]>,
      default: null,
    },
  },
  emits: ["new-from-template"],
  data() {
    return {
      open: false,
    };
  },
  computed: {
    companyId() {
      return window.zoinedContext.companyId;
    },
  },
  methods: {
    show() {
      this.open = true;
    },
    createFromTemplate(template) {
      this.open = false;
      this.$emit("new-from-template", template.id);
    },
  },
});
</script>

<style lang="scss" scoped>
.newsletter-definition-from-template-modal {
  .loading-indicator {
    min-height: 100px;
  }

  .item {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: #cceeff;
    }
  }
}
</style>
