<template>
  <div class="flex-row align-items-center flex-wrap gap-sm">
    <pill-button
      v-for="obj in collection"
      :key="obj.key"
      :enabled="!obj.config.invisible"
      :removable="false"
      @toggle="editObject(obj)"
      >{{ obj.label }} &nbsp;&nbsp;<i class="fa fa-edit"></i
    ></pill-button>
    <metric-dialog ref="metricDialog" :type="type" @save="onSave"></metric-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import _ from "lodash";
import MetricDialog from "./metric-dialog.vue";
import pillButton from "@/components/pill-button.vue";

export default defineComponent({
  components: {
    MetricDialog,
    pillButton,
  },
  props: {
    objects: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  emits: ["save"],
  computed: {
    collection() {
      return _.sortBy(
        _.map(this.objects, (v, k) => {
          return { key: k, label: v.label[this.locale] || k, config: v };
        }),
        "label"
      );
    },
  },
  methods: {
    editObject(obj) {
      (this.$refs.metricDialog as typeof MetricDialog).show(obj);
    },
    onSave(obj) {
      this.$emit("save", obj);
    },
  },
});
</script>
