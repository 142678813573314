<template>
  <div class="sok-provision-section">
    <div class="option">
      <label>{{ $t("filter.config.metrics") }}</label>
      <select :value="section.metric" @input="updateMetric">
        <option v-for="option in metricsOptions" :key="menuItemKey(option)" :value="menuItemKey(option)">{{
          option.name
        }}</option>
      </select>
    </div>
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.common.entries_to_show") }}</label>
      <select :value="section.limit" @input="updateLimit">
        <option v-for="option in limitOptions" :key="option.id" :value="option.id">{{ option.name }}</option>
      </select>
    </div>
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.common.time_period") }}</label>
      <timePeriodDropdown
        :show-title="false"
        :custom="false"
        :available-items="timePeriods"
        :available-series-types="seriesTypes"
        :value="selection"
        @update="updateSelection"
      ></timePeriodDropdown>
    </div>
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.common.comparison") }}</label>
      <comparisonPillList
        :choices="comparisons"
        :custom="false"
        :title="null"
        :radio="true"
        :comparisons="section.comparisons"
        @update="updateComparisons"
      ></comparisonPillList>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import timePeriodDropdown from "../../components/time-period-dropdown.vue";
import comparisonPillList from "../../components/comparison-pill-list.vue";
import MenuItem, { menuItemKey } from "../../interfaces/menu-item";
import { limitOptions } from "../constants";

export default defineComponent({
  components: {
    timePeriodDropdown,
    comparisonPillList,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    timePeriods: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    seriesTypes: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ["update-section"],
  data() {
    return {
      menuItemKey,
    };
  },
  computed: {
    selection() {
      return (
        this.section.time_period && {
          type: this.section.time_period,
          series: this.section.time_series_type,
        }
      );
    },
    metricsOptions(): MenuItem[] {
      return [
        {
          id: "total_profit",
          name: "Yhteensä myyntikate (Alviton) €",
        },
        {
          id: "total_sales",
          name: "Yhteensä myynti (Alviton) €",
        },
      ];
    },
    comparisons() {
      const allowedComparisonTypes = [
        "prev_year_corresponding",
        "2_years_back_corresponding",
        "3_years_back_corresponding",
        "prev_year_corresponding_same_weekday_ending",
        "prev_year_same_days",
        "prev_year_full_month",
        "previous_corresponding_period",
        "budget",
      ];

      return this.$store.state.parameters.comparisons.all?.filter(({ type }) => allowedComparisonTypes.includes(type));
    },
    limitOptions() {
      return limitOptions;
    },
  },
  methods: {
    updateSelection({ type, series }) {
      this.$emit("update-section", { ...this.section, time_period: type, time_series_type: series });
    },
    updateMetric(event) {
      const metric = event.target.value;
      this.$emit("update-section", { ...this.section, metric, insights: null });
    },
    updateLimit(event) {
      const limit = Number.parseInt(event.target.value);
      this.$emit("update-section", { ...this.section, limit });
    },
    updateComparisons(comparisons) {
      this.$emit("update-section", { ...this.section, comparisons });
    },
  },
});
</script>
