<template>
  <div
    class="report-block"
    :class="{
      'detail-open': isDetailsOpen,
      'full-screen': fullScreen,
      'drilldown-enabled': drilldownEnabled,
      handle: editor,
      [componentClass]: true,
      [metricClass]: true,
      [`span-${component.span}`]: component.span,
    }"
  >
    <drilldown-provider class="panel panel-snippet">
      <div class="panel-heading flex-column">
        <div class="flex-row justify-content-space-between align-items-flex-start">
          <h3 class="panel-title">
            <span v-if="title" v-uiv-tooltip="title" class="fg-text-primary font-semibold"
              ><span>{{ title }}</span
              ><span v-if="isIndex"> {{ $t("filter.metric_type.index") }}</span
              ><i
                v-if="component.help_text"
                v-uiv-tooltip.bottom="component.help_text"
                class="fa fa-lg fa-info-circle"
              ></i></span
            ><span v-if="secondaryTitle" class="fg-text-variant font-normal">{{ secondaryTitle }}</span>
          </h3>
          <div v-if="fullScreen">
            <icon-button
              icon="fluent:arrow-minimize-16-regular"
              hover-icon="fluent:arrow-minimize-16-filled"
              button-style="low-contrast"
              @click="fullScreen = !fullScreen"
            ></icon-button>
          </div>
          <div v-if="!fullScreen" class="print-hidden flex-row gap-sm align-items-center">
            <excel-button
              v-if="dashboard && excelExportEnabled && !editor && !preview"
              class="mr-md"
              @click="excelExport()"
            ></excel-button>
            <icon-button
              v-if="editable"
              v-uiv-tooltip.hover="t('actions.edit')"
              icon="fluent:edit-16-regular"
              hover-icon="fluent:edit-16-filled"
              button-style="low-contrast"
              @click="$emit('edit')"
            ></icon-button>
            <icon-button
              v-if="duplicatable"
              v-uiv-tooltip.hover="t('actions.duplicate')"
              icon="fluent:copy-16-regular"
              hover-icon="fluent:copy-16-filled"
              button-style="low-contrast"
              @click="$emit('duplicate')"
            ></icon-button>
            <icon-button
              v-if="removable"
              v-uiv-tooltip.hover="t('actions.delete')"
              icon="fluent:delete-16-regular"
              hover-icon="fluent:delete-16-filled"
              button-style="low-contrast"
              @click="$emit('remove')"
            ></icon-button>
            <icon-button
              v-if="fullScreenEnabled && !editor && !preview"
              icon="fluent:arrow-maximize-16-regular"
              hover-icon="fluent:arrow-maximize-16-filled"
              button-style="low-contrast"
              @click="fullScreen = !fullScreen"
            ></icon-button>
            <button v-if="saveable" class="btn btn-sm btn-primary-inverted ml-md" @click="$emit('save')">
              {{ $t("actions.save") }}
            </button>
          </div>
        </div>
        <active-filters
          v-if="showFilterConfiguration"
          class="full-screen-hidden"
          :filter-configuration="component.filterConfiguration"
          :chart-options="chartOptions"
          @update="updateComponentFilterConfiguration"
        ></active-filters>
        <div
          class="full-screen-hidden flex-row justify-content-space-between align-items-center flex-wrap gap-md mt-md"
        >
          <div class="filter-configuration">
            <legends
              :show-symbol="showLegendSymbol"
              :selection="effectiveFilterConfiguration.selection"
              :comparisons="effectiveFilterConfiguration.comparisons"
              :span="component.span"
            ></legends>
          </div>
          <div
            v-if="!public"
            class="toolbar print-hidden flex-row align-items-center justify-content-space-between gap-md flex-wrap"
            :class="component.span <= 4 && ['hidden-sm', 'hidden-md', 'hidden-lg']"
          >
            <round-values-selector
              v-if="controlsEnabled && showRoundValuesSelector"
              :model-value="effectiveFilters.round_values"
              @update:model-value="updateRoundValues"
            ></round-values-selector>
            <table-column-selector
              v-if="controlsEnabled && showTableColumnSelector"
              :config="tableColumnConfig"
              :component="component"
              @change="tableColumnConfigUpdated"
            ></table-column-selector>
            <data-labels-selector
              v-if="controlsEnabled && showDataLabelsSelector"
              :model-value="effectiveChartOptions.data_labels"
              @update:model-value="updateDataLabels($event)"
            ></data-labels-selector>
            <proportions-selector
              v-if="controlsEnabled && showProportionsSelector"
              :model-value="effectiveFilters.proportions"
              @update:model-value="updateProportions($event)"
            ></proportions-selector>
            <chart-type-selector
              v-if="controlsEnabled && showChartTypeSelector"
              :model-value="chartType"
              @update:model-value="updateChartType($event)"
            ></chart-type-selector
            ><a
              v-if="controlsEnabled && showHeatmapSettings"
              class="btn btn-default btn-sm"
              @click="onShowHeatmapSettings"
              ><i class="fa fa-bar-chart fa-lg"></i
            ></a>
            <table-settings-modal
              v-if="controlsEnabled && showTableSettings"
              :component="component"
              :table-config="effectiveTableConfig"
              :filter-configuration="effectiveFilterConfiguration"
              :chart-options="effectiveChartOptions"
              @update="updateTableConfig($event)"
            ></table-settings-modal>
            <trend-direction-selector
              v-if="controlsEnabled && showTrendDirectionSelector"
              :model-value="sortDirection"
              @update:model-value="onSortDirectionChange"
            ></trend-direction-selector>
            <trend-chart-variant-selector
              v-if="controlsEnabled && showChartVariantSelector"
              :model-value="chartVariant"
              @update:model-value="onChartVariantChange"
            ></trend-chart-variant-selector>
          </div>
        </div>
        <div class="full-screen-hidden print-hidden flex-row flex-wrap mt-md">
          <drilldown-dropdown v-if="drilldownEnabled && !preview"></drilldown-dropdown>
          <div class="flex-1"></div>
          <trend-sort-selector
            v-if="controlsEnabled && showTrendSortSelector"
            :model-value="sortKey"
            @update:model-value="onSortKeyChange"
          ></trend-sort-selector>
        </div>
      </div>
      <div class="panel-body">
        <div class="report-component" :class="component.type + ' ' + component.class_name" :style="style">
          <dashboard-snippet
            v-if="blockToUse == 'dashboard_snippet'"
            :type="effectiveFilters.metrics[0]"
            :component="component"
            :filters="effectiveFilters"
            @data="$emit('data', $event)"
          ></dashboard-snippet>
          <zoined-snippet
            v-if="blockToUse == 'snippet'"
            :type="component.name"
            :component="component"
            :full-screen="fullScreen"
            :filters="effectiveFilters"
            :filter-configuration="effectiveFilterConfiguration"
            :chart-options="effectiveChartOptions"
            :table-config="component.tableConfig || tableConfig"
            :pagination="paginationEnabled"
            @page-changed="handlePageChange"
            @data="onSnippetData"
            @update-limit="updateLimit"
            @update-component="$emit('componentUpdated', $event)"
          ></zoined-snippet>
          <trend-block
            v-else-if="blockToUse == 'trend'"
            :chart="component.name"
            :component="component"
            :filters="effectiveFilters"
            :chart-options="effectiveChartOptions"
            :show-top="false"
            :pagination="paginationEnabled"
            @drilldown="handleDrilldown"
            @page-changed="handlePageChange"
          ></trend-block>
          <zoined-chart
            v-else-if="blockToUse == 'metric_chart'"
            :type="effectiveFilters.metrics[0]"
            :component="component"
            :overflow-hidden="isNotification ? true : null"
            :filters="effectiveFilters"
            :chart-options="effectiveChartOptions"
            :highchart-options="highchartOptions"
            :drilldown="drilldownEnabled"
            :editing="editor"
            @drilldown="handleDrilldown"
            @page-changed="handlePageChange"
            @redraw="handleRedraw"
            @update-limit="updateLimit"
            @update-component="$emit('componentUpdated', $event)"
          ></zoined-chart>
          <insight-chart
            v-else-if="blockToUse == 'insight_chart'"
            :component="component"
            :filters="effectiveFilters"
            :chart-options="effectiveChartOptions"
            @update="updateFilterConfiguration"
          ></insight-chart>
          <trend-insights-chart
            v-else-if="blockToUse == 'trend_insights'"
            :component="component"
            :filters="effectiveFilters"
            :chart-options="effectiveChartOptions"
            :highchart-options="highchartOptions"
            :pagination="paginationEnabled"
            :limit="component.limit"
            :drilldown="drilldownEnabled"
            :editing="editor"
            @drilldown="handleDrilldown"
            @page-changed="handlePageChange"
            @redraw="handleRedraw"
            @update-limit="updateLimit"
          ></trend-insights-chart>
          <zoined-chart
            v-else-if="blockToUse == 'chart'"
            :type="component.name"
            :component="component"
            :filters="effectiveFilters"
            :chart-options="effectiveChartOptions"
            :highchart-options="highchartOptions"
            :pagination="paginationEnabled"
            :limit="component.limit"
            :drilldown="drilldownEnabled"
            :editing="editor"
            @drilldown="handleDrilldown"
            @page-changed="handlePageChange"
            @redraw="handleRedraw"
            @update-limit="updateLimit"
            @update-component="$emit('componentUpdated', $event)"
          ></zoined-chart>
        </div>
      </div>
      <div
        v-if="hasDetails"
        class="panel-footer show-more intercom-tag-detail-chart-toggle"
        :class="{ collapsed: !isDetailsOpen }"
        @click="$emit('toggleDetails', component)"
      >
        <svg-icon class="chevron" :name="isDetailsOpen ? 'chevron-wide-up' : 'chevron-wide-down'"></svg-icon>
      </div>
    </drilldown-provider>
    <heatmap-settings-modal
      ref="heatmapSettingsModal"
      :component="component"
      :filter-configuration="effectiveFilterConfiguration"
      @update="updateChartOptions($event)"
    ></heatmap-settings-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import zoinedSnippet from "../zoined-snippet/zoined-snippet.vue";
import zoinedChart from "../zoined-chart/zoined-chart.vue";
import trendBlock from "../dashboard/blocks/trend.vue";
import dashboardSnippet from "../dashboard/dashboard-snippet/dashboard-snippet.vue";
import * as _ from "lodash";
import { refreshFilterTimes, componentFilters } from "../lib/filter-util";
import I18n from "../i18n";
import proportionsSelector from "../components/proportions-selector.vue";
import legends from "../components/legends.vue";
import tableColumnSelector, { TableColumnSelectorConfig } from "../components/table-column-selector.vue";
import chartTypeSelector from "../components/chart-type-selector.vue";
import dataLabelsSelector from "../components/data-labels-selector.vue";
import roundValuesSelector from "../components/round-values-selector.vue";
import insightChart from "../charts/insight-chart.vue";
import heatmapSettingsModal from "../components/heatmap-settings-modal.vue";
import tableSettingsModal from "../components/table-settings-modal.vue";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import ChartOptions from "../model/chart-options";
import TableConfig from "../model/table-config";
import { asArray } from "../lib/array-utils";
import SvgIcon from "../components/svg-icon.vue";
import DrilldownDropdown from "../drilldown/drilldown-dropdown.vue";
import DrilldownProvider from "../drilldown/drilldown-provider.vue";
import TrendSortSelector from "@/components/trend-sort-selector.vue";
import TrendDirectionSelector from "@/components/trend-direction-selector.vue";
import TrendChartVariantSelector from "@/components/trend-chart-variant-selector.vue";
import TrendInsightsChart from "@/charts/trend-insights-chart.vue";
import ExcelButton from "@/components/excel-button.vue";
import ActiveFilters from "@/components/active-filters/active-filters.vue";
import IconButton from "@/components/icon-button.vue";
import Component from "@/model/component";
import FlatFilterConfiguration from "@/model/flat-filter-configuration";
import HighchartOptions from "@/model/highchart-options";
import HeatmapSettingsModal from "@/components/heatmap-settings-modal.vue";

function metricFromComponentName(name) {
  if (name.startsWith("top_")) {
    return name.substring(4);
  }
  if (name.endsWith("_col") || name.endsWith("_bar")) {
    return name.substring(0, name.length - 4);
  }
  return name;
}

export default defineComponent({
  components: {
    zoinedSnippet,
    zoinedChart,
    trendBlock,
    dashboardSnippet,
    proportionsSelector,
    legends,
    tableColumnSelector,
    chartTypeSelector,
    dataLabelsSelector,
    roundValuesSelector,
    insightChart,
    heatmapSettingsModal,
    tableSettingsModal,
    SvgIcon,
    DrilldownDropdown,
    DrilldownProvider,
    TrendSortSelector,
    TrendDirectionSelector,
    TrendChartVariantSelector,
    TrendInsightsChart,
    ExcelButton,
    ActiveFilters,
    IconButton,
  },
  props: {
    component: {
      type: Object as PropType<Component>,
      required: true,
    },
    filterConfiguration: {
      type: Object as PropType<FlatFilterConfiguration>,
      required: true,
    },
    chartOptions: { default: () => ({}), type: Object as PropType<ChartOptions> },
    tableConfig: {
      type: Object as PropType<TableConfig>,
      default: null,
    },
    highchartOptions: {
      type: Object as PropType<HighchartOptions>,
      default: null,
    },
    isDetailsOpen: {
      type: Boolean,
    },
    editor: {
      type: Boolean,
    },
    preview: {
      type: Boolean,
    },
    showFilterConfiguration: {
      type: Boolean,
    },
    custom: {
      type: Boolean,
    },
    dashboard: {
      type: Boolean,
    },
    public: { default: false, type: Boolean },
    saveable: { default: false, type: Boolean },
    controlsEnabled: { default: true, type: Boolean },
    editable: { default: false, type: Boolean },
    duplicatable: { default: false, type: Boolean },
    removable: { default: false, type: Boolean },
  },
  emits: [
    "edit",
    "duplicate",
    "remove",
    "save",
    "data",
    "toggleDetails",
    "excel-export-component",
    "componentUpdated",
    "chart-options-updated",
    "filter-configuration-updated",
    "table-config-updated",
    "highchart-options-updated",
    "redraw",
    "drilldown",
    "pageChanged",
  ],
  data() {
    return {
      selectedItem: null,
      chartData: null,
      detailChartData: null,
      fullScreen: false,
    };
  },
  computed: {
    chartType() {
      return this.effectiveChartOptions.inverted ? "bar" : "column";
    },
    isNotification() {
      return this.component.name.includes("_notifications");
    },
    componentClass() {
      return {
        dashboard_snippet: "is-metric-snippet",
        metric_chart: "is-metric-chart",
        snippet: "is-table",
        trend: "is-trend",
        chart: "is-chart",
        trend_insights: "is-chart",
      }[this.blockToUse];
    },
    metricClass() {
      if (this.blockToUse == "dashboard_snippet") {
        const metric = this.effectiveFilters.metrics[0];
        return `metric-${metric}`;
      }
      return null;
    },
    isIndex() {
      return this.effectiveFilters.metric_type == "index";
    },
    blockToUse() {
      if (this.component.type == "snippet") {
        return "snippet";
      }
      if (this.component.name == "dashboard_snippet") {
        return "dashboard_snippet";
      }
      if (this.component.name == "metric_chart") {
        return "metric_chart";
      }
      if (this.component.name == "insight") {
        return "insight_chart";
      }
      if (this.component.name.indexOf("_trend") >= 0) {
        return "trend";
      }
      if (this.component.name == "trend_insights") {
        return "trend_insights";
      }
      return "chart";
    },
    showRoundValuesSelector() {
      return this.component.name == "cross_table" || this.component.name == "cross_tab_custom";
    },
    showTableColumnSelector() {
      return this.component.name == "cross_table" || this.component.name == "cross_tab_custom";
    },
    showDataLabelsSelector() {
      const { name } = this.component;
      return (
        name == "metric_chart" ||
        name == "dual_axis" ||
        name == "dual_axis_with_weather" ||
        name == "heatmap" ||
        name == "line"
      );
    },
    showProportionsSelector() {
      const { name } = this.component;
      return (
        name == "metric_chart" ||
        name == "dual_axis" ||
        name == "dual_axis_with_weather" ||
        name == "stacked" ||
        name == "heatmap" ||
        name == "line"
      );
    },
    showChartTypeSelector() {
      return (
        this.component.name == "dual_axis" ||
        this.component.name == "dual_axis_with_weather" ||
        this.component.name == "stacked"
      );
    },
    showHeatmapSettings() {
      return this.component.name == "heatmap";
    },
    showTableSettings() {
      return this.component.name == "cross_table" || this.component.name == "cross_tab_custom";
    },
    showTrendSortSelector() {
      return this.component.name === "trend_insights" || this.component.name === "insight";
    },
    showTrendDirectionSelector() {
      return this.component.name === "trend_insights";
    },
    showChartVariantSelector() {
      return this.component.name === "trend_insights";
    },
    fullScreenEnabled() {
      return this.component.name == "cross_table" || this.component.name == "cross_tab_custom";
    },
    hasDetails() {
      return (
        !this.preview &&
        !this.public &&
        (this.blockToUse == "dashboard_snippet" ||
          this.blockToUse == "metric_chart" ||
          (this.blockToUse == "snippet" && this.component.name.includes("_notifications")))
      );
    },
    effectiveChartOptions() {
      return { ...this.chartOptions, ...this.component.chartOptions };
    },
    effectiveFilters() {
      const { currency } = window.zoinedContext.currency;
      return {
        ...componentFilters(this.filterConfiguration, this.component),
        currency,
      };
    },
    effectiveFilterConfiguration() {
      const filterConfiguration = {
        ...this.filterConfiguration,
        ...this.component.filterConfiguration,
      };

      return refreshFilterTimes(filterConfiguration);
    },
    effectiveTableConfig() {
      return this.dashboard ? this.component.tableConfig : this.tableConfig;
    },
    style() {
      const style: Record<string, string> = {};
      if (this.component.height != null) {
        style.height = this.component.height + (this.component.height == "auto" ? "" : "px");
      }
      return style;
    },
    title() {
      if (this.component.custom_title) {
        return this.component.custom_title;
      } else if (this.component.show_title) {
        const metric_id = metricFromComponentName(this.component.name);
        const metric_label = this.metrics?.find((item) => menuItemKey(item) === metric_id)?.name;
        const title_key = (() => {
          if (this.component.title != null && this.component.title.match("\\.")) {
            return this.component.title;
          }
          if (this.component.title != null) {
            return `${this.component.type}.${this.component.name}.${this.component.title}`;
          }
          return `${this.component.type}.${this.component.name}.title`;
        })();
        return I18n.t(title_key, {
          metric: metric_label,
          defaultValue: metric_label,
        });
      } else {
        return this.metricTitle(this.effectiveFilters.metrics);
      }
    },
    secondaryTitle() {
      if (!this.component.custom_title && !this.component.show_title) {
        return this.metricTitle(this.effectiveFilters.metrics2);
      }
      return null;
    },
    tableColumnConfig() {
      const {
        show_percentual_change = false,
        show_absolute_change = false,
        show_index = false,
        proportions = false,
        column_proportions = false,
      } = {
        ...this.chartOptions,
        ...this.component.filterConfiguration,
      };

      return {
        changePercent: !!show_percentual_change,
        absoluteChange: !!show_absolute_change,
        changeIndex: !!show_index,
        proportions: !!proportions,
        columnProportions: !!column_proportions,
      };
    },
    paginationEnabled() {
      return this.component.pagination && !this.preview;
    },
    drilldownEnabled() {
      return (
        (this.blockToUse === "chart" ||
          this.blockToUse === "trend" ||
          this.blockToUse === "metric_chart" ||
          this.blockToUse === "trend_insights") &&
        !this.component.static &&
        this.component.drilldown !== false &&
        !this.preview
      );
    },
    showLegendSymbol() {
      return (
        this.component.name == "dual_axis" ||
        this.component.name == "dual_axis_with_weather" ||
        this.component.name == "dashboard_snippet" ||
        this.component.name == "metric_chart"
      );
    },
    excelExportEnabled() {
      return window.zoinedContext.allowExport && this.component.name !== "dashboard_snippet";
    },
    metrics(): MenuItem[] {
      return this.$store.getters.getParameters("metrics");
    },
    groupings(): MenuItem[] {
      return this.$store.getters.getParameters("grouping");
    },
    sort() {
      const sort = this.effectiveFilters.sort;
      if (_.isArray(sort)) {
        return sort[0];
      } else {
        return sort;
      }
    },
    sortDirection() {
      return /^[-=]/.test(this.sort) ? this.sort[0] : "";
    },
    sortKey(): string {
      return /^[-=+]/.test(this.sort) ? this.sort.substring(1) : this.sort;
    },
    chartVariant() {
      return this.effectiveChartOptions.chart_variant || "value";
    },
  },
  watch: {
    fullScreen: [
      {
        handler: "onFullScreenChanged",
      },
    ],
  },
  methods: {
    metricTitle(metrics) {
      if (!metrics || metrics.length === 0) {
        return null;
      }

      const grouping = asArray(this.effectiveFilters.grouping);

      if (grouping.length > 0) {
        const metricLabel = metrics
          .map((metric) => {
            return this.metrics?.find((item) => menuItemKey(item) === metric)?.name || metric;
          })
          .join(", ");
        const groupingLabel = this.groupings?.find((item) => menuItemKey(item) === grouping[0])?.name;
        if (metricLabel && groupingLabel) {
          return I18n.t("filter.config.metric_by_grouping", { metric: metricLabel, grouping: groupingLabel });
        }
      }

      return this.metrics?.find((item) => menuItemKey(item) === metrics[0])?.name;
    },
    excelExport() {
      this.$emit("excel-export-component", this.component);
    },
    t(key) {
      return I18n.t(key);
    },
    tableColumnConfigUpdated(tableColumnConfig: TableColumnSelectorConfig) {
      const updates = {
        proportions: !!tableColumnConfig.proportions,
        column_proportions: !!tableColumnConfig.columnProportions,
        show_absolute_change: !!tableColumnConfig.absoluteChange,
        show_index: !!tableColumnConfig.changeIndex,
        show_percentual_change: !!tableColumnConfig.changePercent,
      };
      if (this.dashboard) {
        const component = {
          ...this.component,
          filterConfiguration: {
            ...this.component.filterConfiguration,
            ...updates,
          },
        };
        this.$emit("componentUpdated", component);
      } else {
        this.$emit("chart-options-updated", {
          ...this.chartOptions,
          ...updates,
        });
      }
    },
    updateDataLabels(data_labels) {
      if (this.dashboard) {
        const component = {
          ...this.component,
          chartOptions: {
            ...this.component.chartOptions,
            data_labels,
          },
        };
        this.$emit("componentUpdated", component);
      } else {
        this.$emit("chart-options-updated", { ...this.chartOptions, data_labels });
      }
    },
    updateProportions(proportions) {
      if (this.dashboard) {
        const component = {
          ...this.component,
          filterConfiguration: {
            ...this.component.filterConfiguration,
            proportions,
          },
        };
        this.$emit("componentUpdated", component);
      } else {
        this.$emit("filter-configuration-updated", {
          ...this.filterConfiguration,
          proportions,
        });
      }
    },
    updateRoundValues(round_values) {
      if (this.dashboard) {
        const component = {
          ...this.component,
          filterConfiguration: {
            ...this.component.filterConfiguration,
            round_values,
          },
        };
        this.$emit("componentUpdated", component);
      } else {
        this.$emit("filter-configuration-updated", {
          ...this.filterConfiguration,
          round_values,
        });
      }
    },
    updateChartType(type) {
      const inverted = type === "bar";

      if (this.dashboard) {
        const component = {
          ...this.component,
          chartOptions: {
            ...this.component.chartOptions,
            inverted,
          },
        };
        this.$emit("componentUpdated", component);
      } else {
        this.$emit("chart-options-updated", {
          ...this.chartOptions,
          inverted,
        });
      }
    },
    updateChartOptions(chartOptions) {
      if (this.dashboard) {
        const component = {
          ...this.component,
          chartOptions,
        };
        this.$emit("componentUpdated", component);
      } else {
        this.$emit("chart-options-updated", chartOptions);
      }
    },
    updateTableConfig(tableConfig) {
      if (this.dashboard) {
        this.$emit("componentUpdated", {
          ...this.component,
          tableConfig,
        });
      } else {
        this.$emit("table-config-updated", tableConfig);
      }
    },
    updateLimit(limit) {
      if (this.dashboard) {
        this.$emit("componentUpdated", {
          ...this.component,
          filterConfiguration: {
            ...this.component.filterConfiguration,
            limit,
          },
        });
      } else {
        this.$emit("filter-configuration-updated", {
          ...this.filterConfiguration,
          limit,
        });
      }
    },
    updateFilterConfiguration(filterConfiguration) {
      const config = {
        ...this.filterConfiguration,
        ...filterConfiguration,
      };
      this.$emit("filter-configuration-updated", config);
    },
    updateComponentFilterConfiguration(filterConfiguration) {
      this.$emit("componentUpdated", {
        ...this.component,
        filterConfiguration,
      });
    },
    onSnippetData(data) {
      this.$emit("data", data);
      if (this.fullScreen) {
        this.updateFullscreen();
      }
    },
    updateFullscreen() {
      setTimeout(() => {
        // set sticky header positions
        document.querySelectorAll(".full-screen .sticky-left").forEach((element: HTMLElement) => {
          element.style.left = `${element.offsetLeft}px`;
        });
        document.querySelectorAll(".full-screen .sticky-top").forEach((element: HTMLElement) => {
          element.style.top = `${element.offsetTop}px`;
        });
      }, 200);
    },
    cleanupFullscreen() {
      document.querySelectorAll(".sticky-left").forEach((element: HTMLElement) => {
        element.style.removeProperty("left");
      });
      document.querySelectorAll(".sticky-top").forEach((element: HTMLElement) => {
        element.style.removeProperty("top");
      });
    },
    handleRedraw(chart) {
      const { series } = chart;
      let highchartOptions: { series: any[] } = {
        series: series.map(({ name, visible }) => ({ name, visible })),
      };
      // Set as null if all series are visible so we don't show the report as unsaved if it has no highchartOptions
      if (highchartOptions.series.every(({ visible }) => visible)) {
        highchartOptions = null;
      }
      if (this.dashboard) {
        if (highchartOptions) {
          this.$emit("componentUpdated", {
            ...this.component,
            highchartOptions,
          });
        } else {
          const { highchartOptions, ...component } = this.component;
          this.$emit("componentUpdated", component);
        }
      } else {
        this.$emit("highchart-options-updated", highchartOptions);
      }
      this.$emit("redraw", chart);
    },
    handleDrilldown(filters) {
      this.$emit("drilldown", filters);
    },
    handlePageChange(info) {
      this.$emit("pageChanged", info);
    },
    onSortDirectionChange(sortDirection) {
      this.onSortChange(sortDirection + this.sortKey);
    },
    onSortKeyChange(sortKey) {
      this.onSortChange(this.sortDirection + sortKey);
    },
    onSortChange(sort) {
      this.$emit("componentUpdated", {
        ...this.component,
        filterConfiguration: {
          ...this.component.filterConfiguration,
          sort: {
            [sort]: { enabled: true },
          },
        },
      });
    },
    onChartVariantChange(chartVariant) {
      this.$emit("componentUpdated", {
        ...this.component,
        chartOptions: {
          ...this.component.chartOptions,
          chart_variant: chartVariant,
        },
      });
    },
    onFullScreenChanged(fullScreen) {
      if (fullScreen) {
        document.body.classList.add("full-screen");
        this.updateFullscreen();
      } else {
        document.body.classList.remove("full-screen");
        this.cleanupFullscreen();
      }
    },
    onShowHeatmapSettings() {
      (this.$refs.heatmapSettingsModal as typeof HeatmapSettingsModal).show();
    },
  },
});
</script>

<style scoped lang="scss">
.panel-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 16px;

  @media print {
    margin-bottom: 10px;
  }

  > * {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.toolbar {
  flex-grow: 1;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .toolbar {
    flex-grow: 0;
    justify-content: flex-end;
  }
  .panel-title {
    flex-direction: row;
    font-size: 14px;
  }
}

.span-3,
.span-4,
.span-6 {
  .panel-title {
    flex-direction: column;
    gap: 5px;
  }

  .toolbar {
    flex-grow: 1;
    justify-content: space-between;
  }
}

h3 {
  .pull-right {
    .remove {
      i {
        &:hover {
          color: #81e4ff;
          cursor: pointer;
        }
      }
    }
  }
}

.handle {
  &:hover {
    cursor: move;
  }
}

.remove {
  i {
    margin: 0 2px;
  }
}

.report-block {
  @media screen {
    &.full-screen {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding-top: 44px;
      padding-bottom: 50px;
      z-index: 9999;
      background-color: white;

      .panel-snippet {
        height: 100%;
        padding: 0;
        overflow: auto;
        border-radius: 0;

        .panel-heading {
          position: fixed;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 44px;
          padding: 10px;
          z-index: 11000;
          border-bottom: 1px solid var(--color-border-secondary);

          .panel-title {
            visibility: hidden;
          }
        }

        .panel-body {
          padding: 0;
        }
      }

      .panel-snippet .panel-body .snippet {
        // Unset overflow settings to allow sticky position to work
        overflow: unset;

        &::v-deep {
          .zoined-snippet {
            .content {
              overflow: unset;
            }
            table {
              border-collapse: separate;
              border-spacing: 0px;
              margin: 0;

              thead {
                th.sticky-top {
                  position: sticky;
                  background-color: white;
                  z-index: 10001;

                  &.sticky-left {
                    z-index: 10002;
                  }
                }
              }

              tbody {
                td.sticky-left {
                  position: sticky;
                  background-color: white;
                  z-index: 10000;
                }
              }
            }

            .pagination-control {
              position: fixed;
              bottom: 0;
              padding: 10px;
              height: 50px;
              width: 100%;
              background-color: white;
              border-top: 1px solid var(--color-border-secondary);
            }
          }
        }
      }
    }
  }

  &.detail-open {
    .panel-snippet {
      height: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.panel-snippet {
  display: block; // was "flex"
  flex-direction: column;
  margin: 0;

  .panel-body {
    flex: 1;
  }
}

.is-table {
  .panel-snippet {
    display: block;
  }
}

.filter-configuration {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  .filters {
    font-size: 11px;
  }
}

svg.chevron {
  width: 24px;
  height: auto;
}
</style>

<style lang="scss">
body.full-screen {
  overflow: hidden;

  div#hbl-live-chat-wrapper {
    display: none !important;
  }

  .full-screen-hidden {
    display: none;
  }
}
</style>
