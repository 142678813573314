<template>
  <div class="business-hours-day-selector flex-row align-items-center flex-wrap gap-sm">
    <div class="title">{{ title }}</div>
    <pill-button
      v-if="!modelValue || modelValue.length == 0"
      :label="$t('components.business_hours_editor.closed')"
      :class="{ shaded: isDefault }"
      :removable="false"
    ></pill-button>
    <pill-button
      v-for="(item, index) in modelValue"
      :key="index"
      :label="itemLabel(item)"
      :class="{ shaded: isDefault }"
      @remove="removeItem(item)"
    ></pill-button>
    <dropdown ref="dropdown" v-model="menuOpen" class="inline-block" tag="span">
      <plus-button class="dropdown-toggle"></plus-button>
      <template #dropdown>
        <div class="day-form-dropdown">
          <form class="day-form" name="dayForm" novalidate @submit.prevent="onSubmit">
            <div class="form-row">
              <label class="control-label">Opening time</label>
              <time-picker v-model="model.start_time" :show-meridian="false"></time-picker>
            </div>
            <div class="form-row">
              <label class="control-label">Closing time</label>
              <time-picker v-model="model.end_time" :show-meridian="false"></time-picker>
            </div>
            <div class="day-form-buttons">
              <button class="btn" type="button" @click="menuOpen = false">{{ $t("actions.cancel") }}</button>
              <button class="btn btn-primary" type="submit">{{ $t("actions.add") }}</button>
            </div>
          </form>
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script>
import pillButton from "./pill-button";
import { Dropdown, TimePicker } from "uiv";
import moment from "moment";
import PlusButton from "@/components/buttons/plus-button.vue";
export default {
  components: {
    pillButton,
    Dropdown,
    TimePicker,
    PlusButton,
  },
  props: {
    day: { type: Number, required: true },
    modelValue: { type: Array, default: () => [] },
    isDefault: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      menuOpen: false,
      model: this.defaultModel(),
    };
  },
  computed: {
    title() {
      // Correct index for sunday
      const day = this.day === 7 ? 0 : this.day;

      return this.capitalize(I18n.t("date.day_names." + day));
    },
  },
  methods: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    itemLabel({ start_time, end_time }) {
      start_time = moment(start_time, "HH:mm:ss").format("HH:mm");
      end_time = moment(end_time, "HH:mm:ss").format("HH:mm");
      return `${start_time} - ${end_time}`;
    },
    defaultModel() {
      const start_time = new Date(),
        end_time = new Date();

      start_time.setHours(9, 0, 0);
      end_time.setHours(17, 0, 0);
      return { start_time, end_time };
    },
    removeItem(item) {
      this.$emit(
        "update:modelValue",
        this.modelValue.filter((each) => each !== item)
      );
    },
    onToggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    onSubmit() {
      const item = {
        weekday: this.day,
        start_time: moment(this.model.start_time).format("HH:mm:ss"),
        end_time: moment(this.model.end_time).format("HH:mm:ss"),
      };
      this.$emit("update:modelValue", [...this.modelValue, item]);
      this.menuOpen = false;
      this.model = this.defaultModel();
    },
  },
};
</script>

<style lang="scss" scoped>
.business-hours-day-selector {
  margin-bottom: 5px;

  &:not(:last-child) {
    margin-right: 15px;
  }
  .title {
    display: inline-block;
    margin-right: 10px;
  }

  .pill-button.shaded {
    opacity: 0.5;
  }

  .add-button {
    padding: 4px 7px !important;
  }
  .day-form-dropdown {
    min-width: 250px;
  }
  .day-form {
    padding: 6px;
  }

  .day-form-buttons {
    padding-top: 10px;
    text-align: right;

    .btn + .btn {
      margin-left: 5px;
    }
  }

  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    label {
      display: inline-block;
      margin-right: 10px;
    }
  }
}
</style>
