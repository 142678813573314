import VueApp from "./vue-app";
import { createVueApp } from "./create-vue-app";

document.addEventListener("DOMContentLoaded", () => {
  const element = document.getElementById("vue-app");
  if (element) {
    const app = createVueApp(VueApp);

    app.mount(element);
  }
});
