<template>
  <div class="metric-comparison-section">
    <div class="option">
      <label>{{ $t("filter.config.metrics") }}</label>
      <select :value="section.metric" @input="updateMetric">
        <option v-for="option in metricsOptions" :key="menuItemKey(option)" :value="menuItemKey(option)">{{
          option.name
        }}</option>
      </select>
    </div>
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.common.time_period") }}</label>
      <timePeriodDropdown
        :show-title="false"
        :custom="false"
        :available-items="timePeriods"
        :available-series-types="seriesTypes"
        :value="selection"
        @update="updateSelection"
      ></timePeriodDropdown>
    </div>
    <div v-if="openingHoursDefined" class="option">
      <label>{{ $t("filter.config.limit_to_business_hours") }}</label>
      <businessHoursSelector
        :title="false"
        :value="section.limit_to_business_hours"
        @update="updateLimitToBusinessHours"
      ></businessHoursSelector>
    </div>
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.common.comparison") }}</label>
      <comparisonPillList
        :choices="comparisons"
        :custom="false"
        :title="null"
        :comparisons="section.comparisons"
        @update="updateComparisons"
      ></comparisonPillList>
    </div>
    <div
      v-if="lflEnabled && section.comparisons.length == 1 && isTimeComparison(section.comparisons[0].type)"
      class="option"
    >
      <label>{{ $t("filter.config.like_for_like") }}</label>
      <likeForLikeSelector
        :config="section.like_for_like"
        :title="null"
        @update="updateLikeForLike"
      ></likeForLikeSelector>
    </div>
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.metric_comparison.comparison_text") }}</label>
      <comparisonSelector
        :model-value="section.comparison"
        :choices="comparisonTexts"
        @update:model-value="updateComparison"
      ></comparisonSelector>
    </div>
    <div v-if="insightsVisible && section.metric" class="option">
      <label>{{ $t("newsletter.definition.insights.title") }}</label>
      <newsletterSectionInsights
        :metric="section.metric"
        :configuration="section.insights"
        @change="updateInsights"
      ></newsletterSectionInsights>
    </div>
    <div v-if="newsletterType == 'alert'" class="option">
      <label>{{ $t("newsletter.definition.alert.alert_conditions") }}</label>
      <div style="margin-left: 200px;">
        <newsletterSectionAlertConditions
          :config="section.alert"
          :default-metric="section.metric"
          @update="updateAlert"
        ></newsletterSectionAlertConditions>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import { timeComparisonTypes } from "../../filters/time-period";
import MenuItem, { menuItemKey } from "../../interfaces/menu-item";
import { defineComponent, PropType } from "vue";
import timePeriodDropdown from "../../components/time-period-dropdown.vue";
import businessHoursSelector from "../../components/business-hours-selector.vue";
import comparisonPillList from "../../components/comparison-pill-list.vue";
import likeForLikeSelector from "../../components/like-for-like-selector.vue";
import newsletterSectionInsights from "../newsletter-section-insights.vue";
import newsletterSectionAlertConditions from "../newsletter-section-alert-conditions.vue";
import comparisonSelector from "../../components/comparison-selector.vue";
import i18n from "../../i18n";

export default defineComponent({
  components: {
    timePeriodDropdown,
    businessHoursSelector,
    comparisonPillList,
    likeForLikeSelector,
    newsletterSectionInsights,
    newsletterSectionAlertConditions,
    comparisonSelector,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    newsletterType: {
      type: String,
      required: true,
    },
    timePeriods: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    seriesTypes: {
      type: Array as PropType<string[]>,
      required: true,
    },
    openingHoursDefined: {
      type: Boolean,
    },
    lflEnabled: {
      type: Boolean,
    },
    insightsVisible: {
      type: Boolean,
    },
  },
  emits: ["update-section"],
  data() {
    return {
      menuItemKey,
    };
  },
  computed: {
    selection() {
      return (
        this.section.time_period && {
          type: this.section.time_period,
          series: this.section.time_series_type,
        }
      );
    },
    metricsOptions(): MenuItem[] {
      return this.$store.state.parameters.metrics.all;
    },
    comparisons() {
      const allowedComparisonTypes = [
        "prev_year_corresponding",
        "2_years_back_corresponding",
        "3_years_back_corresponding",
        "prev_year_corresponding_same_weekday_ending",
        "prev_year_same_days",
        "prev_year_full_month",
        "previous_corresponding_period",
        "previous_year_lfl",
        "2_years_back_lfl",
        "3_years_back_lfl",
        "budget",
      ];

      return this.$store.state.parameters.comparisons.all?.filter(({ type }) => allowedComparisonTypes.includes(type));
    },
    comparisonTexts() {
      return (
        this.comparisons && [
          { type: "no_comparison", name: i18n.t("filters.benchmark.no_comparison") },
          ...this.comparisons,
        ]
      );
    },
  },
  methods: {
    updateMetric(event) {
      const metric = event.target.value;
      this.updateSection({ ...this.section, metric, insights: null });
    },
    updateSelection({ type, series }) {
      this.updateSection({ ...this.section, time_period: type, time_series_type: series });
    },
    updateLimitToBusinessHours(limit_to_business_hours) {
      this.updateSection({ ...this.section, limit_to_business_hours });
    },
    updateComparisons(comparisons) {
      this.updateSection({ ...this.section, comparisons });
    },
    updateComparison(comparison) {
      this.updateSection({ ...this.section, comparison });
    },
    updateLikeForLike(like_for_like) {
      this.updateSection({ ...this.section, like_for_like });
    },
    updateInsights(insights) {
      this.updateSection({ ...this.section, insights });
    },
    updateAlert(alert) {
      this.updateSection({ ...this.section, alert });
    },
    updateSection(section) {
      this.$emit("update-section", section);
    },
    isTimeComparison(type) {
      return _.includes(timeComparisonTypes, type);
    },
  },
});
</script>
