<template>
  <div class="data-management">
    <div v-if="successText" class="row">
      <div class="col-md-12">
        <div class="alert alert-success">{{ successText }}</div>
      </div>
    </div>
    <div v-if="errorText" class="row">
      <div class="col-md-12">
        <div class="alert alert-danger">{{ errorText }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>{{ $t("company_admin.data_management.data_type") }}</h4>
        <p>{{ $t("company_admin.data_management.data_type_description") }}</p>
      </div>
      <div class="col-md-12">
        <select v-model="dataType">
          <option v-for="type in sortedDataTypes" :key="type.type" :value="type.type">{{ type.title }}</option>
        </select>
      </div>
      <div class="col-md-12">
        <h4>{{ $t("company_admin.data_management.download_data") }}</h4>
        <p>{{ $t("company_admin.data_management.download_data_description") }}</p>
      </div>
    </div>
    <div class="row mb-md">
      <div class="col-md-4">
        <h5>{{ $t("attributes.start_date") }}</h5>
        <date-picker v-model="startDate"></date-picker>
      </div>
      <div class="col-md-4">
        <h5>{{ $t("attributes.end_date") }}</h5>
        <date-picker v-model="endDate"></date-picker>
      </div>
    </div>
    <div class="row">
      <div v-if="dataType == 'flat_hierarchies'" class="col-md-12 mb-md">
        <h5>{{ $t("metadata.category.FlatHierarchies") }}</h5>
        <select v-model="hierarchyGroup">
          <option :value="null">{{ $t("filter.config.all") }}</option>
          <option v-for="group in hierarchyGroups" :key="group.group" :value="group.group">{{ group.title }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a class="btn btn-primary" @click="downloadData">{{ $t("company_admin.data_management.download_data") }}</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>{{ $t("company_admin.data_management.upload_data_title") }}</h4>
        <p>{{ $t("company_admin.data_management.upload_data_description") }}</p>
      </div>
      <div class="col-md-12">
        <drop-zone
          :key="dataType"
          method="post"
          :url="uploadUrl"
          :accepted-files="acceptedFiles"
          @uploaded="success"
          @error-upload="error"
          @added-file="fileAdded"
        >
          <template #message>
            {{ $t("company_admin.data_management.file_upload_message") }}
          </template>
        </drop-zone>
      </div>
    </div>
    <div v-if="events.length > 0" class="row">
      <div class="col-md-12">
        <h4>{{ $t("company_admin.data_management.uploaded_data_title") }}</h4>
        <p>{{ $t("company_admin.data_management.uploaded_data_desc") }}</p>
      </div>
    </div>
    <div v-if="events.length > 0" class="row">
      <div class="col-md-12">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("company_admin.data_management.data_type") }}</th>
              <th>{{ $t("company_admin.data_management.upload_time") }}</th>
              <th>{{ $t("company_admin.data_management.uploader") }}</th>
              <th>{{ $t("company_admin.data_management.upload_status") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="event in events" :key="event.id">
              <td>{{ dataTypesHash[event.data.type].title }}</td>
              <td>{{ event.timestamp }}</td>
              <td>{{ event.user && event.user.name }}</td>
              <td>
                <span v-if="event.data.status == 'validation_error'" class="error"
                  >{{ $t("company_admin.data_management.validation_error")
                  }}<span v-if="event.data.message">: {{ event.data.message }}</span></span
                ><span v-if="event.data.status == 'failed'" class="error">{{
                  $t("company_admin.data_management.upload_failed")
                }}</span
                ><span v-if="!event.data.status || event.data.status == 'success'">{{
                  $t("company_admin.data_management.data_uploaded")
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import I18n from "../i18n";
import { defineComponent } from "vue";
import EventsApiService from "../api/events-api-service";
import { getDownloadUrl } from "../channel/file-download-channel";
import { v4 as uuidv4 } from "uuid";
import { downloadFile } from "../lib/download";
import _ from "lodash";
import { makeApiInstance } from "../api/instance";
import { showOverlay, hideOverlay } from "../ui/overlay";
import DatePicker from "@/components/date-picker.vue";
import moment from "moment";
import { DropZone } from "dropzone-vue";

type DataType = {
  type: string;
  title: string;
};

const DATA_TYPES: DataType[] = [
  {
    type: "sales",
    title: I18n.t("data_maintenance.types.sales_transactions"),
  },
  {
    type: "sales_summaries",
    title: I18n.t("metadata.category.SalesSummaries"),
  },
  {
    type: "employee_hours",
    title: I18n.t("metadata.category.WorkHours"),
  },
  {
    type: "workshifts",
    title: I18n.t("metadata.category.WorkShift"),
  },
  {
    type: "purchase_orders",
    title: I18n.t("metadata.category.PurchaseOrders"),
  },
  {
    type: "sales_offers",
    title: I18n.t("metadata.category.SalesOffers"),
  },
  {
    type: "sales_orders",
    title: I18n.t("metadata.category.SalesOrders"),
  },
  {
    type: "sales_payments",
    title: I18n.t("metadata.category.SalesPayments"),
  },
  {
    type: "visitors",
    title: I18n.t("metadata.category.Visitors"),
  },
  {
    type: "calendar",
    title: I18n.t("metadata.category.Calendar"),
  },
  {
    type: "products",
    title: I18n.t("metadata.category.Products"),
  },
  {
    type: "customers",
    title: I18n.t("metadata.category.Customers"),
  },
  {
    type: "customer_feedbacks",
    title: I18n.t("metadata.category.CustomerFeedback"),
  },
  {
    type: "organisations",
    title: I18n.t("metadata.category.Organisations"),
  },
  {
    type: "departments",
    title: I18n.t("metadata.category.Departments"),
  },
  {
    type: "sales_persons",
    title: I18n.t("metadata.category.Salespersons"),
  },
  {
    type: "campaigns",
    title: I18n.t("metadata.category.Campaigns"),
  },
  {
    type: "currency_rates",
    title: I18n.t("metadata.category.Currencyrates"),
  },
  {
    type: "suppliers",
    title: I18n.t("metadata.category.Suppliers"),
  },
  {
    type: "flat_hierarchies",
    title: I18n.t("metadata.category.FlatHierarchies"),
  },
  {
    type: "inventory_transactions",
    title: I18n.t("data_maintenance.types.inventory_transactions"),
  },
  {
    type: "inventory_snapshots",
    title: I18n.t("data_maintenance.types.inventory_snapshots"),
  },
  {
    type: "payment_types",
    title: I18n.t("metadata.parameters.PaymentType"),
  },
  {
    type: "accounts",
    title: I18n.t("metadata.category.Accounts"),
  },
  {
    type: "accounting",
    title: I18n.t("metadata.category.Accounting"),
  },
  {
    type: "staff_costs",
    title: I18n.t("data_maintenance.types.staff_costs"),
  },
];

export default defineComponent({
  components: {
    DropZone,
    DatePicker,
  },
  data() {
    return {
      dataType: null,
      successText: null,
      errorText: null,
      events: [],
      fetchEventsIntervalId: null,
      hierarchyGroup: null,
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    companyId() {
      return window.zoinedContext.companyId;
    },
    dataTypes() {
      return DATA_TYPES;
    },
    sortedDataTypes(): DataType[] {
      return _.sortBy(this.dataTypes, "title");
    },
    hierarchyGroups() {
      return ["account", "customer", "organisation", "product"].map((group) => ({
        group,
        title: I18n.t(`flat_hierarchies.hierarchy_groups.${group}`),
      }));
    },
    acceptedFiles() {
      return ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    },
    uploadUrl() {
      return `${location.origin}/api/admin/companies/${this.companyId}/datasets/${this.dataType}/upload`;
    },
    selectedDataType() {
      return this.dataTypes.find((dataType) => dataType.type == this.dataType);
    },
    dataUrl() {
      if (!this.dataType) {
        return null;
      }
      return `/api/admin/companies/${this.companyId}/datasets/${this.dataType}`;
    },
    dataTypesHash(): Record<string, DataType> {
      return _.fromPairs(this.dataTypes.map((dataType) => [dataType.type, dataType]));
    },
  },
  created() {
    this.dataType = this.sortedDataTypes[0].type;

    this.fetchEvents();
    this.fetchEventsIntervalId = setInterval(() => {
      this.fetchEvents();
    }, 10000);
  },
  unmounted() {
    clearInterval(this.fetchEventsIntervalId);
  },
  methods: {
    fileAdded() {
      this.successText = this.errorText = null;
    },
    success() {
      this.fetchEvents();
      this.successText = I18n.t("company_admin.data_management.file_upload_success_message");
    },
    error() {
      this.errorText = I18n.t("company_admin.data_management.file_upload_error_message");
    },
    fetchEvents() {
      new EventsApiService().getEvents({ eventType: "data_upload" }).then((events) => (this.events = events));
    },
    async downloadData() {
      if (!this.dataType) {
        return;
      }
      const download_id = uuidv4();
      const data: Record<string, string> = {
        download_id,
      };
      if (this.dataType == "flat_hierarchies" && this.hierarchyGroup) {
        data.hierarchy_group = this.hierarchyGroup;
      }
      if (this.startDate) {
        data.start = moment(this.startDate).format("YYYY-MM-DD");
      }
      if (this.endDate) {
        data.end = moment(this.endDate).format("YYYY-MM-DD");
      }

      showOverlay(I18n.t("processing_export_file"));
      try {
        // Listen for export finish
        const url = await getDownloadUrl(download_id, {
          connected: () => {
            // Start download
            makeApiInstance()
              .post(`/api/admin/companies/${this.companyId}/datasets/${this.dataType}/download`, data)
              .catch(() => {
                (window.Rollbar || console).error("Data download failed. Download id: " + download_id);
                this.errorText = "Downloading failed.";
                hideOverlay();
              });
          },
        });

        // Wait until export is finished, and then download it
        await downloadFile(url);
      } catch {
        (window.Rollbar || console).error("Data download failed. Download id: " + download_id);
        this.errorText = "Downloading failed.";
      } finally {
        hideOverlay();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.row.margin-bottom-fix-50 {
  margin-bottom: 50px;
}

table td .error {
  color: red;
}

.dropzone {
  border: 2px solid #e5e5e5;
  font-family: Arial, sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  background: #fff;
  transition: 0.2s linear;
  padding: 20px;
  min-height: 150px;
}
</style>
