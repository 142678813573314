<template>
  <div class="filter-category-section" :class="{ open }">
    <div class="flex-row cursor-pointer category-title-row" @click="open = !open">
      <div class="flex-1 category-title">{{ category }}</div>
      <i class="fa fa-sm intercom-tag-filters-category-toggle" :class="open ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
    </div>
    <div v-if="open" class="contents">
      <div class="p-md mt-md flex-row align-items-center flex-wrap gap-md column-gap-xxl">
        <filter-selector
          v-for="filter in enabledFilters"
          :key="filter.id"
          :filter="filter"
          :config="filtersConfiguration.filters[filter.id]"
          @update="updateFilter(filter.id, $event)"
        ></filter-selector>
        <div
          v-if="isLimited && limitedFilters.length > 0"
          class="more-btn"
          @mouseenter="showMore = true"
          @click="showMore = !showMore"
        >
          <more-with-pro-text></more-with-pro-text>
        </div>
        <div
          v-if="isLimited && limitedFilters.length > 0"
          class="flex-row gap-md align-items-flex-start sm-align-items-center"
        >
          <transition name="fade" appear>
            <div v-if="showMore" class="limited-filters flex-row align-items-center flex-wrap gap-md column-gap-xxl">
              <filter-selector
                v-for="filter in limitedFilters"
                :key="filter.id"
                :filter="filter"
                :disabled="true"
              ></filter-selector
              ><a class="btn btn-emphasis btn-rect btn-sm">{{ $t("limited_version.get_pro") }}</a>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import FilterSelector from "../components/filter-selector.vue";
import Filter from "main/model/filter";
import FiltersConfiguration from "main/model/filters-configuration";
import moreWithProText from "main/components/more-with-pro-text.vue";
import _ from "lodash";
import FilterConfigurationItem from "@/model/filter-configuration-item";

export default defineComponent({
  components: {
    FilterSelector,
    moreWithProText,
  },
  props: {
    category: {
      type: String,
      required: true,
    },
    filters: {
      type: Array as PropType<Filter[]>,
      required: true,
    },
    filtersConfiguration: {
      type: Object as PropType<FiltersConfiguration>,
      required: true,
    },
  },
  emits: ["update"],
  data() {
    return {
      open: false,
      showMore: false,
      isLimited: window.zoinedContext.limited,
    };
  },
  computed: {
    enabledFilters() {
      if (!this.isLimited) {
        return this.filters;
      }
      return this.filters.filter((filter) => !this.isLimited || filter.enabled_for_limited);
    },
    limitedFilters() {
      if (!this.isLimited) {
        return [];
      }
      return this.filters.filter((filter) => !filter.enabled_for_limited);
    },
    activeFilters() {
      return this.filters.filter((filter) => !_.isEmpty(this.filtersConfiguration.filters[filter.id]));
    },
    activeSetId() {
      return this.filtersConfiguration.sets?.find(({ enabled }) => enabled)?.id;
    },
    isCleared() {
      return _.isEmpty(this.filtersConfiguration.filters);
    },
  },
  watch: {
    activeSetId: [
      {
        handler: "onActiveSetChanged",
      },
    ],
    isCleared: [
      {
        handler: "onFiltersCleared",
      },
    ],
  },
  created() {
    this.open = this.activeFilters.length > 0;
  },
  methods: {
    updateFilter(filter: string, value: FilterConfigurationItem) {
      this.$emit("update", { filter, value });
      const filtersConfiguration = {
        ...this.filtersConfiguration,
        filters: {
          ...this.filtersConfiguration.filters,
          [filter]: value,
        },
      };
      this.$emit("update", filtersConfiguration);
    },
    onActiveSetChanged() {
      if (this.activeSetId) {
        this.open = this.activeFilters.length > 0;
      }
    },
    onFiltersCleared() {
      if (this.isCleared) {
        this.open = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.filter-category-section {
  color: white;
  padding-bottom: 10px;
  border-bottom: 1px solid #84827d;

  .category-title-row {
    .category-title {
      font-size: 12px;
      text-transform: uppercase;
    }

    &:hover .category-title {
      color: var(--color-text-emphasis-on-dark);
    }
  }

  &.open {
    .category-title {
      color: var(--color-text-emphasis-on-dark);
    }
  }

  .more-btn {
    display: inline-block;
    padding: 10px;
    background-color: #4d4d4d;
    border-radius: 4px;
    margin-bottom: 5px;
  }

  &::v-deep .filter-selector .pill-list {
    margin-bottom: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
