<template>
  <div class="company-settings">
    <spinner v-if="!resolved"></spinner>
    <div v-if="resolved" class="row">
      <div class="col-md-12">
        <h4>{{ $t("company_admin.configuration.general_settings.title") }}</h4>
        <div v-if="saveCompanySettingsSuccess" class="alert alert-success">
          {{ $t("company_admin.configuration.general_settings.success_text") }}
        </div>
        <p>{{ $t("company_admin.configuration.general_settings.desc") }}</p>
        <div class="row">
          <div class="col-md-4">
            <b>{{ $t("activerecord.attributes.company.company_language") }}</b>
            <select v-model="generalSettings.locale">
              <option v-for="l in availableLocales" :key="l.id" :value="l.id">{{ l.name }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <b>{{ $t("activerecord.attributes.company.time_zone") }}</b>
            <select v-model="generalSettings.time_zone">
              <option v-for="zone in timezones" :key="zone" :value="zone">{{ zone }}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <b>{{ $t("activerecord.attributes.company.first_day_of_week") }}</b>
            <select v-model="generalSettings.first_day_of_week">
              <option :value="0">{{ $t("date.day_names.0") }}</option>
              <option :value="1">{{ $t("date.day_names.1") }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <b>{{ $t("activerecord.attributes.company.temperature_unit") }}</b>
            <select v-model="generalSettings.temperature_unit">
              <option value="C">{{ $t("weather.unit.celsius") }}</option>
              <option value="F">{{ $t("weather.unit.fahrenheit") }}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <b>{{ $t("activerecord.attributes.company.date_format") }}</b>
            <select v-model="generalSettings.date_format">
              <option :value="null">{{ $t("date.locale_default") }}</option>
              <option v-for="format in dateFormats" :key="format" :value="format">{{ format }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <b>{{ $t("activerecord.attributes.company.newsletter_time") }}</b>
            <time-select v-model="generalSettings.newsletter_time"></time-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4"></div>
          <div v-if="custom_periods.length > 0" class="col-md-4">
            <b>{{ $t("activerecord.attributes.company.use_business_calendar") }}</b>
            <select v-model="generalSettings.use_business_calendar">
              <option :value="false">{{ $t("options.no") }}</option>
              <option :value="true">{{ $t("options.yes") }}</option>
            </select>
          </div>
        </div>
        <div class="mt-md">
          <button
            class="btn btn-primary save-company-settings"
            :disabled="savingCompanySettings"
            @click="saveCompanySettings()"
          >
            {{ $t("actions.save") }}
          </button>
        </div>
        <h4>{{ $t("activerecord.attributes.company.business_hours") }}</h4>
        <div v-if="savedBusinessHoursSuccess" class="alert alert-success">
          {{ $t("company_admin.configuration.business_hours.success_text") }}
        </div>
        <div>
          <business-hours-editor @update="onUpdateBusinessHours"></business-hours-editor>
        </div>
        <h4>{{ $t("company_admin.configuration.dashboard_defaults.title") }}</h4>
        <div v-if="savedDashboardDefaultsSuccess" class="alert alert-success">
          {{ $t("company_admin.configuration.dashboard_defaults.success_text") }}
        </div>
        <p>{{ $t("company_admin.configuration.dashboard_defaults.desc") }}</p>
        <div class="dashboard-defaults-time">
          <b>{{ $t("filters.selection_time") }}</b>
          <select v-model="dashboard.selection">
            <option v-for="(value, name) in available_times" :key="name" :value="value[0]">{{ value[1] }}</option>
          </select>
        </div>
        <div class="mb-lg">
          <comparison-pill-list
            :choices="comparisonChoices"
            :comparisons="dashboard.comparisons"
            :custom="false"
            :custom-scenario="false"
            @update="updateComparisons"
          ></comparison-pill-list>
        </div>
        <div class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-lg">
          <widget-filter-selector
            name="snippets"
            :config="dashboard.config.widgets.snippets"
            @update="updateWidget('snippets', $event)"
          ></widget-filter-selector>
          <widget-filter-selector
            name="charts"
            :config="dashboard.config.widgets.charts"
            @update="updateWidget('charts', $event)"
          ></widget-filter-selector>
        </div>
        <div class="mb-lg">
          <component-span-selector
            :model-value="dashboard.config.raw_filters.span"
            @update:model-value="updateComponentSpan"
          ></component-span-selector>
        </div>
        <div class="mb-lg">
          <filterset-selector :config="dashboard.config.filters" @update="updateFiltersConfig"></filterset-selector>
        </div>
        <div class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-lg">
          <filter-selector
            v-for="filter in availableFilters"
            :key="filter.id"
            :filter="filter"
            :config="dashboard.config.filters.filters[filter.id]"
            @update="updateFilter(filter.id, $event)"
          ></filter-selector>
        </div>
        <button class="btn btn-primary" :disabled="savingDashboardDefaults" @click="saveDashboardDefaults()">
          {{ $t("actions.save") }}
        </button>
        <div v-if="features.analytics">
          <h4>{{ $t("company_admin.configuration.analytics_defaults.title") }}</h4>
          <div v-if="savedAnalyticsDefaultsSuccess" class="alert alert-success">
            {{ $t("company_admin.configuration.analytics_defaults.success_text") }}
          </div>
          <p>{{ $t("company_admin.configuration.analytics_defaults.desc") }}</p>
          <div>
            <comparison-pill-list
              :choices="comparisonChoices"
              :comparisons="analyticsDefaults.comparisons"
              :custom="false"
              :custom-scenario="false"
              :radio="true"
              @update="updateReportComparisons"
            ></comparison-pill-list>
          </div>
          <div class="report-defaults-save">
            <button class="btn btn-primary" :disabled="savingAnalyticsDefaults" @click="saveReportDefaults()">
              {{ $t("actions.save") }}
            </button>
          </div>
        </div>
        <h4>{{ $t("company_admin.configuration.time_periods_configuration.title") }}</h4>
        <p>{{ $t("company_admin.configuration.time_periods_configuration.desc") }}</p>
        <pill-list
          :items="timePeriods"
          type="time_period"
          @update="updateReportSettings({ time_periods: $event })"
        ></pill-list>
        <h4>{{ $t("company_admin.configuration.comparisons_configuration.title") }}</h4>
        <p>{{ $t("company_admin.configuration.comparisons_configuration.desc") }}</p>
        <pill-list :items="comparisons" type="comparison" @update="updateComparisonSettings($event)"></pill-list>
        <h4>{{ $t("company_admin.configuration.metrics_configuration.title") }}</h4>
        <p>{{ $t("company_admin.configuration.metrics_configuration.desc") }}</p>
        <configuration-pill-list
          :objects="metrics"
          :type="'metric'"
          :locale="locale"
          @save="saveObject('metrics', $event)"
        ></configuration-pill-list>
        <h4>{{ $t("company_admin.configuration.groupings_configuration.title") }}</h4>
        <p>{{ $t("company_admin.configuration.groupings_configuration.desc") }}</p>
        <configuration-pill-list
          :objects="groupings"
          :type="'grouping'"
          :locale="locale"
          @save="saveObject('groupings', $event)"
        ></configuration-pill-list>
        <h4>{{ $t("company_admin.configuration.filters_configuration.title") }}</h4>
        <p>{{ $t("company_admin.configuration.filters_configuration.desc") }}</p>
        <configuration-pill-list
          :objects="filters"
          :type="'filter'"
          :locale="locale"
          @save="saveObject('filters', $event)"
        ></configuration-pill-list>
        <div v-if="features.analytics">
          <h4>{{ $t("company_admin.configuration.available_reports.title") }}</h4>
          <p>{{ $t("company_admin.configuration.available_reports.desc") }}</p>
          <div class="flex-row align-items-center flex-wrap gap-sm">
            <pill-button
              v-for="report in reports"
              :key="report.name"
              :enabled="report.visible"
              :removable="false"
              @toggle="toggleReport(report)"
              >{{ report.title }}</pill-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { filterToFlyover } from "@/lib/filter-util";
import { defineComponent } from "vue";
import moment from "moment-timezone";
import adminCompaniesApi from "@/api/admin-companies-api";
import { ZoinedTimeRangeService } from "@/filters/zoined-time-range.service";
import _ from "lodash";
import TranslationService from "@/core/translation.service";
import { defaultComparisonTypes } from "../filters/time-period";
import { comparisonToMenuKey } from "../lib/menu-helper";
import Company from "@/model/company";
import TimePeriodConfig from "@/model/time-period-config";
import spinner from "@/components/spinner.vue";
import { makeApiInstance } from "@/api/instance";
import TimeSelect from "@/components/time-select.vue";
import businessHoursEditor from "@/components/business-hours-editor.vue";
import ComparisonPillList from "@/components/comparison-pill-list.vue";
import WidgetFilterSelector from "@/components/widget-filter-selector.vue";
import ComponentSpanSelector from "@/components/component-span-selector.vue";
import FiltersetSelector from "@/components/filterset-selector.vue";
import FilterSelector from "@/components/filter-selector.vue";
import ConfigurationPillList from "./configuration-pill-list.vue";
import PillList from "./pill-list.vue";
import pillButton from "@/components/pill-button.vue";
import Actions from "@/store/actions";

const zoinedTimeRangeService = new ZoinedTimeRangeService();

export default defineComponent({
  components: {
    spinner,
    TimeSelect,
    businessHoursEditor,
    ComparisonPillList,
    WidgetFilterSelector,
    ComponentSpanSelector,
    FiltersetSelector,
    FilterSelector,
    ConfigurationPillList,
    PillList,
    pillButton,
  },
  data() {
    const timePeriodsConfig: Record<string, TimePeriodConfig> = {};
    const company: Company = null;
    const dashboard = null;
    const generalSettings: Partial<Company> = {};

    return {
      custom_periods: [],
      combinedPeriods: [],
      generalSettings,
      dashboard,
      analyticsDefaults: { comparisons: [] },
      available_times: [],
      timePeriods: [],
      timezones: moment.tz.names(),
      company,
      resolved: false,
      timePeriodsConfig,
      metrics: null,
      reports: null,
      groupings: null,
      filters: null,
      customPeriodsArray: null,
      timePeriodsArray: null,
      comparisonChoices: null,
      comparisons: null,
      dateFormats: null,
      availableFilters: null,
      availableLocales: null,
      savingCompanySettings: false,
      saveCompanySettingsSuccess: false,
      savingDashboardDefaults: false,
      savedDashboardDefaultsSuccess: false,
      savingAnalyticsDefaults: false,
      savedAnalyticsDefaultsSuccess: false,
      savedBusinessHoursSuccess: false,
      locale: window.zoinedContext.locale,
      companyId: window.zoinedContext.companyId,
      features: window.zoinedContext.features,
      limited: window.zoinedContext.limited,
    };
  },
  watch: {
    timePeriods: [
      {
        handler: "onTimePeriodsChange",
      },
    ],
  },
  created() {
    const promises = [];
    promises.push(
      adminCompaniesApi.get({ id: this.companyId }).then((company) => {
        this.company = company;
      })
    );
    promises.push(
      makeApiInstance()
        .get("/api/v1/parameters/available_filters")
        .then((result) => {
          this.availableFilters = result.data;
        })
    );
    promises.push(
      makeApiInstance()
        .get("/api/v1/parameters/available_locales")
        .then((result) => {
          this.availableLocales = result.data.filter((locale) => locale.id && locale.id !== "");
        })
    );
    promises.push(
      fetch("/api/admin/companies/" + this.companyId + "/settings/metrics.json").then(async (result) => {
        this.metrics = await result.json();
      })
    );
    promises.push(
      fetch("/api/admin/companies/" + this.companyId + "/settings/reports.json").then(async (result) => {
        this.reports = await result.json();
      })
    );
    promises.push(
      fetch("/api/admin/companies/" + this.companyId + "/settings/groupings.json").then(async (result) => {
        this.groupings = await result.json();
      })
    );
    promises.push(
      fetch("/api/admin/companies/" + this.companyId + "/settings/filters.json").then(async (result) => {
        this.filters = await result.json();
      })
    );
    promises.push(
      fetch("/api/admin/companies/" + this.companyId + "/settings/custom_periods.json").then(async (result) => {
        const custom_data = [];

        const data = await result.json();

        data.map((x) => {
          switch (x) {
            case "custom_year":
              return custom_data.push("custom_current_year", "custom_current_full_year", "custom_last_year");
            case "custom_period":
              return custom_data.push(
                "custom_rolling_12_months",
                "custom_rolling_3_months",
                "custom_current_month",
                "custom_current_full_month",
                "custom_last_month"
              );
            case "custom_week":
              return custom_data.push(
                "custom_rolling_4_weeks",
                "custom_rolling_12_weeks",
                "custom_current_week",
                "custom_current_full_week",
                "custom_last_week",
                "custom_current_retail_week_until_today"
              );
            case "custom_season":
              return custom_data.push("custom_current_season", "custom_last_season", "custom_current_full_season");
          }
        });

        this.custom_periods = custom_data;
      })
    );

    Promise.all(promises).then(() => {
      const company = this.company;

      const dashboard: any = {};

      let timePeriodsConfig;
      // Dashboard defaults
      try {
        dashboard.config = filterToFlyover(JSON.parse(company.dashboard_defaults) || {});
      } catch {
        // We'll just use the empty configuration if the saved one cannot be parsed.
        dashboard.config = filterToFlyover({});
      }
      if (!dashboard.config.raw_filters) {
        dashboard.config.raw_filters = {};
      }

      if (dashboard.config.time && dashboard.config.time.selection != null) {
        dashboard.selection = dashboard.config.time.selection.type;
      } else {
        dashboard.selection = "yesterday";
      }
      if (dashboard.config.time && dashboard.config.time.comparisons) {
        dashboard.comparisons = dashboard.config.time.comparisons;
      } else if (dashboard.config.time && dashboard.config.time.comparison != null) {
        dashboard.comparisons = [dashboard.config.time.comparison];
      } else {
        dashboard.comparisons = [{ type: "prev_year_corresponding", enabled: true }];
      }
      this.dashboard = dashboard;

      // Report defaults
      const analyticsDefaults = company.analytics_defaults;
      if (analyticsDefaults && analyticsDefaults.time && analyticsDefaults.time.comparisons) {
        this.analyticsDefaults.comparisons = analyticsDefaults.time.comparisons;
      } else {
        this.analyticsDefaults.comparisons = [{ type: "previous_corresponding_period", enabled: true }];
      }

      if (company.report_settings && company.report_settings.time_periods) {
        timePeriodsConfig = company.report_settings.time_periods.reduce(
          (config, timePeriod) => ({ ...config, [timePeriod.id]: timePeriod }),
          {}
        );
      } else {
        timePeriodsConfig = {};
      }

      this.timePeriodsConfig = timePeriodsConfig;

      this.customPeriodsArray = zoinedTimeRangeService.customPeriods(this.custom_periods);
      this.timePeriodsArray = zoinedTimeRangeService.timePeriods();

      this.combinedPeriods = this.timePeriodsArray.concat(this.customPeriodsArray || {});

      this.timePeriods = this.combinedPeriods.map(({ id, name: label }) =>
        Object.assign({ id, label, enabled: this.timeIsSaved(id), order: 999 }, timePeriodsConfig[id] || {})
      );

      this.timePeriods = _.sortBy(this.timePeriods, "order");

      this.buildComparisons();

      const benchmarks = ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"];
      this.comparisonChoices = this.comparisons.filter(({ enabled, type }) => enabled && !_.includes(benchmarks, type));

      this.generalSettings.time_zone = company.time_zone;
      this.generalSettings.locale = company.locale;
      this.generalSettings.first_day_of_week = company.first_day_of_week;
      this.generalSettings.temperature_unit = company.temperature_unit;
      this.generalSettings.use_business_calendar = company.use_business_calendar || false;
      this.generalSettings.date_format = company.date_format;
      this.generalSettings.newsletter_time = company.newsletter_time;

      this.dateFormats = ["YYYY-MM-DD", "MM/DD/YYYY", "DD/MM/YYYY", "DD.MM.YYYY", "YYYY.MM.DD"];

      this.resolved = true;
    });
  },
  methods: {
    buildComparisons() {
      const company = this.company,
        translationService = new TranslationService();

      const comparisonsConfig = (company.report_settings && company.report_settings.comparisons) || [];

      this.comparisons = window.zoinedContext.availableComparisons.map((comparison) => {
        const config =
          _.find(
            comparisonsConfig,
            ({ type, label, time_period }) =>
              type == comparison.type && label == comparison.label && time_period == comparison.time_period
          ) || {};

        return this.updateComparisonLabels({
          ...comparison,
          order: 999,
          enabled: _.isEmpty(comparisonsConfig) && defaultComparisonTypes.includes(comparison.type),
          ...config,
          name: translationService.comparisonTitle(comparison),
          key: comparisonToMenuKey(comparison),
        });
      });
      this.comparisons = _.sortBy(this.comparisons, "order");
    },
    updateComparisonLabels(comparison) {
      const translationService = new TranslationService();
      if (comparison.type !== "time_period") {
        Object.assign(comparison, {
          translatable: true,
          originalLabels: this.availableLocales.reduce(
            (labels, locale) => ({
              ...labels,
              [locale.id]: translationService.comparisonTitle(comparison, {
                locale: locale.id,
                // get original label
                custom: false,
              }),
            }),
            {}
          ),
          custom_labels: {
            ...this.availableLocales.reduce(
              (labels, locale) => ({
                ...labels,
                [locale.id]: translationService.comparisonTitle(comparison, {
                  locale: locale.id,
                }),
              }),
              {}
            ),
          },
          name: translationService.comparisonTitle(comparison),
        });
      }
      return comparison;
    },
    updateComparisons(comparisons) {
      this.dashboard.comparisons = comparisons;
    },
    updateReportComparisons(comparisons) {
      this.analyticsDefaults.comparisons = comparisons;
    },
    updateComparisonSettings(comparisons) {
      // Dirty hack: update custom label translations from here directly
      comparisons.forEach(({ key, custom_labels }) => {
        window.zoinedContext.custom_labels.comparisons[key] = {
          ...custom_labels,
        };
      });

      // Update name
      comparisons = comparisons.map((comparison) => ({
        ...comparison,
        name: new TranslationService().comparisonTitle(comparison),
      }));

      this.comparisons = comparisons;

      // Update comparisons to server (first remove extra keys)

      const newComparisons = comparisons.map(({ key, translatable, originalLabels, ...rest }) => rest);

      this.updateReportSettings({ comparisons: newComparisons });
    },
    updateReportSettings(updated) {
      const company: Partial<Company> = {
        report_settings: {
          ...this.company.report_settings,
          time_periods: this.timePeriods,
          comparisons: this.comparisons,
          ...updated,
        },
      };
      if (updated.time_periods) {
        this.timePeriods = [...updated.time_periods];
      }
      return adminCompaniesApi.put({ id: this.companyId, company }).then(() => {
        if (updated.time_periods) {
          return this.$store.dispatch(Actions.fetchParameters("timePeriods"));
        }
      });
    },
    updateFilter(name, config) {
      this.dashboard.config.filters.filters[name] = config;
    },
    updateWidget(name, config) {
      this.dashboard.config.widgets[name] = config;
    },
    updateFiltersConfig(config) {
      this.dashboard.config.filters = config;
    },
    updateComponentSpan(value) {
      this.dashboard.config.raw_filters.span = value;
    },
    updateNewsletterTime(value) {
      this.generalSettings.newsletter_time = value;
    },
    saveCompanySettings() {
      this.savingCompanySettings = true;
      return adminCompaniesApi
        .put({ id: this.companyId, company: this.generalSettings })
        .then(() => {
          this.saveCompanySettingsSuccess = true;
          setTimeout(() => {
            this.saveCompanySettingsSuccess = null;
          }, 3000);
        })
        .finally(() => (this.savingCompanySettings = false));
    },
    saveDashboardDefaults() {
      const config = _.cloneDeep(this.dashboard.config);
      config.time = {
        selection: { type: this.dashboard.selection },
        comparisons: this.dashboard.comparisons,
      };
      const company: Partial<Company> = {
        dashboard_defaults: JSON.stringify(config),
      };

      this.savingDashboardDefaults = true;
      return adminCompaniesApi
        .put({
          id: this.companyId,
          company,
        })
        .then(() => {
          this.savingDashboardDefaults = false;
          this.savedDashboardDefaultsSuccess = true;
          setTimeout(() => {
            this.savedDashboardDefaultsSuccess = null;
          }, 3000);
        });
    },
    saveReportDefaults() {
      const company: Partial<Company> = {
        analytics_defaults: {
          time: {
            comparisons: this.analyticsDefaults.comparisons,
          },
        },
      };
      this.savingAnalyticsDefaults = true;
      return adminCompaniesApi
        .put({
          id: this.companyId,
          company,
        })
        .then(() => {
          this.savingAnalyticsDefaults = false;
          this.savedAnalyticsDefaultsSuccess = true;
          setTimeout(() => {
            this.savedAnalyticsDefaultsSuccess = null;
          }, 3000);
        });
    },
    timeIsSaved(time) {
      if (Object.values(this.timePeriodsConfig).length === 0) {
        // By default enable only default time periods (not custom)
        return zoinedTimeRangeService.defaultTimePeriodIds.includes(time);
      }

      return (
        Object.values(this.timePeriodsConfig).filter((x) => {
          return x.id === time && x.enabled;
        }).length > 0
      );
    },
    saveObject(type, { key, config }) {
      fetch("/api/admin/companies/" + this.companyId + "/settings/" + type + "/" + key, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          setting: {
            invisible: config.invisible,
            label: config.label,
          },
        }),
      }).then(() => {
        this[type][key].label = config.label;
        this[type][key].invisible = config.invisible;
        const linkedObjects = (() => {
          switch (type) {
            case "groupings":
              return this.filters;
            case "filters":
              return this.groupings;
          }
        })();
        if (linkedObjects && linkedObjects[key]) {
          linkedObjects[key].label = _.cloneDeep(config.label);
        }
      });
    },
    toggleReport(report) {
      const v = !report.visible;
      return fetch("/api/admin/companies/" + this.companyId + "/settings/reports/" + report.name, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          visible: v,
        }),
      }).then(() => {
        report.visible = v;
      });
    },
    onUpdateBusinessHours() {
      this.savedBusinessHoursSuccess = true;
      setTimeout(() => {
        this.savedBusinessHoursSuccess = null;
      }, 3000);
    },
    onTimePeriodsChange(timePeriods) {
      this.available_times = timePeriods
        ? timePeriods.filter(({ enabled }) => enabled).map(({ id, label }) => [id, label])
        : [];
    },
  },
});
</script>
