<template>
  <pill-list
    :items="partnerItems"
    :available-items="partnerMenuItems"
    :searchable="false"
    @update="onUpdate($event.items)"
  ></pill-list>
</template>

<script lang="ts">
import { makeApiInstance } from "../api/instance";
import MenuItem from "../interfaces/menu-item";
import PillItem from "../interfaces/pill-item";
import Partner from "../model/partner";
import { defineComponent, PropType } from "vue";
import pillList from "../components/pill-list.vue";

export default defineComponent({
  components: { pillList },
  props: {
    modelValue: {
      type: Array as PropType<Partner[]>,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  data() {
    const partners: Partner[] = null;

    return {
      partners,
    };
  },
  computed: {
    api() {
      return makeApiInstance({ baseURL: "/api/zoined_admin/partners/" });
    },
    partnerItems(): PillItem[] {
      return this.modelValue?.map((partner) => ({
        value: partner.id,
        name: partner.name,
      }));
    },
    partnerMenuItems(): MenuItem[] {
      return this.partners?.map((partner) => ({
        key: partner.id,
        name: partner.name,
      }));
    },
  },
  created() {
    this.api.get("").then((result) => (this.partners = result.data));
  },
  methods: {
    onUpdate(items: PillItem[]) {
      const partners: Partner[] = items.map((item) => ({
        id: item.value,
        name: item.name,
      }));
      this.$emit("update:modelValue", partners);
    },
  },
});
</script>
