<template>
  <calendar
    is-expanded
    :class="{ 'hide-year-nav': hideYearNav }"
    :attributes="customCalendarAttributes"
    :first-day-of-week="firstDayOfWeek"
    :locale="locale"
    :initial-page="initialPage"
    :min-date="minDate"
    :max-date="maxDate"
    @dayclick="onDayClick"
  >
    <template #header-title="headerProps">
      <slot v-bind="headerProps" name="title">{{ headerProps.title }}</slot>
    </template>
  </calendar>
</template>

<script lang="ts">
import { Calendar } from "v-calendar";
import { defineComponent, PropType } from "vue";
import i18n from "../i18n";
import moment from "moment";
import { DayOfWeek } from "v-calendar/dist/types/src/utils/date/helpers";
import { PageAddress } from "v-calendar/dist/types/src/utils/page";
import { AttributeConfig } from "v-calendar/dist/types/src/utils/attribute";

export default defineComponent({
  components: {
    Calendar,
  },
  inject: ["dropdown"],
  props: {
    minDate: { default: null, type: Object as PropType<Date> },
    maxDate: { default: null, type: Object as PropType<Date> },
    mode: { default: "single", type: String as PropType<"single" | "range"> },
  },
  emits: ["select-date", "select-custom-range"],
  data() {
    return {
      rangeStartDate: null,
      firstDayOfWeek: (window.zoinedContext.firstDayOfWeek + 1) as DayOfWeek,
      locale: i18n.locale,
    };
  },
  computed: {
    customCalendarAttributes(): AttributeConfig[] {
      return [
        {
          highlight: true,
          dates: this.rangeStartDate,
        } as AttributeConfig,
      ];
    },
    fromDate() {
      if (this.maxDate && moment().isAfter(this.maxDate)) {
        return this.minDate || this.maxDate;
      }
      return null;
    },
    initialPage(): PageAddress {
      return (
        this.fromDate && {
          year: this.fromDate.getFullYear(),
          month: this.fromDate.getMonth(),
          day: this.fromDate.getDate(),
        }
      );
    },
    hideYearNav() {
      return this.minDate && this.maxDate && this.minDate?.getFullYear() === this.maxDate?.getFullYear();
    },
  },
  mounted() {
    this.rangeStartDate = null;
  },
  methods: {
    onDayClick({ date }) {
      if (this.mode === "range") {
        if (this.rangeStartDate) {
          this.$emit("select-custom-range", [this.rangeStartDate, date]);
          this.rangeStartDate = null;
          this.dropdown?.close();
        } else {
          this.rangeStartDate = date;
        }
      } else {
        this.$emit("select-date", date);
        this.dropdown?.close();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.hide-year-nav::v-deep {
  .vc-nav-container > .vc-nav-header {
    display: none;
  }
}
</style>
