<template>
  <div class="inline-block">
    <button class="btn btn-sm btn-primary" :disabled="isSetActive || !hasEnabledFilters" @click="openSaveModal">
      {{ $t("filters.filterset.save_filters") }}
    </button>
    <uiv-modal
      v-model="saveModalOpen"
      :append-to-body="true"
      :title="$t('filters.actions.save_as')"
      :cancel-text="$t(requireConfirmation ? 'actions.edit' : 'actions.cancel')"
      :cancel-type="requireConfirmation ? 'primary' : 'default'"
      :ok-text="$t(requireConfirmation ? 'actions.confirm' : 'actions.save')"
      :ok-type="requireConfirmation ? 'danger' : 'primary'"
      :before-close="beforeClose"
      @hide="onHide"
    >
      <form>
        <spinner v-if="saving"></spinner>
        <div class="form" :class="{ disabled: saving }">
          <div class="form-group">
            <input
              v-model="filterset.name"
              class="form-control"
              type="text"
              required
              :placeholder="$t('filters.filterset.name_placeholder')"
              autofocus
              style="max-width:100%;"
            />
          </div>
          <div v-if="isAdmin" class="form-group">
            <div class="checkbox">
              <label for="shared">
                <input v-model="filterset.shared" type="checkbox" name="shared" />{{ $t("filters.filterset.shared") }}
              </label>
            </div>
          </div>
        </div>
        <div v-if="requireConfirmation">{{ $t("filters.filterset.override_filter_name") }}</div>
        <div v-else-if="saveAsOwn">{{ $t("filters.filterset.cannot_save_shared") }}</div>
      </form>
    </uiv-modal>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import FiltersConfiguration from "main/model/filters-configuration";
import { defineComponent, PropType } from "vue";
import spinner from "../components/spinner.vue";

export default defineComponent({
  components: {
    spinner,
  },
  props: {
    config: {
      default: (): FiltersConfiguration => ({
        v: 2,
        sets: [],
        filters: {},
      }),
      type: Object as PropType<FiltersConfiguration>,
    },
  },
  emits: ["update"],
  data() {
    const lastSetId: number = null;
    const filterset: { name: string; shared: boolean } = {
      name: "",
      shared: false,
    };

    return {
      saveModalOpen: false,
      saving: false,
      requireConfirmation: false,
      confirmed: false,
      filterset,
      lastSetId,
      saveAsOwn: false,
      isAdmin: !!window.zoinedContext?.isAdmin,
    };
  },
  computed: {
    filtersets() {
      return this.$store.state.filtersets.all;
    },
    effectiveConfig(): FiltersConfiguration {
      if (this.config) {
        const sets = (this.config.sets || []).filter(({ id }) => _.find(this.filtersets, (set) => set.id == id));
        return {
          ...this.config,
          sets,
        };
      } else {
        return null;
      }
    },
    isSetActive() {
      return !!this.enabledSetId;
    },
    enabledSetId() {
      return _.find(this.effectiveConfig.sets, ({ enabled }) => enabled)?.id;
    },
    hasEnabledFilters() {
      return !_.isEmpty(this.effectiveConfig.filters);
    },
    lastSet() {
      return this.lastSetId && _.find(this.filtersets, (set) => set.id == this.lastSetId);
    },
  },
  watch: {
    "config.sets": [{ immediate: true, handler: "watchSets" }],
  },
  methods: {
    openSaveModal() {
      this.saveModalOpen = true;
      this.filterset = {
        name: this.lastSet?.name || "",
        shared: !!this.lastSet?.shared,
      };
      if (!this.isAdmin) {
        this.filterset.shared = false;
      }
    },
    onHide() {
      this.saving = this.requireConfirmation = this.saveAsOwn = this.confirmed = false;
    },
    beforeClose(msg) {
      if (msg == "ok") {
        if (this.requireConfirmation) {
          this.confirmed = true;
        }
        this.save();
        return false;
      } else if (msg == "cancel" && this.requireConfirmation) {
        this.requireConfirmation = false;
        return false;
      } else {
        return true;
      }
    },
    save() {
      if (this.saving || _.isEmpty(this.filterset.name)) {
        return;
      }

      const config = {
        v: 2,
        filters: this.config.filters,
      };

      if (this.lastSet?.name === this.filterset.name) {
        if (this.lastSet.shared && !this.isAdmin) {
          this.saveAsOwn = true;
          this.lastSetId = null;
        } else {
          return this.updateFilterset(this.lastSet, config);
        }
      } else {
        const existingSet = _.find(this.filtersets, (set) => {
          return set.name == this.filterset.name && (this.isAdmin || !set.shared);
        });

        if (existingSet) {
          if (this.confirmed) {
            return this.updateFilterset(existingSet, config);
          } else {
            this.requireConfirmation = true;
          }
        } else {
          return this.createFilterset(config);
        }
      }
    },
    createFilterset(config) {
      this.saving = true;

      const filterset = {
        ...this.filterset,
        config,
      };

      this.$store.dispatch("filtersets/add", filterset).then((filterset) => {
        this.saveModalOpen = false;

        // Add set to config
        const sets = [...this.config.sets, { id: filterset.id, enabled: true }];
        const config = {
          ...this.config,
          sets,
        };

        this.$emit("update", config);
      });
    },
    updateFilterset(existingSet, config) {
      this.saving = true;

      const filterset = {
        ...existingSet,
        ...this.filterset,
        config,
      };

      this.$store.dispatch("filtersets/update", [filterset.id, filterset]).then((filterset) => {
        this.saveModalOpen = false;

        const sets = this.config.sets.map(({ id }) => ({
          id,
          enabled: id === filterset.id,
        }));

        if (!sets.find(({ id }) => id == filterset.id)) {
          sets.push({ id: filterset.id, enabled: true });
        }

        const config = {
          ...this.config,
          sets,
        };
        this.$emit("update", config);
      });
    },
    watchSets(sets) {
      const enabledSetId = sets?.find(({ enabled }) => enabled)?.id;
      if (enabledSetId) {
        this.lastSetId = enabledSetId;
      }
    },
  },
});
</script>
