<template>
  <form class="navbar-form">
    <div class="form-group">
      <select v-model="companyId" class="form-control" @change="onChange">
        <option v-for="company in companies" :key="company.id" :value="company.id">{{ companyTitle(company) }}</option>
      </select>
    </div>
  </form>
</template>

<script lang="ts">
import i18n from "../i18n";
import Company from "../model/company";
import { defineComponent } from "vue";
import { postForm } from "../lib/form";
import { showOverlay } from "@/ui/overlay";

export default defineComponent({
  data() {
    return {
      companyId: window.zoinedContext.companyId,
    };
  },
  computed: {
    companies(): Company[] {
      return window.zoinedContext.available_companies || [];
    },
  },
  methods: {
    companyTitle(company: Company) {
      let title = company.name;
      if (window.zoinedContext.isZoinedAdmin && company.partner) {
        title += ` [${company.partner.name}]`;
      }
      return title;
    },
    onChange() {
      window.$(".dropdown.open").removeClass("open");
      showOverlay(i18n.t("please_wait"));
      postForm(`/companies/${this.companyId}/switch`);
    },
  },
});
</script>
