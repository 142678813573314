<template>
  <date-picker :first-day-of-week="firstDayOfWeek" :locale="i18n.locale">
    <template #default="{ inputValue, inputEvents }">
      <input class="form-control" type="text" :value="inputValue" v-on="inputEvents" />
    </template>
  </date-picker>
</template>

<script setup lang="ts">
import { DatePicker } from "v-calendar";
import i18n from "@/i18n";
import { DayOfWeek } from "v-calendar/dist/types/src/utils/date/helpers";

const firstDayOfWeek: DayOfWeek = (window.zoinedContext.firstDayOfWeek + 1) as DayOfWeek;
</script>
