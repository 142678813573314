<template>
  <div class="flex-row align-items-center gap-lg">
    <div class="title">{{ $t("filter.config.zerofill") }}</div>
    <btn-group class="btn-multiple intercom-tag-filters-zerofill">
      <btn v-model="model" class="btn-sm" type="primary" input-type="radio" :input-value="true" @click="update(true)">{{
        $t("options.yes")
      }}</btn>
      <btn
        v-model="model"
        class="btn-sm"
        type="primary"
        input-type="radio"
        :input-value="false"
        @click="update(false)"
        >{{ $t("options.no") }}</btn
      >
    </btn-group>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Btn, BtnGroup } from "uiv";
export default defineComponent({
  components: {
    Btn,
    BtnGroup,
  },
  props: {
    modelValue: {
      type: String as PropType<"true" | "false">,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      model: true,
    };
  },
  watch: {
    modelValue: [
      {
        handler: "setModel",
      },
    ],
  },
  created() {
    this.setModel();
  },
  methods: {
    update(value) {
      this.$emit("update:modelValue", value ? "true" : "false");
    },
    setModel() {
      this.model = this.modelValue !== "false";
    },
  },
});
</script>
