<template>
  <span class="filter-selector">
    <slot v-bind="slotProps">
      <pill-list
        v-bind="slotProps"
        @update="onUpdate($event.items)"
        @search="onSearch($event)"
        @update:menu-open="setEnabled($event)"
      />
    </slot>
  </span>
</template>

<script lang="ts" setup>
import _ from "lodash";
import { menuItemKey } from "../interfaces/menu-item";
import PillItem from "../interfaces/pill-item";
import Filter from "../model/filter";
import FilterConfigurationItemMap from "../model/filter-configuration-item-map";
import I18n from "../i18n";
import pillList from "./pill-list.vue";
import { computed, ref } from "vue";
import { useParameters } from "@/api/parameters-api";

const props = defineProps({
  filter: {
    type: Object as () => Filter,
    required: true,
  },
  config: {
    type: Object as () => FilterConfigurationItemMap,
    default: null,
  },
  defaultConfig: {
    type: Object as () => FilterConfigurationItemMap,
    default: null,
  },
  excludable: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update"]);

const searchQuery = ref(null);
const enabled = ref(false);

const filterId = computed(() => props.filter.id);
const queryParams = computed(() => (searchQuery.value ? { q: searchQuery.value } : {}));
const queryEnabled = computed(() => !!(enabled.value && (!typeahead.value || searchQuery.value)));

const { data, isFetching } = useParameters(filterId.value, { params: queryParams, enabled: queryEnabled });
const availableFilters = computed(() => data.value || []);

const title = computed(() => props.filter.name || I18n.t(`filter.config.${props.filter.id}`));
const searchable = computed(() => props.filter.filter_count > 10);
const typeahead = computed(() => props.filter.filter_count > 100);
const showId = computed(() => props.filter.show_id);
const defaultItems = computed(() => {
  return (
    props.defaultConfig &&
    _.map(props.defaultConfig, ({ name, value, ...rest }, key) => ({
      ...rest,
      value: key,
      name: name || value,
    }))
  );
});
const wildcard = computed(() => searchable.value);

const slotProps = computed(() => {
  return {
    title: title.value,
    searchable: searchable.value,
    typeahead: typeahead.value,
    multiSelect: true,
    excludable: props.excludable,
    wildcard: wildcard.value,
    items: items.value,
    defaults: defaultItems.value,
    availableItems: availableItems.value,
    disabled: props.disabled,
    isLoading: isFetching.value,
  };
});
const availableItems = computed(() => {
  if (availableFilters.value) {
    if (showId.value) {
      return availableFilters.value.map((f) => ({
        ...f,
        name: `${f.name} (${f.id})`,
      }));
    } else {
      return availableFilters.value;
    }
  } else {
    return null;
  }
});
const items = computed(() => {
  let items =
    props.config &&
    _.map(props.config, ({ name, value, ...rest }, key) => {
      const item = findAvailableItem(value);
      return {
        ...rest,
        value: key,
        name: item?.name || name || value,
      };
    });
  if (props.filter.id == "hour") {
    items = _.sortBy(items, "value");
  }
  return items;
});

const onUpdate = (items: PillItem[]) => {
  emit(
    "update",
    items.reduce(
      (result, item) => ({
        ...result,
        [item.value]: item,
      }),
      {}
    ),
    items
  );
};

const onSearch = (q) => {
  if (typeahead.value) {
    searchQuery.value = q;
  } else {
    searchQuery.value = null;
  }
};

const findAvailableItem = (id) => {
  return availableItems.value?.find((item) => menuItemKey(item) == id);
};

const setEnabled = (value) => (enabled.value = value);

defineExpose({ setEnabled });
</script>
