<template>
  <FilterSelector ref="filterSelector" :filter="filter" :config="filteredConfig">
    <template #default="slotProps">
      <ItemMenuDropdown
        :title="title"
        :items="slotProps.availableItems"
        :selected-items="slotProps.items"
        :searchable="slotProps.searchable"
        :typeahead="slotProps.typeahead"
        :multi-select="true"
        :exclude="isExclude"
        :is-loading="slotProps.isLoading"
        :append-dropdown-to-body="true"
        @select="onSelect"
        @deselect="onDeselect"
        @update:menu-open="onMenuOpen"
      >
        <ActiveFiltersBadge class="cursor-pointer" :items="items" :type="type" :print-label="printLabel" />
      </ItemMenuDropdown>
    </template>
  </FilterSelector>
</template>

<script setup lang="ts">
import FilterSelector from "@/components/filter-selector.vue";
import ItemMenuDropdown from "@/components/item-menu-dropdown.vue";
import ActiveFiltersBadge from "@/components/active-filters/active-filters-badge.vue";
import FilterConfigurationItemMap from "@/model/filter-configuration-item-map";
import { computed, ref } from "vue";
import Filter from "@/model/filter";
import MenuItem, { menuItemKey } from "@/interfaces/menu-item";
import _ from "lodash";

export type FiltersBadgeProps = {
  filter: Filter;
  type: "include" | "exclude";
  config: FilterConfigurationItemMap;
};

const filterSelector = ref<typeof FilterSelector>(null);

const props = defineProps<FiltersBadgeProps>();

const emit = defineEmits(["update"]);

const isExclude = computed(() => props.type === "exclude");

const filteredConfig = computed(() => {
  return _.pickBy(props.config, ({ enabled, exclude }) => enabled && !!exclude === isExclude.value);
});

const items = computed(() => {
  return _.sortBy(filteredConfig.value, "order").map(({ name, value }) => name || value);
});

const printLabel = computed(() => {
  return props.filter.name;
});

const title = computed(() => props.filter.name);

const onSelect = (item: MenuItem) => {
  const value = menuItemKey(item);
  const order = Math.max(...Object.values(filteredConfig.value).map(({ order }) => order || 0), 0) + 1;
  const config: FilterConfigurationItemMap = {
    ...props.config,
    [value]: {
      value,
      name: item.name,
      enabled: true,
      exclude: isExclude.value,
      order,
    },
  };
  emit("update", config);
};

const onDeselect = (item: MenuItem) => {
  const value = menuItemKey(item);
  const config = _.omit(props.config, value);
  emit("update", config);
};

const onMenuOpen = (menuOpen) => {
  filterSelector.value.setEnabled(menuOpen);
};
</script>
