<template>
  <pill-list :items="items" :available-items="availableGroupings" @update="onUpdate"></pill-list>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import pillList from "./pill-list.vue";

export default defineComponent({
  components: {
    pillList,
  },
  props: {
    modelValue: {
      type: Array,
      default: null,
    },
    onlyIds: {
      type: Array,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    allGroupings() {
      return this.$store.getters.getParameters("grouping");
    },
    items() {
      return (
        this.modelValue &&
        this.modelValue.map(({ id, enabled }) => ({
          value: id,
          name: id,
          enabled: !!enabled,
        }))
      );
    },
    availableGroupings() {
      let groupings = this.allGroupings;
      if (groupings && this.onlyIds) {
        groupings = groupings.filter(({ id }) => this.onlyIds.includes(id));
      }
      return groupings;
    },
  },
  methods: {
    onUpdate({ items }) {
      this.$emit(
        "update:modelValue",
        items.map(({ value, enabled }) => ({ id: value, enabled }))
      );
    },
  },
});
</script>
