<template>
  <div class="snippet-detail">
    <div class="snippet-chart-wrapper">
      <div class="panel panel-snippet detail-chart-panel">
        <div style="width: 300px"></div>
        <div v-if="!options" class="panel-body spinner">
          <spinner></spinner>
        </div>
        <drilldown-provider v-if="options" class="panel-body">
          <div class="flex-row align-items-center flex-wrap toolbar">
            <dropdown-select :value="grouping" :available-items="options" @update="onGroupingChanged"></dropdown-select>
            <dropdown-select
              v-if="type !== 'multi' && !isTrend"
              :value="sort"
              :available-items="sortOptions"
              @update="onSortChanged"
            >
              <template #toggle="slotProps">
                <dropdown-select-button :menu-open="slotProps.menuOpen" @click="slotProps.toggleMenu()"
                  ><i class="fa mr-sm" :class="sortIcon"></i
                  ><span class="hidden-xs">{{ sortLabel }}</span></dropdown-select-button
                >
              </template>
            </dropdown-select>
            <dropdown-select
              v-if="singleComparison && filters.comparisons.length > 1"
              :value="selectedComparisonIndex"
              :available-items="comparisonOptions"
              @update="onSelectedComparisonChanged"
            ></dropdown-select>
            <div class="flex-1"></div>
            <drilldown-dropdown></drilldown-dropdown>
            <proportions-selector
              v-if="proportionsEnabled"
              :model-value="proportions"
              @update:model-value="onUpdateProportions"
            ></proportions-selector
            ><a class="btn btn-primary-inverted btn-sm" @click="openReport">{{
              $t("dashboard.show_more.view_report")
            }}</a>
          </div>
          <hr class="detailed-hr" style="margin-top: 10px; margin-bottom: 0px" />
          <div v-if="chartType" class="chart" :class="chartClass">
            <trend v-if="isTrend" :chart="chartType" :filters="effectiveFilters"></trend>
            <div v-else class="dashboard-block">
              <chart
                class="graph"
                :type="chartType"
                :pagination="true"
                :filters="effectiveFilters"
                @update-limit="onUpdateLimit"
              ></chart>
            </div>
          </div>
        </drilldown-provider>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import spinner from "../components/spinner.vue";
import GroupingsApiService from "../api/groupings-api-service";
import trend from "./blocks/trend.vue";
import chart from "../zoined-chart/zoined-chart.vue";
import Actions from "../store/actions";
import _ from "lodash";
import { OpenReportParams } from "../lib/report";
import DrilldownDropdown from "../drilldown/drilldown-dropdown.vue";
import DrilldownProvider from "../drilldown/drilldown-provider.vue";
import ProportionsSelector from "../components/proportions-selector.vue";
import DropdownSelect from "../components/dropdown-select.vue";
import DropdownSelectButton from "../components/dropdown-select-button.vue";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    spinner,
    chart,
    trend,
    DrilldownDropdown,
    DrilldownProvider,
    ProportionsSelector,
    DropdownSelect,
    DropdownSelectButton,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    chart: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    metric: {
      type: String,
      required: true,
    },
    controls: { default: true, type: Boolean },
  },
  emits: ["open-report"],
  data() {
    return {
      groupings: null,
      chartType: null,
      selectedComparisonIndex: 0,
      targetReport: null,
    };
  },
  computed: {
    preferences() {
      return this.$store.getters.preferences;
    },
    metricPreferences() {
      return this.preferences.detail_chart_metric_preferences;
    },
    proportionsEnabled() {
      return this.chart.indexOf("_trend") < 0;
    },
    singleComparison() {
      return this.chart == "sales_trend";
    },
    comparisonOptions() {
      return this.filters.comparisons.map((comparison, index) => ({
        key: index,
        name: comparison.text,
      }));
    },
    selectedComparison() {
      return this.filters.comparisons[this.selectedComparisonIndex];
    },
    effectiveFilters() {
      if (this.type === "multi") {
        const f = {
          grouping: _.flatten([this.filters.grouping, this.grouping]),
          proportions: !!this.proportions,
          top_grouping: this.grouping,
          limit: this.limit || 5,
        };

        const effectiveFilters = _.extend({}, this.filters, f);
        delete effectiveFilters["comparisons"];
        return effectiveFilters;
      } else {
        let limit = 10;
        if (this.filters && this.filters.comparisons && this.filters.comparisons.length >= 2) {
          limit = 5;
        }
        const effectiveFilters = Object.assign({}, this.filters, {
          grouping: this.grouping,
          sort: this.sort,
          limit: this.limit || limit,
          proportions: !!this.proportions,
        });

        if (this.chart === "sales_trend") {
          // Update filters
          if (this.selectedComparisonIndex) {
            effectiveFilters.comparisons = this.filters.comparisons[this.selectedComparisonIndex];
          }
        }

        return effectiveFilters;
      }
    },
    chartClass() {
      const chart = this.chart;
      if (chart === "sales_trend" || chart === "visits_trend") {
        return "trend";
      } else if (this.type === "multi") {
        return "multi";
      } else {
        const comparisonCount = this.filters && this.filters.comparisons ? this.filters.comparisons.length : 0;
        return comparisonCount > 2
          ? "top_multi_series"
          : comparisonCount > 1
          ? "top_three_series"
          : comparisonCount == 1
          ? "top_two_series"
          : "top_one_series";
      }
    },
    isTrend() {
      return this.chartClass === "trend";
    },
    sortOptions(): MenuItem[] {
      return this.$store.getters
        .getParameters("sort")
        .map((sort) => ({ ...sort, icon: this.iconForSort(menuItemKey(sort)) }));
    },
    allGroupings(): MenuItem[] {
      return this.$store.getters.getParameters("grouping");
    },
    options(): MenuItem[] {
      if (this.chart != null && this.groupings != null) {
        // Set options for grouping selector
        return _.compact(
          this.groupings.map((key) => this.allGroupings.find((grouping) => menuItemKey(grouping) === key))
        );
      } else {
        return null;
      }
    },
    grouping() {
      if (!this.groupings) {
        return null;
      }
      const cachedGrouping = this.metricPreferences?.[this.metric]?.grouping;
      if (cachedGrouping != null && this.groupings.includes(cachedGrouping)) {
        return cachedGrouping;
      } else if (this.chart === "sales_trend") {
        return "category";
      } else if (this.chart === "visits_trend") {
        return "store";
      } else {
        const grouping = _.find(["product", "store", "sales_person", "store_country"], (grouping) =>
          this.groupings.includes(grouping)
        );
        if (grouping) {
          return grouping;
        } else {
          return this.options.length > 0 ? menuItemKey(this.options[0]) : "store"; // No available groupings at all, try grouping by store, fingers crossed.
        }
      }
    },
    sort() {
      return this.metricPreferences?.[this.metric]?.sort || "-metric";
    },
    limit() {
      return this.metricPreferences?.[this.metric]?.limit;
    },
    proportions() {
      return this.metricPreferences?.[this.metric]?.proportions;
    },
    sortLabel() {
      return this.sortOptions.find((option) => menuItemKey(option) === this.sort)?.name;
    },
    sortIcon() {
      return this.iconForSort(this.sort);
    },
  },
  watch: {
    metric: [
      {
        handler: "onMetricChange",
      },
    ],
  },
  created() {
    const chart = this.chart;
    if (chart === "sales_trend") {
      this.chartType = chart;
      this.targetReport = "sales_trend";
    } else if (chart === "visits_trend") {
      this.chartType = chart;
      this.targetReport = "people_counting_trend";
    } else {
      let chartName;
      if (this.type === "multi") {
        chartName = `multi_${chart}`;
      } else {
        chartName = `top_${chart}`;
      }
      this.chartType = chartName;
      this.targetReport = "organization_adhoc";
    }

    return this.fetchGroupings();
  },
  methods: {
    iconForSort(sort) {
      return {
        "-metric": "fa-sort-amount-desc",
        metric: "fa-sort-amount-asc",
        grouping: "fa-sort-alpha-asc",
      }[sort];
    },
    fetchGroupings() {
      return new GroupingsApiService()
        .getMetricGroupings(this.metric)
        .then((groupings) => (this.groupings = groupings.map(({ key }) => key)));
    },
    openReport() {
      // broadcast is listener by dashboard controller
      const proportionsConfig = this.proportions ? { proportions: true } : {};
      const config: OpenReportParams = {
        report: this.targetReport,
        grouping: this.grouping,
        metric: this.metric,
        limit: this.effectiveFilters.limit,
        ...proportionsConfig,
      };
      if (this.type !== "multi") {
        config.sort = this.sort;
      }
      this.$emit("open-report", config);
    },
    onGroupingChanged(grouping) {
      this.updateMetricPreferences("grouping", grouping);
    },
    onSortChanged(sort) {
      this.updateMetricPreferences("sort", sort);
    },
    onSelectedComparisonChanged(comparison) {
      this.selectedComparisonIndex = comparison;
    },
    updateMetricPreferences(key, value) {
      const metricPreferences = { ...(this.metricPreferences || {}) };
      const selectedMetricPreferences = metricPreferences[this.metric] || {};
      Object.assign(metricPreferences, {
        [this.metric]: {
          ...selectedMetricPreferences,
          [key]: value,
        },
      });
      this.$store.dispatch(Actions.updateDetailChartMetricPreferences, metricPreferences);
    },
    onUpdateLimit(limit) {
      this.updateMetricPreferences("limit", limit);
    },
    onUpdateProportions(proportions) {
      this.updateMetricPreferences("proportions", proportions);
    },
    onMetricChange() {
      return this.fetchGroupings();
    },
  },
});
</script>
<style lang="scss" scoped>
.snippet-detail {
  .panel-snippet {
    // Space for drilldown button
    padding-top: 50px;
  }
}

.toolbar {
  gap: 5px;
}
</style>
