<template>
  <div class="component-details">
    <div v-if="component.span < 12" class="top-placeholder hidden-xs">
      <div
        v-for="(c, index) in rowComponents"
        :key="index"
        class="top-placeholder-item"
        :class="{ half: c.span == 6, third: c.span == 4, quarter: c.span == 3 }"
      >
        <div v-if="c == component" class="top-placeholder-bg">
          <div v-if="!isAtStartOfRow" class="corner-rounding-left"></div>
          <div v-if="!isAtEndOfRow" class="corner-rounding-right"></div>
        </div>
      </div>
    </div>
    <div class="detail-chart-wrapper" :class="{ start: isAtStartOfRow, end: isAtEndOfRow }">
      <detail-chart-component
        v-if="component.type == 'metric_chart'"
        :type="'multi'"
        :chart="metric"
        :caption="component.title"
        :filters="filters"
        :metric="metric"
        @open-report="$emit('open-report', $event)"
      ></detail-chart-component>
      <detail-chart-component
        v-else-if="data"
        :chart="data.chart"
        :caption="data.title"
        :filters="filters"
        :metric="data.metric"
        @open-report="$emit('open-report', $event)"
      ></detail-chart-component>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import detailChartComponent from "../dashboard/detail-chart-component.vue";

export default {
  components: {
    detailChartComponent,
  },
  props: {
    component: {
      type: Object,
      required: true,
    },
    rowComponents: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  emits: ["open-report"],
  computed: {
    metric() {
      return _.flatten([this.filters.metrics])[0];
    },
    rowSpan() {
      return this.rowComponents.reduce((sum, c) => sum + c.span, 0);
    },
    isAtStartOfRow() {
      return this.rowComponents.indexOf(this.component) == 0;
    },
    isAtEndOfRow() {
      return this.rowComponents.indexOf(this.component) == this.rowComponents.length - 1 && this.rowSpan == 12;
    },
  },
};
</script>

<style lang="scss" scoped>
.top-placeholder {
  display: none;

  @media (min-width: 768px) {
    display: grid;
  }

  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 30px;

  .top-placeholder-item {
    grid-column-end: span 12;

    @media (min-width: 768px) {
      &.half {
        grid-column-end: span 6;
      }

      &.third {
        grid-column-end: span 4;
      }

      &.quarter {
        grid-column-end: span 3;
      }
    }
  }

  .top-placeholder-bg {
    height: 20px;
    background-color: white;
    position: relative;

    .corner-rounding-left {
      display: block;
      z-index: 3;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      width: 10px;
      height: 10px;
      background: radial-gradient(circle at top left, transparent 10px, white 10px);
    }

    .corner-rounding-right {
      display: block;
      z-index: 3;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(100%);
      width: 10px;
      height: 10px;
      background: radial-gradient(circle at top right, transparent 10px, white 10px);
    }
  }
}

.detail-chart-wrapper {
  &::v-deep {
    .detail-chart-panel {
      border-radius: var(--panel-snippet-border-radius);
    }
  }

  &.start::v-deep {
    .detail-chart-panel {
      border-top-left-radius: 0;
    }
  }

  &.end::v-deep {
    .detail-chart-panel {
      border-top-right-radius: 0;
    }
  }
}
</style>
