<template>
  <div v-if="team" class="team-members">
    <div>
      <div class="row">
        <div class="col-md-8 col-sm-6">
          <div class="table-controls">
            <search-bar v-model="search"></search-bar>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="pull-right">
            <team-member-input :team-id="id" @add-member="addMember"></team-member-input>
          </div>
        </div>
      </div>
      <div>
        <spinner v-if="!members"></spinner>
        <table class="table table-hover">
          <thead>
            <tr>
              <th>{{ $t("activerecord.attributes.user.first_name") }}</th>
              <th>{{ $t("activerecord.attributes.user.last_name") }}</th>
              <th>{{ $t("activerecord.attributes.user.email") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="members.length == 0">
              <td colspan="6">{{ $t("table.no_results") }}</td>
            </tr>
            <tr v-for="user in members">
              <td>{{ user.first_name }}</td>
              <td>{{ user.last_name }}</td>
              <td>{{ user.email }}</td>
              <td class="text-right">
                <confirm-button @confirm="removeMember(user)">
                  <a>{{ $t("company_admin.teams.remove_member") }}</a></confirm-button
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="total > perPage" class="centered">
          <uiv-pagination
            v-model="page"
            :total-page="Math.ceil(total / perPage)"
            @change="onPageChange"
          ></uiv-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import adminTeamsApi from "../api/admin-teams-api";
import adminTeamMembersApi from "../api/admin-team-members-api";
import SearchBar from "../components/search-bar.vue";
import Spinner from "../components/spinner.vue";
import ConfirmButton from "../components/confirm-button.vue";
import TeamMemberInput from "./team-member-input.vue";
import Mutations from "../store/mutations";
import i18n from "../i18n";

export default defineComponent({
  components: {
    SearchBar,
    Spinner,
    ConfirmButton,
    TeamMemberInput,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(Mutations.clearTitle);
    next();
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      team: null,
      members: [],
      page: 1,
      perPage: 15,
      search: "",
      total: 0,
    };
  },
  computed: {
    companyId() {
      return window.zoinedContext.companyId;
    },
    params() {
      return {
        company_id: this.companyId,
        team_id: this.id,
        page: this.page,
        per_page: this.perPage,
        search: this.search,
      };
    },
  },
  watch: {
    params: [
      {
        handler: "fetchMembers",
      },
    ],
  },
  created() {
    this.fetchTeam();
    this.fetchMembers();
  },
  methods: {
    onPageChange(page) {
      this.page = page;
    },
    fetchTeam() {
      adminTeamsApi.get({ company_id: this.companyId, id: this.id }).then((team) => {
        this.team = team;
        this.$store.commit(Mutations.setTitle, team.name + " - " + i18n.t("company_admin.teams.members"));
      });
    },
    addMember(role) {
      return adminTeamMembersApi.post({ company_id: this.companyId, team_id: this.id, role_id: role.id }).then(() => {
        this.fetchMembers();
      });
    },
    removeMember(role) {
      return adminTeamMembersApi.delete({ company_id: this.companyId, team_id: this.id, id: role.id }).then(() => {
        this.fetchMembers();
      });
    },
    fetchMembers() {
      adminTeamMembersApi.get(this.params).then((members) => {
        this.members = members;
        this.total = members.total;
      });
    },
  },
});
</script>
