<template>
  <modal v-model="open" :title="$t('activerecord.attributes.company.business_hours')">
    <div class="row">
      <div class="col-md-6">
        <label>{{ $t("components.business_hours_editor.store_selection") }}</label>
        <select v-model="storeId">
          <option :value="null">{{ $t("components.business_hours_editor.company_default") }}</option>
          <option v-for="store in stores" :key="store.id" :value="String(store.id)">{{ store.name }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label>{{ $t("components.business_hours_editor.start_date") }}</label>
        <input v-model="startDate" type="date" />
      </div>
      <div class="col-md-6">
        <label>{{ $t("components.business_hours_editor.end_date") }}</label>
        <input v-model="endDate" type="date" />
      </div>
    </div>
    <div v-if="itemsByDay" class="row">
      <div class="col-md-12">
        <div class="flex-column gap-lg">
          <business-hours-day-selector
            v-for="day in days"
            :key="day"
            v-model="itemsByDay[day]"
            :day="day"
          ></business-hours-day-selector>
        </div>
      </div>
    </div>
    <div v-if="!itemsByDay && defaultItemsByDay" class="row defaults">
      <div class="col-md-12">
        <div class="flex-column gap-lg">
          <business-hours-day-selector
            v-for="day in days"
            :key="day"
            :day="day"
            :model-value="defaultItemsByDay[day]"
            :is-default="true"
            @update:model-value="onDefaultItemUpdated(day, $event)"
          ></business-hours-day-selector>
        </div>
      </div>
    </div>
    <template #footer>
      <button class="btn btn-default" @click="open = false">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" :disabled="!isValid" @click="onSave">{{ $t("actions.save") }}</button>
    </template>
  </modal>
</template>

<script>
import businessHoursDaySelector from "./business-hours-day-selector";
import { Modal } from "uiv";

export default {
  components: {
    Modal,
    businessHoursDaySelector,
  },
  props: {
    days: {
      type: Array,
      required: true,
    },
    stores: {
      type: Array,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    configs: {
      type: Array,
      required: true,
    },
  },
  emits: ["save"],
  data() {
    return {
      storeId: null,
      startDate: null,
      endDate: null,
      itemsByDay: null,
      open: false,
    };
  },
  computed: {
    isValid() {
      const config = this.getConfigFromModel();
      return config.start_date;
    },
    defaultItemsByDay() {
      if (this.startDate && !this.itemsByDay) {
        const activeConfig = this.findActiveConfig();
        if (activeConfig) {
          return _.groupBy(activeConfig.items, (item) => item.weekday);
        } else {
          return {};
        }
      }
      return null;
    },
  },
  watch: {
    config() {
      this.getModelFromProps();
    },
  },
  created() {
    this.getModelFromProps();
  },
  methods: {
    show() {
      this.open = true;
    },
    onSave() {
      const config = this.getConfigFromModel();
      this.$emit("save", config);
      this.open = false;
    },
    getConfigFromModel() {
      return {
        id: this.config.id,
        organisation_nk: this.storeId,
        start_date: this.startDate,
        end_date: this.endDate,
        items: _.flatten(_.values(this.itemsByDay || this.defaultItemsByDay || {})),
      };
    },
    getModelFromProps() {
      this.storeId = this.config.organisation_nk;
      this.startDate = this.config.start_date;
      this.endDate = this.config.end_date;
      this.itemsByDay = this.config.items ? _.groupBy(this.config.items, (item) => item.weekday) : null;
    },
    findActiveConfig() {
      const storeConfigs = _.sortBy(
        this.storeId ? this.configs.filter(({ organisation_nk }) => organisation_nk === this.storeId) : [],
        "start_date"
      ).reverse();

      const companyConfigs = _.sortBy(
        this.configs.filter(({ organisation_nk }) => !organisation_nk),
        "start_date"
      ).reverse();

      const configs = [...storeConfigs, ...companyConfigs].filter(({ start_date, end_date }) => {
        return start_date <= this.startDate && (!end_date || end_date >= this.startDate);
      });
      return _.first(configs);
    },
    onDefaultItemUpdated(day, config) {
      this.itemsByDay = {
        ...this.defaultItemsByDay,
        [day]: config,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.scope-selector {
  margin-bottom: 5px;
}
.row {
  margin-bottom: 10px;
}
</style>
