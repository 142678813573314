<template>
  <div id="filters" class="fly-over selectors dark-bg" :class="{ open }">
    <div class="flex-column h-full">
      <div class="header flex-column">
        <div class="flex-row justify-content-flex-end">
          <button class="btn btn-dark btn-icon" @click="hide">
            <icon icon="fluent:dismiss-16-filled" />
          </button>
        </div>
      </div>
      <div v-if="open" class="body flex-row w-full">
        <flyover-filters />
        <ai-assistant-placeholder></ai-assistant-placeholder>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Mutations from "../store/mutations";
import flyoverFilters from "./flyover-filters.vue";
import AiAssistantPlaceholder from "@/ai-assistant/ai-assistant-placeholder.vue";
import { Icon } from "@iconify/vue";
import { useStore } from "@/composables/store";
import { computed } from "vue";

const store = useStore();

const open = computed(() => {
  return store.state.flyoverFilters.open;
});

const hide = () => {
  store.commit(Mutations.closeFlyoverFilters);
};
</script>

<style lang="scss" scoped>
$grid-float-breakpoint: 992px;
.hide-filters-container {
  position: absolute;
  width: 100%;
}
#filters {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-index-flyover-filters);
  background-color: var(--color-surface-neutral-6);

  transform: translateX(100%);
  transition: transform 0.3s ease;

  &.open {
    transform: translateX(0);
  }

  @media (min-width: $grid-float-breakpoint) {
    transform: none;
    position: static;
    width: 100%;
    height: auto;
    background-color: transparent;
  }

  .header {
    position: sticky;
    padding: 20px;
    top: 0;
    background-color: var(--color-black-90);
    z-index: 1;
    border-bottom: 1px solid var(--color-border-variant);

    @media screen and (min-width: $grid-float-breakpoint) {
      display: none;
    }
  }

  .body {
    flex: 1;

    overflow-y: auto;
    overflow-x: visible;

    @media screen and (min-width: $grid-float-breakpoint) {
      overflow: visible;
    }
  }
}
</style>
