import { defineComponent, h } from "vue";
import store from "../store/store";
import router from "./router";
import { createVueApp } from "./create-vue-app";

export function showDialog<T>(component, props = {}): Promise<T> {
  return new Promise((resolve, reject) => {
    const el = document.createElement("div");
    document.body.appendChild(el);

    const vm = defineComponent({
      store,
      router,
      methods: {
        destroy() {
          app.unmount();
          el.remove();
        },
      },
      render() {
        return h(component, {
          ...props,
          onCloseDialog: (data) => {
            resolve(data);
            this.destroy();
          },
          onError: (err) => {
            reject(err);
            this.destroy();
          },
        });
      },
    });

    const app = createVueApp(vm);

    app.mount(el);
  });
}
