<template>
  <div class="newsletter-templates-list">
    <div>
      <dropdown>
        <template #toggle="toggleProps">
          <div class="btn-group">
            <button class="btn btn-primary" type="button" @click="newNewsletterTemplate()">
              {{ $t("newsletter_templates.new_newsletter_template") }}
            </button>
            <button class="btn btn-primary" type="button" @click="toggleProps.toggleDropdown()">
              <span class="caret"></span>
            </button>
          </div>
        </template>
        <template #menu>
          <dropdown-item @click="newNewsletterTemplate()">{{
            $t("newsletter_templates.empty_template")
          }}</dropdown-item>
          <dropdown-item @click="newTemplateFromNewsletter()">{{
            $t("newsletter_templates.from_newsletter")
          }}</dropdown-item>
        </template>
      </dropdown>
    </div>
    <div class="filters">
      <span v-if="partnerFilter" class="badge"
        >{{ partnerFilter.name }} <i class="fa fa-times" @click="partnerFilter = null"></i
      ></span>
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>{{ $t("attributes.name") }}</th>
          <th>{{ $t("attributes.visibility") }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="template in filteredTemplates" :key="template.id">
          <td>{{ template.title }}</td>
          <td>
            <a v-for="site in visibleSites(template)" :key="site.id" class="site" @click.stop="setFilter(site)">{{
              site.name
            }}</a>
          </td>
          <td class="text-right">
            <div class="flex-inline-row gap-sm">
              <IconButton
                v-uiv-tooltip="$t('actions.edit')"
                icon="fluent:edit-16-regular"
                hover-icon="fluent:edit-16-filled"
                button-style="low-contrast"
                @click="editTemplate(template)"
              />
              <IconButton
                v-uiv-tooltip="$t('actions.duplicate')"
                icon="fluent:copy-16-regular"
                hover-icon="fluent:copy-16-filled"
                button-style="low-contrast"
                @click="duplicateTemplate(template)"
              />
              <confirm-button @confirm="deleteTemplate(template)">
                <IconButton
                  v-uiv-tooltip="$t('actions.delete')"
                  icon="fluent:delete-16-regular"
                  hover-icon="fluent:delete-16-filled"
                  button-style="low-contrast"
                />
              </confirm-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <newsletter-template-from-newsletter-modal
      ref="newsletterTemplateFromNewsletterModal"
      @select="onSelectNewsletter"
    ></newsletter-template-from-newsletter-modal>
  </div>
</template>

<script lang="ts">
import { makeApiInstance } from "../../api/instance";
import { defineComponent } from "vue";
import Partner from "../../model/partner";
import NewsletterTemplate from "../../model/newsletter-template";
import confirmButton from "../../components/confirm-button.vue";
import NewsletterTemplateFromNewsletterModal from "./newsletter-template-from-newsletter-modal.vue";
import dropdown from "../../components/dropdown.vue";
import dropdownItem from "../../components/dropdown-item.vue";
import IconButton from "@/components/icon-button.vue";

export default defineComponent({
  components: { confirmButton, dropdown, dropdownItem, NewsletterTemplateFromNewsletterModal, IconButton },
  data() {
    const partnerFilter: Partner = null;
    const newsletterTemplates: NewsletterTemplate[] = null;

    return {
      newsletterTemplates,
      partnerFilter,
    };
  },
  computed: {
    api() {
      return makeApiInstance({ baseURL: "/api/zoined_admin/newsletter_templates" });
    },
    filteredTemplates(): NewsletterTemplate[] {
      if (!this.partnerFilter) {
        return this.newsletterTemplates;
      }

      return this.newsletterTemplates.filter((template) =>
        this.partnerFilter.id === "zoined"
          ? template.visible_in_zoined
          : template.partners.some(({ id }) => id === this.partnerFilter.id)
      );
    },
  },
  created() {
    this.fetchNewsletterTemplates();
  },
  methods: {
    setFilter(filter: Partner) {
      this.partnerFilter = filter;
    },
    visibleSites(template: NewsletterTemplate): Partner[] {
      const sites = [];
      if (template.visible_in_zoined) {
        sites.push({ id: "zoined", name: "Zoined" });
      }
      template.partners.forEach(({ id, name }) => sites.push({ id, name }));
      return sites;
    },
    newNewsletterTemplate() {
      this.$router.push({ name: "new_newsletter_template" });
    },
    newTemplateFromNewsletter() {
      (this.$refs.newsletterTemplateFromNewsletterModal as typeof NewsletterTemplateFromNewsletterModal).show();
    },
    onSelectNewsletter(id) {
      this.$router.push({ name: "new_newsletter_template", query: { definitionId: id } });
    },
    editTemplate(template: NewsletterTemplate) {
      this.$router.push({ name: "edit_newsletter_template", params: { id: template.id.toString() } });
    },
    duplicateTemplate(template: NewsletterTemplate) {
      this.$router.push({ name: "new_newsletter_template", query: { templateId: template.id.toString() } });
    },
    async deleteTemplate(template: NewsletterTemplate) {
      await this.api.delete(template.id.toString());
      this.newsletterTemplates = this.newsletterTemplates.filter((it) => it !== template);
    },
    async fetchNewsletterTemplates() {
      const result = await this.api.get("");
      this.newsletterTemplates = result.data;
    },
  },
});
</script>

<style lang="scss" scoped>
a.site {
  cursor: pointer;
  margin-right: 5px;
}

.actions {
  .action {
    display: inline-block;
    margin: 0 10px;
    a {
      cursor: pointer;
    }
    a:not(:hover) {
      color: gray;
    }
  }
}
</style>
