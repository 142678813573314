<template>
  <div class="dashboard-template-editor">
    <div v-if="!loaded" class="loading-indicator">
      <spinner></spinner>
    </div>
    <div v-else class="editor">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group" :class="{ 'has-error': v$.template.name.$error }">
            <label class="control-label" for="name">{{ $t("attributes.name") }}</label>
            <input id="name" v-model="template.name" class="form-control" type="text" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="name">{{ $t("attributes.description") }}</label>
            <input id="name" v-model="template.description" type="text" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group" :class="{ 'has-error': v$.template.translation_key.$error }">
            <label class="control-label" for="title" style="margin-right: 5px;">{{
              $t("dashboard_templates.translation_key")
            }}</label>
            <div class="subtitle">
              Translation is saved in localizations (localeapp) under "analytics_templates.titles.[key]"
            </div>
            <input id="title" v-model="template.translation_key" class="form-control" type="text" />
            <div v-if="v$.template.translation_key.$error" class="help-block">
              Key can contain only characters a-z and _
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>{{ $t("attributes.visibility") }}</h3>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <div>
              <label>{{ $t("activerecord.attributes.dashboard_template.visible_in_zoined") }}</label>
            </div>
            <toggle-button v-model="template.visible_in_zoined"></toggle-button>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <div>
              <label>{{ $t("activerecord.attributes.dashboard_template.add_for_new_customers") }}</label>
            </div>
            <toggle-button v-model="template.add_for_new_customers"></toggle-button>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <div>
              <label>{{ $t("activerecord.attributes.dashboard_template.partners") }}</label>
            </div>
            <partner-selector v-model="template.partners"></partner-selector>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>{{ $t("dashboard_custom.report_editor.filters") }}</h4>
        </div>
      </div>
      <div class="row time-filters">
        <div class="col-md-12 flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-lg">
          <time-period-dropdown
            :available-items="options.time_ranges"
            :available-series-types="options.seriesTypes"
            :value="filterConfiguration.time.selection"
            @update="updateSelection($event)"
          ></time-period-dropdown>
          <comparison-pill-list
            :comparisons="filterConfiguration.time.comparisons"
            @update="updateComparisons"
          ></comparison-pill-list>
          <business-hours-selector
            :value="filterConfiguration.time.limit_to_business_hours"
            @update="updateBusinessHours($event)"
          ></business-hours-selector>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-lg">
            <filter-selector
              v-for="filter in options.filters"
              :key="filter.id"
              :config="filters[filter.id]"
              :filter="filter"
              @update="updateFilter(filter.id, $event)"
            ></filter-selector>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>{{ $t("dashboard_custom.report_editor.layout") }}</h4>
        </div>
      </div>
      <dashboard-components-grid-editor
        :config="template.config"
        :options="options"
        :shared="false"
        :filter-configuration="template.config.filterConfiguration"
        @update="template.config = $event"
      ></dashboard-components-grid-editor>
      <div class="row buttons">
        <div class="col-md-12">
          <div v-if="id" class="pull-left">
            <confirm-button
              class="delete-dashboard"
              :button-title="$t('actions.delete')"
              variant="danger"
              @confirm="deleteTemplate()"
            ></confirm-button>
          </div>
          <div class="pull-right">
            <button class="pull-right btn btn-primary" :disabled="submitDisabled" @click="save">
              {{ $t("actions.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import { convertToNewFilterConfiguration, refreshFilterTimes } from "@/lib/filter-util";
import { defineComponent } from "vue";
import comparisonPillList from "../../components/comparison-pill-list.vue";
import filterSelector from "../../components/filter-selector.vue";
import timePeriodDropdown from "../../components/time-period-dropdown.vue";
import businessHoursSelector from "../../components/business-hours-selector.vue";
import DashboardComponentsGridEditor from "../../custom-dashboard-editor/dashboard-components-grid-editor.vue";
import ConfirmButton from "../../components/confirm-button.vue";
import { makeApiInstance } from "../../api/instance";
import AnalyticsTemplate from "../../model/analytics-template";
import i18n from "../../i18n";
import spinner from "../../components/spinner.vue";
import toggleButton from "../../components/toggle-button.vue";
import Actions from "../../store/actions";
import partnerSelector from "../../components/partner-selector.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { menuItemKey } from "@/interfaces/menu-item";

export default defineComponent({
  components: {
    comparisonPillList,
    filterSelector,
    timePeriodDropdown,
    businessHoursSelector,
    DashboardComponentsGridEditor,
    ConfirmButton,
    spinner,
    toggleButton,
    partnerSelector,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    dashboardId: {
      type: String,
      default: null,
    },
    templateId: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      template: {
        name: { required },
        translation_key: {
          valid: (key: string) => !key || !!key.match(/^[a-z_]*$/),
        },
      },
    };
  },
  data() {
    const template: Partial<AnalyticsTemplate> = null;

    return {
      template,
    };
  },
  computed: {
    api() {
      return makeApiInstance({ baseURL: "/api/zoined_admin/analytics_templates/" });
    },
    myReportsApi() {
      return makeApiInstance({ baseURL: "/api/v1/my_reports/" });
    },
    title() {
      return this.id
        ? i18n.t("dashboard_templates.edit_dashboard_template")
        : i18n.t("dashboard_templates.new_dashboard_template");
    },
    loaded() {
      return this.template && this.options;
    },
    submitDisabled() {
      return false;
    },
    options() {
      const filters = this.$store.state.parameters.filters.all || [];
      const groupings = this.$store.state.parameters.grouping.all || [];
      const metrics = this.$store.state.parameters.metrics.all || [];
      const time_ranges = (this.$store.state.parameters.timePeriods.all || []).map((item) => ({
        key: menuItemKey(item),
        name: item.name,
      }));
      const sort = this.$store.state.parameters.sort.all || [];

      const seriesTypes = window.zoinedContext.budgets && ["actual", ...Object.keys(window.zoinedContext.budgets)];

      return {
        filters,
        groupings,
        metrics,
        time_ranges,
        sort,
        seriesTypes,
      };
    },
    filterConfiguration() {
      return _.cloneDeep(this.template.config.filterConfiguration);
    },
    filters() {
      return _.reduce(
        this.filterConfiguration.filters.filters,
        (result, config, filter) => {
          return {
            ...result,
            [filter]: _.fromPairs(
              _.map(config, ({ enabled, exclude, value, name }, key) => [
                key,
                {
                  value: key,
                  enabled,
                  exclude: !!exclude,
                  name: name || value || key,
                },
              ])
            ),
          };
        },
        {}
      );
    },
  },
  created() {
    this.fetchOrInitTemplate();
  },
  methods: {
    updateSelection(selection) {
      this.template.config.filterConfiguration.time.selection = selection;
    },
    updateComparisons(comparisons) {
      this.template.config.filterConfiguration.time.comparisons = comparisons;
    },
    updateBusinessHours(limitToBusinessHours) {
      this.template.config.filterConfiguration.time.limit_to_business_hours = limitToBusinessHours;
    },
    updateFilter(filter, filterConfig) {
      if (!_.isEmpty(filterConfig)) {
        const config = _.reduce(
          _.values(filterConfig),
          (result, { enabled, exclude, value, name }) => ({
            ...result,
            [value]: { enabled, exclude: !!exclude, value, name: name || value },
          }),
          {}
        );
        this.template.config.filterConfiguration.filters.filters[filter] = config;
      } else {
        delete this.template.config.filterConfiguration.filters.filters[filter];
      }
    },
    async deleteTemplate() {
      await this.api.delete(this.id);

      this.$router.back();
    },
    async save() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      const url = this.id ? this.id : "";
      const method = this.id ? "put" : "post";
      const analytics_template: Partial<AnalyticsTemplate> = {
        name: this.template.name,
        description: this.template.description,
        analytics_type: "dashboard",
        config: this.template.config,
        add_for_new_customers: this.template.add_for_new_customers,
        visible_in_zoined: this.template.visible_in_zoined,
        partner_ids: this.template.partners?.map(({ id }) => id) || [],
        translation_key: this.template.translation_key,
      };

      await this.api.request({
        url,
        method,
        data: {
          analytics_template,
        },
      });

      this.$store.dispatch(Actions.fetchNavigation);

      this.$router.back();
    },
    async fetchOrInitTemplate() {
      if (this.id) {
        await this.api.get(this.id).then(({ data }) => {
          this.template = data;
        });
      } else if (this.dashboardId) {
        const { filters, report } = await this.myReportsApi.get(this.dashboardId).then((result) => result.data);

        this.template = {
          name: report.metadata.title,
          description: report.metadata.description,
          analytics_type: "dashboard",
          config: { ...report.config, filterConfiguration: filters },
          visible_in_zoined: true,
          add_for_new_customers: false,
        };
      } else if (this.templateId) {
        await this.api.get(this.templateId).then((response) => {
          const template: AnalyticsTemplate = response.data;
          this.template = {
            name: i18n.t("newsletter.definition.copy_of", {
              title: template.name,
            }),
            description: template.description,
            analytics_type: "dashboard",
            config: template.config,
            visible_in_zoined: template.visible_in_zoined,
            add_for_new_customers: template.add_for_new_customers,
          };
        });
      } else {
        const filterConfiguration = convertToNewFilterConfiguration({
          selection: { type: "4_weeks" },
          comparisons: [{ type: "prev_year_corresponding", enabled: true }],
        });

        this.template = {
          name: "",
          description: "",
          config: {
            components: [],
            filters: ["date", "comparisons", "limit_to_business_hours"],
            sort: ["-metric", "metric", "grouping"],
            default: {},
            radio_selectors: ["grouping"],
            excel: true,
            benchmark: ["avg_chain", "best_store", "avg_salesperson", "best_salesperson"],
            budgets_enabled: true,
            filterConfiguration,
          },
          visible_in_zoined: true,
          add_for_new_customers: false,
        };
      }

      this.template.config.filterConfiguration = convertToNewFilterConfiguration(
        this.template.config.filterConfiguration
      );
      this.template.config.filterConfiguration.time = refreshFilterTimes(this.template.config.filterConfiguration.time);
    },
  },
});
</script>

<style lang="scss" scoped>
.dashboard-template-editor {
  margin-bottom: 8rem; // need to have space for the Olark-tab in bottom of the page
}

.title {
  margin-bottom: 20px;
}

.buttons {
  margin-top: 15px;
}

.time-filters {
  margin-bottom: 10px;
}

.row h4 {
  margin: 20px 0;
}
</style>
