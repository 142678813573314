<template>
  <div>
    <spinner v-if="!users"></spinner>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>{{ $t("activerecord.attributes.user.first_name") }}</th>
          <th>{{ $t("activerecord.attributes.user.last_name") }}</th>
          <th>{{ $t("activerecord.attributes.user.email") }}</th>
          <th>{{ $t("activerecord.attributes.user.role") }}</th>
          <th>{{ $t("activerecord.attributes.user.status") }}</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="users">
        <tr v-if="users.length == 0">
          <td colspan="6">{{ $t("table.no_results") }}</td>
        </tr>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.first_name }}</td>
          <td>{{ user.last_name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ $t("roles." + user.role) }}</td>
          <td v-if="user.user_status.type == 'active'">{{ $t("company_admin.user_row.active") }}</td>
          <td v-else-if="user.user_status.type == 'invited'">
            {{ $t("company_admin.user_row.invited", { ago: ago(user.created_at) }) }} &nbsp;<a @click="resend(user)">{{
              $t("company_admin.user_row.resend")
            }}</a>
          </td>
          <td v-else-if="user.user_status.type == 'bad_mailbox'">
            <span class="bg-danger"
              ><i class="fa fa-exclamation-circle text-danger">&nbsp;</i
              >{{ $t("company_admin.user_row.bad_mailbox", { ago: ago(user.updated_at) }) }}</span
            >
          </td>
          <td v-else>
            <span class="bg-warning"
              ><i class="fa fa-exclamation-circle text-warning">&nbsp;</i
              >{{ $t("company_admin.user_row." + user.user_status.type, { ago: ago(user.updated_at) }) }}</span
            >
          </td>
          <td v-if="isAdmin">
            <form class="button_to" method="post" :action="impersonatePath(user)">
              <input type="hidden" name="authenticity_token" :value="csrfToken()" />
              <input value="Impersonate" type="submit" />
            </form>
          </td>
          <td class="text-right">
            <IconButton
              v-uiv-tooltip="$t('actions.edit')"
              icon="fluent:edit-16-regular"
              hover-icon="fluent:edit-16-filled"
              button-style="low-contrast"
              @click="edit(user)"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <user-dialog
      ref="userDialog"
      :teams="teams"
      :definitions="newsletterDefinitions"
      @delete="$emit('delete', $event)"
      @updated="$emit('updated', $event)"
    ></user-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import moment from "moment";
import UserCompanyRole from "@/model/user-company-role";
import Spinner from "@/components/spinner.vue";
import Team from "@/model/team";
import adminTeamsApi from "@/api/admin-teams-api";
import adminNewsletterDefinitions from "@/api/admin-newsletter-definitions-api";
import adminUsersApi from "@/api/admin-users-api";
import NewsletterDefinition from "@/model/newsletter-definition";
import UserDialog from "./user-dialog.vue";
import IconButton from "@/components/icon-button.vue";

export default defineComponent({
  components: { Spinner, UserDialog, IconButton },
  props: {
    users: {
      type: Array as PropType<UserCompanyRole[]>,
      default: null,
    },
  },
  emits: ["delete", "updated", "resend"],
  data() {
    const newsletterDefinitions: NewsletterDefinition[] = null;
    const teams: Team[] = null;

    return {
      teams,
      newsletterDefinitions,
    };
  },
  computed: {
    isAdmin() {
      return window.zoinedContext.isAdmin;
    },
  },
  created() {
    this.fetchTeams();
    this.fetchNewsletterDefinitions();
  },
  methods: {
    ago(time) {
      return moment(time).fromNow(true);
    },
    async edit(user: UserCompanyRole) {
      // fetch full user details
      user = await adminUsersApi.get({ company_id: window.zoinedContext.companyId, id: user.id });

      (this.$refs.userDialog as typeof UserDialog).show(user);
    },
    resend(user: UserCompanyRole) {
      this.$emit("resend", user);
    },
    impersonatePath(role) {
      return `/users/${role.user.id}/impersonate`;
    },
    csrfToken() {
      return document.querySelector("meta[name='csrf-token']")?.getAttribute("content") || "";
    },
    async fetchTeams() {
      this.teams = await adminTeamsApi.get({ company_id: window.zoinedContext.companyId });
    },
    async fetchNewsletterDefinitions() {
      this.newsletterDefinitions = await adminNewsletterDefinitions.get({ company_id: window.zoinedContext.companyId });
    },
  },
});
</script>

<style lang="scss" scoped>
table {
  th,
  td {
    // Email column
    &:nth-child(3) {
      word-break: break-all;
    }
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      display: none;
      @media (min-width: 768px) {
        display: table-cell;
      }
    }
  }
}
</style>
