<template>
  <confirm-button
    v-uiv-tooltip.hover="t('actions.delete')"
    class="intercom-tag-report-actions-delete"
    :popover-message="t('custom_report.delete_report')"
    @confirm="deleteReport"
  >
    <report-action-button icon="fluent:delete-24-filled" :text="t('actions.delete')" />
  </confirm-button>
</template>

<script lang="ts" setup>
import ConfirmButton from "./confirm-button.vue";
import EventBus from "../events/event-bus";
import Events from "../events/events";
import reportActionButton from "@/components/report-action-button.vue";
import { useI18n } from "@/composables/i18n";

const { t } = useI18n();
const deleteReport = () => {
  EventBus.emit(Events.deleteReport);
};
</script>
