<template>
  <ui-toggle-switch :model-value="isRounded" :title="t('filter.config.round_values')" @update:model-value="toggle" />
</template>

<script lang="ts" setup>
import { useI18n } from "@/composables/i18n";
import UiToggleSwitch from "@/ui/ui-toggle-switch.vue";
import { computed } from "vue";

const props = defineProps<{
  modelValue?: boolean;
}>();

const emit = defineEmits(["update:modelValue"]);

const isRounded = computed(() => props.modelValue !== false);

const { t } = useI18n();

const toggle = () => {
  emit("update:modelValue", !isRounded.value);
};
</script>
