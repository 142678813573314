<template>
  <uiv-modal v-model="open" :title="$t('company_admin.import_users.title')" size="lg">
    <div v-if="error">
      <div class="alert alert-danger">{{ error }}</div>
    </div>
    <div v-if="sending">
      <spinner></spinner>
    </div>
    <div v-else-if="users">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("activerecord.attributes.user.first_name") }}</th>
            <th>{{ $t("activerecord.attributes.user.last_name") }}</th>
            <th>{{ $t("activerecord.attributes.user.email") }}</th>
            <th>{{ $t("activerecord.attributes.user.role") }}</th>
            <th>{{ $t("filter.config.store") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.email">
            <td>{{ user.first_name }}</td>
            <td>{{ user.last_name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ $t("roles." + user.role) }}</td>
            <td>{{ userStores(user) }}</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-md">
        <div class="checkbox">
          <label>
            <input v-model="sendEmails" type="checkbox" />{{ $t("company_admin.import_users.send_invitation_emails") }}
          </label>
        </div>
      </div>
    </div>
    <div v-else-if="processing">
      <spinner></spinner>
    </div>
    <div v-else>
      <div class="droparea">
        <input ref="fileInput" type="file" @change="onFileChange" />{{
          $t("company_admin.import_users.drop_file_here")
        }}
      </div>
      <div class="mt-md">
        <a href="/templates/user-import.xlsx" download>{{ $t("company_admin.import_users.download_template") }}</a>
      </div>
    </div>
    <template #footer>
      <button class="btn" @click="cancel()">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" type="submit" :disabled="!users || error || sending" @click="invite()">
        {{ $t("company_admin.import_users.invite_all") }}
      </button>
    </template>
  </uiv-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Spinner from "../components/spinner.vue";
import readXlsxFile from "read-excel-file";
import i18n from "../i18n";
import adminUsersApi from "../api/admin-users-api";
import _ from "lodash";

export default defineComponent({
  components: {
    Spinner,
  },
  emits: ["add-users"],
  data() {
    return {
      open: false,
      processing: false,
      users: null,
      error: null,
      sendEmails: true,
      sending: false,
    };
  },
  methods: {
    show() {
      this.error = null;
      this.users = null;
      this.processing = false;
      this.sending = false;
      this.sendEmails = true;

      this.open = true;
    },
    userStores(user) {
      const { allow, ids } = user.permissions.dimensions.store;
      if (allow === "all") {
        return i18n.t("permissions.all");
      } else if (allow === "none") {
        return i18n.t("permissions.none");
      } else if (allow === "only") {
        return ids.join(", ");
      } else if (allow === "except") {
        return ids.map((id) => "-" + id).join(", ");
      }
    },
    async onFileChange() {
      const files = (this.$refs.fileInput as HTMLInputElement).files;
      if (files.length > 0) {
        try {
          this.error = null;
          this.users = null;
          this.processing = true;

          this.parseExcel(await readXlsxFile(files[0]));
        } catch {
          this.error = i18n.t("company_admin.import_users.invalid_file_format");
        } finally {
          this.processing = false;
        }
      }
    },
    parseExcel(rows) {
      const headers = rows.shift().map((header) =>
        header
          .toString()
          .toLowerCase()
          .trim()
      );

      let firstNameIndex = headers.indexOf("first name");
      if (firstNameIndex < 0) {
        firstNameIndex = headers.indexOf(
          i18n
            .t("activerecord.attributes.user.first_name")
            .toString()
            .toLowerCase()
        );
      }
      let lastNameIndex = headers.indexOf("last name");
      if (lastNameIndex < 0) {
        lastNameIndex = headers.indexOf(
          i18n
            .t("activerecord.attributes.user.last_name")
            .toString()
            .toLowerCase()
        );
      }
      let emailIndex = headers.indexOf("email");
      if (emailIndex < 0) {
        emailIndex = headers.indexOf(
          i18n
            .t("activerecord.attributes.user.email")
            .toString()
            .toLowerCase()
        );
      }
      let storeIndex = headers.indexOf("store");
      if (storeIndex < 0) {
        storeIndex = headers.indexOf(
          i18n
            .t("filter.config.store")
            .toString()
            .toLowerCase()
        );
      }
      let roleIndex = headers.indexOf("role");
      if (roleIndex < 0) {
        roleIndex = headers.indexOf(
          i18n
            .t("activerecord.attributes.user.role")
            .toString()
            .toLowerCase()
        );
      }

      if (firstNameIndex < 0 || lastNameIndex < 0 || emailIndex < 0) {
        throw new Error("Invalid file format");
      }

      const requiredFields = ["first_name", "last_name", "email"];

      const users = rows
        .map((row) => ({
          first_name: row[firstNameIndex] || "",
          last_name: row[lastNameIndex] || "",
          email: row[emailIndex] || "",
          role: this.userRole(row[roleIndex]),
          permissions: {
            dimensions: { store: this.storePermissions(row[storeIndex]) },
            metrics: { allow: "all" },
            groupings: { allow: "all" },
          },
        }))
        .filter((user) => _.every(requiredFields, (field) => user[field].trim().length > 0));

      if (users.length > 0) {
        this.users = users;
      } else {
        this.error = i18n.t("company_admin.import_users.invalid_file_format");
      }
    },
    storePermissions(store) {
      store = (store || "").trim();
      if (store.toLowerCase() === "all") {
        return { allow: "all" };
      } else if (store.toLowerCase() === "none") {
        return { allow: "none" };
      } else {
        const ids = store.split(",").map((s) => s.trim());
        const onlyIds = ids.filter((id) => !id.startsWith("-")).filter((id) => id.length > 0);
        const exceptIds = ids
          .filter((id) => id.startsWith("-"))
          .map((id) => id.substring(1))
          .filter((id) => id.length > 0);

        if (exceptIds.length > 0) {
          return { allow: "except", ids: exceptIds };
        } else if (onlyIds.length > 0) {
          return { allow: "only", ids: onlyIds };
        } else {
          return { allow: "all" };
        }
      }
    },
    userRole(role) {
      role = (role || "").toLowerCase().trim();
      if (role === "admin" || role === "email") {
        return role;
      } else {
        return "user";
      }
    },
    cancel() {
      this.open = false;
    },
    async invite() {
      this.sending = true;

      try {
        const companyId = window.zoinedContext.companyId;
        for (const user of this.users) {
          await adminUsersApi.invite({ company_id: companyId, user, send_email: this.sendEmails });
        }
        this.open = false;
        this.$emit("add-users", this.users);
      } catch (err) {
        this.error = err.response.data.error;
      } finally {
        this.sending = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.droparea {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
