<template>
  <dropdown ref="dropdown" class="dropdown-select">
    <template #toggle="toggleProps">
      <slot name="toggle" :menu-open="toggleProps.menuOpen" :toggle-menu="toggleProps.toggleDropdown">
        <dropdown-select-button
          :class="btnClasses"
          :button-style="buttonStyle"
          :menu-open="toggleProps.menuOpen"
          @click="toggleProps.toggleDropdown"
          >{{ label }}</dropdown-select-button
        >
      </slot>
    </template>
    <template #menu>
      <div v-if="!availableItems" class="simple-spinner">
        <div class="spinner"></div>
      </div>
      <div class="dropdown-items">
        <div
          v-for="item in enabledItems"
          :key="menuItemKey(item)"
          class="dropdown-item flex-row"
          @click="addItem(item)"
        >
          <div class="flex-row"><i v-if="item.icon" class="fa mr-sm" :class="item.icon"></i>{{ item.name }}</div>
        </div>
        <div v-for="item in disabledItems" :key="menuItemKey(item)" class="dropdown-item flex-row">
          <div class="flex-row disabled">
            <i v-if="item.icon" class="fa mr-sm" :class="item.icon"></i>{{ item.name }}
          </div>
          <pro-text class="ml-sm"></pro-text>
        </div>
      </div>
    </template>
  </dropdown>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Dropdown from "./dropdown.vue";
import DropdownSelectButton from "./dropdown-select-button.vue";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import _ from "lodash";
import proText from "./pro-text.vue";

export default defineComponent({
  components: {
    Dropdown,
    DropdownSelectButton,
    proText,
  },
  props: {
    availableItems: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    default: {
      type: Object,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    selectLabel: {
      type: String,
      default: null,
    },
    buttonStyle: { default: "primary-inverted", type: String },
  },
  emits: ["update"],
  data() {
    return {
      isLimited: window.zoinedContext.limited,
      menuItemKey,
    };
  },
  computed: {
    btnClasses() {
      return {
        default: this.default && !this.value,
      };
    },
    label() {
      if (this.value !== null && this.value !== undefined) {
        return this.getLabel(this.value);
      } else if (this.default) {
        return this.getLabel(this.default);
      } else if (this.selectLabel) {
        return this.selectLabel;
      } else {
        return "Select";
      }
    },
    enabledItems() {
      return _.filter(this.availableItems, (item) => !this.isDisabled(item));
    },
    disabledItems() {
      return _.filter(this.availableItems, (item) => this.isDisabled(item));
    },
  },
  methods: {
    addItem(item: MenuItem) {
      this.$emit("update", menuItemKey(item));
      (this.$refs.dropdown as typeof Dropdown).toggle();
    },
    getLabel(key) {
      const item = _.find(this.availableItems, (item) => menuItemKey(item) === key);
      if (item) {
        return item.name;
      } else {
        return key;
      }
    },
    isDisabled(item: MenuItem) {
      return this.isLimited && item.enabled_for_limited === false;
    },
  },
});
</script>

<style lang="scss" scoped>
.dropdown-select {
  .btn.default {
    opacity: 0.5;
  }
  .dropdown-items {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;

    .dropdown-item {
      padding: 7px 10px;
      border-radius: 5px;
      color: #333;
      white-space: nowrap;
      margin: 4px;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-top: 0;
      }

      &:hover {
        background-color: #cceeff;
        cursor: pointer;
      }

      .disabled {
        opacity: 0.5;
      }
    }
  }
}
</style>
