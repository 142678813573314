<template>
  <modal v-model="open" :title="title">
    <h2>{{ $t("chart_settings.color_scheme") }}</h2>
    <label class="row">
      <div class="col-md-6">
        <input v-model="colorScheme" type="radio" name="color_scheme" value="default" />
        <div class="scheme-label">{{ $t("chart_settings.color_schemes.default") }}</div>
      </div>
      <div class="col-md-5 gradient gradient--default"></div>
    </label>
    <label class="row">
      <div class="col-md-6">
        <input v-model="colorScheme" type="radio" name="color_scheme" value="traffic_lights" />
        <div class="scheme-label">{{ $t("chart_settings.color_schemes.traffic_lights") }}</div>
      </div>
      <div class="col-md-5 gradient gradient--traffic-lights"></div>
    </label>
    <label class="row">
      <div class="col-md-6">
        <input v-model="colorScheme" type="radio" name="color_scheme" value="reverse_traffic_lights" />
        <div class="scheme-label">{{ $t("chart_settings.color_schemes.reverse_traffic_lights") }}</div>
      </div>
      <div class="col-md-5 gradient gradient--reverse-traffic-lights"></div>
    </label>
    <label class="row">
      <div class="col-md-6">
        <input v-model="colorScheme" type="radio" name="color_scheme" value="violet" />
        <div class="scheme-label">{{ $t("chart_settings.color_schemes.violet") }}</div>
      </div>
      <div class="col-md-5 gradient gradient--violet"></div>
    </label>
    <label class="row">
      <div class="col-md-6">
        <input v-model="colorScheme" type="radio" name="color_scheme" value="neutral" />
        <div class="scheme-label">{{ $t("chart_settings.color_schemes.neutral") }}</div>
      </div>
      <div class="col-md-5 gradient gradient--neutral"></div>
    </label>
    <label class="row">
      <div class="col-md-6">
        <input v-model="colorScheme" type="radio" name="color_scheme" value="none" />
        <div class="scheme-label">{{ $t("chart_settings.color_schemes.none") }}</div>
      </div>
    </label>
    <template #footer>
      <button class="btn btn-default" @click="open = false">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" @click="update">{{ $t("actions.save") }}</button>
    </template>
  </modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Modal } from "uiv";
import i18n from "../i18n";
export default defineComponent({
  components: { Modal },
  props: {
    component: {
      type: Object,
      required: true,
    },
    filterConfiguration: {
      type: Object,
      required: true,
    },
  },
  emits: ["update"],
  data() {
    return {
      open: false,
      colorScheme: "default",
    };
  },
  computed: {
    title() {
      return i18n.t("chart_settings.title");
    },
  },
  watch: {
    component: [
      {
        handler: "onComponentUpdated",
      },
    ],
  },
  created() {
    this.onComponentUpdated();
  },
  methods: {
    show() {
      this.open = true;
    },
    update() {
      this.open = false;
      this.$emit("update", {
        ...this.component.chartOptions,
        color_scheme: this.colorScheme,
      });
    },
    onComponentUpdated() {
      const { chartOptions = {} } = this.component;

      this.colorScheme = chartOptions.color_scheme || "default";
    },
  },
});
</script>

<style lang="scss" scoped>
label {
  display: block;
}
.scheme-label {
  display: inline;
  margin-left: 5px;
  font-weight: normal;
}
.gradient {
  height: 20px;
  background-color: grey;

  &--default {
    background: linear-gradient(to right, #ffffff, #4bcdf0, #ffbe0f, #fa644a);
  }
  &--traffic-lights {
    background: linear-gradient(to right, green, yellow, red);
  }

  &--reverse-traffic-lights {
    background: linear-gradient(to right, red, yellow, green);
  }

  &--violet {
    background: linear-gradient(to right, #ffffff, #5555ff);
  }

  &--neutral {
    background: linear-gradient(to right, #f2f2f2, #67c9e9);
  }
}
</style>
