<template>
  <div class="dashboard-body">
    <active-filters-panel
      :filter-configuration="flatFilterConfiguration"
      :chart-options="chartOptions"
      @update="updateFilterConfiguration"
    ></active-filters-panel>
    <div class="mt-xl"></div>
    <div v-if="snippets && snippets.length > 0" id="snippets-container">
      <zoined-report
        :components="snippets"
        :filter-configuration="flatFilterConfiguration"
        :controls-enabled="false"
      ></zoined-report>
    </div>
    <div v-if="charts && charts.length > 0" id="charts-container">
      <zoined-report
        :components="charts"
        :filter-configuration="flatFilterConfiguration"
        :controls-enabled="false"
      ></zoined-report>
    </div>
  </div>
</template>

<script lang="ts">
import FilterConfiguration from "../model/filter-configuration";
import { defineComponent } from "vue";
import {
  configToActive,
  convertGroupingToBusinessCalendar,
  DASHBOARD_FILTERS,
  extendComparisonSeries,
  filterToFlyover,
  flatFilterConfiguration,
  mergeFilterConfigurations,
  normalizeConfig,
} from "../lib/filter-util";
import _ from "lodash";
import $ from "jquery";
import i18n from "../i18n";
import ReportContext from "../model/report-context";
import zoinedReport from "../analytics/zoined-report.vue";
import { availableComponents } from "../custom-dashboard-editor/component-types";
import EventBus from "../events/event-bus";
import Events from "../events/events";
import Mutations from "../store/mutations";
import { makeApiInstance } from "../api/instance";
import ActiveFiltersPanel from "@/components/active-filters/active-filters-panel.vue";

export default defineComponent({
  components: {
    zoinedReport,
    ActiveFiltersPanel,
  },
  data() {
    const charts: any[] = [];
    const snippets: any[] = [];

    return {
      snippets,
      charts,
      updateFilterConfiguration: (filterConfiguration) => {
        const flyoverFilterConfiguration = mergeFilterConfigurations(
          this.filterConfiguration,
          filterToFlyover(filterConfiguration)
        );
        this.$store.commit(Mutations.setFilterConfiguration, {
          ...flyoverFilterConfiguration,
          filters: normalizeConfig(flyoverFilterConfiguration.filters),
        });
      },
    };
  },
  computed: {
    reportContext(): ReportContext {
      return this.$store.getters.getReportContext;
    },
    filterConfiguration(): FilterConfiguration {
      return this.reportContext.filter_configuration;
    },
    zoinedContext() {
      return window.zoinedContext;
    },
    useBusinessCalendar() {
      return this.zoinedContext.useBusinessCalendar;
    },
    filter() {
      return configToActive(this.flatFilterConfiguration);
    },
    flatFilterConfiguration(): any {
      return _.omit(flatFilterConfiguration(this.filterConfiguration), DASHBOARD_FILTERS);
    },
    chartOptions() {
      return this.reportContext.chart_options;
    },
    company() {
      return this.zoinedContext.company;
    },
  },
  watch: {
    filterConfiguration: [
      {
        handler: "updateComponents",
      },
      {
        handler: "storeConfig",
      },
    ],
  },
  created() {
    this.updateComponents();
    EventBus.subscribe(Events.setAsDefault, this.setAsDefault);
  },
  unmounted() {
    EventBus.unsubscribe(Events.setAsDefault, this.setAsDefault);
  },
  methods: {
    isExtreme(id) {
      return id && !id.includes("_notifications");
    },
    setAsDefault() {
      const url = `/api/v1/reports/default_dashboard`;
      return makeApiInstance()
        .post(url)
        .then(() => {
          this.$store.commit(Mutations.setDefaultDashboardId, null);
        });
    },
    getSnippet(name: any) {
      return {
        ...availableComponents.dashboard_snippet,
        custom_title: this.customTitle(name),
        help_text: i18n.t(`snippet.${name}.desc`, {
          defaultValue: "",
          currency: this.zoinedContext.currency.symbol,
        }),
        name: this.isExtreme(name) ? "dashboard_snippet" : name,
        type: this.isExtreme(name) ? "dashboard_snippet" : "snippet",
        filter: {
          ...this.filter,
          metrics: [name],
        },
        span: this.filterConfiguration.raw_filters.span || 6,
      };
    },
    getChart(name: any) {
      const f = extendComparisonSeries(this.filter.comparisons, this.flatFilterConfiguration.selection);
      if (this.useBusinessCalendar) {
        f.grouping = convertGroupingToBusinessCalendar(f.grouping);
      }
      const filter = $.extend({}, this.filter, f, { metrics: [name] });
      return {
        ...availableComponents.metric_chart,
        custom_title: this.customTitle(name),
        help_text: i18n.t(`chart.${name}.desc`, {
          defaultValue: "",
          currency: this.zoinedContext.currency.symbol,
        }),
        filter,
      };
    },
    customTitle(name) {
      const title = i18n.t(`chart.${name}.title`, {
        defaultValue: "",
      });

      return title.length > 0 ? title : null;
    },
    updateComponents() {
      const { snippets, charts } = configToActive(this.filterConfiguration.widgets);
      this.snippets = snippets?.map((s) => this.getSnippet(s));
      this.charts = charts?.map((s) => this.getChart(s));
    },
    storeConfig() {
      const config = this.filterConfiguration;

      fetch("/dashboard/store_config", {
        method: "post",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ config }),
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.dashboard-body::v-deep {
  .report-component.metric_chart {
    height: 300px;
  }
}
</style>
