<template>
  <select v-model="model" @change="onModelChange">
    <option v-for="item in items" :key="menuItemKey(item)" :value="menuItemKey(item)">{{ item.name }}</option>
  </select>
</template>

<script lang="ts">
import Comparison from "../model/comparison";
import { defineComponent, PropType } from "vue";
import { comparisonToMenuKey, menuKeyToComparison } from "../lib/menu-helper";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import _ from "lodash";

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<Comparison>,
      required: true,
    },
    choices: {
      type: Array,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    const model: string = null;

    return {
      model,
      menuItemKey,
    };
  },
  computed: {
    items(): MenuItem[] {
      return (
        this.choices?.map((comparison) => {
          return {
            key: comparisonToMenuKey(comparison),
            name: comparison.name,
          };
        }) || []
      );
    },
  },
  watch: {
    modelValue: [
      {
        handler: "updateModel",
      },
    ],
  },
  created() {
    this.updateModel(this.modelValue);
  },
  methods: {
    onModelChange() {
      this.$emit("update:modelValue", menuKeyToComparison(this.model));
    },
    updateModel(value) {
      if (!value || _.isEmpty(value)) {
        this.model = null;
      } else {
        value = _.isString(value) ? { type: value } : value;
        this.model = comparisonToMenuKey(value);
      }
    },
  },
});
</script>
