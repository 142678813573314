<template>
  <div class="min-amount-selector flex-row align-items-center flex-wrap gap-sm">
    <div class="title">{{ title }}</div>
    <pill-button
      v-for="item in items"
      :key="item.value"
      :enabled="item.enabled"
      :label="item.value"
      @toggle="toggleItem(item)"
      @remove="removeItem(item)"
    ></pill-button>
    <dropdown ref="dropdown" v-model="open" class="inline-block" tag="span" :not-close-elements="notCloseElements">
      <plus-button class="dropdown-toggle"></plus-button>
      <template #dropdown>
        <form novalidate @submit.prevent="addItem()">
          <label>{{ $t("actions.set") }} {{ title }}</label>
          <input v-model="minamount" class="form-control" type="number" />
          <div class="form-buttons">
            <button class="btn btn-default" type="button" @click="open = false">{{ $t("actions.cancel") }}</button>
            <button class="btn btn-primary" type="submit" :disabled="!isFormValid">{{ $t("actions.add") }}</button>
          </div>
        </form>
      </template>
    </dropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Dropdown } from "uiv";
import pillButton from "./pill-button.vue";
import FilterConfigurationItemMap, {
  filterConfigurationItemMapFromPillItemList,
} from "../model/filter-configuration-item-map";
import PillItem, { pillItemListFromFilterConfigurationItemMap } from "../interfaces/pill-item";
import i18n from "../i18n";
import PlusButton from "@/components/buttons/plus-button.vue";

export default defineComponent({
  components: {
    Dropdown,
    pillButton,
    PlusButton,
  },
  props: {
    config: {
      type: Object as PropType<FilterConfigurationItemMap>,
      required: true,
    },
    title: { default: () => i18n.t("filter.config.minamount"), type: String },
  },
  emits: ["update"],
  data() {
    const minamount: number = null;

    return {
      open: false,
      notCloseElements: [],
      minamount,
    };
  },
  computed: {
    isFormValid() {
      return this.minamount !== null;
    },
    items(): PillItem[] {
      return pillItemListFromFilterConfigurationItemMap(this.config);
    },
  },
  watch: {
    open: [
      {
        handler: "onChangeOpen",
      },
    ],
  },
  mounted() {
    this.notCloseElements.push((this.$refs.dropdown as typeof Dropdown).$el);
  },
  methods: {
    addItem() {
      if (!this.isFormValid) {
        return;
      }

      const value = this.minamount.toString();
      const items: PillItem[] = [
        ...this.items.map((item) => ({ ...item, enabled: false })),
        { value, name: value, enabled: true },
      ];
      const config = filterConfigurationItemMapFromPillItemList(items);

      this.$emit("update", config);

      this.open = false;
    },
    removeItem(item: PillItem) {
      const config = { ...this.config };
      delete config[item.value];
      this.$emit("update", config);
    },
    toggleItem(item: PillItem) {
      const items = this.items.map((each) =>
        each === item ? { ...item, enabled: !item.enabled } : { ...each, enabled: false }
      );
      const config = filterConfigurationItemMapFromPillItemList(items);
      this.$emit("update", config);
    },
    onChangeOpen() {
      if (this.open) {
        this.minamount = null;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.title {
  display: inline-block;
  margin-right: 10px;
}

form {
  padding: 6px;
  width: 300px;

  .form-buttons {
    padding-top: 10px;
    text-align: right;

    .btn + .btn {
      margin-left: 5px;
    }
  }
}
</style>
