<template>
  <div class="sidenav-category">
    <sidenav-category-title
      :title="category.name"
      :class="category.class_name"
      :open="open"
      :manage-folders-enabled="manageFoldersEnabled"
      @manage-folders="showManageFoldersModal"
      @click="open = !open"
    ></sidenav-category-title>
    <div class="folders" :class="{ hidden: !open }">
      <sidenav-folder v-for="item in category.folders" v-bind="item" :key="item.name"></sidenav-folder>
    </div>
    <manage-reports-modal ref="manageReportsModal" :navigation-item="category"></manage-reports-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SidenavFolder from "./sidenav-folder.vue";
import SidenavCategoryTitle from "./sidenav-category-title.vue";
import ManageReportsModal from "../manage-reports/manage-reports-modal.vue";

export default defineComponent({
  components: { SidenavCategoryTitle, SidenavFolder, ManageReportsModal },
  props: {
    category: {
      type: Object,
      required: true,
    },
    forceOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    isActive() {
      return this.category.folders.some((folder) => folder.reports.some((report) => report.path === location.pathname));
    },
    manageFoldersEnabled() {
      return (
        this.category.type === "my_analytics" ||
        (this.category.type === "company_analytics" && this.isAdmin) ||
        (this.category.type === "team_analytics" && (this.isAdmin || this.isTeamAdmin(this.category.team_id))) ||
        (this.category.type === "partner_analytics" && (this.isZoinedAdmin || this.isPartnerAdmin))
      );
    },
    isAdmin() {
      return window.zoinedContext.isAdmin;
    },
    isPartnerAdmin() {
      return window.zoinedContext.isPartnerAdmin;
    },
    isZoinedAdmin() {
      return window.zoinedContext.isZoinedAdmin;
    },
    teamsMembershipsAsAdmin() {
      return window.zoinedContext.current_role.team_memberships.filter(({ role }) => role === "admin");
    },
  },
  created() {
    this.open = this.forceOpen || this.isActive;
  },
  methods: {
    isTeamAdmin(teamId) {
      return this.teamsMembershipsAsAdmin.some((teamMembership) => teamMembership.team_id === teamId);
    },
    showManageFoldersModal() {
      (this.$refs.manageReportsModal as typeof ManageReportsModal).show();
    },
  },
});
</script>

<style lang="scss" scoped>
.sidenav-category {
  margin-bottom: 30px;

  .folders {
    padding-left: 15px;
  }
}
</style>
