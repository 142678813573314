<template>
  <filter-dropdown
    :title="$t('filters.no_of_past_weeks')"
    :value="value"
    :available-items="availableItems"
    @update="onUpdate"
  ></filter-dropdown>
</template>

<script lang="ts">
import MenuItem from "../interfaces/menu-item";
import Comparison from "../model/comparison";
import { defineComponent, PropType } from "vue";
import FilterDropdown from "./filter-dropdown.vue";

export default defineComponent({
  components: { FilterDropdown },
  props: {
    comparison: {
      type: Object as PropType<Comparison>,
      required: true,
    },
  },
  emits: ["update"],
  computed: {
    value(): string {
      return this.comparison && this.comparison.text?.toString();
    },
    availableItems(): MenuItem[] {
      return Array.from(Array(12).keys())
        .map((i) => (i + 1).toString())
        .map((key) => ({ key, name: key }));
    },
  },
  methods: {
    onUpdate(weeks: string) {
      const comparison: Comparison = { type: undefined, text: weeks, enabled: true };
      this.$emit("update", comparison);
    },
  },
});
</script>
