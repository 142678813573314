<template>
  <uiv-modal v-if="report" v-model="open" :title="t('dashboard_custom.save_dashboard')">
    <spinner v-if="isPending" />
    <div class="flex-row gap-lg">
      <div class="flex-1">
        <label>{{ t("dashboard_custom.report_editor.title") }}</label>
        <input v-model="report.metadata.title" type="text" class="form-control" />
      </div>
      <div class="flex-1">
        <label>{{ t("dashboard_custom.report_editor.description") }}</label>
        <input v-model="report.metadata.description" type="text" class="form-control" />
      </div>
    </div>
    <div class="mt-lg">
      <report-owner-selector :my-report="myReport"></report-owner-selector>
    </div>
    <template #footer>
      <button class="btn btn-default" @click="open = false">{{ t("actions.cancel") }}</button>
      <button class="btn btn-emphasis" :disabled="!isValid || isPending" @click="save()">
        {{ t("actions.save") }}
      </button>
    </template>
  </uiv-modal>
</template>

<script setup lang="ts">
import { useI18n } from "@/composables/i18n";
import { computed, ref } from "vue";
import reportOwnerSelector from "@/components/report-owner-selector.vue";
import MyReport from "@/model/my-report";
import Report from "@/model/report";
import ReportConfig from "@/model/report-config";
import { useCreateReportMutation } from "@/api/reports-api";
import spinner from "@/components/spinner.vue";
import { useStore } from "@/composables/store";
import Actions from "@/store/actions";
import { useToastr } from "@/composables/toastr";

const props = defineProps<{ config: ReportConfig }>();

const { t } = useI18n();

const store = useStore();

const toaster = useToastr();

const open = ref(false);

const report = ref<Report>(null);

const myReport = ref<MyReport>(null);

const isValid = computed(() => {
  return report.value.metadata.title.trim().length > 0;
});

const show = () => {
  myReport.value = {
    dashboard: true,
    user_id: window.zoinedContext.userId,
  } as MyReport;

  report.value = {
    custom: true,
    metadata: { title: "", description: "" },
  } as Report;

  open.value = true;
};

const { mutate, isPending } = useCreateReportMutation();

const save = () => {
  const params = {
    report: {
      ...report.value,
      config: JSON.stringify(props.config),
    } as any,
    myReport: myReport.value,
  };
  mutate(params, {
    onSuccess: () => {
      open.value = false;
      store.dispatch(Actions.fetchNavigation);
      toaster.success(t("custom_report.report_saved"));
    },
  });
};

defineExpose({
  show,
});
</script>

<style scoped></style>
