import moment from "moment";
import $ from "jquery";

$(() => {
  var zoinedContext = window.zoinedContext;
  var lang = $("html").attr("lang") || (zoinedContext && zoinedContext.locale) || "en";
  var firstDayOfWeekDefined = zoinedContext != null ? zoinedContext.firstDayOfWeek : void 0;
  var firstDayOfWeek = firstDayOfWeekDefined != null ? firstDayOfWeekDefined : 1;

  moment.updateLocale(lang.toLowerCase(), { week: { dow: firstDayOfWeek } });
});
