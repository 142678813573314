<template>
  <div class="user-title-selector flex-column">
    <div class="relative">
      <input
        id="titleInput"
        class="form-control"
        type="text"
        autocomplete="off"
        :placeholder="t('activerecord.attributes.user.title') + '...'"
      />
      <!-- clear input icon  -->
      <div @click="clear">
        <icon class="clear-icon" icon="fluent:dismiss-circle-24-filled" />
      </div>
    </div>
    <uiv-typeahead
      v-model="inputValue"
      target="#titleInput"
      :open-on-empty="true"
      :limit="100"
      :match-start="true"
      item-key="label"
      :async-function="getOptions"
      :preselect="false"
      @update:model-value="select"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "@/composables/i18n";
import { computed, ref, watch } from "vue";
import { Icon } from "@iconify/vue";

const commonOptions = [
  "CEO",
  "Store Manager",
  "Sales representative",
  "Finance manager",
  "Accountant",
  "HR manager",
  "Team leader",
  "Purchaser",
  "Area manager",
  "Chain manager",
  "Director of purchasing",
  "Purchasing manager",
  "Regional operations manager",
  "General manager",
  "CFO",
  "COO",
  "Marketing director",
  "Marketing manager",
  "IT manager",
  "Sales director",
  "Data analyst",
];

const optionsByBusiness = {
  B2B: [],
  "eCommerce Retail": [],
  Restaurant: ["Restaurant manager", "Shift manager", "Head chef", "Kitchen manager"],
  "Store Retail": ["Window dresser"],
};

const { t, locale } = useI18n();

const props = defineProps<{
  modelValue?: string;
}>();

const emit = defineEmits(["update:modelValue"]);

const inputValue = ref(null);

const options = computed(() => {
  const business = window.zoinedContext.company.primary_business;
  return commonOptions
    .concat(optionsByBusiness[business] || [])
    .map((key) => ({ key, label: t("user_title." + key, { defaultValue: key }) }))
    .sort((a, b) => a.label.localeCompare(b.label, locale));
});

const getOptions = async (_query, done) => {
  done(options.value);
};

const select = (item) => {
  const key = item.key || item || null;
  emit("update:modelValue", findOption(key)?.key || key);
};

const clear = () => {
  emit("update:modelValue", null);
};

const findOption = (key) => options.value.find((option) => option.key === key || option.label === key);

watch(
  () => props.modelValue,
  (key) => {
    if (key) {
      const option = findOption(key);

      inputValue.value = option ? option : { key, label: key };
    } else {
      inputValue.value = null;
    }
  },
  { immediate: true }
);
</script>

<style scoped lang="scss">
.clear-icon {
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #ccc;
  z-index: 1;
  pointer-events: auto;
  &:hover {
    color: #333;
  }
}
</style>
