<template>
  <div class="filters-display">
    <div v-if="filtersetName" class="filterset">
      <b>{{ $t("filters.filterset.saved_filter") }}: </b>{{ filtersetName }}
    </div>
    <div class="filters">
      <div v-for="(value, label) in filters" :key="label" class="filter">
        <b>{{ label }}: </b><span v-html="value"></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import FiltersConfiguration from "../model/filters-configuration";
import { defineComponent, PropType } from "vue";
import _ from "lodash";
import Filter from "../model/filter";

export default defineComponent({
  props: {
    config: {
      default: (): FiltersConfiguration => ({
        v: 2,
        sets: [],
        filters: {},
      }),
      type: Object as PropType<FiltersConfiguration>,
    },
  },
  computed: {
    availableFilters(): Filter[] {
      return this.$store.state.parameters.filters.all;
    },
    filtersetName(): string {
      return _.first(
        this.config.sets &&
          this.config.sets
            .filter(({ enabled }) => enabled)
            .map(
              ({ id }) =>
                _.get(
                  _.find(this.filtersets, (set) => set.id == id),
                  "name"
                ) || String(id)
            )
      );
    },
    filters(): Record<string, string> {
      return _.reduce(
        this.availableFilters,
        (result, filter) => {
          const enabledFilters = _.values(this.config.filters[filter.id])
            .filter(({ enabled }) => enabled)
            .map(({ name, exclude }) => (exclude ? `<strike>${name}</strike>` : name));
          if (enabledFilters.length > 0) {
            result = { ...result, [filter.name]: enabledFilters.join(", ") };
          }
          return result;
        },
        {}
      );
    },
    filtersets() {
      return this.$store.state.filtersets.all;
    },
  },
});
</script>

<style lang="scss" scoped>
.filters-display {
  margin-bottom: 10px;
}
</style>
