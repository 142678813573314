import Filter from "../../model/filter";
import Metric from "../..//model/metric";
import { ApiSliceState, createApiSlice } from "../../store/api-slice";
import RootState from "../root-state";
import { Module } from "vuex";
import MenuItem from "@/interfaces/menu-item";
import Comparison from "@/model/comparison";

export type ParametersState = {
  filters: ApiSliceState<Filter>;
  grouping: ApiSliceState<MenuItem>;
  metrics: ApiSliceState<Metric>;
  snippets: ApiSliceState<Metric>;
  charts: ApiSliceState<Metric>;
  sort: ApiSliceState<MenuItem>;
  timePeriods: ApiSliceState<MenuItem>;
  comparisons: ApiSliceState<Comparison>;
  locales: ApiSliceState<{ id: string; name: string }>;
};

const currency = window.zoinedContext?.currency?.currency;

const parameters: Module<ParametersState, RootState> = {
  namespaced: true,
  modules: {
    filters: createApiSlice<Filter>("filters", "/api/v1/parameters/available_filters"),
    grouping: createApiSlice<MenuItem>("grouping", "/api/v1/parameters/available_groupings"),
    metrics: createApiSlice<Metric>("metrics", {
      url: "/api/v1/parameters/metrics",
      params: {
        ...(currency ? { currency } : {}),
      },
    }),
    snippets: createApiSlice<Metric>("snippets", "/api/v1/parameters/snippets"),
    charts: createApiSlice<Metric>("charts", "/api/v1/parameters/charts"),
    sort: createApiSlice<MenuItem>("sort", "/api/v1/parameters/sort"),
    timePeriods: createApiSlice<MenuItem>("timePeriods", "/api/v1/parameters/available_time_periods"),
    comparisons: createApiSlice<Comparison>("comparisons", "/api/v1/parameters/available_comparisons"),
    locales: createApiSlice("locales", "/api/v1/parameters/available_locales"),
  },
};

export default parameters;
