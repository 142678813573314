import "v-calendar/style.css";
import "dropzone-vue/dist/dropzone-vue.common.css";

import { createApp } from "vue";
import store from "@/store/store";
import router from "./router";
import i18n from "@/i18n";
import { handleError } from "@/core/error-handling";
import { VueQueryPlugin } from "@tanstack/vue-query";
import * as uiv from "uiv";
import "v-calendar/style.css";
import { setupCalendar } from "v-calendar";
import DropZone from "dropzone-vue";

export const createVueApp = (component) => {
  const app = createApp(component);

  app.config.globalProperties.$t = i18n.t.bind(i18n);
  app.config.errorHandler = handleError;
  app.use(store);
  app.use(router);
  app.use(VueQueryPlugin);
  app.use(DropZone);
  app.use(uiv, { prefix: "uiv" });
  app.use(setupCalendar, {});

  return app;
};
