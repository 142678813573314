<template>
  <select :model-value="modelValue" @change="onChange">
    <option :value="null">{{ $t("newsletter.definition.sending_time_default") }}</option>
    <option v-for="item in sendingTimeOptions" :key="item.id" :value="item.id">{{ item.name }}</option>
  </select>
</template>

<script lang="ts">
import _ from "lodash";
import moment from "moment";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    sendingTimeOptions() {
      return _.map(_.range(0, 96), function (v) {
        const m = moment([2000, 1, 1]).add(15 * v, "minutes");
        const id = m.format("HH:mm");
        return {
          id,
          name: m.format("LT"),
        };
      });
    },
  },
  methods: {
    onChange(evt) {
      this.$emit("update:modelValue", evt.target.value);
    },
  },
});
</script>
