<template>
  <uiv-modal v-model="open" :title="$t('newsletter.definition.messages.select_filter')">
    <div v-if="model && availableFilters">
      <div class="mb-lg">
        <filtersetSelector :config="model" @update="updateFiltersConfig"></filtersetSelector>
      </div>
      <div class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl">
        <filterSelector
          v-for="filter in availableFilters"
          :key="filter.id"
          :config="model.filters[filter.id]"
          :filter="filter"
          @update="updateFilter(filter.id, $event)"
        ></filterSelector>
      </div>
    </div>
    <template #footer>
      <button class="btn btn-default" @click="cancel()">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" @click="ok()">{{ $t("actions.save") }}</button>
    </template>
  </uiv-modal>
</template>

<script lang="ts">
import Filter from "../model/filter";
import FiltersConfiguration from "../model/filters-configuration";
import { defineComponent, PropType } from "vue";
import _ from "lodash";
import filtersetSelector from "../components/filterset-selector.vue";
import filterSelector from "../components/filter-selector.vue";

export default defineComponent({
  components: {
    filtersetSelector,
    filterSelector,
  },
  props: {
    filters: {
      default: (): FiltersConfiguration => ({
        v: 2,
        sets: [],
        filters: {},
      }),
      type: Object as PropType<FiltersConfiguration>,
    },
  },
  emits: ["update"],
  data() {
    const model: FiltersConfiguration = null;

    return {
      open: false,
      model,
    };
  },
  computed: {
    availableFilters(): Filter[] {
      return this.$store.state.parameters.filters.all;
    },
  },
  methods: {
    show() {
      this.model = _.cloneDeep(this.filters);
      this.open = true;
    },
    updateFilter(filter, filters) {
      this.model.filters = {
        ...this.model.filters,
        [filter]: filters,
      };
    },
    updateFiltersConfig(config) {
      this.model = config;
    },
    ok() {
      this.$emit("update", this.model);
      this.open = false;
    },
    cancel() {
      this.open = false;
    },
  },
});
</script>
