<template>
  <uiv-modal id="edit-modal" v-model="open" :title="$t('profile.edit.title')">
    <div v-if="error" class="alert alert-danger" role="alert">{{ error }}</div>
    <spinner v-if="loading"></spinner>
    <div v-else class="row">
      <form class="form-horizontal">
        <div class="form-group">
          <label class="col-md-4 control-label" for="user_first_name">{{
            $t("activerecord.attributes.user.first_name")
          }}</label>
          <div class="col-md-8">
            <input v-model="first_name" class="string required" type="text" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="user_last_name">{{
            $t("activerecord.attributes.user.last_name")
          }}</label>
          <div class="col-md-8">
            <input v-model="last_name" class="string required" type="text" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="language">{{ $t("activerecord.attributes.user.language") }}</label>
          <div class="col-md-8">
            <select v-model="selected">
              <option v-for="language in languages" :key="language.locale" :value="language.locale">{{
                language.native
              }}</option>
            </select>
          </div>
        </div>
      </form>
    </div>
    <template #footer>
      <button class="btn btn-default" type="button" @click="open = false">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" type="button" @click="save">{{ $t("actions.save") }}</button>
    </template>
  </uiv-modal>
</template>

<script lang="ts">
import { fetchData, updateProfile, switchLanguage } from "../lib/data/profile";
import i18n from "@/i18n";
import spinner from "../components/spinner.vue";

export default {
  components: {
    spinner,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ["get-alert", "fetch-data"],
  data() {
    return {
      open: false,
      fetchIndex: 0,
      loading: false,
      languages: null,
      error: null,
      selected: null,
      first_name: null,
      last_name: null,
    };
  },
  methods: {
    fetchLanguages: function() {
      this.loading = true;

      fetchData("/profile/languages", this.fetchIndex)
        .then((res) => {
          this.languages = res;
          this.loading = false;
        })
        .catch((err) => {
          this.error = err;
        });
    },
    handleSubmit: function() {
      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        language: this.selected,
      };

      this.loading = true;

      updateProfile(data, this.fetchIndex)
        .then((res) => {
          this.loading = false;
          this.fetchIndex = res.index;
          this.$emit("get-alert", res.alert);
          this.$emit("fetch-data");
          this.open = false;
          if (this.user.language != res.language) {
            switchLanguage(res.language);
          }
        })
        .catch((err) => {
          this.error = err;
        });
    },
    save: function() {
      if (this.first_name.trim().length == 0) {
        this.error = `${i18n.t("activerecord.attributes.user.first_name")} ${i18n.t("errors.messages.blank")}`;
      } else if (this.last_name.trim().length == 0) {
        this.error = `${i18n.t("activerecord.attributes.user.last_name")} ${i18n.t("errors.messages.blank")}`;
      } else {
        this.handleSubmit();
      }
    },
    show: function() {
      this.fetchLanguages();
      this.selected = this.user.language;
      this.first_name = this.user.first_name;
      this.last_name = this.user.last_name;
      this.open = true;
    },
  },
};
</script>
