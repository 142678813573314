<template>
  <modal v-model="open" class="embed-report-modal" :title="title" :footer="!!components">
    <div v-if="!reportsByCategory" class="mh-100">
      <spinner></spinner>
    </div>
    <div v-else class="content">
      <div v-if="components" class="mh-100">
        <div v-if="components.length == 0">
          {{ $t("dashboard_custom.report_editor.no_embeddable_components_message") }}
        </div>
        <zoined-report-component
          v-for="(component, index) in components"
          :key="index"
          :component="component"
          :filter-configuration="{}"
          :show-filter-configuration="true"
          :chart-options="{ custom: true }"
          :custom="true"
          :dashboard="true"
          :preview="true"
        ></zoined-report-component>
      </div>
      <div v-else>
        <div v-for="category in categories" :key="category">
          <div v-if="reportsByCategory[category]" class="category" @click="toggleCategory(category)">
            <div class="category__title">
              <span>{{ category }}</span>
              <div class="pull-right">
                <i class="fa" :class="openCategory == category ? 'fa-caret-up' : 'fa-caret-down'"></i>
              </div>
            </div>
          </div>
          <div v-if="openCategory == category" class="category__reports">
            <div
              v-for="report in reportsByCategory[category]"
              :key="report.id"
              class="report"
              @click="selectReport(report)"
            >
              <div class="report__title">{{ reportTitle(report) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="components" #footer>
      <button class="btn btn-default" @click="open = false">
        {{ $t(components.length == 0 ? "actions.ok" : "actions.cancel") }}
      </button>
      <button v-if="components.length > 0" class="btn btn-primary" @click="embedComponents">
        {{ $t("actions.confirm") }}
      </button>
    </template>
  </modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Modal } from "uiv";
import I18n from "../i18n";
import _ from "lodash";
import { flatFilterConfiguration } from "../lib/filter-util";
import { buildComponent } from "./utils";
import zoinedReportComponent from "../analytics/zoined-report-component.vue";
import spinner from "../components/spinner.vue";
import MyReport from "../model/my-report";
import Category from "../model/category";
import { makeApiInstance } from "../api/instance";

const supportedComponentTypes = ["dual_axis", "cross_table", "cross_tab_custom"];

export default defineComponent({
  components: {
    Modal,
    zoinedReportComponent,
    spinner,
  },
  emits: ["embedComponents"],
  data() {
    const savedReports: MyReport[] = null;
    const reports: MyReport[] = null;

    return {
      open: false,
      reports,
      savedReports,
      openCategory: null,
      components: null,
    };
  },
  computed: {
    title() {
      return I18n.t("dashboard_custom.report_editor.embed_report");
    },
    categories(): string[] {
      if (!this.reports) {
        return null;
      }
      const categories: Category[] = Object.values(
        this.reports.reduce((result, { report: { category } }) => ({ ...result, [category.id]: category }), {})
      );
      const categoryNames = _.sortBy(categories, "id").map((category) => this.categoryTitle(category));
      console.log(categoryNames);

      return [I18n.t("analytics.index.my_reports"), I18n.t("analytics.index.company_reports"), ...categoryNames];
    },
    reportsByCategory(): { [x: string]: MyReport[] } {
      if (!this.reports || !this.savedReports) {
        return null;
      }

      let reportsByCategory = {
        ..._.groupBy(this.savedReports, (myReport) =>
          myReport.user_id ? I18n.t("analytics.index.my_reports") : I18n.t("analytics.index.company_reports")
        ),
        ..._.groupBy(this.reports, (myReport) => this.categoryTitle(myReport.report.category)),
      };

      reportsByCategory = _.mapValues(reportsByCategory, (reports) =>
        _.sortBy(reports, (report) => report.report.sort_order)
      );

      return reportsByCategory;
    },
  },
  watch: {
    categories: [
      {
        handler: "setOpenCategory",
      },
    ],
  },
  created() {
    this.fetchReports();
    this.fetchSavedReports();
  },
  methods: {
    show() {
      this.open = true;
      this.components = null;

      if (this.categories) {
        this.openCategory = this.categories[0];
      }
    },
    toggleCategory(category) {
      this.openCategory = this.openCategory === category ? null : category;
    },
    reportTitle(myReport: MyReport) {
      return myReport.name || I18n.t(`report.${myReport.report.name}.title`);
    },
    categoryTitle(category) {
      return I18n.t(`report.category.${category.name}.name`);
    },
    selectReport(myReport) {
      const {
        filter_configuration,
        report: {
          config: { components },
        },
      } = myReport;

      const filterConfiguration = flatFilterConfiguration(filter_configuration);

      this.components = components
        .filter(({ name }) => supportedComponentTypes.indexOf(name) >= 0)
        .map((component) => buildComponent(component, { filterConfiguration }));
    },
    embedComponents() {
      this.$emit("embedComponents", this.components);
      this.open = false;
    },
    fetchReports() {
      return makeApiInstance()
        .get(`/api/v1/reports/analytics_reports`)
        .then((response) => response.data)
        .then((reports) => (this.reports = reports));
    },
    fetchSavedReports() {
      return makeApiInstance()
        .get(`/api/v1/reports/saved_reports`)
        .then((response) => response.data)
        .then((reports) => (this.savedReports = reports));
    },
    setOpenCategory() {
      if (!this.openCategory) {
        this.openCategory = this.categories[0];
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.category {
  padding: 10px;

  &__title {
    .icon {
      display: inline-block;
      width: 15px;
    }
  }
  background-color: #eee;
  margin-bottom: 5px;
  border-radius: 5px;
}
.report {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;

  &:hover {
    background-color: #cceeff;
  }
}

.mh-100 {
  min-height: 100px;
}
</style>

<style lang="scss">
.embed-report-modal {
  .modal-body {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
  }
}
</style>
