<template>
  <div v-if="configs && stores" class="business-hours-editor">
    <div v-for="(storeConfigs, title) in configsByStore" :key="title" class="business-hours-config">
      <div class="business-hours-config__title">{{ title }}</div>
      <table>
        <thead>
          <tr>
            <th>{{ $t("components.business_hours_editor.time_span") }}</th>
            <th>{{ $t("activerecord.attributes.company.business_hours") }}</th>
            <th>{{ $t("components.business_hours_editor.actions") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="config in storeConfigs" :key="config.id">
            <td>{{ daysText(config) }}</td>
            <td>{{ itemsText(config.items) }}</td>
            <td>
              <i class="fa fa-pencil" :title="$t('actions.edit')" @click="editConfig(config)"></i>
              <confirm-button class="delete-button" @confirm="deleteConfig(config)"
                ><i class="fa fa-times" :title="$t('actions.delete')"></i
              ></confirm-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="add-configuration btn btn-primary" @click="addNew">
      {{ $t("components.business_hours_editor.add_configuration") }}
    </button>
    <business-hours-modal
      ref="businessHoursModal"
      :days="days"
      :config="currentConfig"
      :configs="configs"
      :stores="stores"
      @save="saveCurrentConfig"
    ></business-hours-modal>
  </div>
</template>

<script>
import businessHoursModal from "./business-hours-modal";
import { v4 as uuidv4 } from "uuid";
import confirmButton from "../components/confirm-button";
import moment from "moment";
import _ from "lodash";
import toastr from "toastr";
import i18n from "../i18n";
import { makeApiInstance } from "../api/instance";

export default {
  components: {
    businessHoursModal,
    confirmButton,
  },
  data() {
    const days = [7, 1, 2, 3, 4, 5, 6];
    if (window.zoinedContext.firstDayOfWeek === 1) {
      days.push(days.shift());
    }
    return {
      days,
      currentConfig: {},
      configs: null,
      stores: null,
    };
  },
  computed: {
    configsByStore() {
      const configs = Array.from(this.configs).sort((a, b) =>
        a.organisation_nk === null
          ? -1
          : b.organisation_nk === null
          ? 1
          : this.storeTitle(a.organisation_nk).localeCompare(this.storeTitle(b.organisation_nk))
      );
      return _.mapValues(
        _.groupBy(configs, (config) => this.storeTitle(config.organisation_nk)),
        (configs) => _.sortBy(configs, "start_date").reverse()
      );
    },
  },
  created() {
    this.fetchStores();
    this.fetchOpeningHours();
  },
  methods: {
    addNew() {
      this.currentConfig = { organisation_nk: null };
      this.$refs.businessHoursModal.show();
    },
    editConfig(config) {
      this.currentConfig = config;
      this.$refs.businessHoursModal.show();
    },
    deleteConfig(config) {
      this.configs = this.configs.filter(({ id }) => id !== config.id);
      this.updateOpeningHours();
    },
    saveCurrentConfig(config) {
      if (config.id) {
        this.configs = this.configs.map((each) => (each.id === config.id ? config : each));
      } else {
        this.configs = [...this.configs, { ...config, id: uuidv4() }];
      }
      this.updateOpeningHours();
    },
    weekdayTitle(day) {
      // Correct index for sunday
      day = day === 7 ? 0 : day;

      return this.capitalize(I18n.t("date.day_names." + day));
    },
    storeTitle(storeId) {
      if (!storeId) {
        return "Company default";
      } else {
        const store = this.stores.find(({ id }) => id === storeId);
        return store ? store.name : "Unknown";
      }
    },
    capitalize: function(value) {
      if (!value) {
        return "";
      }
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    daysText({ start_date, end_date }) {
      start_date = moment(start_date).format("L");
      end_date = end_date ? moment(end_date).format("L") : "";
      return `${start_date} - ${end_date}`;
    },
    itemsText(items) {
      const texts = items.map(({ weekday, start_time, end_time }) => {
        start_time = moment(start_time, "HH:mm:ss").format("HH:mm");
        end_time = moment(end_time, "HH:mm:ss").format("HH:mm");
        return `${this.weekdayTitle(weekday)} ${start_time}-${end_time}`;
      });
      if (texts.length > 0) {
        return texts.join(", ");
      } else {
        return I18n.t("components.business_hours_editor.closed");
      }
    },
    fetchStores() {
      const url = `/api/v1/parameters/store`;

      makeApiInstance()
        .get(url)
        .then((response) => {
          this.stores = response.data;
        });
    },
    fetchOpeningHours() {
      const url = `/api/v1/opening_hours`;

      makeApiInstance()
        .get(url)
        .then((response) => {
          // group configs by store id, start date and end date
          const groupedConfigs = _.groupBy(response.data, ({ start_date, end_date = null, organisation_nk = null }) =>
            [start_date, end_date, organisation_nk].join("-")
          );

          // build configuration objects
          this.configs = _.map(groupedConfigs, (configs) => {
            const { start_date, end_date = null, organisation_nk = null } = configs[0];
            return {
              start_date,
              end_date,
              organisation_nk,
              items: configs
                .map(({ start_time, end_time, weekday }) => ({
                  start_time,
                  end_time,
                  weekday,
                }))
                .filter(({ start_time, end_time }) => !(start_time == end_time)),
              id: uuidv4(),
            };
          });
        });
    },

    updateOpeningHours() {
      // Convert to flat api format
      const openingHours = _.flatten(
        this.configs.map(({ start_date, end_date, organisation_nk = null, items }) => {
          // Ensure empty end_date is null (not empty string)
          if (_.isEmpty(end_date)) {
            end_date = null;
          }
          const itemsByWeekday = _.groupBy(items, "weekday");

          return _.flatten(
            this.days.map((weekday) =>
              (itemsByWeekday[weekday] || [{ weekday, start_time: "00:00:00", end_time: "00:00:00" }]).map(
                ({ weekday, start_time, end_time }) => ({
                  start_date,
                  end_date,
                  organisation_nk,
                  weekday,
                  start_time,
                  end_time,
                })
              )
            )
          );
        })
      );

      const url = `/api/v1/opening_hours`;

      makeApiInstance()
        .put(url, openingHours)
        .catch(() => {
          toastr.error(i18n.t("errors.saving_business_hours_failed"), "", { timeOut: 0, extendedTimeOut: 1 });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.scope-selector {
  margin-bottom: 5px;
}
.business-hours-config {
  margin-bottom: 10px;

  &__title {
    font-weight: bold;
    font-size: 1.1em;
  }

  table {
    width: 100%;

    tr {
      th:first-child {
        width: 200px;
      }
      th:last-child {
        width: 100px;
      }
      td:last-child {
        i {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
