<template>
  <div v-if="filterConfiguration" class="active-filters scrollbar-hidden">
    <div class="items" :class="{ 'align-center': alignItems === 'center' }">
      <template v-if="filterSetItems.length > 0">
        <ActiveFiltersBadge v-for="(item, index) in filterSetItems" :key="index" type="include" :items="item.items" />
      </template>
      <template v-if="filterSetItems.length == 0">
        <FiltersBadge
          v-for="filterConfig in filterConfigs"
          v-bind="filterConfig"
          :key="filterConfig.filter.id + ':' + filterConfig.type"
          @update="updateFilterConfig(filterConfig.filter.id, $event)"
        />
      </template>
      <ActiveFiltersBadge v-for="(item, index) in metricRangeItems" :key="index" type="range" :items="item.items" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "@/composables/store";
import Actions from "@/store/actions";
import { computed } from "vue";
import { ActiveFiltersBadgeProps } from "@/components/active-filters/active-filters-badge.vue";
import { removeSpecialAndExtraFiltersFromFilterConfiguration } from "@/lib/filter-util";
import _ from "lodash";
import FlatFilterConfiguration from "@/model/flat-filter-configuration";
import { useI18n } from "@/composables/i18n";
import TranslationService from "@/core/translation.service";
import FiltersBadge, { FiltersBadgeProps } from "@/components/active-filters/filters-badge.vue";
import { menuItemKey } from "@/interfaces/menu-item";
import Filters from "@/model/filters";
import Filter from "@/model/filter";
import FilterConfigurationItemMap from "@/model/filter-configuration-item-map";
import ActiveFiltersBadge from "@/components/active-filters/active-filters-badge.vue";
import ChartOptions from "@/model/chart-options";

const props = withDefaults(
  defineProps<{
    filterConfiguration: FlatFilterConfiguration;
    chartOptions?: ChartOptions;
    alignItems?: "left" | "center";
  }>(),
  {
    chartOptions: null,
    alignItems: "left",
  }
);

const emit = defineEmits(["update"]);

const store = useStore();

const { t } = useI18n();

const filtersets = computed(() => store.state.filtersets.all);

const fetchFiltersets = () => {
  store.dispatch(Actions.fetchFiltersets);
};

const availableFilters = computed<Filter[]>(() => {
  return store.getters.getParameters("filters");
});

const filterConfigs = computed<FiltersBadgeProps[]>(() => {
  if (!availableFilters.value) {
    return [];
  }
  const filters = removeSpecialAndExtraFiltersFromFilterConfiguration(props.filterConfiguration);

  return _.reduce<Filters, FiltersBadgeProps[]>(
    filters,
    (acc, filterConfigMap, filterId) => {
      const filter = availableFilters.value.find((filter) => menuItemKey(filter) === filterId);
      if (filter) {
        const hasIncludeConfigs = _.some(filterConfigMap, ({ enabled, exclude }) => enabled && !exclude);
        if (hasIncludeConfigs) {
          acc.push({ filter, type: "include", config: filterConfigMap });
        }
        const hasExcludeConfigs = _.some(filterConfigMap, ({ enabled, exclude }) => enabled && exclude);
        if (hasExcludeConfigs) {
          acc.push({ filter, type: "exclude", config: filterConfigMap });
        }
      }
      return acc;
    },
    []
  );
});

const filterSetItems = computed<ActiveFiltersBadgeProps[]>(() => {
  if (props.chartOptions?.show_filterset_name) {
    const sets = _.filter(props.filterConfiguration.sets, ({ enabled }) => enabled);

    if (sets && sets.length > 0) {
      if (!filtersets.value) {
        fetchFiltersets();
        return [];
      } else {
        return [
          {
            printLabel: t("filters.filterset.saved_filter"),
            items: sets.map((set) => filtersets.value.find(({ id }) => id === set.id)?.name),
            type: "include",
          },
        ];
      }
    }
  }
  return [];
});

const metricRangeItems = computed<ActiveFiltersBadgeProps[]>(() => {
  if (props.filterConfiguration.metric_range) {
    const separator = props.filterConfiguration.rangefilter_union ? ` ${t("filter.config.or").toUpperCase()} ` : ", ";

    const labels = _.filter(props.filterConfiguration.metric_range, ({ enabled }) => enabled).map((metricRange) =>
      new TranslationService().metricRangeTitle(metricRange)
    );

    return [{ items: labels, type: "range", separator }];
  } else {
    return [];
  }
});

const updateFilterConfig = (filterId: string, config: FilterConfigurationItemMap) => {
  const filterConfiguration = { ...props.filterConfiguration, [filterId]: config };
  emit("update", filterConfiguration);
};
</script>

<style lang="scss" scoped>
.active-filters {
  overflow-x: scroll;

  .items {
    display: flex;
    gap: 10px;
  }

  .items {
    width: max-content;
    flex-wrap: nowrap;

    @media screen {
      &.align-center {
        justify-content: center;
      }
    }
  }

  @media (min-width: 768px) {
    overflow-x: unset;

    .items {
      width: 100%;
      flex-wrap: wrap;
    }
  }

  @media print {
    overflow-x: unset;

    .items {
      width: 100%;
      flex-wrap: wrap;
    }
  }
}
</style>
