<template>
  <ui-toggle-switch
    :model-value="modelValue"
    :title="t('newsletter.definition.sections.common.chart_type.show_datalabels')"
    @update:model-value="toggle"
  />
</template>

<script lang="ts" setup>
import { useI18n } from "@/composables/i18n";
import UiToggleSwitch from "@/ui/ui-toggle-switch.vue";

const { t } = useI18n();

const props = defineProps<{
  modelValue?: boolean;
}>();

const emit = defineEmits(["update:modelValue"]);

const toggle = () => {
  emit("update:modelValue", !props.modelValue);
};
</script>
