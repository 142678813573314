<template>
  <spinner v-if="loading"></spinner>
  <div v-else class="subscriptions-list-page">
    <div class="top-actions pull-right">
      <button v-if="isAdmin || isTeamAdmin" class="btn btn-primary" @click="gotoManageReports()">
        {{ $t("newsletter.subscription.manage_email_reports") }}
      </button>
    </div>
    <div v-if="!!sortedSubscriptions.length" class="subscriptions">
      <h2>{{ $t("newsletter.subscription.my_email_reports") }}</h2>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>{{ $t("newsletter.title") }}</th>
            <th class="frequency">{{ $t("newsletter.definition.frequency") }}</th>
            <th class="default-parameter">
              {{ $t("newsletter.definition.default_parameter") }}
              <i v-uiv-tooltip.bottom="defaultParameterHelpText" class="fa fa-lg fa-info-circle"></i>
            </th>
            <th class="actions"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="subscription in sortedSubscriptions" :key="subscription.id">
            <td>
              {{ subscription.newsletter_definition.title
              }}<span v-if="subscription.team">
                ({{ $t("newsletter.definition.team") }}: {{ subscription.team.name }})</span
              >
            </td>
            <td class="frequency">{{ $t("newsletter.frequency." + subscription.newsletter_definition.frequency) }}</td>
            <td class="default-parameter">{{ defaultParameter(subscription.newsletter_definition) }}</td>
            <td class="actions">
              <a @click="preview(subscription.newsletter_definition)">{{ $t("actions.preview") }}</a
              ><a v-if="!subscription.team_id" @click="unsubscribe(subscription)">{{
                $t("newsletter.subscription.unsubscribe")
              }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!!sortedDefinitions.length" class="definitions">
      <h2>{{ $t("newsletter.subscription.available_email_reports") }}</h2>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>{{ $t("newsletter.title") }}</th>
            <th class="frequency">{{ $t("newsletter.definition.frequency") }}</th>
            <th class="default-parameter">
              {{ $t("newsletter.definition.default_parameter") }}
              <i v-uiv-tooltip.bottom="defaultParameterHelpText" class="fa fa-lg fa-info-circle"></i>
            </th>
            <th class="actions"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="definition in sortedDefinitions" :key="definition.id">
            <td>{{ definition.title }}</td>
            <td class="frequency">{{ $t("newsletter.frequency." + definition.frequency) }}</td>
            <td class="default-parameter">{{ defaultParameter(definition) }}</td>
            <td class="actions">
              <a @click="preview(definition)">{{ $t("actions.preview") }}</a
              ><a @click="subscribe(definition)">{{ $t("newsletter.subscription.subscribe") }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <newsletter-preview-modal ref="newsletterPreviewModal"></newsletter-preview-modal>
  </div>
</template>

<script lang="ts">
import spinner from "../components/spinner.vue";
import Mutations from "../store/mutations";
import i18n from "../i18n";
import { defineComponent } from "vue";
import newsletterSubscriptionsApi from "../api/newsletter-subscriptions-api";
import teamNewsletterSubscriptionsApi from "../api/team-newsletter-subscriptions-api";
import newsletterDefinitionsApi from "../api/newsletter-definitions-api";
import _ from "lodash";
import NewsletterPreviewModal from "../newsletter-definition-editor/newsletter-preview-modal.vue";
import { menuItemKey } from "@/interfaces/menu-item";

export default defineComponent({
  components: {
    spinner,
    NewsletterPreviewModal,
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.commit(Mutations.clearTitle);
    next();
  },
  data() {
    return {
      subscriptions: [],
      teamSubscriptions: [],
      definitions: [],
      loading: false,
    };
  },
  computed: {
    allSubscriptions() {
      return [...this.subscriptions, ...this.teamSubscriptions];
    },
    sortedSubscriptions() {
      return _.sortBy(this.allSubscriptions, ({ newsletter_definition: { title } }) => title?.toLowerCase());
    },
    subscribedDefinitionsIds() {
      return this.subscriptions.map(({ newsletter_definition: { id } }) => id);
    },
    filteredDefinitions() {
      return this.definitions.filter(({ id }) => !this.subscribedDefinitionsIds.includes(id));
    },
    sortedDefinitions() {
      return _.sortBy(this.filteredDefinitions, ({ title }) => title);
    },
    isAdmin() {
      return window.zoinedContext.isAdmin;
    },
    teamsMembershipsAsAdmin(): any[] {
      return window.zoinedContext.current_role.team_memberships.filter(({ role }) => role === "admin");
    },
    isTeamAdmin() {
      return this.teamsMembershipsAsAdmin.length > 0;
    },
    groupings() {
      return this.$store.getters.getParameters("grouping");
    },
    defaultParameterHelpText() {
      return i18n.t("newsletter.definition.default_parameter_help_text");
    },
  },
  created() {
    this.$store.commit(Mutations.setTitle, i18n.t("newsletter.subscription.email_reports"));
    this.fetchData();
  },
  methods: {
    defaultParameter({ use_default_parameters, default_parameter }) {
      if (use_default_parameters) {
        const grouping = this.groupings.find((grouping) => menuItemKey(grouping) == default_parameter);
        return grouping?.name || "-";
      } else {
        return i18n.t("newsletter.definition.default_parameter_not_set");
      }
    },
    gotoManageReports() {
      if (this.isAdmin) {
        this.$router.push("/company_admin/newsletter_definitions");
      } else if (this.isTeamAdmin) {
        this.$router.push("/team_admin/newsletter_definitions");
      }
    },
    async fetchData() {
      this.loading = true;
      [this.subscriptions, this.teamSubscriptions, this.definitions] = await Promise.all([
        newsletterSubscriptionsApi.getAll(),
        teamNewsletterSubscriptionsApi.getAll(),
        newsletterDefinitionsApi.getAll(),
      ]);
      this.loading = false;
    },
    async subscribe(definition) {
      const subscription = await newsletterSubscriptionsApi.create({ newsletter_definition_id: definition.id });
      this.subscriptions = [...this.subscriptions, subscription];
    },
    async unsubscribe(subscription) {
      await newsletterSubscriptionsApi.destroy(subscription);
      this.subscriptions = this.subscriptions.filter((it) => it !== subscription);
    },
    preview(definition) {
      (this.$refs.newsletterPreviewModal as typeof NewsletterPreviewModal).show(definition);
    },
  },
});
</script>

<style lang="scss" scoped>
td,
th {
  &.frequency {
    width: 20%;
  }
  &.default-parameter {
    width: 20%;
  }
  &.actions {
    width: 20%;
    a {
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
</style>
