<template>
  <div v-if="groupings && groupings.length > 0" class="newsletter-section-insights">
    <div v-if="configuration" class="current-configuration">
      <b>{{ $t("filters.compare_to") }}:</b><span>{{ $t("filters.benchmark." + configuration.comparison) }}.</span
      ><b>{{ $t("newsletter.definition.insights.dimensions_title") }}:</b><span>{{ dimensionsText }}</span>
    </div>
    <div v-if="!configuration" class="buttons">
      <button key="applyButton" class="btn btn-primary" :disabled="disabled" @click="apply">
        {{ $t("actions.apply") }}</button
      ><i v-if="disabled" class="enable-insights-info"
        >&nbsp; {{ $t("newsletter.definition.insights.enable_insights_info") }}</i
      >
    </div>
    <div v-else class="buttons">
      <button class="btn btn-primary" @click="edit">{{ $t("actions.edit") }}</button>
      <button v-if="configuration" class="btn btn-default" @click="clearInsights">{{ $t("actions.delete") }}</button>
    </div>
    <newsletter-section-insights-modal
      ref="newsletterSectionInsightsModal"
      :metric="metric"
      :groupings="groupings"
      :configuration="configuration"
      @update="$emit('change', $event)"
    ></newsletter-section-insights-modal>
  </div>
</template>
<script>
import newsletterSectionInsightsModal from "./newsletter-section-insights-modal";
import GroupingsApiService from "../api/groupings-api-service";

export default {
  components: {
    newsletterSectionInsightsModal: newsletterSectionInsightsModal,
  },
  props: {
    metric: {
      type: String,
      required: true,
    },
    configuration: {
      type: Object,
      default: null,
    },
  },
  emits: ["change"],
  data() {
    return {
      groupings: null,
    };
  },
  computed: {
    defaultConfiguration() {
      // Filter default dimensions by available groupings
      let dimensions = ["store", "category", "subcategory", "supplier"].filter(
        (dimension) => !!this.groupings.find(({ key }) => key == dimension)
      );

      // Take just first few groupings if none matched
      if (dimensions.length == 0) {
        dimensions = _.take(this.groupings, 4).map(({ key }) => key);
      }

      return {
        dimensions,
        comparison: "prev_year_corresponding",
        insights_limit: 1,
        sub_insights_limit: 1,
      };
    },
    dimensionsText() {
      return this.configuration.dimensions.map((dimension) => I18n.t(`filter.config.${dimension}`)).join(", ");
    },
    disabled() {
      return !window.zoinedContext.features.insights;
    },
  },
  watch: {
    metric() {
      this.loadGroupings();
    },
  },
  created() {
    if (this.metric) {
      this.loadGroupings();
    }
  },
  methods: {
    apply() {
      this.$emit("change", { ...this.defaultConfiguration });
    },
    clearInsights() {
      this.$emit("change", null);
    },
    loadGroupings() {
      new GroupingsApiService().getMetricGroupings(this.metric).then((groupings) => (this.groupings = groupings));
    },
    edit() {
      this.$refs.newsletterSectionInsightsModal.show();
    },
  },
};
</script>

<style lang="scss" scoped>
.current-configuration > * {
  margin-right: 0.5em;
}
.buttons > * {
  margin-right: 0.5em;
}
</style>
