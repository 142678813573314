<template>
  <uiv-popover
    v-model="show"
    viewport="body"
    :title="$t('actions.are_you_sure')"
    :disabled="disabled"
    :enable="!disabled"
    :tag="tag"
  >
    <template #default>
      <slot>
        <uiv-btn :type="variant">{{ buttonTitle }}</uiv-btn>
      </slot>
    </template>
    <template #popover>
      <p v-if="popoverMessage">{{ popoverMessage }}</p>
      <div class="flex-row justify-content-space-between gap-md">
        <div class="flex-1">
          <uiv-btn type="success" block @click.stop="confirm()">{{ $t("actions.confirm") }}</uiv-btn>
        </div>
        <div class="flex-1">
          <uiv-btn block @click.stop="cancel()">{{ $t("actions.cancel") }}</uiv-btn>
        </div>
      </div>
    </template>
  </uiv-popover>
</template>

<script>
export default {
  props: {
    buttonTitle: { type: String, default: null },
    popoverMessage: { type: String, default: null },
    variant: {
      type: String,
      default: "danger",
    },
    tag: {
      type: String,
      default: "span",
    },
    disabled: Boolean,
  },
  emits: ["confirm", "cancel"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.show = false;
    },
    cancel() {
      this.$emit("cancel");
      this.show = false;
    },
  },
};
</script>
