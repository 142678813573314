<template>
  <div class="budget-planning">
    <spinner v-if="uploading"></spinner>
    <div class="row uploaded-budgets">
      <div class="col-md-12">
        <h4>{{ $t("company_admin.budget.uploaded_budgets") }}</h4>
        <div class="spinner-base">
          <spinner v-if="loading"></spinner>
          <div v-if="notifications" class="notifications">
            <div
              v-for="notification in notifications"
              :key="notification.id"
              class="alert"
              :class="{ 'alert-success': notification.status == 'ok', 'alert-danger': notification.status == 'error' }"
            >
              <div class="pull-right">
                <button class="close" type="button" @click="deleteNotification(notification)">&times;</button>
              </div>
              <b>{{ date(notification.created_at) }}</b
              >: {{ notification.message }}
            </div>
          </div>
          <div v-if="!loading">
            <p v-if="uploadedMetrics">{{ $t("company_admin.budget.uploaded_budgets_desc") }}</p>
            <p v-if="!uploadedMetrics">{{ $t("company_admin.budget.no_uploaded_budgets_desc") }}</p>
            <table v-if="uploadedMetrics" class="table">
              <thead>
                <tr>
                  <th>{{ $t("filter.config.year") }}</th>
                  <th>{{ $t("company_admin.budget.type") }}</th>
                  <th>{{ $t("filter.config.metrics") }}</th>
                  <th>{{ $t("filter.config.grouping") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in uploadedMetrics" :key="index">
                  <td>{{ row.year }}</td>
                  <td>{{ row.type }}</td>
                  <td>{{ row.metric }}</td>
                  <td>{{ row.dimensions.join(", ") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>{{ $t("company_admin.budget.download_budget") }}</h4>
        <p>{{ $t("company_admin.budget.download_budget_desc") }}</p>
        <div v-if="errors.download" class="alert alert-danger">{{ errors.download }}</div>
        <form>
          <div class="row">
            <div class="form-group col-md-4">
              <label>{{ $t("attributes.start_date") }}</label>
              <date-picker v-model="download.start_date"></date-picker>
            </div>
            <div class="form-group col-md-4">
              <label>{{ $t("attributes.end_date") }}</label>
              <date-picker v-model="download.end_date"></date-picker>
            </div>
            <div class="form-group col-md-4">
              <label>{{ $t("company_admin.budget.type") }}</label>
              <select v-model="download.type" class="form-control">
                <option v-for="t in availableTypes" :key="t" :value="t">{{ $t("filter.config." + t) }}</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label>{{ $t("company_admin.budget.plan_type") }}</label>
              <select v-model="download.label" class="form-control">
                <option v-for="label in availableLabels" :key="label" :value="label">{{
                  $t("filters.budgets." + label)
                }}</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label>{{ $t("filter.config.metrics") }}</label>
              <div v-if="config">
                <metrics-selector
                  :only-ids="config.valid.metrics"
                  :model-value="download.metrics"
                  @update:model-value="updateMetrics"
                ></metrics-selector>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>{{ $t("filter.config.grouping") }}</label>
              <div v-if="config">
                <groupings-selector
                  :only-ids="config.valid.dimensions"
                  :model-value="download.dimensions"
                  @update:model-value="updateDimensions"
                ></groupings-selector>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label>{{ $t("filters.compare_to") }}</label>
              <pill-select :items="comparisonItems" @change="updateComparison"></pill-select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-12">
              <label>{{ $t("filters.title") }}</label>
              <div v-if="showFilters" class="flex-row align-items-center gap-lg column-gap-xxl flex-wrap">
                <template v-for="filter in filterItems" :key="filter.id">
                  <filter-selector
                    :config="filters[filter.id]"
                    :filter="filter"
                    @update="updateFilter(filter.id, $event)"
                  ></filter-selector>
                </template>
              </div>
              <div v-else>
                <a @click="showFilters = true">{{ $t("filters.add_filters") }}</a>
              </div>
            </div>
            <div class="col-md-12">
              <button class="btn btn-primary" type="button" @click="downloadTemplate()">
                {{ $t("company_admin.budget.download_budget") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>{{ $t("company_admin.budget.update_budgets") }}</h4>
        <p>{{ $t("company_admin.budget.update_budgets_desc") }}</p>
        <div v-if="uploadSuccess" class="alert alert-success">{{ $t("company_admin.budget.budget_saved_desc") }}</div>
        <div v-if="errors.upload" class="alert alert-danger">{{ errors.upload }}</div>
        <div class="file-drop">
          {{ $t("company_admin.budget.upload_budget") }}
          <input ref="fileInput" type="file" @change="onFileChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Spinner from "../components/spinner.vue";
import metricsSelector from "../components/metrics-selector.vue";
import groupingsSelector from "../components/groupings-selector.vue";
import _ from "lodash";
import adminCompaniesApi from "../api/admin-companies-api";
import adminNotificationsApi from "../api/admin-notifications-api";
import { metricLabel } from "../lib/i18n-util";
import I18n from "../i18n";
import toaster from "toastr";
import moment from "moment";
import { downloadFile } from "@/lib/download";
import datePicker from "@/components/date-picker.vue";
import FilterConfigurationItemMap from "@/model/filter-configuration-item-map";
import FilterSelector from "@/components/filter-selector.vue";
import { configToActive } from "@/lib/filter-util";
import { hideOverlay, showOverlay } from "@/ui/overlay";
import PillSelect from "@/components/pill-select.vue";
import { date } from "@/lib/vue/filters/date";
import Filter from "@/model/filter";

export default defineComponent({
  components: {
    Spinner,
    metricsSelector,
    groupingsSelector,
    datePicker,
    FilterSelector,
    PillSelect,
  },
  data() {
    return {
      loading: false,
      config: null,
      uploadedMetrics: null,
      notifications: null,
      uploading: false,
      uploadSuccess: false,
      showFilters: false,
      download: {
        start_date: moment()
          .startOf("year")
          .toDate(),
        end_date: moment()
          .endOf("year")
          .toDate(),
        type: "month",
        metrics: [],
        dimensions: [],
        label: "budget",
      },
      errors: {
        upload: "",
        download: "",
      },
      notificationInterval: null,
      filters: {},
      comparisonItems: [
        { value: "prev_year_corresponding", label: I18n.t("filters.benchmark.prev_year_corresponding"), enabled: true },
        {
          value: "2_years_back_corresponding",
          label: I18n.t("filters.benchmark.2_years_back_corresponding"),
          enabled: true,
        },
      ],
      availableYears: [moment().year() + 2, moment().year() + 1, moment().year(), moment().year() - 1],
      availableTypes: ["month", "week", "day"],
      availableLabels: ["budget", "forecast", "target"],
    };
  },
  computed: {
    companyId() {
      return window.zoinedContext.companyId;
    },
    filterItems(): Filter[] {
      return this.$store.getters.getParameters("filters");
    },
  },
  created() {
    this.fetchBudgetConfiguration();
    this.fetchNotifications();
    this.notificationInterval = setInterval(() => {
      this.fetchNotifications();
    }, 60000);
  },
  unmounted() {
    clearInterval(this.notificationInterval);
  },
  methods: {
    fetchBudgetConfiguration() {
      this.loading = true;
      adminCompaniesApi
        .getBudgetConfiguration({ id: this.companyId, yearly: true })
        .then((config) => {
          this.config = config;
          this.uploadedMetrics = _.flatten(
            _.map(config.budgeted.budget, (budgets, m) => {
              return _.map(budgets, (budget) => {
                return {
                  metric: metricLabel(m),
                  year: budget.year,
                  type: budget.type == "D" ? I18n.t("filter.config.day") : I18n.t("filter.config.month"),
                  dimensions: _.sortBy(budget.dimensions.map((d) => I18n.t(`filter.config.${d}`))),
                };
              });
            })
          );
        })
        .finally(() => (this.loading = false));
    },
    fetchNotifications() {
      adminNotificationsApi.get({ company_id: this.companyId, type: "budget" }).then((results) => {
        this.notifications = results;
      });
    },
    onFileChange() {
      const files = (this.$refs.fileInput as HTMLInputElement).files;
      if (files.length > 0) {
        this.uploadFile(files[0]);
      }
    },
    uploadFile(file) {
      this.uploading = true;
      this.uploadSuccess = false;
      const formData = new FormData();
      formData.append("file", file);
      fetch(`/api/admin/companies/${this.companyId}/budgets`, {
        method: "POST",
        body: formData,
      })
        .then((result) => {
          if (!result.ok) {
            return result.json().then((data) => {
              throw data;
            });
          }
          this.uploadSuccess = true;
          this.errors.upload = "";
          toaster.success(I18n.t("company_admin.budget.budget_saved"));
          this.fetchBudgetConfiguration();
        })
        .catch((err) => {
          toaster.error(I18n.t("company_admin.budget.error_storing"), { timeOut: 0 });
          this.errors.upload = err.error || I18n.t("company_admin.budget.unknown_error");
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    downloadTemplate() {
      const errors = [];
      if (_.isEmpty(this.download.metrics)) {
        errors.push(I18n.t("company_admin.budget.errors.select_metric"));
      }
      if (errors.length > 0) {
        this.errors.download = errors.join(", ");
        return;
      } else {
        this.errors.download = "";
        const params = new URLSearchParams({
          start_date: moment(this.download.start_date).format("YYYY-MM-DD"),
          end_date: moment(this.download.end_date).format("YYYY-MM-DD"),
          type: this.download.type,
          label: this.download.label,
        });
        const comparisons = this.comparisonItems.filter((c) => c.enabled).map((c) => c.value);
        if (comparisons.length > 0) {
          comparisons.forEach((c) => params.append("comparisons[]", c));
        } else {
          params.append("comparisons[]", "");
        }
        this.download.metrics
          .filter((m) => m.enabled)
          .map((m) => m.id)
          .forEach((m) => params.append("metrics[]", m));
        this.download.dimensions
          .filter((m) => m.enabled)
          .map((m) => m.id)
          .forEach((m) => params.append("dimensions[]", m));
        const filters: Record<string, string[]> = configToActive(this.filters);
        Object.keys(filters).map((key) => {
          const values = filters[key];
          values.forEach((value) => {
            params.append(`filters[${key}][]`, value);
          });
        });

        const url = `/api/admin/companies/${this.companyId}/budgets?${params.toString()}`;

        showOverlay(I18n.t("please_wait"));
        return downloadFile(url)
          .then(() => {
            toaster.success(I18n.t("company_admin.budget.budget_downloaded"));
          })
          .catch(() => {
            toaster.error(I18n.t("company_admin.budget.errors.failed_to_download"));
          })
          .finally(() => {
            hideOverlay();
          });
      }
    },
    deleteNotification(notification) {
      adminNotificationsApi.delete({ company_id: this.companyId, id: notification.id }).then(() => {
        this.notifications = _.filter(this.notifications, (n) => n.id !== notification.id);
      });
    },
    updateDimensions(dimensions) {
      this.download.dimensions = dimensions;
    },
    updateMetrics(metrics) {
      this.download.metrics = metrics;
    },
    updateFilter(filter: string, config: FilterConfigurationItemMap) {
      this.filters = {
        ...this.filters,
        [filter]: config,
      };
    },
    updateComparison(items) {
      this.comparisonItems = items;
    },
    date(s) {
      date(s);
    },
  },
});
</script>
