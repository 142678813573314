<template>
  <div class="newsletter-subscriptions">
    <div class="table-controls">
      <search-bar v-model="search"></search-bar>
    </div>
    <div>
      <spinner v-if="!subscriptions"></spinner>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>{{ $t("newsletter.subscription.user") }}</th>
            <th>{{ $t("newsletter.title") }}</th>
            <th>{{ $t("newsletter.definition.frequency") }}</th>
            <th>{{ $t("newsletter.last_sent_at") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="subscriptions">
          <tr v-if="subscriptions.length == 0">
            <td colspan="6">{{ $t("table.no_results") }}</td>
          </tr>
          <tr v-for="subscription in subscriptions">
            <td :title="subscription.user.email">
              {{ subscription.user.first_name }} {{ subscription.user.last_name }}
            </td>
            <td>{{ subscription.title }}</td>
            <td>{{ $t("newsletter.frequency." + subscription.frequency) }}</td>
            <td>{{ dateTime(subscription.last_sent_at) }}</td>
            <td class="text-right">
              <a class="mr-sm" @click="unsubscribe(subscription)">{{ $t("newsletter.subscription.unsubscribe") }}</a>
              <a v-if="permissions.send" @click="deliver(subscription)">{{
                $t("newsletter_subscription.send_latest")
              }}</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="centered" v-if="total > perPage">
        <uiv-pagination v-model="page" :total-page="Math.ceil(total / perPage)"></uiv-pagination>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Spinner from "../components/spinner.vue";
import SearchBar from "../components/search-bar.vue";
import adminNewsletterSubscriptionsApi from "../api/admin-newsletter-subscriptions-api";
import toaster from "toastr";
import i18n from "../i18n";
import { dateTime } from "@/lib/vue/filters/date";

export default defineComponent({
  components: {
    Spinner,
    SearchBar,
  },
  data() {
    return {
      page: 1,
      perPage: 15,
      search: "",
      total: 0,
      subscriptions: null,
    };
  },
  computed: {
    permissions() {
      return window.zoinedContext.permissions;
    },
    companyId() {
      return window.zoinedContext.companyId;
    },
    params() {
      const params = {
        page: this.page,
        per_page: this.perPage,
        company_id: this.companyId,
      };

      if (this.search.length > 0) {
        params["q"] = this.search;
      }

      return params;
    },
  },
  methods: {
    unsubscribe(subscription) {
      return adminNewsletterSubscriptionsApi.delete({ company_id: this.companyId, id: subscription.id }).then(() => {
        this.fetchSubscriptions();
      });
    },
    deliver(subscription) {
      return adminNewsletterSubscriptionsApi.deliver({ company_id: this.companyId, id: subscription.id }).then(() => {
        toaster.success(i18n.t("newsletter.subscription.send_latest_success"));
        this.subscriptions = this.subscriptions.map((s) => {
          if (s.id === subscription.id) {
            s.last_sent_at = new Date().toISOString();
          }
          return s;
        });
      });
    },
    dateTime(s) {
      return dateTime(s);
    },
    fetchSubscriptions() {
      return adminNewsletterSubscriptionsApi.get(this.params).then((subscriptions) => {
        this.subscriptions = subscriptions;
        this.total = subscriptions.total_count;
      });
    },
    onSearchChange() {
      this.page = 1;
    },
  },
  watch: {
    params: [{ immediate: true, handler: "fetchSubscriptions" }],
    search: [
      {
        handler: "onSearchChange",
      },
    ],
  },
});
</script>
