<template>
  <div
    class="pill-button"
    :class="{ inactive: !enabled, exclude, 'button-style': buttonStyle, 'pill-outline': pillOutline }"
    @click="$emit('toggle')"
  >
    <slot>{{ label }}</slot
    ><span v-if="removable" class="pill-button-del" @click.stop="$emit('remove')"
      ><i class="fa fa-circle border"></i><i class="fa fa-times-circle"></i
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    exclude: {
      type: Boolean,
      default: false,
    },
    pillStyle: {
      type: String,
      default: "pill",
    },
  },
  emits: ["toggle", "remove"],
  computed: {
    buttonStyle() {
      return this.pillStyle == "button";
    },
    pillOutline() {
      return this.pillStyle == "pill-outline";
    },
  },
};
</script>

<style lang="scss" scoped>
.pill-button {
  display: inline-block;
  position: relative;
  padding: 4px 16px;
  border-radius: 17px;
  font-size: 0.9em;
  font-weight: 600;

  &.button-style {
    padding: 5px 16px;
    border-radius: 5px;
  }

  background-color: var(--color-button-primary-background);
  color: var(--color-button-primary-text);
  &:hover {
    background-color: var(--color-button-primary-hover-background);
    color: var(--color-button-primary-hover-text);
  }
  &.exclude {
    background-color: var(--color-button-negative-background);
    color: var(--color-button-negative-text);
  }
  &.inactive,
  &.inactive.exclude:hover {
    background-color: var(--color-button-primary-disabled-background);
    border-color: var(--color-button-primary-disabled-background);
    color: var(--color-button-primary-disabled-text);
  }

  &.pill-outline {
    background-color: transparent;
    border: 1px solid var(--color-button-primary-inverted-border);
    color: var(--color-button-primary-inverted-text);
    &:hover {
      background-color: var(--color-button-primary-inverted-hover-background);
      border-color: var(--color-button-primary-inverted-hover-background);
      color: var(--color-button-primary-inverted-hover-text);
    }
    &.inactive {
      background-color: transparent;
      border-color: #84827d;
      color: #dadad9;
    }
  }

  .pill-button-del {
    position: absolute;
    top: -1px;
    right: -12px;
    display: none;
    width: 1em;
    height: 1em;
    font-size: 170%;
    i {
      position: absolute;
      top: 0;
      left: 0;
    }
    .fa-circle.border {
      color: #fff;
      font-size: 70%;
      top: 10%;
      left: 10%;
    }
    .fa-times-circle {
      color: #333436;
    }
    &:hover {
      display: block;
      .fa-circle.border {
        color: #333436;
      }
      .fa-times-circle {
        color: darken(#fff, 8%);
      }
    }
  }
  &:hover .pill-button-del {
    display: inline-block;
  }
}
</style>
