<template>
  <div class="sidenav-search-results">
    <sidenav-report v-for="report in reports" :key="report.path" :report="report"></sidenav-report>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SidenavReport from "./sidenav-report.vue";

export default defineComponent({
  components: {
    SidenavReport,
  },
  props: {
    reports: {
      type: Array,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.sidenav-search-results {
  margin-top: 20px;
}
</style>
