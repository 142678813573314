<template>
  <div class="choice-chips-selector">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.choice-chips-selector {
  display: flex;
  gap: 10px;
  padding: 5px;
  background-color: var(--color-surface-neutral-1);
  border-radius: 9999px;
}
</style>
