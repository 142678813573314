<template>
  <div class="top-section">
    <div class="option">
      <label>{{ $t("filter.config.grouping") }}</label>
      <select :value="section.grouping" @input="updateGrouping">
        <option v-for="option in groupingOptions" :key="menuItemKey(option)" :value="menuItemKey(option)">{{
          option.name
        }}</option>
      </select>
    </div>
    <div class="option">
      <label>{{ $t("filter.config.metrics") }}</label>
      <select :value="section.metric" @input="updateMetric">
        <option v-for="option in metricsOptions" :key="menuItemKey(option)" :value="menuItemKey(option)">{{
          option.name
        }}</option>
      </select>
    </div>
    <div class="option">
      <label>{{ $t("filter.config.sort") }}</label>
      <select :value="section.sort" @input="updateSort">
        <option v-for="option in sortOptions" :key="option.id" :value="option.id">{{ option.name }}</option>
      </select>
    </div>
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.common.entries_to_show") }}</label>
      <select :value="section.limit" @input="updateLimit">
        <option v-for="option in limitOptions" :key="option.id" :value="option.id">{{ option.name }}</option>
      </select>
    </div>
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.common.chart_type.description") }}</label>
      <select :value="section.displayType" @input="updateDisplayType">
        <option v-for="option in displayTypes" :key="option.id" :value="option.id">{{ option.name }}</option>
      </select>
    </div>
    <div class="option">
      <label>{{ $t("newsletter.definition.sections.common.time_period") }}</label>
      <timePeriodDropdown
        :show-title="false"
        :custom="false"
        :available-items="timePeriods"
        :available-series-types="seriesTypes"
        :value="selection"
        @update="updateSelection"
      ></timePeriodDropdown>
    </div>
    <div v-if="openingHoursDefined" class="option">
      <label>{{ $t("filter.config.limit_to_business_hours") }}</label>
      <businessHoursSelector
        :title="false"
        :value="section.limit_to_business_hours"
        @update="updateLimitToBusinessHours"
      ></businessHoursSelector>
    </div>
    <div v-if="section.displayType == 'bar'" class="option">
      <label>{{ $t("newsletter.definition.sections.common.comparison") }}</label>
      <comparisonPillList
        :choices="comparisons"
        :custom="false"
        :title="null"
        :togglable="false"
        :comparisons="section.comparisons"
        @update="updateComparisons"
      ></comparisonPillList>
    </div>
    <div
      v-if="lflEnabled && section.comparisons.length == 1 && isTimeComparison(section.comparisons[0].type)"
      class="option"
    >
      <label>{{ $t("filter.config.like_for_like") }}</label>
      <likeForLikeSelector
        :config="section.like_for_like"
        :title="null"
        @update="updateLikeForLike"
      ></likeForLikeSelector>
    </div>
    <div v-if="section.displayType == 'bar' && section.comparisons.length == 1" class="option">
      <label></label>
      <div class="checkbox">
        <label>
          <input type="checkbox" :checked="section.pairwiseDiff" @input="updatePairwiseDiff" />{{
            $t("newsletter.definition.sections.common.pairwise_diff")
          }}
        </label>
      </div>
    </div>
    <div v-if="insightsVisible && section.metric" class="option">
      <label>{{ $t("newsletter.definition.insights.title") }}</label>
      <newsletterSectionInsights
        :metric="section.metric"
        :configuration="section.insights"
        @change="updateInsights"
      ></newsletterSectionInsights>
    </div>
    <div v-if="newsletterType == 'alert'" class="option">
      <label>{{ $t("newsletter.definition.alert.alert_conditions") }}</label>
      <div style="margin-left: 200px;">
        <newsletterSectionAlertConditions
          :config="section.alert"
          :default-metric="section.metric"
          @update="updateAlert"
        ></newsletterSectionAlertConditions>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { timeComparisonTypes } from "../../filters/time-period";
import MenuItem, { menuItemKey } from "../../interfaces/menu-item";
import { defineComponent, PropType } from "vue";
import _ from "lodash";
import timePeriodDropdown from "../../components/time-period-dropdown.vue";
import businessHoursSelector from "../../components/business-hours-selector.vue";
import comparisonPillList from "../../components/comparison-pill-list.vue";
import likeForLikeSelector from "../../components/like-for-like-selector.vue";
import newsletterSectionInsights from "../newsletter-section-insights.vue";
import newsletterSectionAlertConditions from "../newsletter-section-alert-conditions.vue";
import { displayTypes, limitOptions, sortOptions } from "../constants";

export default defineComponent({
  components: {
    timePeriodDropdown,
    businessHoursSelector,
    comparisonPillList,
    likeForLikeSelector,
    newsletterSectionInsights,
    newsletterSectionAlertConditions,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    newsletterType: {
      type: String,
      required: true,
    },
    timePeriods: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    seriesTypes: {
      type: Array as PropType<string[]>,
      required: true,
    },
    openingHoursDefined: {
      type: Boolean,
    },
    lflEnabled: {
      type: Boolean,
    },
    insightsVisible: {
      type: Boolean,
    },
  },
  emits: ["update-section"],
  data() {
    return {
      menuItemKey,
    };
  },
  computed: {
    selection() {
      return (
        this.section.time_period && {
          type: this.section.time_period,
          series: this.section.time_series_type,
        }
      );
    },
    metricsOptions(): MenuItem[] {
      return this.$store.state.parameters.metrics.all;
    },
    groupingOptions(): MenuItem[] {
      return this.$store.state.parameters.grouping.all;
    },
    comparisons() {
      const allowedComparisonTypes = [
        "prev_year_corresponding",
        "2_years_back_corresponding",
        "3_years_back_corresponding",
        "prev_year_corresponding_same_weekday_ending",
        "prev_year_same_days",
        "prev_year_full_month",
        "previous_corresponding_period",
        "previous_year_lfl",
        "2_years_back_lfl",
        "3_years_back_lfl",
        "avg_chain",
        "avg_store",
        "best_store",
        "avg_salesperson",
        "best_salesperson",
        "budget",
        "budget_full_month",
      ];
      return this.$store.state.parameters.comparisons.all?.filter(({ type }) => allowedComparisonTypes.includes(type));
    },
    limitOptions() {
      return limitOptions;
    },
    sortOptions() {
      return sortOptions;
    },
    displayTypes() {
      return displayTypes(["bar", "pie"]);
    },
  },
  methods: {
    updateGrouping(event) {
      const grouping = event.target.value;
      this.updateSection({ ...this.section, grouping });
    },
    updateMetric(event) {
      const metric = event.target.value;
      this.updateSection({ ...this.section, metric, insights: null });
    },
    updateSort(event) {
      const sort = event.target.value;
      this.updateSection({ ...this.section, sort });
    },
    updateLimit(event) {
      const limit = Number.parseInt(event.target.value);
      this.updateSection({ ...this.section, limit });
    },
    updateDisplayType(event) {
      const displayType = event.target.value;
      this.updateSection({ ...this.section, displayType });
    },
    updatePairwiseDiff(event) {
      const pairwiseDiff = event.target.checked;
      this.updateSection({ ...this.section, pairwiseDiff });
    },
    updateSelection({ type, series }) {
      this.updateSection({ ...this.section, time_period: type, time_series_type: series });
    },
    updateLimitToBusinessHours(limit_to_business_hours) {
      this.updateSection({ ...this.section, limit_to_business_hours });
    },
    updateComparisons(comparisons) {
      this.updateSection({ ...this.section, comparisons });
    },
    updateLikeForLike(like_for_like) {
      this.updateSection({ ...this.section, like_for_like });
    },
    updateInsights(insights) {
      this.updateSection({ ...this.section, insights });
    },
    updateAlert(alert) {
      this.updateSection({ ...this.section, alert });
    },
    updateSection(section) {
      this.$emit("update-section", section);
    },
    isTimeComparison(type) {
      return _.includes(timeComparisonTypes, type);
    },
  },
});
</script>
