<template>
  <div class="flex-inline-row align-items-center flex-wrap gap-lg">
    <div v-if="title" class="title">{{ title }}</div>
    <dropdown-select v-bind="$attrs" button-style="primary"></dropdown-select>
  </div>
</template>

<script>
import DropdownSelect from "./dropdown-select.vue";

export default {
  components: {
    DropdownSelect,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>
