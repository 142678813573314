<template>
  <pill-list :title="title" :items="items" :available-items="menuItems" @update="onUpdate"></pill-list>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import pillList from "../components/pill-list.vue";
import MenuItem from "../interfaces/menu-item";
import PillItem from "../interfaces/pill-item";

export default defineComponent({
  components: {
    pillList,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    restrictedReports: {
      type: Array,
      required: true,
    },
    allowedReports: {
      type: Array,
      required: true,
    },
  },
  emits: ["change"],
  computed: {
    items(): PillItem[] {
      return this.allowedReports.map(({ id, name }) => ({ value: id, name }));
    },
    menuItems(): MenuItem[] {
      return this.restrictedReports.map(({ id, name }) => ({ key: id, name }));
    },
  },
  methods: {
    onUpdate({ items }) {
      this.$emit(
        "change",
        items.map(({ value, name }) => ({ id: value, name }))
      );
    },
  },
});
</script>
