<template>  
  <uiv-modal v-model="open" class="newsletter-preview-modal" :title="$t('newsletter.definition.preview')">
    <spinner v-if="!errors && !previewUrl"></spinner>
    <div v-if="errors" class="errors">
      <h3>{{ $t('newsletter.definition.messages.preview_errors') }}</h3>{{errors}}
    </div>
    <div v-if="!errors && !previewUrl" class="preview-in-progress">{{ $t('newsletter.definition.messages.preview_generating') }}</div>
    <div class="preview-content" :class="{loaded: previewUrl }">
      <div v-if="emailSent" class="send-preview">{{ $t('newsletter.definition.messages.email_sent') }}</div>
      <iframe ref="iframe" :src="previewUrl" seamless: true></iframe>
    </div>
    <template #footer>
      <button v-if="!errors && previewUrl && !emailSent" class="btn btn-primary" :disabled="sendingEmail" @click="sendPreview()">{{ $t('newsletter.definition.actions.send_preview') }}</button>
      <button class="btn btn-warning" @click="cancel()">{{ $t('actions.cancel') }}</button>
    </template>
  </uiv-modal>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";
import newsletterDefinitionsApi from "../api/admin-newsletter-definitions-api";
import spinner from "../components/spinner.vue";

export default defineComponent({
  components: {
    spinner,
  },
    data() {
        return {
            open: false,
            sendingEmail: false,
            emailSent: false,
            token: null,
            previewUrl: null,
            errors: null
        };
    },
    methods: {
        show(definition) {
            this.open = true;
                this.sendingEmail = false;
                this.emailSent = false;
                this.token = null;
                this.previewUrl = null;
                this.errors = null;

                const resultCheck = () => {
                  return axios
                    .get("/newsletter_previews/" + this.token + ".json")
                    .then((result) => {
                      if (result.data.status === "ok") {
                        this.previewUrl = result.data.preview_url;
                        (this.$refs.iframe as HTMLIFrameElement).contentWindow.focus();
                      } else if (this.open) {
                        return window.setTimeout(resultCheck, 1000);
                      }
                    })
                    .catch((error) => {
                      if (error.response) {
                        this.errors = error.response.data.error;
                      } else {
                        throw error;
                      }
                    });
                };

                const company_id = window.zoinedContext.companyId;

                newsletterDefinitionsApi
                  .preview({ company_id }, definition)
                  .then((data) => {
                    this.token = data.token;
                    resultCheck();
                  })
                  .catch((error) => {
                    if (error.response) {
                      this.errors = error.response.data.error;
                    } else {
                      throw error;
                    }
                  });
        },
        sendPreview() {
            this.sendingEmail = true;
            return axios.post("/newsletter_previews/" + this.token + "/send.json").then(() => {
              this.sendingEmail = false;
              this.emailSent = true;
            });
        },
        cancel() {
            this.open = false;
        }
    },
})

</script>

<style lang="scss" scoped>
.preview-content {
  &.loaded {
    height: 75vh;
  }
}
</style>
