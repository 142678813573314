<template>
  <div class="drilldown-wrapper">
    <span class="nowrap text-xs font-semibold">{{ $t("chart.clickGraphDrillingToSelect") }}</span>
    <dropdown-select-button class="selected-drilldown" :menu-open="dropdownOpen" @click="showDrilldownOptions()">
      <span v-uiv-tooltip.hover="clickGraphDrillingToTitle">
        <span class="drilldown-value">{{ selectedText }}</span>
      </span>
    </dropdown-select-button>

    <div v-if="dropdownOpen" class="drilldown-options" :class="{ 'right-align': rightAlignDrilldownOptions }">
      <i v-uiv-tooltip.hover="infoText" class="fa fa-lg fa-info-circle info-icon"></i>

      <v-select
        v-model="drilldownModel"
        label="label"
        class="selector"
        :placeholder="$t('chart.choose_drilling')"
        :options="options"
        :show-labels="false"
        group-values="groupings"
        group-label="categoryLabel"
        :group-select="false"
      ></v-select>

      <div class="flex-row gap-md">
        <div class="flex-1">
          <button class="btn btn-primary btn-block" @click="applyDrilldown()">{{ $t("actions.apply") }}</button>
        </div>
        <div class="flex-1">
          <button class="btn btn-default btn-block" @click="saveDrilldown()">{{ $t("actions.save") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import i18n from "../i18n";
import { defineComponent } from "vue";
import DropdownSelectButton from "../components/dropdown-select-button.vue";
import { makeApiInstance } from "../api/instance";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import _ from "lodash";
import vSelect from "vue-multiselect";

export default defineComponent({
  components: {
    DropdownSelectButton,
    vSelect,
  },
  inject: ["drilldownData"],
  data() {
    const drilldownModel = null;

    return {
      drilldownModel,
      dropdownOpen: false,
      metricGroupings: [],
      rightAlignDrilldownOptions: false,
      isLimited: window.zoinedContext.limited,
    };
  },
  computed: {
    selectedDrilldown() {
      return this.drilldownData?.selected;
    },
    metric() {
      return this.drilldownData?.metric;
    },
    currentGrouping() {
      return this.drilldownData?.currentGrouping;
    },
    clickGraphDrillingToTitle() {
      return i18n.t("chart.clickGraphDrillingToTitle");
    },
    selectedText() {
      if (this.selectedDrilldown) {
        const grouping = this.metricGroupings.find((grouping) => grouping.key === this.selectedDrilldown.value);
        if (grouping) {
          const categoryLabel = this.translateCategory(grouping.category);
          return `${categoryLabel}: ${this.selectedDrilldown.label}`;
        }
      }
      return i18n.t(`chart.no_default_drilldown`);
    },
    infoText() {
      return i18n.t("filters.actions.info_text");
    },
    allGroupings(): MenuItem[] {
      return this.$store.getters.getParameters("grouping");
    },
    enabledGroupings(): MenuItem[] {
      if (this.isLimited) {
        return this.metricGroupings.filter((item) => {
          const grouping = this.allGroupings.find((g) => menuItemKey(g) === item.key);
          return grouping && grouping.enabled_for_limited !== false;
        });
      } else {
        return this.metricGroupings;
      }
    },
    options() {
      const groupingsByCategory = _.groupBy(this.enabledGroupings, "category");

      return Object.keys(groupingsByCategory).map((category) => {
        return {
          category,
          categoryLabel: this.translateCategory(category),
          groupings: groupingsByCategory[category].map((grouping) => ({
            label: this.translateGrouping(menuItemKey(grouping)),
            value: menuItemKey(grouping),
            category,
          })),
        };
      });
    },
  },
  watch: {
    metric: [{ immediate: true, handler: "fetchDrilldownOptions" }],
    selectedDrilldown: [{ immediate: true, handler: "onSelectedDrilldownChange" }],
    dropdownOpen: [
      {
        handler: "onDropdownOpenChanged",
      },
    ],
  },
  methods: {
    showDrilldownOptions() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    applyDrilldown() {
      this.drilldownData.selected = this.drilldownModel;
      this.drilldownData.userChangedDrilldown = true;
      this.dropdownOpen = !this.dropdownOpen;
    },
    saveDrilldown() {
      this.drilldownData.selected = this.drilldownModel;
      this.drilldownData.userChangedDrilldown = true;
      this.dropdownOpen = !this.dropdownOpen;

      return makeApiInstance().get(
        `/api/v1/edit_drilldowns/?metric=${this.metric}&c_grouping=${this.currentGrouping}&grouping=${this.selectedDrilldown?.value}`
      );
    },
    translateGrouping(grouping) {
      if (grouping) {
        return i18n.t(`filter.config.${grouping}`);
      } else {
        return "";
      }
    },
    translateCategory(category) {
      if (category) {
        return i18n.t(`metadata.category.${category}`);
      } else {
        return "";
      }
    },
    fetchDrilldownOptions() {
      if (!(this.metric?.length > 0)) {
        return;
      }
      return makeApiInstance()
        .get(`/api/v1/groupings/${this.metric}`)
        .then((response) => {
          this.metricGroupings = _.flatten(response.data);
        });
    },
    onSelectedDrilldownChange() {
      this.drilldownModel = this.selectedDrilldown;
    },
    onDropdownOpenChanged() {
      const rect = this.$el.getBoundingClientRect();
      this.rightAlignDrilldownOptions = rect.x + rect.width / 2 > window.innerWidth / 2;
    },
  },
});
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.drilldown-options {
  position: absolute;
  padding: 10px;
  z-index: 99;
  top: 100%;
  margin-top: 2px;
  background-color: white;
  border: 1px solid white;
  border-radius: var(--dropdown-border-radius);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  &.right-align {
    right: 0px;
  }
}

.drilldown-wrapper {
  position: relative;
  z-index: 3;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.drilldown-button {
  background-color: transparent;
  border: none;
  float: right;
  outline: 0px !important;
  appearance: none;
  -webkit-appearance: none;
}

.selected-drilldown {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.drilldown-button i {
  font-size: 20px;
  margin-top: 6px;
  color: rgba(0, 0, 0, 0.2);
}

.drilldown-button i:hover {
  color: #4bcdf0;
}

.drilldown-icon {
  margin-left: 5px;
}

.info-icon {
  color: #ccc;
  margin-bottom: 10px;
}

.drilldown-info {
  background-color: #3d3d3d;
  color: #fff;
  position: absolute;
  z-index: 1000;
  padding: 5px;
  transform: translate(-50%);
  border-radius: 4px;
  margin-top: 4px;
  font-size: 12px;
  text-align: center;
}

.selector {
  min-width: 250px;
  margin-bottom: 10px;
}
</style>
