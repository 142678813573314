<template>
  <div v-uiv-tooltip.bottom="title" class="navbar-title">
    <span class="print-hidden">{{ title }}</span
    ><span class="visible-print">{{ printTitle }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  computed: {
    title() {
      return this.$route.meta?.title || this.$store.getters.pageTitle;
    },
    printTitle() {
      return [this.title, window.zoinedContext.company?.name].filter(Boolean).join(" - ");
    },
  },
});
</script>

<style lang="scss" scoped>
.navbar-title {
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media print {
    font-size: 14pt;
    font-weight: 400;
  }
}
</style>
