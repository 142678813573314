<template>
  <div class="insight-chart">
    <zoined-chart
      type="insight"
      :title="chartTitle"
      :component="component"
      :clean-update="true"
      :filters="chartFilters"
      :chart-options="chartOptions"
      :chart-data="overriddenChartData"
      :pagination="true"
      :drilldown="false"
      @select="onSelect"
      @data="onData"
      @update-limit="limit = $event"
      @loading="selectedItem = null"
    ></zoined-chart>
    <div v-if="selectedItem" class="detail-chart">
      <zoined-chart
        type="insight"
        :title="detailChartTitle"
        :filters="detailChartFilters"
        :chart-options="chartOptions"
        :chart-data="overriddenDetailChartData"
        :limit="true"
        :drilldown="false"
        @data="onDetailData"
        @update-limit="detailChartLimit = $event"
      ></zoined-chart>
    </div>
  </div>
</template>
<script lang="ts">
import _ from "lodash";
import zoinedChart from "../zoined-chart/zoined-chart.vue";
import { defineComponent } from "vue";
const insightsSelectedColor = "#81E4FF";
import i18n from "@/i18n";

export default defineComponent({
  components: {
    zoinedChart,
  },
  props: {
    component: { type: Object, required: true },
    filters: { type: Object, required: true },
    chartOptions: { type: Object, required: true },
  },
  emits: ["update"],
  data() {
    return {
      selectedItem: null,
      chartData: null,
      detailChartData: null,
    };
  },
  computed: {
    limit: {
      get() {
        return this.filters.limit || 5;
      },
      set(limit) {
        this.$emit("update", { limit });
      },
    },
    detailChartLimit: {
      get() {
        return this.filters.detail_chart_limit || 5;
      },
      set(detail_chart_limit) {
        this.$emit("update", { detail_chart_limit });
      },
    },
    chartTitle() {
      return this.firstChartItem && this.insightItemTitle(this.firstChartItem);
    },
    showDetailChart() {
      return !!this.detailChartType;
    },
    detailChartTitle() {
      const item = _.get(this.detailChartData, "series.0.data.0");
      return item && this.insightItemTitle(item);
    },
    chartFilters() {
      const filter = { ...this.filters, limit: this.limit };

      delete filter.detail_chart_limit;

      return filter;
    },
    detailChartFilters() {
      const filter = { ...this.filters, limit: this.detailChartLimit };

      if (this.firstChartItem && this.selectedItem) {
        const { id, dimension } = this.selectedItem;

        // Set grouping and dimensions
        filter.grouping = [dimension];
        filter.dimensions = _.without(filter.dimensions, dimension);

        // Set filters
        filter[dimension] = [id];
        if (this.firstChartItem != this.selectedItem) {
          filter[this.firstChartItem.dimension] = [this.firstChartItem.id];
        }
      }

      return filter;
    },
    overriddenChartData() {
      if (this.chartData) {
        return {
          ...this.chartData,
          series: this.chartData.series.map((series) => ({
            ...series,
            data: series.data.map((item) => ({
              ...item,
              color:
                this.selectedItem && this.selectedItem.id === item.id && this.selectedItem.dimension === item.dimension
                  ? insightsSelectedColor
                  : item.color,
            })),
          })),
        };
      }
      return null;
    },
    overriddenDetailChartData() {
      if (this.detailChartData) {
        return {
          ...this.detailChartData,
          series: this.detailChartData.series.map((series) => ({
            ...series,
            data: series.data.map((item, i) => ({
              ...item,
              color: i === 0 ? insightsSelectedColor : item.color,
            })),
          })),
          // Same yAxis configuration (min, max) as with main chart
          yAxis: this.chartData.yAxis,
        };
      }
      return null;
    },
    firstChartItem() {
      return _.get(this.chartData, "series.0.data.0");
    },
  },
  methods: {
    insightItemTitle(item) {
      const dimensionName = i18n.t(`filter.config.${item.dimension}`);
      return `${dimensionName} / ${item.name}`;
    },
    onSelect(item) {
      this.selectedItem = item;
    },
    onData(data) {
      this.chartData = data;
      this.onSelect(_.get(this.chartData, "series.0.data.1"));
    },
    onDetailData(data) {
      this.detailChartData = data;
    },
  },
});
</script>

<style lang="scss" scoped>
.insight-chart {
  margin-top: 5px;
}
.chart-title {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
.paging {
  margin-right: 10px;
}
</style>
