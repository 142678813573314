<template>
  <dropdown-select :value="modelValue" :available-items="sortOptions" @update="emit('update:modelValue', $event)">
    <template #toggle="slotProps">
      <dropdown-select-button :menu-open="slotProps.menuOpen" @click="slotProps.toggleMenu()">
        <i class="fa mr-md fa-sort-amount-desc" />
        <span class="hidden-xs">{{ sortLabel }}</span>
      </dropdown-select-button>
    </template>
  </dropdown-select>
</template>

<script setup lang="ts">
import { computed } from "vue";
import MenuItem, { menuItemKey } from "@/interfaces/menu-item";
import { useI18n } from "@/composables/i18n";
import DropdownSelect from "@/components/dropdown-select.vue";
import DropdownSelectButton from "@/components/dropdown-select-button.vue";

const { t } = useI18n();

const props = defineProps<{
  modelValue: string;
}>();

const emit = defineEmits(["update:modelValue"]);

const sortOptions: MenuItem[] = [
  { key: "balanced", name: t("components.trend_sort_selector.balanced_sort") },
  { key: "trend", name: t("components.trend_sort_selector.trend_sort") },
  { key: "absolute", name: t("components.trend_sort_selector.absolute_change_sort") },
];

const sortLabel = computed(() => {
  return sortOptions.find((sort) => menuItemKey(sort) === props.modelValue)?.name;
});
</script>
