<template>
  <div>
    <div id="layout-container" class="custom-dashboard-editor">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="name">{{ $t("dashboard_custom.report_editor.title") }}</label>
            <input id="name" v-model="report.metadata.title" type="text" />
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <label for="description">{{ $t("dashboard_custom.report_editor.description") }}</label>
            <textarea id="description" v-model="report.metadata.description"></textarea>
          </div>
        </div>
      </div>
      <div v-if="isAdmin || isTeamAdmin" class="row">
        <div class="col-md-12">
          <report-owner-selector :my-report="myReport" @update-my-report="updateMyReport"></report-owner-selector>
        </div>
      </div>
      <div v-if="companyReport" class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div>
              <label for="restricted">{{ $t("activerecord.attributes.my_report.restrict_access") }}</label>
            </div>
            <restrict-access-selector v-model="myReport.restrict_access" />
          </div>
        </div>
      </div>
      <div v-if="companyReport && myReport.restrict_access" class="row">
        <div class="col-md-12">
          <user-role-pill-list v-model="myReport.my_report_accesses"></user-role-pill-list>
          <team-pill-list v-model="myReport.my_report_accesses"></team-pill-list>
        </div>
      </div>
      <div v-if="partnerReport" class="row">
        <div class="col-md-12">
          <visible-to-customers-selector v-model="myReport.visible_to_customers"></visible-to-customers-selector>
        </div>
      </div>
      <div v-if="partnerReport && myReport.visible_to_customers == 'selected'" class="row">
        <div class="col-md-12">
          <company-my-report-access-editor v-model="myReport.my_report_accesses"></company-my-report-access-editor>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>{{ $t("dashboard_custom.report_editor.filters") }}</h4>
        </div>
      </div>
      <div class="row time-filters">
        <div class="col-md-12 flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-lg">
          <time-period-dropdown
            :available-items="timeRanges"
            :available-series-types="seriesTypes"
            :value="filterConfiguration.time.selection"
            @update="updateSelection($event)"
          ></time-period-dropdown>
          <comparison-pill-list
            :comparisons="filterConfiguration.time.comparisons"
            @update="updateComparisons"
          ></comparison-pill-list>
          <business-hours-selector
            :value="filterConfiguration.time.limit_to_business_hours"
            @update="updateBusinessHours($event)"
          ></business-hours-selector>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-lg">
          <filterset-selector :config="filterConfiguration.filters" @update="updateFiltersConfig"></filterset-selector>
          <show-filterset-name-toggle
            v-if="hasEnabledFilterSets"
            v-model="myReport.control_state.show_filterset_name"
          ></show-filterset-name-toggle>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div
            class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-lg intercom-tag-create-report-filters-section"
          >
            <filter-selector
              v-for="filter in filterOptions"
              :key="filter.id"
              :config="filters[filter.id]"
              :filter="filter"
              @update="updateFilter(filter.id, $event)"
            ></filter-selector>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>{{ $t("dashboard_custom.report_editor.layout") }}</h4>
        </div>
      </div>
      <dashboard-components-grid-editor
        :filter-configuration="filterConfiguration"
        :config="report.config"
        :chart-options="myReport.control_state"
        :shared="companyReport || partnerReport"
        @update="report.config = $event"
      ></dashboard-components-grid-editor>
      <div class="row buttons">
        <div class="col-md-12">
          <div class="pull-right">
            <button
              class="pull-right btn btn-primary intercom-tag-save-report-button"
              :disabled="submitDisabled"
              @click="save"
            >
              {{ $t("dashboard_custom.report_editor.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import _ from "lodash";
import { refreshFilterTimes, convertToNewFilterConfiguration, mergeFilterConfigurations } from "../lib/filter-util";
import userRolePillList from "../components/user-role-pill-list.vue";
import teamPillList from "../components/team-pill-list.vue";
import comparisonPillList from "../components/comparison-pill-list.vue";
import filterSelector from "../components/filter-selector.vue";
import timePeriodDropdown from "../components/time-period-dropdown.vue";
import businessHoursSelector from "../components/business-hours-selector.vue";
import filtersetSelector from "../components/filterset-selector.vue";
import showFiltersetNameToggle from "../components/show-filterset-name-toggle.vue";
import DashboardComponentsGridEditor from "./dashboard-components-grid-editor.vue";
import FilterConfigurationItemMap from "../model/filter-configuration-item-map";
import MyReport from "../model/my-report";
import ReportOwnerSelector from "../components/report-owner-selector.vue";
import Actions from "../store/actions";
import i18n from "../i18n";
import { makeApiInstance } from "../api/instance";
import { hideOverlay, showOverlay } from "../ui/overlay";
import VisibleToCustomersSelector from "@/components/visible-to-customers-selector.vue";
import CompanyMyReportAccessEditor from "@/components/my-report-access/company-my-report-access-editor.vue";
import RestrictAccessSelector from "@/components/restrict-access-selector.vue";

export default defineComponent({
  components: {
    userRolePillList,
    teamPillList,
    comparisonPillList,
    filterSelector,
    timePeriodDropdown,
    businessHoursSelector,
    filtersetSelector,
    showFiltersetNameToggle,
    DashboardComponentsGridEditor,
    ReportOwnerSelector,
    VisibleToCustomersSelector,
    CompanyMyReportAccessEditor,
    RestrictAccessSelector,
  },
  props: {
    defaultReport: {
      type: Object as PropType<any>,
      required: true,
    },
    defaultMyReport: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  data() {
    const isAdmin: boolean = null;
    const myReport: any = null;
    const report: any = null;

    return {
      report,
      myReport,
      isAdmin,
      initialData: null,
    };
  },
  computed: {
    companyReport() {
      return !this.myReport.user_id && !this.myReport.team_id && !this.myReport.partner_id;
    },
    partnerReport() {
      return !!this.myReport.partner_id;
    },
    teamsAsAdmin() {
      return window.zoinedContext.current_role?.team_memberships.filter(({ role }) => role === "admin") || [];
    },
    isTeamAdmin() {
      return this.teamsAsAdmin.length > 0;
    },
    submitDisabled() {
      return this.report.metadata.title.length == 0 || this.report.config.components.length == 0;
    },
    filterConfiguration() {
      return _.cloneDeep(this.myReport.filters);
    },
    timeRanges() {
      return this.$store.state.parameters.timePeriods.all || [];
    },
    seriesTypes() {
      return window.zoinedContext?.budgets && ["actual", ...Object.keys(window.zoinedContext.budgets)];
    },
    filterOptions() {
      return this.$store.state.parameters.filters.all || [];
    },
    filters() {
      return _.reduce(
        this.filterConfiguration.filters.filters,
        (result, config, filter) => {
          return {
            ...result,
            [filter]: _.fromPairs(
              _.map(config, ({ enabled, exclude, value, name }, key) => [
                key,
                {
                  value: key,
                  enabled,
                  exclude: !!exclude,
                  name: name || value || key,
                },
              ])
            ),
          };
        },
        {}
      );
    },
    hasEnabledFilterSets() {
      let { sets } = this.filterConfiguration.filters;
      sets = sets || [];
      return sets.filter(({ enabled }) => enabled).length > 0;
    },
    saveData() {
      return _.cloneDeep({
        report: {
          ...this.report,
          config: JSON.stringify(this.report.config),
        },
        my_report: {
          dashboard: true,
          restrict_access: this.myReport.restrict_access,
          visible_to_customers: this.myReport.visible_to_customers,
          control_state: this.myReport.control_state,
          my_report_accesses: this.myReport.my_report_accesses,
          user_id: this.myReport.user_id,
          team_id: this.myReport.team_id,
          partner_id: this.myReport.partner_id,
          company_id: this.myReport.company_id,
          filters: this.filterConfiguration,
        },
      });
    },
    hasChanges() {
      return this.initialData && !_.isEqual(this.initialData, this.saveData);
    },
  },
  created() {
    const report = _.cloneDeep(this.defaultReport);
    const myReport = _.cloneDeep(this.defaultMyReport);

    const defaultConfig = {
      selection: { type: "4_weeks" },
      comparisons: [{ type: "prev_year_corresponding", enabled: true }],
    };
    const filterConfigurations = _.compact([
      defaultConfig,
      report.config.filterConfiguration,
      myReport.filters,
    ]).map((config) => convertToNewFilterConfiguration(config));
    myReport.filters = mergeFilterConfigurations(...filterConfigurations);
    myReport.filters.time = refreshFilterTimes(myReport.filters.time);
    delete report.config.filterConfiguration;

    report.config.default = {};
    report.config.components = report.config.components || [];

    myReport.restrict_access = !!myReport.restrict_access;
    myReport.control_state = myReport.control_state || {};

    this.report = report;
    this.myReport = myReport;
    this.isAdmin = !!window.zoinedContext?.isAdmin;

    this.initialData = this.saveData;
  },
  methods: {
    setRestricted(restricted) {
      this.myReport.restrict_access = restricted;
    },
    updateSelection(selection) {
      this.myReport.filters.time.selection = selection;
    },
    updateComparisons(comparisons) {
      this.myReport.filters.time.comparisons = comparisons;
    },
    updateBusinessHours(limitToBusinessHours) {
      this.myReport.filters.time.limit_to_business_hours = limitToBusinessHours;
    },
    updateFiltersConfig(config) {
      this.myReport.filters.filters = config;
    },
    updateFilter(filter, filterConfig: FilterConfigurationItemMap) {
      if (!_.isEmpty(filterConfig)) {
        const config = _.reduce(
          _.values(filterConfig),
          (result, { enabled, exclude, value, name, wildcard }) => ({
            ...result,
            [value]: { enabled, exclude: !!exclude, value, wildcard, name: name || value },
          }),
          {}
        );
        this.myReport.filters.filters.filters[filter] = config;
      } else {
        delete this.myReport.filters.filters.filters[filter];
      }
    },
    updateMyReport(myReport: MyReport) {
      this.myReport = myReport;
    },
    save() {
      showOverlay(i18n.t("please_wait"));
      const url = this.report.id ? `/api/v1/reports/${this.report.id}` : `/api/v1/reports`;
      const method = this.report.id ? "PUT" : "POST";
      const data = (this.initialData = this.saveData);
      return makeApiInstance()
        .request({
          url,
          method,
          data,
        })
        .then((response) => response.data)
        .then(({ id }) => {
          this.$router.push({ name: "custom_dashboard", params: { id } });
          this.$store.dispatch(Actions.fetchNavigation);
        })
        .finally(() => {
          hideOverlay();
        });
    },
  },
});
</script>

<style scoped lang="scss">
#layout-container {
  margin-bottom: 8rem; // need to have space for the Olark-tab in bottom of the page
}

.buttons {
  margin-top: 15px;
}

.show {
  text-align: center;

  a:hover {
    cursor: pointer;
  }
}

.time-filters {
  margin-bottom: 10px;
}

.row h4 {
  margin: 20px 0;
}
</style>
