<template>
  <span class="tranlation-modal">
    <modal v-model="open" :title="title">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>{{ $t("company_admin.configuration.label.locale") }}</th>
            <th>{{ $t("company_admin.configuration.label.current") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="language in languages" :key="language.id">
            <td>{{ language.name }} {{ language.id }}</td>
            <td>
              <input v-model="model[language.id]" />
            </td>
          </tr>
        </tbody>
      </table>
      <template #footer>
        <button class="btn btn-default" @click="open = false">{{ $t("actions.cancel") }}</button>
        <button class="btn btn-primary" @click="update">{{ $t("actions.save") }}</button>
      </template>
    </modal>
    <a @click="openModal()"><slot></slot></a
  ></span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Modal } from "uiv";
import { makeApiInstance } from "../api/instance";

export default defineComponent({
  components: { Modal },
  props: {
    title: { type: String, required: true },
    config: { type: Object, default: null },
  },
  emits: ["update"],
  data() {
    return {
      open: false,
      languages: null,
      model: {},
    };
  },
  created() {
    this.fetchLanguages();
  },
  methods: {
    openModal() {
      this.open = true;
      this.model = { ...this.config };
    },
    update() {
      this.open = false;
      this.$emit("update", {
        ...this.model,
      });
    },
    fetchLanguages() {
      makeApiInstance()
        .get(`/api/v1/parameters/available_locales`)
        .then((response) => response.data)
        .then((languages) => (this.languages = languages.filter(({ id }) => id && id.length > 0)));
    },
  },
});
</script>
