<template>
  <div v-if="loaded" class="edit-custom-dashboard-page">
    <div class="mt-md"></div>
    <custom-dashboard-editor
      ref="editor"
      :default-report="report"
      :default-my-report="myReport"
    ></custom-dashboard-editor>
  </div>
  <spinner v-else></spinner>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import spinner from "../components/spinner.vue";
import i18n from "../i18n";
import CustomDashboardEditor from "./custom-dashboard-editor.vue";
import Report from "../model/report";
import MyReport from "../model/my-report";
import { makeApiInstance } from "../api/instance";
import ReportContext from "../model/report-context";
import Mutations from "../store/mutations";
import _ from "lodash";
import FilterConfiguration from "../model/filter-configuration";
import ChartOptions from "../model/chart-options";
import TableConfig from "../model/table-config";

export default defineComponent({
  components: {
    spinner,
    CustomDashboardEditor,
  },
  beforeRouteUpdate(to, from, next) {
    this.handleRouteChange(next);
  },
  beforeRouteLeave(to, from, next) {
    this.handleRouteChange(next);
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    templateId: {
      type: String,
      default: null,
    },
    duplicateId: {
      type: String,
      default: null,
    },
    config: {
      type: Object as PropType<{
        filter_configuration?: FilterConfiguration;
        chart_options?: ChartOptions;
        table_config?: TableConfig;
      }>,
      default: null,
    },
  },
  data() {
    const myReport: MyReport = null;
    const report: Report = null;

    return {
      report,
      myReport,
    };
  },
  computed: {
    title() {
      if (this.id) {
        return this.myReport.name;
      } else {
        return i18n.t("dashboard_custom.new_dashboard");
      }
    },
    loaded() {
      return this.myReport && this.report;
    },
  },
  async created() {
    if (this.id) {
      const reportContext: ReportContext = await makeApiInstance()
        .get("/api/v1/reports/report_context", { params: { report_id: this.id } })
        .then((response) => response.data);
      this.report = reportContext.report;
      this.myReport = reportContext.my_report;
    } else if (this.duplicateId) {
      const reportContext: ReportContext = await makeApiInstance()
        .get("/api/v1/reports/report_context", { params: { report_id: this.duplicateId } })
        .then((response) => response.data);
      this.report = _.pick(reportContext.report, "metadata", "config") as Report;
      this.report.metadata.title = `Copy of ${this.report.metadata.title}`;
      this.myReport = {
        dashboard: true,
        user_id: window.zoinedContext.userId,
      } as MyReport;
    } else if (this.templateId) {
      const { title, config } = await makeApiInstance()
        .get(`/api/v1/analytics_templates/${this.templateId}`)
        .then((response) => response.data);
      this.report = {
        metadata: {
          title,
        },
        config,
      } as Report;
      this.myReport = {
        dashboard: true,
        user_id: window.zoinedContext.userId,
      } as MyReport;
    } else {
      this.report = {
        metadata: {
          title: "",
        },
        config: {
          components: [],
        },
      } as Report;
      this.myReport = {
        dashboard: true,
        user_id: window.zoinedContext.userId,
      } as MyReport;
    }

    // Overrides
    if (this.config?.filter_configuration) {
      this.myReport.filters = this.config.filter_configuration;
    }
    if (this.config?.chart_options) {
      this.myReport.control_state = this.config.chart_options;
    }

    this.$store.commit(Mutations.setTitle, this.title);
  },
  methods: {
    beforeRouteUpdate(to, from, next) {
      this.handleRouteChange(next);
    },
    beforeRouteLeave(to, from, next) {
      this.handleRouteChange(next);
    },
    handleRouteChange(next) {
      let confirmed = true;

      if ((this.$refs.editor as typeof CustomDashboardEditor)?.hasChanges) {
        confirmed = confirm(i18n.t("confirm.unsaved_changes_confirmation"));
      }

      if (confirmed) {
        this.$store.commit(Mutations.clearTitle);
        next();
      } else {
        next(false);
      }
    },
  },
});
</script>
