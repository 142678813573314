<template>
  <div class="search-bar">
    <Icon icon="fluent:search-16-regular" class="text-base search-icon" />
    <input :value="modelValue" :placeholder="placeholder" @input="onInput" />
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import { defineComponent } from "vue";
import { Icon } from "@iconify/vue";
import i18n from "@/i18n";

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: i18n.t("actions.search"),
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      onInput: _.debounce((event) => {
        this.$emit("update:modelValue", event.target.value);
      }, 400),
    };
  },
});
</script>

<style lang="scss" scoped>
.search-bar {
  position: relative;
  height: 44px;
  width: 300px;
  padding: 14px;
  color: var(--color-text-variant);
  transition: all 0.2s;

  &:hover {
    color: var(--color-text-primary);
  }

  .search-icon {
    position: relative;
    z-index: 1;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px 16px 12px 44px;
    background-color: var(--color-interactive-fill-neutral-low-contrast);
    border: 1px solid var(--color-interactive-fill-neutral-low-contrast);
    color: var(--color-text-primary);
    border-radius: 4px;
    outline: none;
    transition: all 0.2s;

    &::placeholder {
      color: var(--color-text-variant);
    }

    &:hover {
      background-color: var(--color-interactive-fill-neutral-mid-contrast);
      border: 1px solid var(--color-interactive-fill-neutral-mid-contrast);

      &::placeholder {
        color: var(--color-text-primary);
      }
    }

    &:focus {
      background-color: var(--color-interactive-fill-neutral-low-contrast);
      border: 1px solid var(--color-border-emphasis);

      &::placeholder {
        color: var(--color-text-primary);
      }
    }
  }
}
</style>
