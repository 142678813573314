<template>
  <div class="section">
    <div class="thumbnail">
      <div class="section-type-image" :class="section.type.id"></div>
    </div>
    <div class="configuration">
      <h3>{{ section.type.title }}</h3>
      <div v-if="section.errors" class="alert alert-danger">
        <div v-for="(errors, key) in section.errors" :key="key">{{ errors.join(" ") }}</div>
      </div>
      <div class="fields">
        <metricComparisonSection
          v-if="section.type.id == 'metric_comparison'"
          :section="section"
          :newsletter-type="newsletterType"
          :time-periods="timePeriods"
          :series-types="seriesTypes"
          :opening-hours-defined="openingHoursDefined"
          :lfl-enabled="lflEnabled"
          :insights-visible="insightsVisible"
          @update-section="updateSection"
        ></metricComparisonSection>
        <topSection
          v-if="section.type.id == 'top'"
          :section="section"
          :newsletter-type="newsletterType"
          :time-periods="timePeriods"
          :series-types="seriesTypes"
          :opening-hours-defined="openingHoursDefined"
          :lfl-enabled="lflEnabled"
          :insights-visible="insightsVisible"
          @update-section="updateSection"
        ></topSection>
        <metricExtremesSection
          v-if="section.type.id == 'metric_extremes'"
          :section="section"
          @update-section="updateSection"
        ></metricExtremesSection>
        <timeSeriesSection
          v-if="section.type.id == 'time_series'"
          :section="section"
          :newsletter-type="newsletterType"
          :time-periods="timePeriods"
          :series-types="seriesTypes"
          :opening-hours-defined="openingHoursDefined"
          :lfl-enabled="lflEnabled"
          :insights-visible="insightsVisible"
          @update-section="updateSection"
        ></timeSeriesSection>
        <reportAttachmentSection
          v-if="section.type.id == 'report_attachment'"
          :section="section"
          @update-section="updateSection"
        ></reportAttachmentSection>
        <pdfAttachmentSection
          v-if="section.type.id == 'pdf_attachment'"
          :section="section"
          @update-section="updateSection"
        ></pdfAttachmentSection>
        <sokProvisionSection
          v-if="section.type.id == 'sok_provision'"
          :section="section"
          :time-periods="timePeriods"
          :series-types="seriesTypes"
          @update-section="updateSection"
        ></sokProvisionSection>
      </div>
      <div class="section-filters">
        <filtersDisplay :config="section.filters"></filtersDisplay>
        <button class="btn btn-primary" @click="editFilters()">
          {{ $t("newsletter.definition.actions.edit_filter") }}
        </button>
      </div>
    </div>
    <div>
      <i class="fa fa-files-o" @click="duplicateSection()"></i>
      &nbsp;
      <i class="fa fa-times" @click="removeSection()"></i>
    </div>

    <newsletter-section-filters-modal
      ref="newsletterSectionFiltersModal"
      :filters="section.filters"
      @update="updateFilters"
    ></newsletter-section-filters-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import metricComparisonSection from "./sections/metric-comparison-section.vue";
import topSection from "./sections/top-section.vue";
import metricExtremesSection from "./sections/metric-extremes-section.vue";
import timeSeriesSection from "./sections/time-series-section.vue";
import reportAttachmentSection from "./sections/report-attachment-section.vue";
import pdfAttachmentSection from "./sections/pdf-attachment-section.vue";
import sokProvisionSection from "./sections/sok-provision-section.vue";
import filtersDisplay from "../components/filters-display.vue";
import MenuItem from "../interfaces/menu-item";
import NewsletterSectionFiltersModal from "./newsletter-section-filters-modal.vue";

export default defineComponent({
  components: {
    metricComparisonSection,
    topSection,
    metricExtremesSection,
    timeSeriesSection,
    reportAttachmentSection,
    pdfAttachmentSection,
    sokProvisionSection,
    filtersDisplay,
    NewsletterSectionFiltersModal,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    newsletterType: {
      type: String,
      required: true,
    },
    timePeriods: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
  },
  emits: ["update", "remove", "duplicate"],
  computed: {
    zoinedContext() {
      return window.zoinedContext;
    },
    lflEnabled() {
      return !!this.zoinedContext.features.lfl;
    },
    insightsVisible() {
      return !!this.zoinedContext.features.insights;
    },
    openingHoursDefined() {
      return !!this.zoinedContext.openingHoursDefined;
    },
    seriesTypes() {
      return this.zoinedContext.budgets && ["actual", ...Object.keys(this.zoinedContext.budgets)];
    },
  },
  methods: {
    editFilters() {
      (this.$refs.newsletterSectionFiltersModal as typeof NewsletterSectionFiltersModal).show();
    },
    removeSection() {
      this.$emit("remove", this.section);
    },
    duplicateSection() {
      this.$emit("duplicate", this.section);
    },
    updateSection(config) {
      this.$emit("update", this.section, config);
    },
    updateFilters(filters) {
      this.$emit("update", this.section, {
        ...this.section,
        filters,
      });
    },
  },
});
</script>
