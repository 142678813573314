<template>
  <div class="btn btn-sm" :class="[buttonStyleClass, menuOpen && 'menu-open']">
    <slot></slot><i class="fa ml-sm" :class="[menuOpen ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    menuOpen: {
      type: Boolean,
    },
    buttonStyle: { default: "primary-inverted", type: String },
  },
  computed: {
    buttonStyleClass() {
      return `btn-${this.buttonStyle}`;
    },
  },
});
</script>
