<template>
  <div>
    <div id="layout-container" class="report-editor">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="name">{{ $t("dashboard_custom.report_editor.title") }}</label>
            <input id="name" v-model="myReport.name" type="text" />
          </div>
        </div>
      </div>
      <div v-if="isAdmin || isTeamAdmin" class="row">
        <div class="col-md-12">
          <report-owner-selector :my-report="myReport" @update-my-report="updateMyReport"></report-owner-selector>
        </div>
      </div>
      <div v-if="companyReport" class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div>
              <label for="restricted">{{ $t("activerecord.attributes.my_report.restrict_access") }}</label>
            </div>
            <restrict-access-selector v-model="myReport.restrict_access" />
          </div>
        </div>
      </div>
      <div v-if="companyReport && myReport.restrict_access" class="row">
        <div class="col-md-12 flex-row align-items-center flex-wrap gap-md column-gap-xxl">
          <user-role-pill-list v-model="myReport.my_report_accesses"></user-role-pill-list>
          <team-pill-list v-model="myReport.my_report_accesses"></team-pill-list>
        </div>
      </div>
      <div v-if="partnerReport" class="row">
        <div class="col-md-12">
          <visible-to-customers-selector v-model="myReport.visible_to_customers"></visible-to-customers-selector>
        </div>
      </div>
      <div v-if="partnerReport && myReport.visible_to_customers == 'selected'" class="row">
        <div class="col-md-12">
          <company-my-report-access-editor v-model="myReport.my_report_accesses"></company-my-report-access-editor>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>{{ $t("dashboard_custom.report_editor.filters") }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="time-filters flex-row align-items-center flex-wrap gap-md column-gap-xxl mb-md">
            <time-period-dropdown
              v-if="isFilterEnabled('date')"
              :available-items="options.time_ranges"
              :available-series-types="options.seriesTypes"
              :value="filterConfiguration.time.selection"
              @update="updateSelection($event)"
            ></time-period-dropdown>
            <comparison-pill-list
              v-if="isFilterEnabled('comparisons')"
              :comparisons="filterConfiguration.time.comparisons"
              @update="updateComparisons"
            ></comparison-pill-list>
            <business-hours-selector
              v-if="isFilterEnabled('limit_to_business_hours')"
              :value="filterConfiguration.time.limit_to_business_hours"
              @update="updateBusinessHours($event)"
            ></business-hours-selector>
          </div>
          <widget-filters-section
            :filter-configuration="filterConfiguration"
            :report-config="report.config"
            @update="updateFilterConfiguration"
          ></widget-filters-section>
          <hr class="mt-0" />
          <div v-if="isFilterEnabled('basket_filters')">
            <basket-filters-section
              :model-value="filterConfiguration.basket_filters"
              :items="options.filters"
              @update:model-value="updateBasketFilters"
            ></basket-filters-section>
            <hr class="mt-0" />
          </div>
          <div class="flex-row align-items-center flex-wrap gap-md column-gap-xxl mb-md">
            <filterset-selector
              :config="filterConfiguration.filters"
              @update="updateFiltersConfig"
            ></filterset-selector>
            <show-filterset-name-toggle
              v-if="hasEnabledFilterSets"
              v-model="myReport.control_state.show_filterset_name"
            ></show-filterset-name-toggle>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div
            class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl mb-md intercom-tag-create-report-filters-section"
          >
            <filter-selector
              v-for="filter in options.filters"
              :key="filter.id"
              :config="filters[filter.id]"
              :filter="filter"
              @update="updateFilter(filter.id, $event)"
            ></filter-selector>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>{{ $t("actions.preview") }}</h4>
        </div>
      </div>
      <div v-for="(component, index) in report.config.components" :key="index" class="row mb-md">
        <div :class="'col-md-' + (component.span || 12)">
          <zoined-report-component
            :component="component"
            :filter-configuration="flatFilterConfiguration"
            :chart-options="chartOptions"
            :table-config="tableConfig"
            :preview="true"
            @filter-configuration-updated="updateFilterConfiguration"
            @chart-options-updated="updateChartOptions"
            @table-config-updated="updateTableConfig"
          ></zoined-report-component>
        </div>
      </div>
      <div class="row buttons">
        <div class="col-md-12">
          <div class="pull-right">
            <button
              class="pull-right btn btn-primary intercom-tag-save-report-button"
              :disabled="submitDisabled"
              @click="save"
            >
              {{ $t("actions.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import _ from "lodash";
import { refreshFilterTimes, convertToNewFilterConfiguration, flatFilterConfiguration } from "../lib/filter-util";
import userRolePillList from "../components/user-role-pill-list.vue";
import teamPillList from "../components/team-pill-list.vue";
import comparisonPillList from "../components/comparison-pill-list.vue";
import filterSelector from "../components/filter-selector.vue";
import timePeriodDropdown from "../components/time-period-dropdown.vue";
import businessHoursSelector from "../components/business-hours-selector.vue";
import filtersetSelector from "../components/filterset-selector.vue";
import showFiltersetNameToggle from "../components/show-filterset-name-toggle.vue";
import FilterConfigurationItemMap from "../model/filter-configuration-item-map";
import zoinedReportComponent from "../analytics/zoined-report-component.vue";
import WidgetFiltersSection from "../flyover-filters/widget-filters-section.vue";
import FilterConfiguration from "../model/filter-configuration";
import i18n from "../i18n";
import ReportOwnerSelector from "../components/report-owner-selector.vue";
import MyReport from "../model/my-report";
import AnalyticsApiService from "../api/analytics-api-service";
import Actions from "../store/actions";
import ChartOptions from "../model/chart-options";
import TableConfig from "../model/table-config";
import { showOverlay, hideOverlay } from "../ui/overlay";
import BasketFiltersSection from "@/flyover-filters/basket-filters-section.vue";
import VisibleToCustomersSelector from "@/components/visible-to-customers-selector.vue";
import CompanyMyReportAccessEditor from "@/components/my-report-access/company-my-report-access-editor.vue";
import RestrictAccessSelector from "@/components/restrict-access-selector.vue";

export default defineComponent({
  components: {
    userRolePillList,
    teamPillList,
    comparisonPillList,
    filterSelector,
    timePeriodDropdown,
    businessHoursSelector,
    filtersetSelector,
    showFiltersetNameToggle,
    zoinedReportComponent,
    WidgetFiltersSection,
    ReportOwnerSelector,
    BasketFiltersSection,
    VisibleToCustomersSelector,
    CompanyMyReportAccessEditor,
    RestrictAccessSelector,
  },
  props: {
    defaultReport: {
      type: Object as PropType<Report>,
      required: true,
    },
    defaultMyReport: {
      type: Object as PropType<MyReport>,
      required: true,
    },
  },
  data() {
    const isAdmin: boolean = null;
    const myReport = null;
    const report = null;

    return {
      report,
      myReport,
      isAdmin,
      selectedReportType: null,
      initialData: null,
    };
  },
  computed: {
    analyticsApi() {
      return new AnalyticsApiService();
    },
    companyReport() {
      return !this.myReport.user_id && !this.myReport.team_id && !this.myReport.partner_id;
    },
    partnerReport() {
      return !!this.myReport.partner_id;
    },
    teamsAsAdmin() {
      return window.zoinedContext.current_role.team_memberships.filter(({ role }) => role === "admin");
    },
    isTeamAdmin() {
      return this.teamsAsAdmin.length > 0;
    },
    submitDisabled() {
      return this.myReport.name.length == 0;
    },
    flatFilterConfiguration() {
      return flatFilterConfiguration(this.filterConfiguration);
    },
    filterConfiguration() {
      return _.cloneDeep(this.myReport.filters);
    },
    chartOptions() {
      return _.cloneDeep(this.myReport.control_state);
    },
    tableConfig() {
      return _.cloneDeep(this.myReport.table_config);
    },
    options() {
      const sort = this.$store.getters.getParameters("sort") || [];
      const seriesTypes = window.zoinedContext?.budgets && ["actual", ...Object.keys(window.zoinedContext.budgets)];
      const groupings = _.clone(this.$store.getters.getParameters("grouping"));
      const metrics = this.$store.state.parameters.metrics.all;
      const snippets = this.$store.state.parameters.metrics.all?.map((item) => ({ ...item, snippet: true }));
      const filters = this.$store.state.parameters.filters.all;
      const time_ranges = this.$store.state.parameters.timePeriods.all;

      return {
        metrics: metrics || [],
        snippets: snippets || [],
        time_ranges: time_ranges || [],
        filters: filters || [],
        sort,
        seriesTypes,
        groupings,
      };
    },
    filters() {
      return _.reduce(
        this.filterConfiguration.filters.filters,
        (result, config, filter) => {
          return {
            ...result,
            [filter]: _.fromPairs(
              _.map(config, ({ enabled, exclude, value, name }, key) => [
                key,
                {
                  value: key,
                  enabled,
                  exclude: !!exclude,
                  name: name || value || key,
                },
              ])
            ),
          };
        },
        {}
      );
    },
    hasEnabledFilterSets() {
      let { sets } = this.filterConfiguration.filters;
      sets = sets || [];
      return sets.filter(({ enabled }) => enabled).length > 0;
    },
    saveData() {
      return _.cloneDeep({
        name: this.myReport.name,
        report_name: this.report.name,
        filters: this.myReport.filters,
        restrict_access: this.myReport.restrict_access,
        visible_to_customers: this.myReport.visible_to_customers,
        control_state: this.myReport.control_state,
        table_config: this.myReport.table_config,
        my_report_accesses: this.myReport.my_report_accesses,
        user_id: this.myReport.user_id,
        team_id: this.myReport.team_id,
        partner_id: this.myReport.partner_id,
        company_id: this.myReport.company_id,
      }) as MyReport;
    },
    hasChanges() {
      console.log(this.initialData, this.saveData);
      return this.initialData && !_.isEqual(this.initialData, this.saveData);
    },
  },
  created() {
    const myReport = _.cloneDeep(this.defaultMyReport);

    myReport.restrict_access = !!myReport.restrict_access;
    myReport.control_state = myReport.control_state || {};

    myReport.filters = myReport.filters || {};
    myReport.filters = convertToNewFilterConfiguration(myReport.filters);
    myReport.filters.time = refreshFilterTimes(myReport.filters.time);
    myReport.filters.raw_filters = myReport.filters.raw_filters || {};

    this.report = this.defaultReport;
    this.myReport = myReport;
    this.isAdmin = !!window.zoinedContext?.isAdmin;

    this.initialData = this.saveData;
  },
  methods: {
    setRestricted(restricted) {
      this.myReport.restrict_access = restricted;
    },
    isFilterEnabled(filter) {
      return this.report.config.filters?.includes(filter);
    },
    updateSelection(selection) {
      this.myReport.filters.time.selection = selection;
    },
    updateComparisons(comparisons) {
      this.myReport.filters.time.comparisons = comparisons;
    },
    updateBusinessHours(limitToBusinessHours) {
      this.myReport.filters.time.limit_to_business_hours = limitToBusinessHours;
    },
    updateFiltersConfig(config) {
      this.myReport.filters.filters = config;
    },
    updateBasketFilters(config) {
      this.myReport.filters.basket_filters = config;
    },
    updateMyReport(myReport: MyReport) {
      this.myReport = myReport;
    },
    updateFilter(filter, filterConfig: FilterConfigurationItemMap) {
      if (!_.isEmpty(filterConfig)) {
        const config = _.reduce(
          _.values(filterConfig),
          (result, { enabled, exclude, value, name, wildcard }) => ({
            ...result,
            [value]: { enabled, exclude: !!exclude, value, wildcard, name: name || value },
          }),
          {}
        );
        this.myReport.filters.filters.filters[filter] = config;
      } else {
        delete this.myReport.filters.filters.filters[filter];
      }
    },
    updateFilterConfiguration(filterConfiguration: FilterConfiguration) {
      filterConfiguration = convertToNewFilterConfiguration(filterConfiguration);
      this.myReport.filters = filterConfiguration;
    },
    updateChartOptions(chartOptions: ChartOptions) {
      this.myReport.control_state = chartOptions;
    },
    updateTableConfig(tableConfig: TableConfig) {
      this.myReport.table_config = tableConfig;
    },
    save() {
      showOverlay(i18n.t("please_wait"));

      const data = (this.initialData = this.saveData);

      const action = this.myReport.id
        ? this.analyticsApi.update(this.myReport.id, data)
        : this.analyticsApi.create(data);

      return action
        .then(({ report_name, id }) => {
          this.$router.push({ name: "report", params: { name: report_name, myReportId: id.toString() } });
          this.$store.dispatch(Actions.fetchNavigation);
        })
        .finally(() => {
          hideOverlay();
        });
    },
  },
});
</script>

<style scoped lang="scss">
#layout-container {
  margin-bottom: 8rem; // need to have space for the Olark-tab in bottom of the page
}

.buttons {
  margin-top: 30px;
}

.show {
  text-align: center;

  a:hover {
    cursor: pointer;
  }
}

.row h4 {
  margin: 20px 0;
}
</style>
