<template>
  <div class="inline-block">
    <input
      id="team-member-input"
      class="form-control"
      type="text"
      :placeholder="$t('company_admin.teams.add_member_by_name')"
      autocomplete="off"
    />
    <uiv-typeahead
      v-model="user"
      force-select
      target="#team-member-input"
      item-key="name"
      :async-function="searchUsers"
      @update:model-value="onItemSelected"
    ></uiv-typeahead>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import adminUsersApi from "../api/admin-users-api";

export default defineComponent({
  props: {
    teamId: {
      type: Number,
      required: true,
    },
  },
  emits: ["add-member"],
  data() {
    return {
      user: null,
    };
  },
  computed: {
    companyId() {
      return window.zoinedContext.companyId;
    },
  },
  methods: {
    async searchUsers(search, done) {
      const users = (await adminUsersApi.get({ company_id: this.companyId, search })).map((user) => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name} (${user.email})`,
      }));
      done(users);
    },
    onItemSelected() {
      if (this.user) {
        this.$emit("add-member", this.user);
        this.user = null;
      }
    },
  },
});
</script>
