<template>
  <div class="btn-group btn-multiple flex-row">
    <button
      class="btn btn-primary btn-xs"
      :class="{ active: modelValue === 'column' }"
      @click="emit('update:modelValue', 'column')"
    >
      <i class="icon-zoined-vertical-chart"></i>
    </button>
    <button
      class="btn btn-primary btn-xs"
      :class="{ active: modelValue === 'bar' }"
      @click="emit('update:modelValue', 'bar')"
    >
      <i class="icon-zoined-horizontal-chart"></i>
    </button>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  modelValue: string;
}>();

const emit = defineEmits(["update:modelValue"]);
</script>
