<template>
  <div class="comparison-pill-list flex-row align-items-center flex-wrap gap-sm">
    <pill-list
      :title="title"
      :searchable="false"
      :items="items"
      :defaults="defaultItems"
      :pill-style="pillStyle"
      :draggable="true"
      :radio="radio"
      :togglable="togglable"
      :addable="false"
      @update="onItemsUpdated($event)"
    ></pill-list>
    <dropdown right>
      <template #toggle="toggleProps">
        <plus-button class="intercom-tag-filters-add-comparison" @click="toggleProps.toggleDropdown"></plus-button>
      </template>
      <template #menu>
        <dropdown-item v-for="item in menuItems" :key="item.key" @click="addItem(item)">{{ item.name }}</dropdown-item>
        <div v-if="isLimited" class="divider"></div>
        <more-with-pro-menu>
          <div v-if="proVersionMenuItems.length > 0" class="divider"></div>
          <dropdown-item v-for="item in proVersionMenuItems" :key="item.key" @click="addItem(item)">{{
            item.name
          }}</dropdown-item>
          <div v-if="custom" class="divider"></div>
          <dropdown-submenu v-if="custom" :title="$t('filters.date_selector.custom')">
            <dropdown-calendar
              mode="range"
              :max-date="new Date()"
              @select-custom-range="selectCustomRange"
            ></dropdown-calendar>
          </dropdown-submenu>
          <div v-if="customScenario" class="divider"></div>
          <dropdown-submenu v-if="customScenario" :title="$t('filters.custom_scenario.title')">
            <comparison-scenario-form
              :available-comparisons="availableComparisons"
              @add="addCustomScenario"
            ></comparison-scenario-form>
          </dropdown-submenu>
        </more-with-pro-menu>
      </template>
    </dropdown>
  </div>
</template>

<script lang="ts">
import pillList from "./pill-list.vue";
import { defineComponent, PropType } from "vue";
import moment from "moment";
import TranslationService from "../core/translation.service";
import i18n from "../i18n";
import { comparisonToMenuKey, menuKeyToComparison } from "../lib/menu-helper";
import _ from "lodash";
import dropdown from "./dropdown.vue";
import DropdownItem from "./dropdown-item.vue";
import DropdownSubmenu from "./dropdown-submenu.vue";
import DropdownCalendar from "./dropdown-calendar.vue";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import Comparison from "main/model/comparison";
import ComparisonScenarioForm from "./comparison-scenario-form.vue";
import MoreWithProMenu from "./more-with-pro-menu.vue";
import PlusButton from "@/components/buttons/plus-button.vue";

const translationService = new TranslationService();

export default defineComponent({
  components: {
    pillList,
    dropdown,
    DropdownItem,
    DropdownSubmenu,
    DropdownCalendar,
    ComparisonScenarioForm,
    MoreWithProMenu,
    PlusButton,
  },
  props: {
    title: { default: i18n.t("filters.compare_to"), type: String },
    comparisons: {
      type: Array as PropType<Comparison[]>,
      default: null,
    },
    choices: {
      type: Array as PropType<Comparison[]>,
      default: null,
    },
    defaults: {
      type: Array as PropType<Comparison[]>,
      default: null,
    },
    pillStyle: { default: "pill", type: String },
    radio: { default: false, type: Boolean },
    togglable: { default: true, type: Boolean },
    custom: { default: true, type: Boolean },
    customScenario: { default: true, type: Boolean },
  },
  emits: ["update"],
  data() {
    return {
      isLimited: window.zoinedContext.limited,
    };
  },
  computed: {
    availableComparisons(): Comparison[] {
      return this.choices || this.$store.getters.getParameters("comparisons");
    },
    items() {
      return (
        this.comparisons &&
        this.comparisons.map((comparison) => ({
          value: this.menuKey(comparison),
          name: translationService.comparisonTitle(comparison),
          enabled: !!comparison.enabled,
        }))
      );
    },
    defaultItems() {
      return (
        this.defaults &&
        this.defaults.map((comparison) => ({
          value: this.menuKey(comparison),
          name: translationService.comparisonTitle(comparison),
          enabled: comparison.enabled,
        }))
      );
    },
    menuItems() {
      if (!this.availableComparisons) {
        return [];
      } else {
        return this.availableComparisons
          .filter(
            (comparison) =>
              (!this.isLimited || comparison.enabled_for_limited) &&
              !_.some(this.items, (item) => item.value == this.menuKey(comparison))
          )
          .map((comparison) => ({
            key: this.menuKey(comparison),
            name: comparison.name,
          }));
      }
    },
    proVersionMenuItems() {
      if (!this.isLimited) {
        return [];
      }
      return (this.availableComparisons || [])
        .filter((comparison) => !comparison.enabled_for_limited)
        .map((comparison) => ({
          key: this.menuKey(comparison),
          name: comparison.name,
        }));
    },
  },
  methods: {
    menuKey(comparison) {
      return comparisonToMenuKey(comparison);
    },
    addCustomScenario(scenario: Comparison) {
      let comparisons = this.comparisons || [];
      if (this.radio) {
        comparisons = comparisons.map((c) => ({ ...c, enabled: false }));
      }

      comparisons = [...comparisons, scenario];

      this.$emit("update", comparisons);
    },
    addItem(item: MenuItem) {
      const comparison = menuKeyToComparison(menuItemKey(item), true);
      if (comparison) {
        this.$emit("update", [...(this.comparisons || []), comparison]);
      }
    },
    onItemsUpdated(event) {
      const { items } = event;
      const comparisons = items.map(({ value, enabled }) => {
        return menuKeyToComparison(value, enabled);
      });
      this.$emit("update", comparisons);
    },
    selectCustomRange([d1, d2]) {
      let start = moment(d1);
      let end = moment(d2);
      [start, end] = start.isAfter(end) ? [end, start] : [start, end];

      const comparison = {
        type: "custom",
        start_time: start.format("YYYY-MM-DD"),
        end_time: end.format("YYYY-MM-DD"),
        enabled: true,
      };
      if (!this.comparisonExists(comparison)) {
        this.$emit("update", [...(this.comparisons || []), comparison]);
      }
    },
    comparisonExists(comparison) {
      return _.some(this.comparisons, (c) => this.menuKey(c) == this.menuKey(comparison));
    },
  },
});
</script>

<style lang="scss" scoped>
.custom {
  border-top: 1px solid #ccc;
  padding: 14px;
  cursor: pointer;
}
.custom-scenario {
  border-top: 1px solid #ccc;
  padding: 14px;
  cursor: pointer;
}

.custom-calendar {
  margin-top: 10px;
}

.custom-scenario-form {
  margin-top: 15px;

  .input-group-btn:not(:last-child) {
    .btn {
      border-radius: 0;
      margin-left: -1px;
    }
  }

  .actions {
    margin-top: 10px;
  }
}
</style>
