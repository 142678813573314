<template>
  <choice-chips-selector>
    <choice-chip :selected="modelValue == '='" @select="select('=')">All</choice-chip>
    <choice-chip :selected="modelValue == '-'" @select="select('-')">
      <svg-icon class="fg-semantic-success" name="arrow-up-circle"></svg-icon><span>Positive</span>
    </choice-chip>
    <choice-chip :selected="modelValue == ''" @select="select('')">
      <svg-icon class="fg-semantic-error" name="arrow-down-circle"></svg-icon><span>Negative</span>
    </choice-chip>
  </choice-chips-selector>
</template>

<script setup lang="ts">
import ChoiceChipsSelector from "@/components/choice-chips-selector.vue";
import ChoiceChip from "@/components/choice-chip.vue";
import SvgIcon from "@/components/svg-icon.vue";

defineProps<{
  modelValue: string;
}>();

const emit = defineEmits(["update:modelValue"]);

const select = (value: string) => {
  emit("update:modelValue", value);
};
</script>
