<template>
  <uiv-modal id="email-modal" v-model="open" :title="$t('profile.change_email')">
    <div v-if="error" class="alert alert-danger" role="alert">{{ error }}</div>
    <spinner v-if="loading"></spinner>
    <div v-else class="row">
      <form class="form-horizontal">
        <div class="form-group">
          <label class="col-md-4 control-label" for="email">{{ $t("activerecord.attributes.user.email") }}</label>
          <div class="col-md-8">
            <input v-model="email" type="email" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="current_password">{{
            $t("activerecord.attributes.user.current_password")
          }}</label>
          <div class="col-md-8">
            <input v-model="current_password" type="password" />
          </div>
        </div>
      </form>
    </div>
    <template #footer>
      <button class="btn btn-default" type="button" @click="open = false">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" type="button" @click="save">{{ $t("actions.save") }}</button>
    </template>
  </uiv-modal>
</template>

<script>
import { changeEmail } from "../lib/data/profile";

import spinner from "../components/spinner.vue";

export default {
  components: {
    spinner,
  },
  emits: ["get-alert", "fetch-data"],
  data() {
    return {
      open: false,
      loading: false,
      current_password: "",
      email: "",
      error: null,
    };
  },
  mounted: function() {
    this.loading = true;
  },
  methods: {
    save: function() {
      const data = {
        email: this.email,
        current_password: this.current_password,
      };

      this.loading = true;

      changeEmail(data)
        .then((res) => {
          this.$emit("get-alert", res.success);
          this.$emit("fetch-data");
          this.open = false;
          this.email = "";
          this.current_password = "";
          this.error = null;
          this.loading = false;
        })
        .catch((error) => {
          this.error = error.message;
          this.email = "";
          this.current_password = "";
          this.loading = false;
        });
    },
    show: function() {
      this.loading = false;
      this.open = true;
    },
  },
};
</script>
