<template>
  <pill-list
    class="intercom-tag-filters-like-for-like"
    :title="title"
    :searchable="false"
    :items="items"
    :available-items="menuItems"
    :disabled="disabled"
    :excludable="true"
    @update="onUpdate"
  ></pill-list>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import pillList from "./pill-list.vue";
import i18n from "../i18n";
import FilterConfigurationItemMap, {
  filterConfigurationItemMapFromPillItemList,
} from "../model/filter-configuration-item-map";
import MenuItem from "../interfaces/menu-item";
import PillItem, { pillItemListFromFilterConfigurationItemMap } from "../interfaces/pill-item";

export default defineComponent({
  components: {
    pillList,
  },
  props: {
    config: {
      type: Object as PropType<FilterConfigurationItemMap>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: { default: i18n.t("filter.config.like_for_like"), type: String },
  },
  emits: ["update"],
  computed: {
    items(): PillItem[] {
      return pillItemListFromFilterConfigurationItemMap(this.config).map((item) => ({
        ...item,
        name: i18n.t("filters.like_for_like.day"),
      }));
    },
    menuItems(): MenuItem[] {
      return [{ key: "day", name: i18n.t("filters.like_for_like.day") }];
    },
  },
  methods: {
    onUpdate({ items }) {
      this.$emit("update", filterConfigurationItemMapFromPillItemList(items));
    },
  },
});
</script>
